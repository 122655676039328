import { z } from "zod";

export const customerFormSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
      invalid_type_error: "Email is required",
    })
    .email("Invalid email address")
    .min(1, "Email is required"),
  name: z.string().nullish(),
  phone: z.string().nullish(),
});

export type CustomerForm = z.infer<typeof customerFormSchema>;
