import { zodResolver } from "@hookform/resolvers/zod";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Button } from "@dashboard/common/ui/button";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { z } from "zod";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { X } from "lucide-react";
import { Label } from "@dashboard/common/ui/label";
import { Switch } from "@dashboard/common/ui/switch";
import { useImageUpload } from "./-hooks/useImageUpload";
import { Upload } from "lucide-react";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/",
)({
  component: NewProduct,
});

const newProductSchema = z.object({
  name: z.string().min(1, "Product name is required"),
  url: z
    .string()
    .url("Please enter a valid URL")
    .min(1, "Product URL is required"),
  collections: z.array(z.string().min(1, "Collection name cannot be empty")),
  price: z.object({
    currentPrice: z.coerce.number().nonnegative("Price cannot be negative"),
    regularPrice: z.coerce
      .number()
      .nonnegative("Regular price cannot be negative")
      .optional(),
    onSale: z.boolean(),
  }),
  longDescription: z
    .string()
    .optional()
    .transform((value) => (value === "" ? undefined : value)),
  imageUrl: z
    .string()
    .url("Please enter a valid image URL")
    .transform((value) => (value === "" ? undefined : value)),
  variants: z.array(
    z.object({
      title: z.string().min(1, "Variant title is required"),
      sku: z.string().min(1, "SKU is required"),
      price: z.object({
        currentPrice: z
          .number()
          .nonnegative("Price cannot be negative")
          .refine((n) => !isNaN(n), "Please enter a valid price"),
        regularPrice: z
          .number()
          .nonnegative("Regular price cannot be negative")
          .refine((n) => !isNaN(n), "Please enter a valid regular price")
          .optional(),
        onSale: z.boolean(),
      }),
      metafields: z.array(
        z.object({
          key: z.string().min(1, "Metafield key is required"),
          value: z.string().min(1, "Metafield value is required"),
        }),
      ),
      longDescription: z
        .string()
        .optional()
        .transform((value) => (value === "" ? undefined : value)),
      imageUrl: z
        .string()
        .transform((value) => (value === "" ? undefined : value))
        .pipe(
          z.string().url("Please enter a valid variant image URL").optional(),
        ),
    }),
  ),
  metafields: z.array(
    z.object({
      key: z.string().min(1, "Metafield key is required"),
      value: z.string().min(1, "Metafield value is required"),
    }),
  ),
});

export type NewProductProps = z.infer<typeof newProductSchema>;

function NewProduct() {
  const { organizationSlug, businessSlug } = Route.useParams();

  const defaultValues: NewProductProps = {
    name: "",
    url: "",
    collections: [],
    longDescription: "",
    imageUrl: "",
    price: {
      currentPrice: "" as unknown as number,
      onSale: false,
    },
    variants: [],
    metafields: [],
  };

  const form = useForm<NewProductProps>({
    resolver: zodResolver(newProductSchema),
    defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm();

  const { handleImageUpload, isUploading } = useImageUpload(
    form.setValue,
    form.getValues,
  );

  const variants = form.watch("variants");
  const hasVariants = variants.length > 0;

  return (
    <Form {...form}>
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <div className="relative py-0">
          <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Create a new product
            </h1>
            <div className="flex items-center gap-2">
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products"
                params={{ organizationSlug, businessSlug }}
              >
                <Button variant="secondary">Back to products</Button>
              </Link>

              <Button
                variant="publish"
                className="flex items-center"
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>

          <div className="flex gap-10 px-10 py-5">
            <div className="flex w-full max-w-2xl flex-col gap-5">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Product Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Give a unique and descriptive title to the product, e.g.
                      &quot;Blue Shoes&quot;, &quot;Red Sneakers&quot;, etc.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Product URL</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      The URL of the product on the source website.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="imageUrl"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Image</FormLabel>
                    <FormControl>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <Input {...field} placeholder="Enter image URL" />
                          <div className="relative">
                            <Input
                              type="file"
                              accept="image/*"
                              className="absolute inset-0 cursor-pointer opacity-0"
                              onChange={(e) => {
                                const file = e.target.files?.[0];
                                if (file) {
                                  void handleImageUpload(file, "imageUrl");
                                }
                              }}
                            />
                            <Button
                              type="button"
                              variant="secondary"
                              disabled={isUploading}
                            >
                              <Upload className="mr-2 h-4 w-4" />
                              {isUploading ? "Uploading..." : "Upload"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormControl>
                    <FormDescription>
                      Enter an image URL or upload an image file
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="longDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormDescription>
                      The description of the product.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <FormField
                    control={form.control}
                    name="price.onSale"
                    render={({ field }) => (
                      <FormItem className="flex items-center gap-2">
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={hasVariants}
                          />
                        </FormControl>
                        <FormLabel className="!mt-0">On Sale</FormLabel>
                      </FormItem>
                    )}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="price.currentPrice"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Current Price</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            value={field.value === 0 ? "" : field.value}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === "" || /^\d*\.?\d*$/.test(value)) {
                                field.onChange(value);
                              }
                            }}
                            disabled={hasVariants}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." &&
                                !field.value.toString().includes(".")
                              ) {
                                return;
                              }
                              const allowedKeys = [
                                "Backspace",
                                "Delete",
                                "Tab",
                                "Enter",
                                "ArrowLeft",
                                "ArrowRight",
                              ];
                              if (
                                !allowedKeys.includes(e.key) &&
                                !/\d/.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </FormControl>
                        <FormDescription>
                          The current selling price of this product
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="price.regularPrice"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel
                          className={
                            !form.watch("price.onSale") ? "text-gray-400" : ""
                          }
                        >
                          Regular Price
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            step="0.01"
                            min="0"
                            disabled={
                              !form.watch("price.onSale") || hasVariants
                            }
                            {...field}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value))
                            }
                          />
                        </FormControl>
                        <FormDescription>
                          Original price before discount (when on sale)
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <FormField
                control={form.control}
                name="collections"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Collections</FormLabel>
                    <FormControl>
                      <div className="flex flex-col gap-4">
                        {field.value.map((collection, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <Input
                              value={collection}
                              onChange={(e) => {
                                const newCollections = [...field.value];
                                newCollections[index] = e.target.value;
                                field.onChange(newCollections);
                              }}
                            />
                            <Button
                              type="button"
                              variant="ghost"
                              className="h-8 w-8 p-0"
                              onClick={() => {
                                const newCollections = [...field.value];
                                newCollections.splice(index, 1);
                                field.onChange(newCollections);
                              }}
                            >
                              <X className="h-4 w-4" />
                            </Button>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => {
                            field.onChange([...field.value, ""]);
                          }}
                        >
                          Add Collection
                        </Button>
                      </div>
                    </FormControl>
                    <FormDescription>
                      Add the collections this product belongs to.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="metafields"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Metafields</FormLabel>
                    <FormControl>
                      <div className="flex flex-col gap-4">
                        {field.value.map((metafield, index) => (
                          <div
                            key={index}
                            className="flex flex-col gap-2 rounded-md border p-4"
                          >
                            <div className="flex justify-between">
                              <h4 className="font-medium">
                                Metafield {index + 1}
                              </h4>
                              <Button
                                type="button"
                                variant="ghost"
                                className="h-8 w-8 p-0"
                                onClick={() => {
                                  const newMetafields = [...field.value];
                                  newMetafields.splice(index, 1);
                                  field.onChange(newMetafields);
                                }}
                              >
                                <X className="h-4 w-4" />
                              </Button>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <Label>Key</Label>
                                <Input
                                  value={metafield.key}
                                  onChange={(e) => {
                                    const newMetafields = [...field.value];
                                    newMetafields[index] = {
                                      ...newMetafields[index],
                                      key: e.target.value,
                                    };
                                    field.onChange(newMetafields);
                                  }}
                                />
                              </div>
                              <div>
                                <Label>Value</Label>
                                <Input
                                  value={metafield.value}
                                  onChange={(e) => {
                                    const newMetafields = [...field.value];
                                    newMetafields[index] = {
                                      ...newMetafields[index],
                                      value: e.target.value,
                                    };
                                    field.onChange(newMetafields);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => {
                            field.onChange([
                              ...field.value,
                              {
                                key: "",
                                value: "",
                              },
                            ]);
                          }}
                        >
                          Add Metafield
                        </Button>
                      </div>
                    </FormControl>
                    <FormDescription>
                      Add additional metadata for the product using key-value
                      pairs.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="variants"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Variants</FormLabel>
                    <FormControl>
                      <div className="flex flex-col gap-4">
                        {field.value.map((variant, index) => (
                          <div
                            key={index}
                            className="flex flex-col gap-2 rounded-md border p-4"
                          >
                            <div className="flex justify-between">
                              <h4 className="font-medium">
                                Variant {index + 1}
                              </h4>
                              <Button
                                type="button"
                                variant="ghost"
                                className="h-8 w-8 p-0"
                                onClick={() => {
                                  const newVariants = [...field.value];
                                  newVariants.splice(index, 1);
                                  field.onChange(newVariants);
                                }}
                              >
                                <X className="h-4 w-4" />
                              </Button>
                            </div>
                            <div className="grid gap-4">
                              <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-2">
                                  <Label>Title</Label>
                                  <Input
                                    value={variant.title || ""}
                                    onChange={(e) => {
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        title: e.target.value,
                                      };
                                      field.onChange(newVariants);
                                    }}
                                  />
                                  <p className="text-muted-foreground text-sm">
                                    The specific name or title for this variant
                                  </p>
                                  {form.formState.errors.variants?.[index]
                                    ?.title && (
                                    <p className="text-destructive text-sm">
                                      {
                                        form.formState.errors.variants[index]
                                          .title.message
                                      }
                                    </p>
                                  )}
                                </div>
                                <div className="space-y-2">
                                  <Label>SKU</Label>
                                  <Input
                                    value={variant.sku || ""}
                                    onChange={(e) => {
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        sku: e.target.value,
                                      };
                                      field.onChange(newVariants);
                                    }}
                                  />
                                  <p className="text-muted-foreground text-sm">
                                    Unique identifier for inventory tracking
                                  </p>
                                  {form.formState.errors.variants?.[index]
                                    ?.sku && (
                                    <p className="text-destructive text-sm">
                                      {
                                        form.formState.errors.variants[index]
                                          .sku.message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                <div className="flex items-center justify-start gap-4 space-y-0">
                                  <Switch
                                    checked={variant.price.onSale}
                                    onCheckedChange={(e) => {
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        price: {
                                          ...newVariants[index].price,
                                          onSale: e,
                                        },
                                      };
                                      field.onChange(newVariants);
                                    }}
                                  />
                                  <Label className="flex items-center whitespace-nowrap">
                                    On Sale
                                  </Label>
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                <p className="text-muted-foreground text-sm">
                                  Toggle whether this variant is currently on
                                  sale
                                </p>
                              </div>
                              <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-2">
                                  <Label>Current Price</Label>
                                  <Input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    value={variant.price.currentPrice || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        price: {
                                          ...newVariants[index].price,
                                          currentPrice:
                                            value === "" ? 0 : Number(value),
                                        },
                                      };
                                      field.onChange(newVariants);
                                    }}
                                    onInvalid={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  <p className="text-muted-foreground text-sm">
                                    The current selling price of this variant
                                  </p>
                                  {isNaN(variant.price.currentPrice) && (
                                    <p className="text-destructive text-sm">
                                      Please enter a valid number
                                    </p>
                                  )}
                                </div>
                                <div className="space-y-2">
                                  <Label
                                    className={
                                      !variant.price.onSale
                                        ? "text-gray-400"
                                        : ""
                                    }
                                  >
                                    Regular Price
                                  </Label>
                                  <Input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    disabled={!variant.price.onSale}
                                    placeholder="Regular Price"
                                    value={variant.price.regularPrice ?? ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        price: {
                                          ...newVariants[index].price,
                                          regularPrice:
                                            value === "" ? 0 : Number(value),
                                        },
                                      };
                                      field.onChange(newVariants);
                                    }}
                                    onInvalid={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  <p className="text-muted-foreground text-sm">
                                    Original price before discount (when on
                                    sale)
                                  </p>
                                  {variant.price.onSale &&
                                    variant.price.regularPrice === 0 && (
                                      <p className="text-destructive text-sm">
                                        Please enter a valid number
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="space-y-2">
                                <Label>Description</Label>
                                <Textarea
                                  value={variant.longDescription ?? ""}
                                  onChange={(e) => {
                                    const newVariants = [...field.value];
                                    newVariants[index] = {
                                      ...newVariants[index],
                                      longDescription: e.target.value,
                                    };
                                    field.onChange(newVariants);
                                  }}
                                />
                                <p className="text-muted-foreground text-sm">
                                  Detailed description specific to this variant
                                </p>
                              </div>
                              <div className="space-y-2">
                                <Label>Image</Label>
                                <div className="flex items-center gap-2">
                                  <Input
                                    value={variant.imageUrl ?? ""}
                                    onChange={(e) => {
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        imageUrl: e.target.value,
                                      };
                                      field.onChange(newVariants);
                                    }}
                                    placeholder="Enter image URL"
                                  />
                                  <div className="relative">
                                    <Input
                                      type="file"
                                      accept="image/*"
                                      className="absolute inset-0 cursor-pointer opacity-0"
                                      onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                          void handleImageUpload(
                                            file,
                                            `variants.${index}.imageUrl`,
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      type="button"
                                      variant="secondary"
                                      disabled={isUploading}
                                    >
                                      <Upload className="mr-2 h-4 w-4" />
                                      {isUploading ? "Uploading..." : "Upload"}
                                    </Button>
                                  </div>
                                </div>
                                <p className="text-muted-foreground text-sm">
                                  Enter an image URL or upload an image file
                                </p>
                                {form.formState.errors.variants?.[index]
                                  ?.imageUrl && (
                                  <p className="text-destructive text-sm">
                                    {
                                      form.formState.errors.variants[index]
                                        .imageUrl.message
                                    }
                                  </p>
                                )}
                              </div>
                              <div className="space-y-2">
                                <Label>Variant Metafields</Label>
                                <div className="flex flex-col gap-4">
                                  {variant.metafields.map(
                                    (metafield, metaIndex) => (
                                      <div
                                        key={metaIndex}
                                        className="flex flex-col gap-2 rounded-md border p-4"
                                      >
                                        <div className="flex justify-between">
                                          <h4 className="font-medium">
                                            Metafield {metaIndex + 1}
                                          </h4>
                                          <Button
                                            type="button"
                                            variant="ghost"
                                            className="h-8 w-8 p-0"
                                            onClick={() => {
                                              const newVariants = [
                                                ...field.value,
                                              ];
                                              const newMetafields = [
                                                ...newVariants[index]
                                                  .metafields,
                                              ];
                                              newMetafields.splice(
                                                metaIndex,
                                                1,
                                              );
                                              newVariants[index] = {
                                                ...newVariants[index],
                                                metafields: newMetafields,
                                              };
                                              field.onChange(newVariants);
                                            }}
                                          >
                                            <X className="h-4 w-4" />
                                          </Button>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4">
                                          <div className="space-y-2">
                                            <Label>Key</Label>
                                            <Input
                                              value={metafield.key}
                                              onChange={(e) => {
                                                const newVariants = [
                                                  ...field.value,
                                                ];
                                                const newMetafields = [
                                                  ...newVariants[index]
                                                    .metafields,
                                                ];
                                                newMetafields[metaIndex] = {
                                                  ...newMetafields[metaIndex],
                                                  key: e.target.value,
                                                };
                                                newVariants[index] = {
                                                  ...newVariants[index],
                                                  metafields: newMetafields,
                                                };
                                                field.onChange(newVariants);
                                              }}
                                            />
                                          </div>
                                          <div className="space-y-2">
                                            <Label>Value</Label>
                                            <Input
                                              value={metafield.value}
                                              onChange={(e) => {
                                                const newVariants = [
                                                  ...field.value,
                                                ];
                                                const newMetafields = [
                                                  ...newVariants[index]
                                                    .metafields,
                                                ];
                                                newMetafields[metaIndex] = {
                                                  ...newMetafields[metaIndex],
                                                  value: e.target.value,
                                                };
                                                newVariants[index] = {
                                                  ...newVariants[index],
                                                  metafields: newMetafields,
                                                };
                                                field.onChange(newVariants);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                  <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => {
                                      const newVariants = [...field.value];
                                      newVariants[index] = {
                                        ...newVariants[index],
                                        metafields: [
                                          ...newVariants[index].metafields,
                                          { key: "", value: "" },
                                        ],
                                      };
                                      field.onChange(newVariants);
                                    }}
                                  >
                                    Add Variant Metafield
                                  </Button>
                                </div>
                                <p className="text-muted-foreground text-sm">
                                  Additional custom attributes specific to this
                                  variant
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => {
                            field.onChange([
                              ...field.value,
                              {
                                sku: "",
                                title: "",
                                price: {
                                  currentPrice: 0,
                                  onSale: false,
                                },
                                longDescription: "",
                                imageUrl: "",
                                metafields: [],
                              },
                            ]);
                          }}
                        >
                          Add Variant
                        </Button>
                      </div>
                    </FormControl>
                    <FormDescription>
                      Add different variants of the product with their specific
                      details.
                    </FormDescription>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
