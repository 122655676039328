import { z } from "zod";

export const AthletesFootOrderSchema = z.object({
  additionalFeePrice: z.number(),
  billingAddress: z.coerce.string(),
  billingArea: z.string(),
  billingCity: z.coerce.string().optional(),
  billingCountry: z.string(),
  billingPostalCode: z.coerce.string().optional(),
  billingProvince: z.coerce.string().optional(),
  billingCompanyVatNumber: z.coerce.string().optional(),
  billingFirstName: z.string(),
  billingLastName: z.coerce.string().optional(),
  billingZipCode: z.number(),
  deliveryMethod: z.string(),
  email: z.string(),
  orderDate: z.string(),
  orderNumber: z.string(),
  storeOrderNumber: z.string().optional(),
  parcelLockerAddress: z.string().optional(),
  parcelLockerZipCode: z.string().optional(),
  paymentMethod: z.string(),
  phoneNumber: z.coerce.string(),
  productDiscountPrice: z.number(),
  productImage: z.string(),
  productName: z.coerce.string().optional(),
  productQty: z.number(),
  productRegularPrice: z.number(),
  productSku: z.coerce.string().optional(),
  productVat: z.number(),
  shippingAddress: z.coerce.string(),
  shippingArea: z.string().optional(),
  shippingCity: z.coerce.string(),
  shippingCountry: z.string(),
  shippingFirstName: z.string(),
  shippingLastName: z.string().optional(),
  shippingPrice: z.number(),
  shippingZipCode: z.number(),
  status: z.string(),
  totalPrice: z.string(),
  totalProductsPrice: z.string(),
  warehouseCode: z.number(),
  trackingNumber: z.coerce.string().optional(),
});

export type AthletesFootOrder = z.infer<typeof AthletesFootOrderSchema>;
