import { Route } from "../..";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { UserPen } from "lucide-react";

export function SendersFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const genericSenderOptions = [
    { label: "Bot", value: "bot" },
    { label: "Agent", value: "agent" },
    { label: "Customer", value: "customer" },
    { label: "External", value: "external" },
  ];

  return (
    <CommandMultiSelect
      filterType="senders"
      optionGroups={[
        {
          groupName: "Senders",
          options: genericSenderOptions,
        },
        {
          groupName: "Agents",
          options: users.map((user) => ({
            label: user.fullName,
            value: user.id,
          })),
        },
      ]}
      icon={UserPen}
    />
  );
}
