import { trpc } from "@chatbot/utils/trpc";
import { useEffect, useMemo } from "react";
import { createWSClient } from "@trpc/client";
import { wsLink } from "@trpc/client/links/wsLink";
import { env } from "@chatbot/env";
import { Event } from "@be/modules/channels/channels.types";
import { conversationStore, forceUpdateStore } from "./conversations.hooks";
import { incrementForceUpdate } from "./conversations.hooks";
import { useSnapshot } from "valtio";

export function useWebSocketSubscriptions({
  browserSessionId,
}: {
  browserSessionId: string | undefined;
}) {
  const trpcUtils = trpc.useUtils();

  useEffect(() => {
    if (!browserSessionId) return;

    const webSocketClient = createWSClient({
      url:
        env.CHATBOT_TRPC_WS_URL +
        `?browserSessionId=${browserSessionId}&commitHash=${env.CHATBOT_COMMIT_HASH}`,

      onOpen: () => {
        console.log("WebSocket connection opened");
      },

      onClose: () => {
        console.log("WebSocket connection closed");
      },
    });

    const trpcWebSocketClient = trpc.createClient({
      links: [wsLink({ client: webSocketClient })],
    });

    // Create subscriptions
    const messageSubscription = trpcWebSocketClient.webChat.onMessage.subscribe(
      { browserSessionId },
      {
        onData: () => {
          console.log(
            "[onMessage] Received message, invalidating and refetching conversation data",
            performance.now(),
          );
          void trpcUtils.webChat.getConversationData
            .invalidate()
            .then(async () => {
              console.log(
                "[onMessage] invalidate done, refetching",
                performance.now(),
              );
              await trpcUtils.webChat.getConversationData.refetch().then(() => {
                console.log("[onMessage] refetch done", performance.now());
                incrementForceUpdate();
              });
            });
        },
      },
    );

    const eventSubscription = trpcWebSocketClient.webChat.onEvent.subscribe(
      { browserSessionId },
      {
        onData: (event) => {
          console.log("[onEvent] Received event:", event);
          if (conversationStore.conversations?.isLoading) {
            console.log(
              "[onEvent] Skipping event - conversation is loading",
              performance.now(),
            );
            return;
          }
          void trpcUtils.webChat.getConversationData.invalidate().then(() => {
            void trpcUtils.webChat.getConversationData.refetch().then(() => {
              incrementForceUpdate();
            });
          });
          handleEvent(event);
        },
      },
    );

    // Cleanup function to unsubscribe when component unmounts or browserSessionId changes
    return () => {
      console.log(
        "[useWebSocketSubscriptions] Cleaning up WebSocket subscriptions",
      );
      messageSubscription.unsubscribe();
      eventSubscription.unsubscribe();
      webSocketClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSessionId]);
}

export function useUpdateConversationStore({
  browserSessionId,
  configId,
}: {
  browserSessionId: string;
  configId: string | undefined;
}) {
  const getConversationDataQuery = trpc.webChat.getConversationData.useQuery(
    {
      browserSessionId,
      url: window.location.href,
      configId: configId ?? "",
      testValue: `test-${configId ?? "unknown"}`,
    },
    {
      enabled: !!browserSessionId && !!configId,
      refetchInterval: 10000,
      staleTime: 0,
      networkMode: "always",
      refetchOnMount: true,
    },
  );

  const { count: forceUpdate } = useSnapshot(forceUpdateStore);

  useMemo(() => {
    console.log("useUpdateConversationStore memo", performance.now());
    if (!getConversationDataQuery.data) return;

    console.log(
      "[useUpdateConversationStore] Received conversation data:",
      getConversationDataQuery.data,
      performance.now(),
    );

    if (!browserSessionId) {
      throw new Error(
        "InvalidState: browserSessionId and initialBotMessage should be initialized",
      );
    }

    const { activeConversation, pastConversations, initialBotMessage, email } =
      getConversationDataQuery.data;

    const oldCurrent = conversationStore.conversations?.current;
    if (
      oldCurrent?.status === "active" &&
      oldCurrent.id === activeConversation?.id &&
      oldCurrent.messages.length === activeConversation.messages.length
    ) {
      console.log(
        "[useUpdateConversationStore] Skipping update - conversation is the same",
      );
      return;
    }

    const shouldHideConversations =
      new URL(window.location.href).searchParams.get("octocom-no-bill") !==
      null;

    console.log(
      "[useUpdateConversationStore] Updating conversation store",
      performance.now(),
    );

    conversationStore.conversations = {
      email,
      agreedToTermsAndConditions: activeConversation ? true : undefined,
      initialBotMessage,
      browserSessionId,
      current: activeConversation
        ? {
            status: "active",
            ...activeConversation,
          }
        : { status: "pending" },
      past: pastConversations,
      isLoading: false,
      isHandedOff: activeConversation?.isHandedOff ?? false,
      hide: shouldHideConversations,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConversationDataQuery.data, browserSessionId, forceUpdate]);
}

function handleEvent(eventType: Event): void {
  const { conversations } = conversationStore;

  console.log("Handling event: ", eventType);

  if (!conversations) {
    throw new Error("Conversations not initialized");
  }

  switch (eventType) {
    case "close":
      console.log(
        "[handleEvent] Received close event, will create new pending conversation",
      );
      setTimeout(() => {
        newPendingConversation();
      }, 1000);
      break;
    case "takeover":
      console.log(
        "[handleEvent] Received takeover event, setting isHandedOff to true",
      );
      conversations.isHandedOff = true;
      break;
    default:
      break;
  }
}

export function newPendingConversation() {
  const conversations = conversationStore.conversations;

  if (!conversations) {
    throw new Error("InvalidState: conversations should be initialized");
  }

  const { current, past } = conversations;

  console.log("[newPendingConversation] Creating new pending conversation");

  if (current.status === "active") {
    console.log(
      "[newPendingConversation] Moving current active conversation to past",
    );
    past.push(current);
  }

  conversations.isHandedOff = false;

  conversations.current = { status: "pending" };
  console.log("[newPendingConversation] New pending conversation created");
}
