import { Route } from "..";
import { toast } from "sonner";
import { FlowForm } from "../../-utils/flowFormSchema";

export function useSubmitForm() {
  const navigate = Route.useNavigate();
  const { organizationSlug, businessSlug, flowId } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  async function navigateToFlows() {
    await navigate({
      to: "/organization/$organizationSlug/settings/$businessSlug/custom-flows",
      params: { organizationSlug, businessSlug },
    });
  }

  const upsertMutation = trpc.dashboard.flows.upsert.useMutation({
    onSuccess: async () => {
      await Promise.all([
        trpcUtils.dashboard.flows.get.invalidate({
          businessSlug,
          id: flowId,
        }),
        trpcUtils.dashboard.flows.getAll.invalidate({ businessSlug }),
      ]);
      void navigateToFlows();
      toast.success("Successfully saved flow.");
    },
    onError: () => {
      toast.error("Failed to save flow. Please try again.");
    },
  });

  return {
    onSubmit: (data: FlowForm) => {
      upsertMutation.mutate({
        businessSlug,
        ...data,
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.error("Failed to save flow.");
    },
  };
}
