import { Button } from "@dashboard/common/ui/button";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@dashboard/common/ui/radio-group";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { useState, useEffect } from "react";
import type { LucideIcon } from "lucide-react";
import type { Filter, FilterValue } from "../../../-context/FiltersContext";
import { useFilters } from "../../../-context/FiltersContext";
import { FilterTrigger } from "./FilterTrigger";

export type CommandMultiSelectFilterTypes =
  | "assignees"
  | "languages"
  | "tags"
  | "topics"
  | "senders"
  | "taskNames"
  | "orderStatuses"
  | "orderShippingMethods"
  | "orderBillingCities"
  | "orderBillingStates"
  | "orderBillingCountries"
  | "orderRecipientCities"
  | "orderRecipientStates"
  | "orderRecipientCountries"
  | "orderFulfillmentStatuses"
  | "orderCouriers";

const FILTER_METADATA: Record<
  CommandMultiSelectFilterTypes,
  { objectName: string; placeholder: string }
> = {
  assignees: { objectName: "Assignee", placeholder: "Select Assignees" },
  languages: { objectName: "Language", placeholder: "Select Languages" },
  tags: { objectName: "Tag", placeholder: "Select Tags" },
  topics: { objectName: "Topic", placeholder: "Select Topics" },
  senders: { objectName: "Sender", placeholder: "Select Senders" },
  taskNames: { objectName: "Task Name", placeholder: "Select Task Names" },
  orderStatuses: {
    objectName: "Order Statuse",
    placeholder: "Select Order Statuses",
  },
  orderShippingMethods: {
    objectName: "Shipping Method",
    placeholder: "Select Shipping Methods",
  },
  orderBillingCities: {
    objectName: "Billing City",
    placeholder: "Select Billing Cities",
  },
  orderBillingStates: {
    objectName: "Billing State",
    placeholder: "Select Billing States",
  },
  orderBillingCountries: {
    objectName: "Billing Country",
    placeholder: "Select Billing Countries",
  },
  orderRecipientCities: {
    objectName: "Recipient City",
    placeholder: "Select Recipient Cities",
  },
  orderRecipientStates: {
    objectName: "Recipient State",
    placeholder: "Select Recipient States",
  },
  orderRecipientCountries: {
    objectName: "Recipient Country",
    placeholder: "Select Recipient Countries",
  },
  orderFulfillmentStatuses: {
    objectName: "Fulfillment Status",
    placeholder: "Select Fulfillment Statuses",
  },
  orderCouriers: {
    objectName: "Courier",
    placeholder: "Select Couriers",
  },
};

interface Option {
  label: string;
  value: string;
}

interface OptionGroup {
  groupName?: string;
  options: Option[];
}

interface CommandMultiSelectProps {
  filterType: CommandMultiSelectFilterTypes;
  optionGroups: OptionGroup[];
  exclusiveOptionGroup?: OptionGroup;
  maxHeight?: number;
  icon?: LucideIcon;
}

export function CommandMultiSelect({
  filterType,
  optionGroups,
  exclusiveOptionGroup,
  maxHeight = 300,
  icon: Icon,
}: CommandMultiSelectProps) {
  const [open, setOpen] = useState(false);
  const {
    filters,
    addFilter,
    updateFilter,
    removeFilter,
    lastAddedFilter,
    setLastAddedFilter,
  } = useFilters();

  const metadata = FILTER_METADATA[filterType];
  const { objectName, placeholder: defaultPlaceholder } = metadata;
  const searchPlaceholder = `Search ${objectName.toLowerCase()}...`;

  const filter = filters.find((f) => f.type === filterType) as
    | Filter<CommandMultiSelectFilterTypes>
    | undefined;

  const [selectedValue, setSelectedValue] = useState<
    FilterValue<CommandMultiSelectFilterTypes>
  >(filter?.value ?? []);

  useEffect(() => {
    if (lastAddedFilter === filterType) {
      setOpen(true);
      setLastAddedFilter(null);
    }
  }, [lastAddedFilter, filterType, setLastAddedFilter]);

  if (!filter) {
    addFilter({ type: filterType });
    return null;
  }

  // Combine all options from all option groups
  const allOptions = optionGroups.flatMap((group) => group.options);
  const exclusiveOptions = exclusiveOptionGroup?.options;

  const handleExclusiveSelect = (
    value: FilterValue<CommandMultiSelectFilterTypes>,
  ) => {
    setSelectedValue(value);
  };

  const handleSelect = (value: string) => {
    setSelectedValue((prev) => {
      if (Array.isArray(prev)) {
        if (prev.includes(value)) {
          return prev.filter((v) => v !== value);
        }
        return [...prev, value];
      }
      return [value];
    });
  };

  const handleConfirm = () => {
    if (!selectedValue || selectedValue.length === 0) {
      updateFilter({ type: filterType, value: undefined, active: false });
    } else {
      updateFilter({ type: filterType, value: selectedValue, active: true });
    }
    setOpen(false);
  };

  const handleSelectAll = () => {
    const allSelected =
      Array.isArray(selectedValue) &&
      selectedValue.length === allOptions.length;

    if (allSelected) {
      setSelectedValue([]);
    } else {
      // Select all regular options
      const allValues = allOptions.map((opt) => opt.value);
      setSelectedValue(allValues);
    }
  };

  const getSelectAllButtonText = () => {
    const allSelected =
      Array.isArray(selectedValue) &&
      selectedValue.length === allOptions.length;

    return allSelected ? "Deselect All" : "Select All";
  };

  const getSelectionLabel = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return {
        prefix: defaultPlaceholder,
        value: undefined,
      };
    }

    if (Array.isArray(selectedValue)) {
      const selectedLabels = selectedValue
        .map((value) => allOptions.find((opt) => opt.value === value)?.label)
        .filter(Boolean);

      return {
        prefix: `${objectName} is`,
        value: selectedLabels.join(", "),
      };
    }

    const label = exclusiveOptions?.find(
      (opt) => opt.value === selectedValue,
    )?.label;

    return {
      prefix: `${objectName} is`,
      value: label,
    };
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      handleConfirm();
    }
    setOpen(isOpen);
  };

  const { prefix, value } = getSelectionLabel();

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger>
        <FilterTrigger
          isOpen={open}
          isActive={filter.isActive}
          onRemove={() => {
            removeFilter({ type: filterType });
            setOpen(false);
          }}
          icon={Icon}
          labelPrefix={prefix}
          labelValue={value}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-[240px] p-0" align="start">
        {allOptions.length > 0 && (
          <div className="border-b p-2">
            <Button
              variant="secondary"
              onClick={handleSelectAll}
              className="w-full"
            >
              {getSelectAllButtonText()}
            </Button>
          </div>
        )}
        <Command className="max-h-[300px]">
          <CommandInput placeholder={searchPlaceholder} />
          <CommandList className={`max-h-[${maxHeight}px]`}>
            <CommandEmpty>No {objectName.toLowerCase()} found.</CommandEmpty>

            {/* Exclusive options (radio buttons) */}
            {exclusiveOptionGroup &&
              exclusiveOptions &&
              exclusiveOptions.length > 0 && (
                <CommandGroup heading={exclusiveOptionGroup.groupName}>
                  <RadioGroup
                    value={Array.isArray(selectedValue) ? "" : selectedValue}
                    className="flex flex-col gap-0"
                  >
                    {exclusiveOptions.map((option) => {
                      const isSelected = selectedValue === option.value;
                      return (
                        <CommandItem
                          key={option.value}
                          onSelect={() =>
                            handleExclusiveSelect(
                              option.value as FilterValue<CommandMultiSelectFilterTypes>,
                            )
                          }
                        >
                          <div className="flex items-center gap-2">
                            <RadioGroupItem
                              value={option.value}
                              id={`radio-${option.value}`}
                              className="mr-2"
                              checked={isSelected}
                            />
                            <span>{option.label}</span>
                          </div>
                        </CommandItem>
                      );
                    })}
                  </RadioGroup>
                </CommandGroup>
              )}

            {/* Regular multi-select options */}
            {optionGroups.map(
              (group) =>
                group.options.length > 0 && (
                  <CommandGroup
                    key={`group-${group.groupName ?? "default"}`}
                    heading={group.groupName}
                  >
                    {group.options.map((option) => (
                      <CommandItem
                        key={option.value}
                        onSelect={() => handleSelect(option.value)}
                        className="flex items-center gap-2"
                      >
                        <Checkbox
                          checked={
                            Array.isArray(selectedValue) &&
                            selectedValue.includes(option.value)
                          }
                          className="mr-2"
                        />
                        <span>{option.label}</span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                ),
            )}
          </CommandList>
        </Command>
        <div className="border-t p-2">
          <Button onClick={handleConfirm} className="w-full">
            Confirm
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
