import { Tooltip } from "@dashboard/common/ui/tooltip";
import { format } from "date-fns";
import { z } from "zod";

export function Event({
  event,
  createdAt,
  metadata,
}: {
  event: string;
  createdAt: Date | string;
  metadata?: unknown;
}) {
  let content = null;

  const date = format(new Date(createdAt), "MMM d, yyyy");
  const time = format(new Date(createdAt), "HH:mm");

  switch (event) {
    case "webChatTimeout": {
      content = `Live chat timed out, conversation moved to email on ${date} at ${time}`;
      break;
    }
    case "customerLeftWebChat": {
      content = `The customer left the chat, conversation moved to email on ${date} at ${time}`;
      break;
    }
    case "reopen": {
      const opener = z.string().optional().nullable().parse(metadata);

      content = `Conversation was reopend ${
        opener ? `by ${opener}` : "automatically"
      } on ${date} at ${time}`;
      break;
    }
    case "close": {
      const reason = z.string().optional().nullable().parse(metadata);

      content = `Conversation was closed ${
        reason ? `by ${reason}` : "automatically"
      } on ${date} at ${time}`;
      break;
    }
    case "handOff": {
      const handoffer = z.string().optional().nullable().parse(metadata);

      content = `Conversation was handed off ${
        handoffer ? `by ${handoffer}` : "automatically"
      } on ${date} at ${time}`;
      break;
    }
    case "snooze": {
      content = `Conversation was snoozed on ${date} at ${time}`;
      break;
    }
    case "unsnooze": {
      content = `Conversation was unsnoozed on ${date} at ${time}`;
      break;
    }
    case "threatDetected": {
      const threatSlug = z.string().parse(metadata);
      content = `Threat "${threatSlug}" detected on ${date} at ${time}`;
      break;
    }
    case "raw": {
      const note = z.string().parse(metadata);
      content = note;
      break;
    }
    case "merge": {
      const { mergedBy, sourceId } = z
        .object({ mergedBy: z.string(), sourceId: z.string() })
        .parse(metadata);

      content = `Conversation was merged with ${sourceId} by ${mergedBy} on ${date} at ${time}`;
      break;
    }
    case "orderMarkedAsUrgent": {
      const { orderId } = z
        .object({ orderId: z.string().nullish() })
        .parse(metadata);

      content = "Order";
      if (orderId) {
        content += ` ${orderId}`;
      }
      content += ` marked as urgent on ${date} at ${time}`;

      break;
    }
    case "orderContactDetailsUpdated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} contact details updated on ${date} at ${time}`;
      break;
    }
    case "orderShippingAddressUpdated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} shipping address updated on ${date} at ${time}`;
      break;
    }
    case "orderCancelled": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} cancelled on ${date} at ${time}`;
      break;
    }
    case "orderItemsUpdated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} items updated on ${date} at ${time}`;
      break;
    }
    case "orderContactDetailsUpdateLinkGenerated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} contact details update link generated on ${date} at ${time}`;
      break;
    }
    case "orderShippingAddressUpdateLinkGenerated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} shipping address update link generated on ${date} at ${time}`;
      break;
    }
    case "orderItemsUpdateLinkGenerated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} items update link generated on ${date} at ${time}`;
      break;
    }
    case "orderPlacedOnHold": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} placed on hold on ${date} at ${time}`;
      break;
    }
    case "orderCancelationLinkGenerated": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} cancelation link generated on ${date} at ${time}`;
      break;
    }
    default: {
      break;
    }
  }

  if (!content) {
    return null;
  }

  const trigger = (
    <span className="rounded-lg px-3 text-center text-xs italic text-gray-500">
      {content}
    </span>
  );

  const formattedDate = format(new Date(createdAt), "PPp");

  return (
    <div className="flex w-full items-center justify-center px-10 py-2">
      <Tooltip
        trigger={trigger}
        content={formattedDate}
        side="right"
        delayDuration={700}
      />
    </div>
  );
}
