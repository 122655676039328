import { createFileRoute } from "@tanstack/react-router";
import { Topbar } from "./-components/Topbar";
import { z } from "zod";
import { periodToRange } from "./-utils/periodToRange";
import { filtersSchema } from "./-utils/filtersSchema";
import { FiltersProvider } from "./-context/FiltersContext";
import { Card } from "./-components/Card/Card";
import { BarChart } from "./-components/BarChart";
import { LineChart } from "./-components/LineChart";
import { HorizontalBarChart } from "./-components/HorizontalBarChart";
import { StatsCard } from "./-components/StatsCard";
import { useAvgReplyTime } from "./-hooks/useAvgReplyTime";
import { useAvgTimeToClose } from "./-hooks/useAvgTimeToClose";
import { useAvgMessagesPerChat } from "./-hooks/useAvgMessagesPerChat";
import { useSalesData } from "./-hooks/useSalesData";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@dashboard/common/ui/tabs";
import { useBanner } from "../../-hooks/useBanner";
import { ChartPie } from "./-components/ChartPie";
import { Breakdown } from "./-components/Breakdown";
import { useCustomerMessagesPerChat } from "./-hooks/useCustomerMessagesPerChat";
import { useHandoffTopicsData } from "./-hooks/useHandoffTopicsData";
import { useTBYSHandoffData } from "./-hooks/useTBYSHandoffData";
import { ChartSwitcher } from "./-components/ChartSwitcher";
import { useConversationsByBusiness } from "./-hooks/useConversationsByBusiness";
import { CHART_COLORS, getSeriesColor } from "./-utils/colors";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/metrics/",
)({
  validateSearch: z.object({
    filters: filtersSchema,
  }),
  loaderDeps: ({ search: { filters } }) => ({ filters }),
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
    deps: { filters },
  }) => {
    const range = periodToRange(filters.period);

    await Promise.all([
      trpcClientUtils.dashboard.metrics.getConversationsCounts.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getMessagesCounts.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getTopicsCounts.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
      }),
      trpcClientUtils.dashboard.conversations.getLanguages.ensureData({
        organizationSlug,
      }),
      trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
        organizationSlug,
      }),
      trpcClientUtils.dashboard.v2Bot.getTaskNames.ensureData({
        organizationSlug,
      }),
      trpcClientUtils.dashboard.conversations.tags.getOrganizationTags.ensureData(
        {
          organizationSlug,
        },
      ),
      trpcClientUtils.dashboard.conversations.messageTopics.getOrgTopics.ensureData(
        {
          organizationSlug,
          includeDeleted: true,
        },
      ),
      trpcClientUtils.dashboard.conversations.orderMetadata.getOrgFieldValues.ensureData(
        {
          organizationSlug,
        },
      ),
      trpcClientUtils.dashboard.metrics.getAvgAgentReplyTime.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getAvgAgentTimeToClose.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getConversationsCountsByChannel.ensureData(
        {
          organizationSlug,
          filters: {
            ...filters,
            from: range.from,
            to: range.to,
            totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
            totalOrderAmountTo: filters.totalOrderAmountRange?.to,
          },
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      ),
      trpcClientUtils.dashboard.metrics.getCountryData.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
      }),
      trpcClientUtils.dashboard.metrics.getBotActionsCounts.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
      }),
      trpcClientUtils.dashboard.metrics.getConversationsByBusiness.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getHandoffTopicsCounts.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getTBYSHandoffData.ensureData({
        organizationSlug,
        filters: {
          ...filters,
          from: range.from,
          to: range.to,
          totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
          totalOrderAmountTo: filters.totalOrderAmountRange?.to,
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    ]);
  },

  component: MetricsPage,
});

function MetricsPage() {
  const { trpc, hasExternalTicketConfig, organization, businesses } =
    Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { filters } = Route.useSearch();
  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();

  const range = periodToRange(filters.period);

  console.log("Range:", range);
  console.log("Timezone:", Intl.DateTimeFormat().resolvedOptions().timeZone);

  const [conversationsCounts] =
    trpc.dashboard.metrics.getConversationsCounts.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  const [messagesCounts] =
    trpc.dashboard.metrics.getMessagesCounts.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  const [topicsCounts] =
    trpc.dashboard.metrics.getTopicsCounts.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
    });

  const [botActionsCounts] =
    trpc.dashboard.metrics.getBotActionsCounts.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
    });

  const [channelCounts] =
    trpc.dashboard.metrics.getConversationsCountsByChannel.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  const [countryData] = trpc.dashboard.metrics.getCountryData.useSuspenseQuery({
    organizationSlug,
    filters: {
      ...filters,
      from: range.from,
      to: range.to,
      totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
      totalOrderAmountTo: filters.totalOrderAmountRange?.to,
    },
  });

  console.log("Conversations Counts Data:", conversationsCounts);
  console.log("Messages Counts Data:", messagesCounts);
  console.log("Topics Counts Data:", topicsCounts);
  console.log("Bot Actions Counts Data:", botActionsCounts);
  console.log("Channel Counts Data:", channelCounts);
  console.log("Country Data:", countryData);

  const avgReplyTimeMetrics = useAvgReplyTime();
  const avgTimeToCloseMetrics = useAvgTimeToClose();
  const avgMessagesPerChat = useAvgMessagesPerChat({
    messagesCounts,
    conversationsCounts,
  });

  const salesMetrics = useSalesData();

  const customerMessagesPerChat = useCustomerMessagesPerChat({
    customerMessagesCounts: messagesCounts.customerCount,
    conversationsCounts: conversationsCounts.totalCount,
  });

  const conversationsByBusiness = useConversationsByBusiness();
  const handoffTopicsData = useHandoffTopicsData();
  const tbysHandoffData = useTBYSHandoffData();

  console.log("Average Reply Time Metrics:", avgReplyTimeMetrics);
  console.log("Average Time to Close Metrics:", avgTimeToCloseMetrics);
  console.log("Average Messages Per Chat:", avgMessagesPerChat);
  console.log("Sales Metrics:", salesMetrics);
  console.log("Customer Messages Per Chat:", customerMessagesPerChat);
  console.log("Conversations By Business:", conversationsByBusiness);
  console.log("Handoff Topics Data:", handoffTopicsData);
  console.log("TBYS Handoff Data:", tbysHandoffData);

  const usingMockMetrics = organization.enableMockMetricsData;

  const multipleBusinessesAvailable = businesses.length > 1;

  return (
    <FiltersProvider>
      <Topbar />
      <div className="flex h-full w-full flex-col">
        <Tabs defaultValue="ai" className="flex w-full flex-1 flex-col">
          {!hasExternalTicketConfig && !usingMockMetrics && (
            <>
              <TabsList
                variant="underline"
                className="border-b-gray-light fixed z-10 h-11 w-full shrink-0 justify-start gap-4 border-b bg-white px-10"
                style={{
                  top: bannerVisible
                    ? `${120 + parseInt(bannerHeight)}px`
                    : "120px",
                }}
              >
                <TabsTrigger variant="underline" value="ai">
                  AI
                </TabsTrigger>
                <TabsTrigger variant="underline" value="agent-productivity">
                  Agent Productivity
                </TabsTrigger>
              </TabsList>

              {/* Add spacing for the fixed TabsList */}
              <div className="h-11" aria-hidden="true" />
            </>
          )}

          <TabsContent
            value="ai"
            className="mx-auto mt-0 w-full max-w-7xl px-10 pb-10"
          >
            {/* Stats Cards */}
            <div className="mt-10 flex max-w-7xl flex-row gap-6">
              {/* Average Conversations per Period */}
              <Card title="Average Conversations per day">
                <StatsCard
                  value={conversationsCounts.averageCount.toFixed(2)}
                />
              </Card>

              {/* Average Messages per Chat */}
              <Card title="Average Messages per Conversation">
                <StatsCard value={avgMessagesPerChat.toFixed(2)} />
              </Card>

              {usingMockMetrics && (
                <Card title="Average AI Generated Revenue per day">
                  <StatsCard value={`$${salesMetrics.average.toFixed(2)}`} />
                </Card>
              )}
            </div>

            {/* Daily Handoff Rate */}
            <Card
              className="mt-10"
              title="Handoff Rate"
              tooltipText="The chart displays the percentage of conversations that were handed off to a human agent on each day within the selected period."
            >
              <LineChart
                value={
                  conversationsCounts.handoffRate.length > 0
                    ? `${
                        Math.round(
                          (conversationsCounts.handoffRate.reduce(
                            (acc, curr) => acc + Number(curr.value),
                            0,
                          ) /
                            conversationsCounts.handoffRate.length) *
                            100,
                        ) / 100
                      }`
                    : "0.00"
                }
                series={[
                  {
                    name: "Handoff Rate",
                    data: conversationsCounts.handoffRate.map(
                      ({ datetime, value }) => ({
                        x: datetime,
                        y: value,
                      }),
                    ),
                    style: { color: CHART_COLORS.BLUE },
                  },
                ]}
                yTitle="%"
                yDomain={[0, 100]}
              />
            </Card>

            {/* Revenue Chart */}
            {usingMockMetrics && (
              <Card
                className="mt-10"
                title="AI Generated Revenue"
                tooltipText="The chart displays the total revenue generated by AI on each day within the selected period."
              >
                <LineChart
                  series={[
                    {
                      name: "AI Generated Revenue",
                      data: salesMetrics.perDay,
                      style: { color: CHART_COLORS.GREEN },
                    },
                  ]}
                  value={`${salesMetrics.total.toLocaleString()}`}
                  yTitle="USD"
                />
              </Card>
            )}

            {/* Conversations */}
            <Card
              className="mt-10"
              title="Conversations"
              tooltipText="The chart shows the distribution of conversations across different businesses over time."
            >
              <BarChart
                value={conversationsByBusiness.reduce(
                  (total, day) =>
                    total +
                    day.businesses.reduce(
                      (sum, business) => sum + Number(business.count),
                      0,
                    ),
                  0,
                )}
                series={conversationsByBusiness[0].businesses.map(
                  (business, index) => ({
                    name: business.name,
                    data: conversationsByBusiness.map((day) => ({
                      x: day.datetime,
                      y:
                        day.businesses.find((b) =>
                          usingMockMetrics
                            ? b.name === business.name
                            : b.id === business.id,
                        )?.count ?? 0,
                    })),
                    style: {
                      color: getSeriesColor(index),
                      barSize: 24,
                    },
                  }),
                )}
                yTitle="Conversations"
                stacked={usingMockMetrics || multipleBusinessesAvailable}
                showLegend={usingMockMetrics || multipleBusinessesAvailable}
              />
            </Card>

            {/* Business Distribution */}
            {(usingMockMetrics || multipleBusinessesAvailable) && (
              <Card
                className="mt-10"
                title="Business Distribution"
                tooltipText="The charts show the distribution of conversations across different businesses."
              >
                <div className="flex flex-row items-center gap-x-16">
                  <ChartPie
                    data={conversationsByBusiness[0].businesses.map(
                      (business, index) => ({
                        x: business.name,
                        y: conversationsByBusiness.reduce(
                          (total, day) =>
                            total +
                            (day.businesses.find(
                              (b) => b.name === business.name,
                            )?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }),
                    )}
                  />
                  <Breakdown
                    title="Breakdown by business"
                    data={conversationsByBusiness[0].businesses.map(
                      (business, index) => {
                        const totalCount = conversationsByBusiness.reduce(
                          (total, day) =>
                            total +
                            (day.businesses.find(
                              (b) => b.name === business.name,
                            )?.count ?? 0),
                          0,
                        );
                        const totalAllBusinesses =
                          conversationsByBusiness.reduce(
                            (total, day) =>
                              total +
                              day.businesses.reduce(
                                (sum, b) => sum + b.count,
                                0,
                              ),
                            0,
                          );
                        return {
                          name: business.name,
                          percentage: Number(
                            ((totalCount / totalAllBusinesses) * 100).toFixed(
                              2,
                            ),
                          ),
                          tooltipContent: `This indicates the percentage of conversations from ${business.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      },
                    )}
                  />
                </div>
              </Card>
            )}

            {/* Topics */}
            {topicsCounts.length > 0 && (
              <Card
                className="mt-10"
                title="Topics"
                tooltipText="The chart shows the number of messages that were assigned to each topic."
              >
                <HorizontalBarChart
                  series={{
                    data: topicsCounts.map(({ topic, count }, index) => ({
                      x: topic,
                      y: count,
                      color: getSeriesColor(index),
                    })),
                    style: { barSize: 24 },
                  }}
                />
              </Card>
            )}

            {/* Daily Handoff Topics Distribution */}
            {handoffTopicsData.length > 0 &&
              handoffTopicsData[0]?.topics?.length > 0 && (
                <Card
                  className="mt-10"
                  title="Daily Handoff Topics Distribution"
                  tooltipText="The chart shows the distribution of handoff topics across different days within the selected period."
                >
                  <BarChart
                    value={handoffTopicsData.reduce(
                      (sum, series) =>
                        sum +
                        series.topics.reduce(
                          (daySum, topic) => daySum + topic.count,
                          0,
                        ),
                      0,
                    )}
                    series={handoffTopicsData[0].topics.map(
                      (topicData, index) => ({
                        name: topicData.name,
                        data: handoffTopicsData.map((series) => ({
                          x: series.datetime,
                          y:
                            series.topics.find((t) => t.name === topicData.name)
                              ?.count ?? 0,
                        })),
                        style: {
                          color: getSeriesColor(index),
                          barSize: 24,
                        },
                      }),
                    )}
                    yTitle="Handoffs"
                    stacked={true}
                    showLegend={true}
                  />
                </Card>
              )}

            {/* Handoff Topics Distribution */}
            {handoffTopicsData.length > 0 &&
              handoffTopicsData[0]?.topics?.length > 0 && (
                <Card
                  className="mt-10"
                  title="Handoff Topics Distribution"
                  tooltipText="The charts show the distribution of handoff topics during the selected period."
                >
                  <div className="flex flex-row items-center gap-x-16">
                    <ChartPie
                      data={handoffTopicsData[0].topics.map((topic, index) => ({
                        x: topic.name,
                        y: handoffTopicsData.reduce(
                          (sum, day) =>
                            sum +
                            (day.topics.find((t) => t.name === topic.name)
                              ?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }))}
                    />
                    <Breakdown
                      title="Breakdown by handoff topic"
                      data={handoffTopicsData[0].topics.map((topic, index) => {
                        const totalCount = handoffTopicsData.reduce(
                          (sum, day) =>
                            sum +
                            (day.topics.find((t) => t.name === topic.name)
                              ?.count ?? 0),
                          0,
                        );
                        const totalAllTopics = handoffTopicsData.reduce(
                          (sum, day) =>
                            sum + day.topics.reduce((s, t) => s + t.count, 0),
                          0,
                        );
                        return {
                          name: topic.name,
                          percentage: Number(
                            ((totalCount / totalAllTopics) * 100).toFixed(2),
                          ),
                          tooltipContent: `This indicates the percentage of handoffs for ${topic.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      })}
                    />
                  </div>
                </Card>
              )}

            {/* TBYS Handoff Reasons */}
            {tbysHandoffData.handoffReasons.length > 0 &&
              tbysHandoffData.handoffReasons[0]?.reasons?.length > 0 && (
                <Card
                  className="mt-10"
                  title="Handoff Reasons"
                  tooltipText="The chart shows the distribution of handoff reasons (Manual, Bot Action, Instant Handoff, Action Execution Failed) over time. Toggle between bar chart and distribution views."
                >
                  <ChartSwitcher
                    barChartValue={tbysHandoffData.handoffReasons.reduce(
                      (sum, day) =>
                        sum + day.reasons.reduce((s, r) => s + r.count, 0),
                      0,
                    )}
                    barChartSeries={tbysHandoffData.handoffReasons[0].reasons.map(
                      (reason, index) => ({
                        name: reason.name,
                        data: tbysHandoffData.handoffReasons.map((day) => ({
                          x: day.datetime,
                          y:
                            day.reasons.find((r) => r.name === reason.name)
                              ?.count ?? 0,
                        })),
                        style: {
                          color: getSeriesColor(index),
                          barSize: 24,
                        },
                      }),
                    )}
                    barChartYTitle="Handoffs"
                    barChartStacked={true}
                    barChartShowLegend={true}
                    pieChartData={tbysHandoffData.handoffReasons[0].reasons.map(
                      (reason, index) => ({
                        x: reason.name,
                        y: tbysHandoffData.handoffReasons.reduce(
                          (sum, day) =>
                            sum +
                            (day.reasons.find((r) => r.name === reason.name)
                              ?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }),
                    )}
                    breakdownTitle="Breakdown by handoff reason"
                    breakdownData={tbysHandoffData.handoffReasons[0].reasons.map(
                      (reason, index) => {
                        const totalCount =
                          tbysHandoffData.handoffReasons.reduce(
                            (sum, day) =>
                              sum +
                              (day.reasons.find((r) => r.name === reason.name)
                                ?.count ?? 0),
                            0,
                          );
                        const totalAllReasons =
                          tbysHandoffData.handoffReasons.reduce(
                            (sum, day) =>
                              sum +
                              day.reasons.reduce((s, r) => s + r.count, 0),
                            0,
                          );
                        return {
                          name: reason.name,
                          percentage: Number(
                            ((totalCount / totalAllReasons) * 100).toFixed(2),
                          ),
                          tooltipContent: `This indicates the percentage of handoffs with reason: ${reason.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      },
                    )}
                    defaultChartType="bar"
                  />
                </Card>
              )}

            {/* Manual Handoff Tasks */}
            {tbysHandoffData.handoffTasks.manual.length > 0 &&
              tbysHandoffData.handoffTasks.manual[0]?.tasks?.length > 0 && (
                <Card
                  className="mt-10"
                  title="Manual Handoff Tasks"
                  tooltipText="The chart shows the distribution of tasks that led to manual handoffs. Toggle between bar chart and distribution views."
                >
                  <ChartSwitcher
                    barChartValue={tbysHandoffData.handoffTasks.manual.reduce(
                      (sum, day) =>
                        sum + day.tasks.reduce((s, t) => s + t.count, 0),
                      0,
                    )}
                    barChartSeries={tbysHandoffData.handoffTasks.manual[0].tasks.map(
                      (task, index) => ({
                        name: task.name,
                        data: tbysHandoffData.handoffTasks.manual.map(
                          (day) => ({
                            x: day.datetime,
                            y:
                              day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0,
                          }),
                        ),
                        style: {
                          color: getSeriesColor(index),
                          barSize: 24,
                        },
                      }),
                    )}
                    barChartYTitle="Handoffs"
                    barChartStacked={true}
                    barChartShowLegend={true}
                    barChartHeight={400}
                    pieChartData={tbysHandoffData.handoffTasks.manual[0].tasks.map(
                      (task, index) => ({
                        x: task.name,
                        y: tbysHandoffData.handoffTasks.manual.reduce(
                          (sum, day) =>
                            sum +
                            (day.tasks.find((t) => t.name === task.name)
                              ?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }),
                    )}
                    breakdownTitle="Breakdown by manual handoff task"
                    breakdownData={tbysHandoffData.handoffTasks.manual[0].tasks.map(
                      (task, index) => {
                        const totalCount =
                          tbysHandoffData.handoffTasks.manual.reduce(
                            (sum, day) =>
                              sum +
                              (day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0),
                            0,
                          );
                        const totalAllTasks =
                          tbysHandoffData.handoffTasks.manual.reduce(
                            (sum, day) =>
                              sum + day.tasks.reduce((s, t) => s + t.count, 0),
                            0,
                          );
                        return {
                          name: task.name,
                          percentage: Number(
                            ((totalCount / totalAllTasks) * 100).toFixed(2),
                          ),
                          tooltipContent: `This indicates the percentage of manual handoffs for task: ${task.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      },
                    )}
                    defaultChartType="bar"
                  />
                </Card>
              )}

            {/* Bot Action Handoff Tasks */}
            {tbysHandoffData.handoffTasks.botAction.length > 0 &&
              tbysHandoffData.handoffTasks.botAction[0]?.tasks?.length > 0 && (
                <Card
                  className="mt-10"
                  title="Bot Action Handoff Tasks"
                  tooltipText="The chart shows the distribution of tasks that led to bot action handoffs. Toggle between bar chart and distribution views."
                >
                  <ChartSwitcher
                    barChartValue={tbysHandoffData.handoffTasks.botAction.reduce(
                      (sum, day) =>
                        sum + day.tasks.reduce((s, t) => s + t.count, 0),
                      0,
                    )}
                    barChartSeries={tbysHandoffData.handoffTasks.botAction[0].tasks.map(
                      (task, index) => ({
                        name: task.name,
                        data: tbysHandoffData.handoffTasks.botAction.map(
                          (day) => ({
                            x: day.datetime,
                            y:
                              day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0,
                          }),
                        ),
                        style: {
                          color: getSeriesColor(index),
                          barSize: 24,
                        },
                      }),
                    )}
                    barChartYTitle="Handoffs"
                    barChartStacked={true}
                    barChartShowLegend={true}
                    barChartHeight={400}
                    pieChartData={tbysHandoffData.handoffTasks.botAction[0].tasks.map(
                      (task, index) => ({
                        x: task.name,
                        y: tbysHandoffData.handoffTasks.botAction.reduce(
                          (sum, day) =>
                            sum +
                            (day.tasks.find((t) => t.name === task.name)
                              ?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }),
                    )}
                    breakdownTitle="Breakdown by bot action handoff task"
                    breakdownData={tbysHandoffData.handoffTasks.botAction[0].tasks.map(
                      (task, index) => {
                        const totalCount =
                          tbysHandoffData.handoffTasks.botAction.reduce(
                            (sum, day) =>
                              sum +
                              (day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0),
                            0,
                          );
                        const totalAllTasks =
                          tbysHandoffData.handoffTasks.botAction.reduce(
                            (sum, day) =>
                              sum + day.tasks.reduce((s, t) => s + t.count, 0),
                            0,
                          );
                        return {
                          name: task.name,
                          percentage: Number(
                            ((totalCount / totalAllTasks) * 100).toFixed(2),
                          ),
                          tooltipContent: `This indicates the percentage of bot action handoffs for task: ${task.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      },
                    )}
                    defaultChartType="bar"
                  />
                </Card>
              )}

            {/* Instant Handoff Tasks */}
            {tbysHandoffData.handoffTasks.instantHandoff.length > 0 &&
              tbysHandoffData.handoffTasks.instantHandoff[0]?.tasks?.length >
                0 && (
                <Card
                  className="mt-10"
                  title="Instant Handoff Tasks"
                  tooltipText="The chart shows the distribution of tasks that led to instant handoffs. Toggle between bar chart and distribution views."
                >
                  <ChartSwitcher
                    barChartValue={tbysHandoffData.handoffTasks.instantHandoff.reduce(
                      (sum, day) =>
                        sum + day.tasks.reduce((s, t) => s + t.count, 0),
                      0,
                    )}
                    barChartSeries={tbysHandoffData.handoffTasks.instantHandoff[0].tasks.map(
                      (task, index) => ({
                        name: task.name,
                        data: tbysHandoffData.handoffTasks.instantHandoff.map(
                          (day) => ({
                            x: day.datetime,
                            y:
                              day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0,
                          }),
                        ),
                        style: {
                          color: getSeriesColor(index),
                          barSize: 24,
                        },
                      }),
                    )}
                    barChartYTitle="Handoffs"
                    barChartStacked={true}
                    barChartShowLegend={true}
                    barChartHeight={400}
                    pieChartData={tbysHandoffData.handoffTasks.instantHandoff[0].tasks.map(
                      (task, index) => ({
                        x: task.name,
                        y: tbysHandoffData.handoffTasks.instantHandoff.reduce(
                          (sum, day) =>
                            sum +
                            (day.tasks.find((t) => t.name === task.name)
                              ?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }),
                    )}
                    breakdownTitle="Breakdown by instant handoff task"
                    breakdownData={tbysHandoffData.handoffTasks.instantHandoff[0].tasks.map(
                      (task, index) => {
                        const totalCount =
                          tbysHandoffData.handoffTasks.instantHandoff.reduce(
                            (sum, day) =>
                              sum +
                              (day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0),
                            0,
                          );
                        const totalAllTasks =
                          tbysHandoffData.handoffTasks.instantHandoff.reduce(
                            (sum, day) =>
                              sum + day.tasks.reduce((s, t) => s + t.count, 0),
                            0,
                          );
                        return {
                          name: task.name,
                          percentage: Number(
                            ((totalCount / totalAllTasks) * 100).toFixed(2),
                          ),
                          tooltipContent: `This indicates the percentage of instant handoffs for task: ${task.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      },
                    )}
                    defaultChartType="bar"
                  />
                </Card>
              )}

            {/* Action Execution Failed Tasks */}
            {tbysHandoffData.handoffTasks.actionExecutionFailed.length > 0 &&
              tbysHandoffData.handoffTasks.actionExecutionFailed[0]?.tasks
                ?.length > 0 && (
                <Card
                  className="mt-10"
                  title="Action Execution Failed Tasks"
                  tooltipText="The chart shows the distribution of tasks that led to action execution failures. Toggle between bar chart and distribution views."
                >
                  <ChartSwitcher
                    barChartValue={tbysHandoffData.handoffTasks.actionExecutionFailed.reduce(
                      (sum, day) =>
                        sum + day.tasks.reduce((s, t) => s + t.count, 0),
                      0,
                    )}
                    barChartSeries={tbysHandoffData.handoffTasks.actionExecutionFailed[0].tasks.map(
                      (task, index) => ({
                        name: task.name,
                        data: tbysHandoffData.handoffTasks.actionExecutionFailed.map(
                          (day) => ({
                            x: day.datetime,
                            y:
                              day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0,
                          }),
                        ),
                        style: {
                          color: getSeriesColor(index),
                          barSize: 24,
                        },
                      }),
                    )}
                    barChartYTitle="Handoffs"
                    barChartStacked={true}
                    barChartShowLegend={true}
                    barChartHeight={400}
                    pieChartData={tbysHandoffData.handoffTasks.actionExecutionFailed[0].tasks.map(
                      (task, index) => ({
                        x: task.name,
                        y: tbysHandoffData.handoffTasks.actionExecutionFailed.reduce(
                          (sum, day) =>
                            sum +
                            (day.tasks.find((t) => t.name === task.name)
                              ?.count ?? 0),
                          0,
                        ),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }),
                    )}
                    breakdownTitle="Breakdown by action execution failed task"
                    breakdownData={tbysHandoffData.handoffTasks.actionExecutionFailed[0].tasks.map(
                      (task, index) => {
                        const totalCount =
                          tbysHandoffData.handoffTasks.actionExecutionFailed.reduce(
                            (sum, day) =>
                              sum +
                              (day.tasks.find((t) => t.name === task.name)
                                ?.count ?? 0),
                            0,
                          );
                        const totalAllTasks =
                          tbysHandoffData.handoffTasks.actionExecutionFailed.reduce(
                            (sum, day) =>
                              sum + day.tasks.reduce((s, t) => s + t.count, 0),
                            0,
                          );
                        return {
                          name: task.name,
                          percentage: Number(
                            ((totalCount / totalAllTasks) * 100).toFixed(2),
                          ),
                          tooltipContent: `This indicates the percentage of action execution failures for task: ${task.name}.`,
                          style: {
                            color: getSeriesColor(index),
                          },
                        };
                      },
                    )}
                    defaultChartType="bar"
                  />
                </Card>
              )}

            {/* Bot Actions */}
            {usingMockMetrics && (
              <Card
                className="mt-10"
                title="Bot Actions"
                tooltipText="The chart shows the distribution of different actions performed by the bot."
              >
                <HorizontalBarChart
                  series={{
                    data: botActionsCounts.map(({ topic, count }, index) => ({
                      x: topic,
                      y: count,
                      color: getSeriesColor(index),
                    })),
                    style: { barSize: 24 },
                  }}
                />
              </Card>
            )}

            {/* Bot & Agent Messages */}
            {!hasExternalTicketConfig && (
              <Card
                className="mt-10"
                title="Bot & Agent Messages"
                tooltipText="The chart shows a comparison between the number of messages sent by the bot and the number of messages sent by the agent."
              >
                <LineChart
                  value={
                    messagesCounts.agentCount.reduce(
                      (acc, curr) => acc + Number(curr.value),
                      0,
                    ) +
                    messagesCounts.botCount.reduce(
                      (acc, curr) => acc + Number(curr.value),
                      0,
                    )
                  }
                  series={[
                    {
                      name: "Bot",
                      data: messagesCounts.botCount.map(
                        ({ datetime, value }) => ({
                          x: datetime,
                          y: value,
                        }),
                      ),
                      style: { color: CHART_COLORS.BLUE },
                    },
                    {
                      name: "Agent",
                      data: messagesCounts.agentCount.map(
                        ({ datetime, value }) => ({
                          x: datetime,
                          y: value,
                        }),
                      ),
                      style: { color: "#F63434" },
                    },
                  ]}
                  yTitle="Messages"
                  yDomain={[
                    0,
                    Math.max(
                      ...messagesCounts.agentCount.map((d) => d.value),
                      ...messagesCounts.botCount.map((d) => d.value),
                    ),
                  ]}
                />
              </Card>
            )}

            {/* Bot Productivity */}
            {!hasExternalTicketConfig && (
              <Card
                className="mt-10"
                title="Bot Productivity"
                tooltipText="The chart shows the percentage of messages sent by the bot compared to total messages sent."
              >
                <LineChart
                  value={`${(() => {
                    const botTotal = messagesCounts.botCount.reduce(
                      (acc, curr) => acc + Number(curr.value),
                      0,
                    );

                    const agentTotal = messagesCounts.agentCount.reduce(
                      (acc, curr) => acc + Number(curr.value),
                      0,
                    );

                    const total = botTotal + agentTotal;

                    return total === 0
                      ? "100"
                      : ((botTotal / total) * 100).toFixed(2);
                  })()}`}
                  series={[
                    {
                      name: "Bot Message Percentage",
                      data: messagesCounts.botCount.map(
                        ({ datetime }, index) => ({
                          x: datetime,
                          y: Number(
                            (Number(messagesCounts.botCount[index].value) +
                              Number(messagesCounts.agentCount[index].value) ===
                            0
                              ? 100
                              : (Number(messagesCounts.botCount[index].value) /
                                  (Number(
                                    messagesCounts.botCount[index].value,
                                  ) +
                                    Number(
                                      messagesCounts.agentCount[index].value,
                                    ))) *
                                100
                            ).toFixed(2),
                          ),
                        }),
                      ),
                      style: { color: CHART_COLORS.BLUE },
                    },
                  ]}
                  yTitle="%"
                  yDomain={[0, 100]}
                />
              </Card>
            )}

            <Card
              className="mt-10"
              title="Average Customer Messages per Conversation"
              tooltipText="The average number of messages sent by customers per conversation each day"
            >
              <LineChart
                value={customerMessagesPerChat.average.toString()}
                series={[
                  {
                    name: "Customer Messages per Conversation",
                    data: customerMessagesPerChat.perDay.map(
                      ({ datetime, count }) => ({
                        x: datetime,
                        y: count,
                      }),
                    ),
                    style: { color: CHART_COLORS.BLUE },
                  },
                ]}
                yTitle="Messages"
              />
            </Card>

            {/* Channels Distribution */}
            {channelCounts.length > 0 && (
              <div className="mt-10 flex gap-6">
                <Card
                  className="flex-1"
                  title="Channels Distribution Over Time"
                  tooltipText="The charts show the distribution of conversations across different channels over time."
                >
                  <LineChart
                    series={channelCounts.map(({ channel, data }, index) => ({
                      name: channel,
                      data: data.map((point) => ({
                        x: point.datetime,
                        y: point.value,
                      })),
                      style: {
                        color: getSeriesColor(index),
                        dot: { r: 2 },
                      },
                    }))}
                    yTitle="Conversations"
                  />
                </Card>

                <Card
                  className="flex-1"
                  title="Channels Distribution"
                  tooltipText="The charts show the distribution of conversations across different channels."
                >
                  <div className="flex flex-row gap-x-16">
                    <ChartPie
                      data={channelCounts.map(({ channel, data }, index) => ({
                        x: channel,
                        y: data.reduce((sum, point) => sum + point.value, 0),
                        style: {
                          color: getSeriesColor(index),
                        },
                      }))}
                    />
                    <Breakdown
                      title="Breakdown by channel type"
                      data={channelCounts.map(({ channel, data }, index) => ({
                        name: channel,
                        percentage: Number(
                          (
                            (data.reduce((sum, point) => sum + point.value, 0) /
                              channelCounts.reduce(
                                (total, ch) =>
                                  total +
                                  ch.data.reduce(
                                    (sum, point) => sum + point.value,
                                    0,
                                  ),
                                0,
                              )) *
                            100
                          ).toFixed(2),
                        ),
                        tooltipContent: `This indicates the percentage of people who initiated a conversation through ${channel}.`,
                        style: {
                          color: getSeriesColor(index),
                        },
                      }))}
                    />
                  </div>
                </Card>
              </div>
            )}

            {/* Country Distribution */}
            {usingMockMetrics && (
              <Card
                className="mt-10"
                title="Country Distribution"
                tooltipText="The charts show the distribution of conversations across different countries."
              >
                <div className="flex flex-row items-center gap-x-16">
                  <ChartPie
                    data={countryData
                      .filter((item) => item.topic !== "Other")
                      .map(({ topic, count }, index) => ({
                        x: topic,
                        y: count,
                        style: {
                          color: getSeriesColor(index),
                        },
                      }))}
                  />
                  <Breakdown
                    title="Breakdown by country"
                    data={countryData
                      .filter((item) => item.topic !== "Other")
                      .map(({ topic, count }, index) => ({
                        name: topic,
                        percentage: Number(
                          (
                            (count /
                              countryData.reduce(
                                (total, t) => total + t.count,
                                0,
                              )) *
                            100
                          ).toFixed(2),
                        ),
                        tooltipContent: `This indicates the percentage of conversations from ${topic}.`,
                        style: {
                          color: getSeriesColor(index),
                        },
                      }))}
                  />
                </div>
              </Card>
            )}
          </TabsContent>

          <TabsContent
            value="agent-productivity"
            className="mx-auto mt-0 w-full max-w-7xl px-10 pb-10"
          >
            {/* Average Reply Time graph */}
            <Card
              className="mt-10"
              title="Average Reply Time"
              tooltipText="The average time it takes to reply to a handed off conversation."
            >
              <div className="flex flex-row gap-x-16">
                <LineChart
                  value={avgReplyTimeMetrics.value}
                  series={[
                    {
                      name: "Average Reply Time",
                      data: avgReplyTimeMetrics.perDay,
                      style: { color: CHART_COLORS.BLUE },
                    },
                  ]}
                  yTitle={avgReplyTimeMetrics.yTitle}
                  yDomain={[
                    0,
                    Math.max(...avgReplyTimeMetrics.perDay.map((d) => d.y)),
                  ]}
                />
              </div>
            </Card>

            {/* Average Time to Close graph */}
            <Card
              className="mt-10"
              title="Average Time to Close"
              tooltipText="The average time it takes to close a conversation after it has been handed off."
            >
              <div className="flex flex-row gap-x-16">
                <LineChart
                  value={avgTimeToCloseMetrics.value}
                  series={[
                    {
                      name: "Average Time to Close",
                      data: avgTimeToCloseMetrics.perDay,
                      style: { color: CHART_COLORS.BLUE },
                    },
                  ]}
                  yTitle={avgTimeToCloseMetrics.yTitle}
                  yDomain={[
                    0,
                    Math.max(...avgTimeToCloseMetrics.perDay.map((d) => d.y)),
                  ]}
                />
              </div>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </FiltersProvider>
  );
}
