import { toast } from "sonner";
import { Route } from "..";

export function useDeleteRules() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const deleteRulesMutation =
    trpc.dashboard.emails.inboundRules.deleteRules.useMutation({
      onSuccess: async (_, { ids }) => {
        await trpcUtils.dashboard.emails.inboundRules.getRules.invalidate({
          businessSlug,
        });

        if (ids.length === 1) {
          toast.success("Rule deleted successfully.");
        } else {
          toast.success(`${ids.length} rules deleted successfully.`);
        }
      },
      onError: (error, { ids }) => {
        if (ids.length === 1) {
          toast.error("Failed to delete rule.");
        } else {
          toast.error(`Failed to delete ${ids.length} rules.`);
        }
        console.error(error);
      },
    });

  return {
    deleteRules: (ids: string[]) => {
      deleteRulesMutation.mutate({
        businessSlug,
        ids,
      });
    },
  };
}
