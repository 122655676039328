import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useOrganization } from "../-hooks/useOrganization";
import { env } from "@dashboard/env";
import { useBusiness } from "../-hooks/useBusiness";

const formSchema = z.object({
  domain: z
    .string({
      required_error: "Domain is required",
      invalid_type_error: "Domain is required",
    })
    .min(1, "Domain is required")
    .url("Invalid domain URL"),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/platform/woocommerce",
)({
  component: WooCommerceSetupPage,
});

function WooCommerceSetupPage() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      domain: "",
    },
  });

  const business = useBusiness();

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    const installUrl = new URL(
      `${env.DASHBOARD_BASE_API_URL}/woocommerce/install`,
    );

    const params = new URLSearchParams({
      businessId: business.id,
      website: values.domain,
    });

    installUrl.search = params.toString();

    window.location.href = installUrl.toString();
  };

  const organization = useOrganization();
  const stepCompleted = organization.onboardingStatus !== "PLATFORM";

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect your WooCommerce store
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Enter your WooCommerce store domain to connect your store to Octocom
      </h2>
      <div className="w-full max-w-3xl py-4">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="domain"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className="h-10 border-[#041627]"
                      placeholder="Enter your store domain (e.g., https://mystore.com)"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="bg-blue-dark h-10 text-white">
              Connect WooCommerce
            </Button>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/platform"
          nextPath="/organization/$organizationSlug/onboarding/help-desk"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
