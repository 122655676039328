import { slugify } from "@be/lib/utils/text.utils";
import crypto from "crypto";
import he from "he";
import { decodeGreekText } from "@be/lib/utils/text.utils";

export interface Transformation {
  id: string;
  name: string;
  description: string;
  parameters?: {
    name: string;
    type: string;
    description: string;
  }[];
  transform: (
    value: string,
    params?: Record<string, string>,
    productData?: Record<string, unknown>,
  ) => unknown;
}

export const transformations: Transformation[] = [
  {
    id: "split-by-keyword",
    name: "Split by keyword",
    description: "Splits a string by a given keyword",
    parameters: [
      {
        name: "keyword",
        type: "string",
        description: "The keyword to split by",
      },
    ],
    transform: (value: string, params?: Record<string, string>) => {
      if (!params?.keyword) return value;
      return value.split(params.keyword);
    },
  },
  {
    id: "slugify",
    name: "Slugify",
    description: "Converts a string to a URL-friendly slug",
    transform: (value: string) => {
      return slugify(value);
    },
  },
  {
    id: "string-to-boolean",
    name: "String to boolean",
    description: "Converts a string to boolean based on a matching value",
    parameters: [
      {
        name: "trueValue",
        type: "string",
        description: "The value that should result in true",
      },
    ],
    transform: (value: string, params?: Record<string, string>) => {
      if (!params?.trueValue) return false;
      return value === params.trueValue;
    },
  },
  {
    id: "to-string",
    name: "To string",
    description: "Converts any value to a string",
    transform: (value: string | number) => {
      return String(value);
    },
  },
  {
    id: "add-random-hex",
    name: "Add random hex",
    description: "Adds a random hex string to the value",
    transform: (value: string) => {
      return value + "-" + crypto.randomBytes(4).toString("hex");
    },
  },
  {
    id: "string-replace",
    name: "String replace",
    description:
      "Replaces all occurrences of a search string with a replacement string",
    parameters: [
      {
        name: "search",
        type: "string",
        description: "The string to search for",
      },
      {
        name: "replacement",
        type: "string",
        description: "The string to replace with",
      },
    ],
    transform: (value: string, params?: Record<string, string>) => {
      if (!params?.search || !params?.replacement) return value;
      return value.replace(new RegExp(params.search, "g"), params.replacement);
    },
  },
  {
    id: "to-float",
    name: "To float",
    description: "Converts a string to a floating point number",
    transform: (value: string) => {
      const num = parseFloat(value);
      return isNaN(num) ? value : num;
    },
  },
  {
    id: "does-not-equal",
    name: "Does not equal",
    description:
      "Checks if a value does not equal a specified comparison value or product field",
    parameters: [
      {
        name: "compareValue",
        type: "string",
        description: "The static value to compare against",
      },
      {
        name: "compareField",
        type: "string",
        description:
          "The product field to compare against (takes precedence over compareValue)",
      },
    ],
    transform: (
      value: string,
      params?: Record<string, string>,
      productData?: Record<string, unknown>,
    ) => {
      // Convert value to number if it's numeric
      const numValue = !isNaN(Number(value)) ? Number(value) : value;

      if (params?.compareField && productData) {
        const fieldValue = productData[params.compareField];
        const numFieldValue = !isNaN(Number(fieldValue))
          ? Number(fieldValue)
          : fieldValue;
        return numValue !== numFieldValue;
      }
      if (params?.compareValue) {
        const numCompareValue = !isNaN(Number(params.compareValue))
          ? Number(params.compareValue)
          : params.compareValue;
        return numValue !== numCompareValue;
      }
      return true;
    },
  },
  {
    id: "html-decode",
    name: "HTML decode",
    description: "Decodes HTML entities into their corresponding characters",
    transform: (value: string) => {
      return he.decode(value);
    },
  },
  {
    id: "html-remove",
    name: "HTML remove",
    description: "Removes HTML tags from the text while preserving the content",
    transform: (value: string) => {
      return value.replace(/<[^>]*>/g, "").trim();
    },
  },
  {
    id: "decode-greek-text",
    name: "Decode Greek text",
    description: "Decodes Greek HTML entities and normalizes whitespace",
    transform: (value: string) => {
      return decodeGreekText(value);
    },
  },
  {
    id: "static-value",
    name: "Static value",
    description: "Sets a static value, ignoring the input",
    parameters: [
      {
        name: "value",
        type: "string",
        description: "The static value to use",
      },
    ],
    transform: (_value: string, params?: Record<string, string>) => {
      return params?.value ?? "";
    },
  },
];
