import { z } from "zod";

export const ruleFormSchema = z.object({
  type: z.enum(["email", "domain"], {
    required_error: "Type is required",
  }),
  value: z
    .string({
      required_error: "Value is required",
      invalid_type_error: "Value is required",
    })
    .min(1, { message: "Value is required" }),
  action: z.enum(["handoff", "spam", "ignore"], {
    required_error: "Action is required",
  }),
  organization: z.boolean().default(false),
  isActive: z.boolean().default(true),
});

export type RuleForm = z.infer<typeof ruleFormSchema>;
