import { Route } from "..";
import { toast } from "sonner";

export const useCreateMessageTopics = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  const creationMutation =
    trpc.dashboard.conversations.messageTopics.createMany.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.conversations.messageTopics.getConfig.invalidate(
          {
            businessSlug,
          },
        );

        setOpen(false);
        toast.success("Message topics created");
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to create message topics");
      },
    });

  return {
    onSubmit: (data: { topics: string[] }) => {
      creationMutation.mutate({ businessSlug, ...data });
    },
  };
};
