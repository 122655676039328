import { useFilters, FilterType } from "../../../-context/FiltersContext";
import { BusinessFilter } from "../BusinessFilter";
import { ChannelsFilter } from "../ChannelsFilter";
import { LanguagesFilter } from "../LanguagesFilter";
import { AssigneesFilter } from "../AssigneesFilter";
import { TagsFilter } from "../TagsFilter";
import { TopicsFilter } from "../TopicsFilter";
import { SendersFilter } from "../SendersFilter";
import { TypeFilter } from "../TypeFilter";
import { HandedOffFilter } from "../HandedOffFilter";
import { ClosedFilter } from "../ClosedFilter";
import { SnoozedFilter } from "../SnoozedFilter";
import { OrderCanceledFilter } from "../OrderCanceledFilter";
import { OrderStatusesFilter } from "../OrderStatusesFilter";
import { OrderShippingMethodsFilter } from "../OrderShippingMethodsFilter";
import { OrderBillingCitiesFilter } from "../OrderBillingCitiesFilter";
import { OrderBillingStatesFilter } from "../OrderBillingStatesFilter";
import { OrderBillingCountriesFilter } from "../OrderBillingCountriesFilter";
import { OrderRecipientCitiesFilter } from "../OrderRecipientCitiesFilter";
import { OrderRecipientStatesFilter } from "../OrderRecipientStatesFilter";
import { OrderRecipientCountriesFilter } from "../OrderRecipientCountriesFilter";
import { OrderFulfillmentStatusesFilter } from "../OrderFulfillmentStatusesFilter";
import { OrderCouriersFilter } from "../OrderCouriersFilter";
import { TotalOrderAmountFilter } from "../TotalOrderAmountFilter";
import { TaskNamesFilter } from "../TaskNamesFilter";

export function CurrentFilters() {
  const { filters } = useFilters();

  if (filters.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-2">
      {filters.map((filter) => (
        <div key={filter.type}>{renderFilterComponent(filter.type)}</div>
      ))}
    </div>
  );
}

function renderFilterComponent(type: FilterType) {
  // Conversation Details filters
  switch (type) {
    case "businessIds":
      return <BusinessFilter />;
    case "channels":
      return <ChannelsFilter />;
    case "languages":
      return <LanguagesFilter />;
    case "taskNames":
      return <TaskNamesFilter />;
    case "assignees":
      return <AssigneesFilter />;
    case "tags":
      return <TagsFilter />;
    case "topics":
      return <TopicsFilter />;
    case "senders":
      return <SendersFilter />;
    case "type":
      return <TypeFilter />;
    case "isHandedOff":
      return <HandedOffFilter />;
    case "isClosed":
      return <ClosedFilter />;
    case "isSnoozed":
      return <SnoozedFilter />;
    case "orderCanceled":
      return <OrderCanceledFilter />;
    case "orderStatuses":
      return <OrderStatusesFilter />;
    case "orderShippingMethods":
      return <OrderShippingMethodsFilter />;
    case "orderBillingCities":
      return <OrderBillingCitiesFilter />;
    case "orderBillingStates":
      return <OrderBillingStatesFilter />;
    case "orderBillingCountries":
      return <OrderBillingCountriesFilter />;
    case "orderRecipientCities":
      return <OrderRecipientCitiesFilter />;
    case "orderRecipientStates":
      return <OrderRecipientStatesFilter />;
    case "orderRecipientCountries":
      return <OrderRecipientCountriesFilter />;
    case "orderFulfillmentStatuses":
      return <OrderFulfillmentStatusesFilter />;
    case "orderCouriers":
      return <OrderCouriersFilter />;
    case "totalOrderAmountRange":
      return <TotalOrderAmountFilter />;
  }
}
