import { ClipboardList } from "lucide-react";
import { FilterSelect } from "./FilterSelect";
import { Route } from "../../..";

export function TaskName({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [taskNameData] = trpc.dashboard.v2Bot.getTaskNames.useSuspenseQuery({
    organizationSlug,
  });

  const options = taskNameData.taskNames.map((taskName) => ({
    label: taskName,
    value: taskName,
  }));

  return (
    <FilterSelect
      filterName="Task Name"
      valueIcon={ClipboardList}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
