interface BreakdownProps {
  title?: string;
  data: {
    name: string;
    percentage: number;
    style?: {
      color: string;
    };
  }[];
}

export function Breakdown({ data, title }: BreakdownProps) {
  // Sort data in descending order by percentage
  const sortedData = [...data].sort((a, b) => b.percentage - a.percentage);

  return (
    <div className="mt-4 w-full overflow-scroll">
      {title && <h4 className="text-sm font-semibold text-black">{title}</h4>}

      <div className="mt-3 max-h-[500px] overflow-y-auto">
        {sortedData.map((item, index) => (
          <div
            key={index}
            className="flex flex-row items-center border-b py-3 pr-4"
          >
            <div
              className="h-2.5 w-2.5 rounded-full"
              style={{ backgroundColor: item.style?.color }}
            ></div>
            <span className="ml-2 text-sm text-black">{item.name}</span>
            <div className="ml-auto flex flex-row items-center">
              <span className="text-sm">{item.percentage}%</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
