import { toast } from "sonner";
import { useNavigate } from "@tanstack/react-router";
import { Route } from "../../onboarding";

export function useBookDemo() {
  const navigate = useNavigate();
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const bookDemoMutation = trpc.dashboard.onboarding.bookDemo.useMutation();

  const bookDemo = () => {
    if (bookDemoMutation.isPending) return;

    bookDemoMutation.mutate(
      { businessSlug: organizationSlug },
      {
        onSuccess: () => {
          void navigate({
            to: "/organization/$organizationSlug/onboarding/demo-success",
            params: { organizationSlug },
          });
        },
        onError: () => {
          toast.error("Failed to book demo. Please try again.");
        },
      },
    );
  };
  return {
    bookDemo,
    isLoading: bookDemoMutation.isPending,
  };
}
