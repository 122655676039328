import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { EmptyState } from "./EmptyState";

interface CustomTooltipPieProps {
  active?: boolean;
  payload?: {
    payload: {
      x: string;
      y: number | string; // Depends on your data structure
    };
    style: {
      color: string;
    };
  }[];
}

const CustomTooltipPie: React.FC<CustomTooltipPieProps> = ({
  active,
  payload,
}) => {
  if (active && payload?.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip rounded-md border border-gray-300 bg-white px-3 py-2.5 shadow-sm">
        <p className="font-medium text-blue-500">{data.x}</p>
        <p className="text-gray-700">{data.y}</p>
      </div>
    );
  }
  return null;
};

interface ChartProps {
  data: {
    x: string;
    y: number;
    style: {
      color: string;
    };
  }[];
  showLegend?: boolean;
}

export function ChartPie({ data, showLegend }: ChartProps) {
  // Check if there's no data or all values are 0
  const hasData = data.some((point) => point.y !== 0);
  if (!hasData) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="aspect-square w-[280px]">
          <EmptyState />
        </div>
      </div>
    );
  }

  // Sort data in decreasing order
  const sortedData = [...data].sort((a, b) => b.y - a.y);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="aspect-square w-[280px]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={sortedData}
              cx="50%"
              cy="50%"
              startAngle={90}
              endAngle={-270}
              outerRadius="90%"
              innerRadius={70}
              paddingAngle={0}
              fill="#8884d8"
              dataKey="y"
              nameKey="x"
            >
              {sortedData.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={sortedData[index].style.color}
                  strokeWidth={0}
                />
              ))}
            </Pie>
            {showLegend && (
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
                wrapperStyle={{
                  paddingTop: "20px",
                }}
              />
            )}
            <Tooltip content={<CustomTooltipPie />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
