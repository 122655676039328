import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { toast } from "sonner";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { BotMessage } from "../Message";
import { cn } from "@dashboard/utils/ui";
import { useState } from "react";
import { Maximize2, Minimize2 } from "lucide-react";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import ReactJson from "react-json-view";

export function DebugModal({
  open,
  setOpen,
  message,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  message: BotMessage;
}) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isArgumentsExpanded, setIsArgumentsExpanded] = useState(false);
  const [isResultExpanded, setIsResultExpanded] = useState(false);
  const [isRewrittenObjectsExpanded, setIsRewrittenObjectsExpanded] =
    useState(false);

  const copyToClipboard = (value: string | null | undefined) => {
    if (!value) return;
    void navigator.clipboard.writeText(value);
    toast("Value copied to clipboard");
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setIsFullscreen(false); // Reset fullscreen when closing
    }
    setOpen(open);
  };
  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent
        className={cn(
          "origin-center p-0 transition-all duration-300 ease-in-out",
          isFullscreen
            ? "fixed left-0 top-0 z-50 h-[100vh] w-[100vw] max-w-none translate-x-0 translate-y-0 scale-100 rounded-none"
            : "h-[50vh] w-[90vw] max-w-[1000px] scale-100",
        )}
      >
        <div className="max-h-full overflow-y-auto">
          <DialogHeader className="bg-background sticky top-0 z-10 rounded-t-lg border-b p-6">
            <div className="flex flex-row items-center gap-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsFullscreen(!isFullscreen)}
              >
                {isFullscreen ? (
                  <Minimize2 className="h-4 w-4" />
                ) : (
                  <Maximize2 className="h-4 w-4" />
                )}
              </Button>
              <DialogTitle>Response debugging information</DialogTitle>
            </div>
          </DialogHeader>
          <div className="space-y-6 p-6">
            {!message.generationDetails ? (
              <p>
                This bot response was not generated by AI. This is a fixed
                response.
              </p>
            ) : (
              <>
                {/* Wrapper div for content and system prompt sections */}
                <div
                  className={cn(
                    "space-y-6",
                    isFullscreen && "grid grid-cols-2 gap-6 space-y-0",
                  )}
                >
                  {/* Content section */}
                  <div className="space-y-2">
                    <h2 className="font-semibold">
                      Bot message content
                      <span className="text-muted-foreground ml-2 text-sm">
                        {message.generationDetails.executionTime &&
                        message.generationDetails.executionTime > 0
                          ? `(Response time: ${Math.round(
                              message.generationDetails.executionTime,
                            )}ms)`
                          : "(Response time unknown)"}
                      </span>
                    </h2>
                    <Accordion type="single" collapsible className="w-full">
                      <AccordionItem
                        value="content"
                        className="[&>[data-state]]:transition-all [&>[data-state]]:duration-300"
                      >
                        <AccordionTrigger className="px-4">
                          Expand message content
                        </AccordionTrigger>
                        <AccordionContent className="px-8">
                          <Tooltip
                            trigger={
                              <div
                                className="cursor-pointer text-left"
                                onClick={() => copyToClipboard(message.text)}
                              >
                                <MarkdownContent content={message.text} />
                              </div>
                            }
                            content={<p>Click to copy message content</p>}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  {/* System prompt section */}
                  <div className="space-y-2">
                    <h2 className="font-semibold">
                      System prompt{" "}
                      {message.generationDetails.systemPromptTokenLength && (
                        <span className="text-muted-foreground ml-2 text-sm">
                          ({message.generationDetails.systemPromptTokenLength}{" "}
                          tokens)
                        </span>
                      )}
                    </h2>

                    <Accordion type="single" collapsible className="w-full">
                      <AccordionItem
                        value="system-prompt"
                        className="[&>[data-state]]:transition-all [&>[data-state]]:duration-300"
                      >
                        <AccordionTrigger className="px-4">
                          Expand system prompt
                        </AccordionTrigger>
                        <AccordionContent className="px-8">
                          <Tooltip
                            trigger={
                              <div
                                className="cursor-pointer text-left"
                                onClick={() =>
                                  copyToClipboard(
                                    message.generationDetails?.systemPrompt,
                                  )
                                }
                              >
                                <MarkdownContent
                                  content={
                                    message.generationDetails.systemPrompt ?? ""
                                  }
                                />
                              </div>
                            }
                            content={<p>Click to copy system prompt</p>}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>

                {/* Rest of the sections with consistent spacing */}
                {message.generationDetails.threatReasoning && (
                  <div className="space-y-2">
                    <h2 className="font-semibold">Threat reasoning</h2>
                    <MarkdownContent
                      content={message.generationDetails.threatReasoning}
                    />
                  </div>
                )}

                {message.generationDetails.workflowReasoning && (
                  <div className="space-y-2">
                    <h2 className="font-semibold">Workflow reasoning</h2>
                    <MarkdownContent
                      content={message.generationDetails.workflowReasoning}
                    />
                  </div>
                )}

                {/* Tool calls section */}
                {message.generationDetails.functionCalls.length > 0 && (
                  <div className="space-y-2">
                    <h2 className="font-semibold">Tool calls</h2>
                    <Accordion type="single" collapsible className="w-full">
                      {message.generationDetails.functionCalls.map(
                        (call, idx) => (
                          <AccordionItem
                            key={idx}
                            value={idx.toString()}
                            className="[&>[data-state]]:transition-all [&>[data-state]]:duration-300"
                          >
                            <AccordionTrigger className="w-full px-4">
                              <div className="flex w-full items-center gap-2">
                                <span className="text-left">
                                  {call.functionName}
                                </span>
                                <span className="text-muted-foreground shrink-0 text-sm">
                                  {call.executionTime && call.executionTime > 0
                                    ? `(Execution time: ${Math.round(
                                        call.executionTime,
                                      )}ms)`
                                    : "(Execution time unknown)"}
                                </span>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent className="px-6">
                              <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-2">
                                  <div className="flex items-center gap-2">
                                    <Tooltip
                                      trigger={
                                        <Button
                                          onClick={() =>
                                            copyToClipboard(call.arguments)
                                          }
                                          variant="ghost"
                                          className="h-auto px-2 py-1 text-sm font-medium"
                                        >
                                          Arguments
                                        </Button>
                                      }
                                      content={<p>Click to copy arguments</p>}
                                    />
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() =>
                                        setIsArgumentsExpanded(
                                          !isArgumentsExpanded,
                                        )
                                      }
                                    >
                                      {isArgumentsExpanded
                                        ? "Collapse all"
                                        : "Expand all"}
                                    </Button>
                                  </div>
                                  <div className="rounded border p-2">
                                    <ReactJson
                                      src={
                                        typeof call.arguments === "string"
                                          ? (JSON.parse(
                                              call.arguments,
                                            ) as object)
                                          : ({} as object)
                                      }
                                      theme="summerfruit:inverted"
                                      collapsed={
                                        isArgumentsExpanded ? false : 3
                                      }
                                      displayDataTypes={false}
                                      displayObjectSize={false}
                                    />
                                  </div>
                                </div>
                                <div className="space-y-2">
                                  <div className="flex items-center gap-2">
                                    <Tooltip
                                      trigger={
                                        <Button
                                          onClick={() =>
                                            copyToClipboard(call.result)
                                          }
                                          variant="ghost"
                                          className="h-auto px-2 py-1 text-sm font-medium"
                                        >
                                          Result
                                        </Button>
                                      }
                                      content={<p>Click to copy result</p>}
                                    />
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() =>
                                        setIsResultExpanded(!isResultExpanded)
                                      }
                                    >
                                      {isResultExpanded
                                        ? "Collapse all"
                                        : "Expand all"}
                                    </Button>
                                  </div>
                                  <div className="rounded border p-2">
                                    <ReactJson
                                      src={
                                        typeof call.result === "string"
                                          ? (JSON.parse(call.result) as object)
                                          : ({} as object)
                                      }
                                      theme="summerfruit:inverted"
                                      collapsed={isResultExpanded ? false : 3}
                                      displayDataTypes={false}
                                      displayObjectSize={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        ),
                      )}
                    </Accordion>
                  </div>
                )}

                {/* After Tool calls section and before Chunks section */}
                {message.generationDetails.rewrittenObjects &&
                  message.generationDetails.rewrittenObjects.length > 0 && (
                    <div className="space-y-2">
                      <h2 className="font-semibold">Rewritten Objects</h2>
                      <Accordion type="single" collapsible className="w-full">
                        {message.generationDetails.rewrittenObjects.map(
                          (obj, idx) => (
                            <AccordionItem
                              key={idx}
                              value={idx.toString()}
                              className="[&>[data-state]]:transition-all [&>[data-state]]:duration-300"
                            >
                              <AccordionTrigger className="w-full px-4">
                                <div className="flex w-full items-center gap-2">
                                  <span className="text-left">
                                    Rewritten Object {idx + 1}
                                  </span>
                                </div>
                              </AccordionTrigger>
                              <AccordionContent className="px-6">
                                <div className="space-y-2">
                                  <div className="flex items-center gap-2">
                                    <Tooltip
                                      trigger={
                                        <Button
                                          onClick={() =>
                                            copyToClipboard(JSON.stringify(obj))
                                          }
                                          variant="ghost"
                                          className="h-auto px-2 py-1 text-sm font-medium"
                                        >
                                          Object Data
                                        </Button>
                                      }
                                      content={<p>Click to copy object data</p>}
                                    />
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() =>
                                        setIsRewrittenObjectsExpanded(
                                          !isRewrittenObjectsExpanded,
                                        )
                                      }
                                    >
                                      {isRewrittenObjectsExpanded
                                        ? "Collapse all"
                                        : "Expand all"}
                                    </Button>
                                  </div>
                                  <div className="rounded border p-2">
                                    <ReactJson
                                      src={obj as object}
                                      theme="summerfruit:inverted"
                                      collapsed={
                                        isRewrittenObjectsExpanded ? false : 3
                                      }
                                      displayDataTypes={false}
                                      displayObjectSize={false}
                                    />
                                  </div>
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          ),
                        )}
                      </Accordion>
                    </div>
                  )}

                {/* Chunks section */}
                {message.generationDetails.chunks.length > 0 && (
                  <div className="space-y-2">
                    <h2 className="font-semibold">Chunks used</h2>
                    <Accordion type="single" collapsible className="w-full">
                      {message.generationDetails.chunks.map((chunk, idx) => (
                        <AccordionItem
                          key={idx}
                          value={idx.toString()}
                          className="[&>[data-state]]:transition-all [&>[data-state]]:duration-300"
                        >
                          <AccordionTrigger className="px-4">
                            {`Chunk Id: ${chunk.contextChunkId}; Chunk Source: "${chunk.source}"; Chunk relevance: ${chunk.relevanceScore}`}
                          </AccordionTrigger>
                          <AccordionContent className="px-8">
                            {chunk.content}
                          </AccordionContent>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
