import { createFileRoute, redirect } from "@tanstack/react-router";
import { App } from "@chatbot/App";
import { Select } from "@dashboard/common/ui/select";
import { z } from "zod";
import { AvailableActions } from "./-components/AvailableActions";
import { OrderStatusTable } from "./-components/OrderStatusTable";
import { secondsToMilliseconds } from "date-fns";
import { TrackedOrder } from "@be/modules/orders/orders.types";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/demo/",
)({
  validateSearch: z.object({
    businessSlug: z.string().optional(),
  }),
  beforeLoad: ({
    context: { businesses },
    search: { businessSlug },
    params: { organizationSlug },
  }) => {
    if (!businessSlug) {
      const business = businesses[0];
      return { business };
    } else {
      const business = businesses.find(
        (business) => business.slug === businessSlug,
      );

      if (!business) {
        throw redirect({
          to: `/organization/$organizationSlug/setup`,
          params: { organizationSlug },
          search: { businessSlug: undefined },
        });
      }

      return { business };
    }
  },
  loader: async ({ context: { trpcClientUtils, businesses } }) => {
    await Promise.all(
      businesses.map((business) =>
        Promise.all([
          trpcClientUtils.dashboard.webChat.getConfig.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.business.getOrThrow.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.orders.getAllOrders.ensureData({
            businessSlug: business.slug,
          }),
        ]),
      ),
    );
  },
  component: DemoPage,
});

function DemoPage() {
  const { trpc, business, businesses } = Route.useRouteContext();
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const { organizationSlug } = Route.useParams();

  const [flowConfig] =
    trpc.dashboard.flows.getSimpleFlowConfiguration.useSuspenseQuery({
      businessSlug: business.slug,
    });

  const [webChatConfig] = trpc.dashboard.webChat.getConfig.useSuspenseQuery({
    businessSlug: business.slug,
  });

  const [orders] = trpc.dashboard.orders.getAllOrders.useSuspenseQuery(
    {
      businessSlug: business.slug,
    },
    {
      refetchInterval: secondsToMilliseconds(30),
    },
  );

  const options = businesses.map((business) => ({
    label: business.internalName,
    value: business.slug,
  }));

  const setBusiness = (value: string) => {
    void navigate({
      search: { ...search, businessSlug: value },
    });
  };

  return (
    <div className="flex h-full w-full flex-col">
      <div className="border-b-gray-light flex w-full items-center justify-between border-b px-10 py-4">
        <h2 className="text-2xl font-semibold">Welcome to Octocom</h2>
        {businesses.length > 1 && (
          <Select
            variant="dropdown"
            value={business.slug}
            onChange={setBusiness}
            options={options}
            placeholder="Select a business"
          />
        )}
      </div>
      <div className="flex h-full w-full overflow-hidden">
        <div className="w-full overflow-auto px-10 py-6">
          <div className="flex  w-full  flex-col">
            <div className="h-50 flex flex-col pb-4">
              <h1 className="text-2xl font-medium">Test Demo AI</h1>
            </div>
            <div className="rounded-lg border bg-white p-4">
              <div className="flex flex-col">
                <AvailableActions
                  organizationSlug={organizationSlug}
                  businessSlug={business.slug}
                  flowConfig={flowConfig}
                />
                <h3 className="mt-4 text-lg font-medium text-gray-900">
                  Fake Data
                </h3>
                <p className="mb-6 mt-1 text-gray-600">
                  Below is a list of mock orders that you can use to test the AI
                  assistant. These orders are completely fake and can be
                  manipulated freely. Any manipulations of these orders{" "}
                  <b>will not update</b> the actual orders in the database.
                  These are meant to experience the AI assistant in a controlled
                  environment.
                </p>
                <OrderStatusTable orders={orders as TrackedOrder[]} />
              </div>
            </div>
          </div>
        </div>
        <div className="border-l-gray-light flex h-full w-[50%] items-center justify-center overflow-auto border-l p-4">
          <div className="h-full w-full" id="octocom-container">
            <App presetConfigId={webChatConfig.id} containerMode={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
