import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Map } from "lucide-react";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderRecipientStatesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.recipientState.map((state) => ({
    label: state,
    value: state,
  }));

  return (
    <CommandMultiSelect
      filterType="orderRecipientStates"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Map}
    />
  );
}
