import { useState } from "react";
import { transformations } from "@be/modules/xmlParser/xmlParser.transformations";
import type { PathSelectProps } from "../-utils/types";

export function PathSelect({
  value,
  transforms = [],
  onChange,
  placeholder,
  required = false,
  availablePaths,
  isArrayField = false,
}: PathSelectProps) {
  const [params, setParams] = useState<Record<number, Record<string, string>>>(
    Object.fromEntries(transforms.map((t, i) => [i, t.params ?? {}])),
  );

  const getArrayPaths = (paths: string[]) => {
    const basePaths = new Set<string>();

    paths.forEach((path) => {
      // Handle array paths (ending with .0)
      if (path.match(/\.0$/)) {
        basePaths.add(path.replace(/\.0$/, ""));
      }
      // Handle object paths (check if parent path exists)
      const parentPath = path.split(".").slice(0, -1).join(".");
      if (paths.includes(parentPath)) {
        basePaths.add(parentPath);
      }
    });

    return Array.from(basePaths);
  };

  const getObjectPaths = (paths: string[]) => {
    const objectPaths = new Set<string>();

    paths.forEach((path) => {
      // Add the path itself
      objectPaths.add(path);

      // Add parent paths that have child paths
      const parts = path.split(".");
      for (let i = 1; i < parts.length; i++) {
        const parentPath = parts.slice(0, i).join(".");
        if (paths.some((p) => p.startsWith(parentPath + "."))) {
          objectPaths.add(parentPath);
        }
      }
    });

    return Array.from(objectPaths);
  };

  const arrayPaths = isArrayField ? getArrayPaths(availablePaths) : [];
  const displayPaths = isArrayField
    ? arrayPaths
    : getObjectPaths(availablePaths);

  const addTransform = () => {
    onChange(value, [...transforms, { id: "" }]);
  };

  const removeTransform = (index: number) => {
    const newTransforms = transforms.filter((_, i) => i !== index);
    onChange(value, newTransforms);
  };

  const updateTransform = (
    index: number,
    transformId: string,
    transformParams?: Record<string, string>,
  ) => {
    const newTransforms = [...transforms];
    newTransforms[index] = { id: transformId, params: transformParams };
    if (!transformParams) {
      setParams((prevParams) => ({
        ...prevParams,
        [index]: {},
      }));
    }
    onChange(value, newTransforms);
  };

  return (
    <div className="space-y-2">
      <div className="flex gap-2">
        <select
          value={value}
          onChange={(e) => onChange(e.target.value, transforms)}
          className="w-full rounded border p-2 text-sm"
          required={required}
        >
          <option value="">{placeholder}</option>
          {displayPaths.map((path) => (
            <option key={path} value={path}>
              {path} {isArrayField ? " (Array)" : ""}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={addTransform}
          className="rounded bg-blue-500 px-2 py-1 text-white hover:bg-blue-600"
        >
          Add Transform
        </button>
      </div>

      {transforms.map((transform, index) => {
        const selectedTransform = transformations.find(
          (t) => t.id === transform.id,
        );
        return (
          <div key={index} className="ml-4 border-l-2 border-gray-200 pl-2">
            <div className="flex items-center gap-2">
              <select
                value={transform.id}
                onChange={(e) => updateTransform(index, e.target.value)}
                className="rounded border p-2 text-sm"
              >
                <option value="">Select transform</option>
                {transformations.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={() => removeTransform(index)}
                className="rounded bg-red-500 px-2 py-1 text-white hover:bg-red-600"
              >
                Remove
              </button>
            </div>

            {selectedTransform?.parameters && (
              <div className="mt-2 space-y-2">
                {selectedTransform.parameters.map((param) => (
                  <div key={param.name} className="flex items-center gap-2">
                    <label className="text-sm font-medium">
                      {param.description}:
                    </label>
                    <input
                      type="text"
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      value={params[index] ? params[index][param.name] : ""}
                      onChange={(e) => {
                        const newParams = {
                          ...params,
                          [index]: {
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            ...(params[index] ?? {}),
                            [param.name]: e.target.value,
                          },
                        };
                        setParams(newParams);
                        updateTransform(index, transform.id, newParams[index]);
                      }}
                      className="flex-1 rounded border p-1 text-sm"
                      placeholder={param.name}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
