import { useConversation } from "../../-hooks/useConversation.hook";
import { useUpsertCustomer } from "../../-hooks/useUpsertCustomer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { customerFormSchema } from "../../-utils/customerFormSchema";
import { CustomerForm } from "../../-utils/customerFormSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { ConversationDetailSection } from "./ConversationDetailSection";

export function CustomerSection() {
  const conversation = useConversation();
  const { customer } = conversation;

  const [isEditing, setIsEditing] = useState(false);

  const { onSubmit, isLoading } = useUpsertCustomer({
    setIsEditing,
  });

  const form = useForm<CustomerForm>({
    resolver: zodResolver(customerFormSchema),
    defaultValues: {
      email: customer?.email ?? "",
      name: customer?.name ?? "",
      phone: customer?.phone ?? "",
    },
  });

  return (
    <div>
      <div className="flex items-center justify-between pl-7 pr-7 pt-4 text-xs font-medium uppercase">
        <span>Customer Details</span>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsEditing(!isEditing)}
          className="text-xs"
        >
          {isEditing ? "Cancel" : "Edit"}
        </Button>
      </div>
      {isEditing ? (
        <div className="px-7 py-4">
          <Form {...form}>
            <form
              onSubmit={(e) => {
                void form.handleSubmit(onSubmit)(e);
              }}
              className="flex flex-col gap-2"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="name@example.com"
                        {...field}
                        className="shadow-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                      <Input
                        placeholder="Full name (optional)"
                        {...field}
                        className="shadow-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone</FormLabel>
                    <FormControl>
                      {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                      <Input
                        placeholder="Phone number (optional)"
                        {...field}
                        className="shadow-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" disabled={isLoading} className="mt-2">
                {isLoading ? "Updating..." : "Update Customer"}
              </Button>
            </form>
          </Form>
        </div>
      ) : (
        <ConversationDetailSection
          details={[
            ...(customer?.name
              ? [
                  {
                    label: "Name:",
                    value: customer.name,
                    copy: true,
                  },
                ]
              : []),
            {
              label: "Email:",
              value: customer?.email ?? "",
              copy: true,
            },
            ...(customer?.phone
              ? [
                  {
                    label: "Phone:",
                    value: customer.phone,
                    copy: true,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
}
