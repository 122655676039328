import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MessageTopicForm,
  messageTopicFormSchema,
} from "../-utils/messageTopicFormSchema";
import { MessageTopicsFormBody } from "./MessageTopicsFormBody";
import { toast } from "sonner";
import { useCreateMessageTopics } from "../-hooks/useCreateMessageTopics";
import { formatPayload } from "../-utils/formatPayload";
import { Route } from "..";

export function CreateMessageTopicsForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const form = useForm<MessageTopicForm>({
    resolver: zodResolver(messageTopicFormSchema),
    defaultValues: {
      topics: "",
    },
  });

  const { onSubmit: onSubmitMutation } = useCreateMessageTopics({ setOpen });

  const [topics] =
    trpc.dashboard.conversations.messageTopics.getConfig.useSuspenseQuery({
      businessSlug,
    });

  const onSubmit = (data: MessageTopicForm) => {
    const payload = formatPayload(data);

    const otherInTopics = payload.topics.filter(
      (topic) => topic.toLowerCase() === "other",
    );

    if (otherInTopics.length > 0) {
      form.setError("topics", {
        message: "'Other' is a reserved default topic and cannot be added",
      });

      return;
    }

    const currentCount = topics.length;

    if (currentCount + payload.topics.length > 99) {
      form.setError("topics", {
        message: `Maximum of 99 topics allowed. You can add ${
          99 - currentCount
        } more. Current list includes ${payload.topics.length} topics, remove ${
          payload.topics.length - (99 - currentCount)
        }.`,
      });

      return;
    }

    onSubmitMutation(payload);
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to create message topics");
  };

  return (
    <MessageTopicsFormBody form={form} onSubmit={onSubmit} onError={onError} />
  );
}
