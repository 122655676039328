/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as OrganizationImport } from './routes/organization'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as OrganizationIndexImport } from './routes/organization/index'
import { Route as ConnectIndexImport } from './routes/connect/index'
import { Route as PublicRoutesWelcomeImport } from './routes/public-routes/welcome'
import { Route as ConnectSuccessImport } from './routes/connect/success'
import { Route as ConnectSelectionImport } from './routes/connect/selection'
import { Route as ConnectFailImport } from './routes/connect/fail'
import { Route as PublicRoutesTestWhatsappWaMeIconIndexImport } from './routes/public-routes/test-whatsapp-wa-me-icon/index'
import { Route as PublicRoutesRateConversationIndexImport } from './routes/public-routes/rate-conversation/index'
import { Route as PublicRoutesAthletesFootOrderUploadIndexImport } from './routes/public-routes/athletes-foot-order-upload/index'
import { Route as ConnectSignupIndexImport } from './routes/connect/signup/index'
import { Route as ConnectDemoIndexImport } from './routes/connect/demo/index'
import { Route as AuthSignUpIndexImport } from './routes/_auth/sign-up/index'
import { Route as AuthLoginIndexImport } from './routes/_auth/login/index'
import { Route as PublicRoutesRateConversationSuccessImport } from './routes/public-routes/rate-conversation/success'
import { Route as OrganizationOrganizationSlugOnboardingImport } from './routes/organization/$organizationSlug/onboarding'
import { Route as OrganizationOrganizationSlugNavbarImport } from './routes/organization/$organizationSlug/_navbar'
import { Route as OrganizationOrganizationSlugOnboardingDemoSuccessImport } from './routes/organization/$organizationSlug/onboarding/demo-success'
import { Route as OrganizationOrganizationSlugOnboardingToneIndexImport } from './routes/organization/$organizationSlug/onboarding/tone/index'
import { Route as OrganizationOrganizationSlugOnboardingPlatformIndexImport } from './routes/organization/$organizationSlug/onboarding/platform/index'
import { Route as OrganizationOrganizationSlugOnboardingPlanIndexImport } from './routes/organization/$organizationSlug/onboarding/plan/index'
import { Route as OrganizationOrganizationSlugOnboardingKnowledgeIndexImport } from './routes/organization/$organizationSlug/onboarding/knowledge/index'
import { Route as OrganizationOrganizationSlugOnboardingHelpDeskIndexImport } from './routes/organization/$organizationSlug/onboarding/help-desk/index'
import { Route as OrganizationOrganizationSlugOnboardingFeaturesIndexImport } from './routes/organization/$organizationSlug/onboarding/features/index'
import { Route as OrganizationOrganizationSlugNavbarSetupIndexImport } from './routes/organization/$organizationSlug/_navbar/setup/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/index'
import { Route as OrganizationOrganizationSlugNavbarMetricsIndexImport } from './routes/organization/$organizationSlug/_navbar/metrics/index'
import { Route as OrganizationOrganizationSlugNavbarMetricsOldIndexImport } from './routes/organization/$organizationSlug/_navbar/metrics-old/index'
import { Route as OrganizationOrganizationSlugNavbarDemoIndexImport } from './routes/organization/$organizationSlug/_navbar/demo/index'
import { Route as OrganizationOrganizationSlugNavbarAccountSettingsIndexImport } from './routes/organization/$organizationSlug/_navbar/account-settings/index'
import { Route as OrganizationOrganizationSlugOnboardingPlatformWoocommerceImport } from './routes/organization/$organizationSlug/onboarding/platform/woocommerce'
import { Route as OrganizationOrganizationSlugOnboardingKnowledgeWebsitesImport } from './routes/organization/$organizationSlug/onboarding/knowledge/websites'
import { Route as OrganizationOrganizationSlugOnboardingKnowledgeArticleImport } from './routes/organization/$organizationSlug/onboarding/knowledge/article'
import { Route as OrganizationOrganizationSlugOnboardingHelpDeskZendeskImport } from './routes/organization/$organizationSlug/onboarding/help-desk/zendesk'
import { Route as OrganizationOrganizationSlugOnboardingHelpDeskGorgiasImport } from './routes/organization/$organizationSlug/onboarding/help-desk/gorgias'
import { Route as OrganizationOrganizationSlugOnboardingHelpDeskEmailImport } from './routes/organization/$organizationSlug/onboarding/help-desk/email'
import { Route as OrganizationOrganizationSlugNavbarConversationConversationSidebarImport } from './routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar'
import { Route as OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListImport } from './routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList'
import { Route as OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexImport } from './routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/index'
import { Route as OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexImport } from './routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/index'
import { Route as OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexImport } from './routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/index'
import { Route as OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexImport } from './routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/index'
import { Route as OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditImport } from './routes/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit'

// Create Virtual Routes

const OrganizationOrganizationSlugImport = createFileRoute(
  '/organization/$organizationSlug',
)()
const OrganizationOrganizationSlugNavbarConversationImport = createFileRoute(
  '/organization/$organizationSlug/_navbar/conversation',
)()
const OrganizationOrganizationSlugNavbarSettingsBusinessSlugImport =
  createFileRoute(
    '/organization/$organizationSlug/_navbar/settings/$businessSlug',
  )()

// Create/Update Routes

const OrganizationRoute = OrganizationImport.update({
  id: '/organization',
  path: '/organization',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const OrganizationOrganizationSlugRoute =
  OrganizationOrganizationSlugImport.update({
    id: '/$organizationSlug',
    path: '/$organizationSlug',
    getParentRoute: () => OrganizationRoute,
  } as any)

const OrganizationIndexRoute = OrganizationIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OrganizationRoute,
} as any)

const ConnectIndexRoute = ConnectIndexImport.update({
  id: '/connect/',
  path: '/connect/',
  getParentRoute: () => rootRoute,
} as any)

const PublicRoutesWelcomeRoute = PublicRoutesWelcomeImport.update({
  id: '/public-routes/welcome',
  path: '/public-routes/welcome',
  getParentRoute: () => rootRoute,
} as any)

const ConnectSuccessRoute = ConnectSuccessImport.update({
  id: '/connect/success',
  path: '/connect/success',
  getParentRoute: () => rootRoute,
} as any)

const ConnectSelectionRoute = ConnectSelectionImport.update({
  id: '/connect/selection',
  path: '/connect/selection',
  getParentRoute: () => rootRoute,
} as any)

const ConnectFailRoute = ConnectFailImport.update({
  id: '/connect/fail',
  path: '/connect/fail',
  getParentRoute: () => rootRoute,
} as any)

const PublicRoutesTestWhatsappWaMeIconIndexRoute =
  PublicRoutesTestWhatsappWaMeIconIndexImport.update({
    id: '/public-routes/test-whatsapp-wa-me-icon/',
    path: '/public-routes/test-whatsapp-wa-me-icon/',
    getParentRoute: () => rootRoute,
  } as any)

const PublicRoutesRateConversationIndexRoute =
  PublicRoutesRateConversationIndexImport.update({
    id: '/public-routes/rate-conversation/',
    path: '/public-routes/rate-conversation/',
    getParentRoute: () => rootRoute,
  } as any)

const PublicRoutesAthletesFootOrderUploadIndexRoute =
  PublicRoutesAthletesFootOrderUploadIndexImport.update({
    id: '/public-routes/athletes-foot-order-upload/',
    path: '/public-routes/athletes-foot-order-upload/',
    getParentRoute: () => rootRoute,
  } as any)

const ConnectSignupIndexRoute = ConnectSignupIndexImport.update({
  id: '/connect/signup/',
  path: '/connect/signup/',
  getParentRoute: () => rootRoute,
} as any)

const ConnectDemoIndexRoute = ConnectDemoIndexImport.update({
  id: '/connect/demo/',
  path: '/connect/demo/',
  getParentRoute: () => rootRoute,
} as any)

const AuthSignUpIndexRoute = AuthSignUpIndexImport.update({
  id: '/sign-up/',
  path: '/sign-up/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginIndexRoute = AuthLoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => AuthRoute,
} as any)

const PublicRoutesRateConversationSuccessRoute =
  PublicRoutesRateConversationSuccessImport.update({
    id: '/public-routes/rate-conversation/success',
    path: '/public-routes/rate-conversation/success',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingRoute =
  OrganizationOrganizationSlugOnboardingImport.update({
    id: '/onboarding',
    path: '/onboarding',
    getParentRoute: () => OrganizationOrganizationSlugRoute,
  } as any)

const OrganizationOrganizationSlugNavbarRoute =
  OrganizationOrganizationSlugNavbarImport.update({
    id: '/_navbar',
    getParentRoute: () => OrganizationOrganizationSlugRoute,
  } as any)

const OrganizationOrganizationSlugNavbarConversationRoute =
  OrganizationOrganizationSlugNavbarConversationImport.update({
    id: '/conversation',
    path: '/conversation',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingDemoSuccessRoute =
  OrganizationOrganizationSlugOnboardingDemoSuccessImport.update({
    id: '/demo-success',
    path: '/demo-success',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugImport.update({
    id: '/settings/$businessSlug',
    path: '/settings/$businessSlug',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingToneIndexRoute =
  OrganizationOrganizationSlugOnboardingToneIndexImport.update({
    id: '/tone/',
    path: '/tone/',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingPlatformIndexRoute =
  OrganizationOrganizationSlugOnboardingPlatformIndexImport.update({
    id: '/platform/',
    path: '/platform/',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingPlanIndexRoute =
  OrganizationOrganizationSlugOnboardingPlanIndexImport.update({
    id: '/plan/',
    path: '/plan/',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute =
  OrganizationOrganizationSlugOnboardingKnowledgeIndexImport.update({
    id: '/knowledge/',
    path: '/knowledge/',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute =
  OrganizationOrganizationSlugOnboardingHelpDeskIndexImport.update({
    id: '/help-desk/',
    path: '/help-desk/',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingFeaturesIndexRoute =
  OrganizationOrganizationSlugOnboardingFeaturesIndexImport.update({
    id: '/features/',
    path: '/features/',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugNavbarSetupIndexRoute =
  OrganizationOrganizationSlugNavbarSetupIndexImport.update({
    id: '/setup/',
    path: '/setup/',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugNavbarSettingsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugNavbarMetricsIndexRoute =
  OrganizationOrganizationSlugNavbarMetricsIndexImport.update({
    id: '/metrics/',
    path: '/metrics/',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugNavbarMetricsOldIndexRoute =
  OrganizationOrganizationSlugNavbarMetricsOldIndexImport.update({
    id: '/metrics-old/',
    path: '/metrics-old/',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugNavbarDemoIndexRoute =
  OrganizationOrganizationSlugNavbarDemoIndexImport.update({
    id: '/demo/',
    path: '/demo/',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute =
  OrganizationOrganizationSlugNavbarAccountSettingsIndexImport.update({
    id: '/account-settings/',
    path: '/account-settings/',
    getParentRoute: () => OrganizationOrganizationSlugNavbarRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute =
  OrganizationOrganizationSlugOnboardingPlatformWoocommerceImport.update({
    id: '/platform/woocommerce',
    path: '/platform/woocommerce',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute =
  OrganizationOrganizationSlugOnboardingKnowledgeWebsitesImport.update({
    id: '/knowledge/websites',
    path: '/knowledge/websites',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute =
  OrganizationOrganizationSlugOnboardingKnowledgeArticleImport.update({
    id: '/knowledge/article',
    path: '/knowledge/article',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute =
  OrganizationOrganizationSlugOnboardingHelpDeskZendeskImport.update({
    id: '/help-desk/zendesk',
    path: '/help-desk/zendesk',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute =
  OrganizationOrganizationSlugOnboardingHelpDeskGorgiasImport.update({
    id: '/help-desk/gorgias',
    path: '/help-desk/gorgias',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute =
  OrganizationOrganizationSlugOnboardingHelpDeskEmailImport.update({
    id: '/help-desk/email',
    path: '/help-desk/email',
    getParentRoute: () => OrganizationOrganizationSlugOnboardingRoute,
  } as any)

const OrganizationOrganizationSlugNavbarConversationConversationSidebarRoute =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarImport.update(
    {
      id: '/_conversationSidebar',
      getParentRoute: () => OrganizationOrganizationSlugNavbarConversationRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport.update(
    {
      id: '/_config-sidebar',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListImport.update(
    {
      id: '/_conversationList',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarConversationConversationSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexImport.update(
    {
      id: '/views/',
      path: '/views/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarConversationConversationSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackImport.update(
    {
      id: '/feedback',
      path: '/feedback',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexImport.update(
    {
      id: '/xmlParser/',
      path: '/xmlParser/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexImport.update(
    {
      id: '/web-chat/',
      path: '/web-chat/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexImport.update(
    {
      id: '/tickets/',
      path: '/tickets/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexImport.update(
    {
      id: '/threats/',
      path: '/threats/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexImport.update(
    {
      id: '/team/',
      path: '/team/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexImport.update(
    {
      id: '/simple-flows/',
      path: '/simple-flows/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexImport.update(
    {
      id: '/product-search/',
      path: '/product-search/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexImport.update(
    {
      id: '/message-topics/',
      path: '/message-topics/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexImport.update(
    {
      id: '/macros/',
      path: '/macros/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexImport.update(
    {
      id: '/knowledge/',
      path: '/knowledge/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexImport.update(
    {
      id: '/integrations/',
      path: '/integrations/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexImport.update(
    {
      id: '/email/',
      path: '/email/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexImport.update(
    {
      id: '/email-rules/',
      path: '/email-rules/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexImport.update(
    {
      id: '/customer-tags/',
      path: '/customer-tags/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexImport.update(
    {
      id: '/custom-flows/',
      path: '/custom-flows/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexImport.update(
    {
      id: '/business/',
      path: '/business/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexImport.update(
    {
      id: '/bot-config/',
      path: '/bot-config/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexImport.update(
    {
      id: '/billing/',
      path: '/billing/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexImport.update(
    {
      id: '/new/',
      path: '/new/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexImport.update(
    {
      id: '/$publicId/',
      path: '/$publicId/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestImport.update(
    {
      id: '/integrations/orbio-world-reviews-test',
      path: '/integrations/orbio-world-reviews-test',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessImport.update(
    {
      id: '/integrations/fq-google-my-business',
      path: '/integrations/fq-google-my-business',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewImport.update(
    {
      id: '/customer-tags/new',
      path: '/customer-tags/new',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexImport.update(
    {
      id: '/knowledge/websites/',
      path: '/knowledge/websites/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexImport.update(
    {
      id: '/knowledge/products/',
      path: '/knowledge/products/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexImport.update(
    {
      id: '/knowledge/documents/',
      path: '/knowledge/documents/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexImport.update(
    {
      id: '/knowledge/bot-rules/',
      path: '/knowledge/bot-rules/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexImport.update(
    {
      id: '/knowledge/articles/',
      path: '/knowledge/articles/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexImport.update(
    {
      id: '/customer-tags/$tagId/',
      path: '/customer-tags/$tagId/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexImport.update(
    {
      id: '/custom-flows/templates/',
      path: '/custom-flows/templates/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexImport.update(
    {
      id: '/custom-flows/new/',
      path: '/custom-flows/new/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexImport.update(
    {
      id: '/custom-flows/$flowId/',
      path: '/custom-flows/$flowId/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsImport.update(
    {
      id: '/knowledge/websites/context-elements',
      path: '/knowledge/websites/context-elements',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexImport.update(
    {
      id: '/knowledge/products/new/',
      path: '/knowledge/products/new/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexImport.update(
    {
      id: '/knowledge/products/$productSlug/',
      path: '/knowledge/products/$productSlug/',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditImport.update(
    {
      id: '/knowledge/products/$productSlug/edit',
      path: '/knowledge/products/$productSlug/edit',
      getParentRoute: () =>
        OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/organization': {
      id: '/organization'
      path: '/organization'
      fullPath: '/organization'
      preLoaderRoute: typeof OrganizationImport
      parentRoute: typeof rootRoute
    }
    '/connect/fail': {
      id: '/connect/fail'
      path: '/connect/fail'
      fullPath: '/connect/fail'
      preLoaderRoute: typeof ConnectFailImport
      parentRoute: typeof rootRoute
    }
    '/connect/selection': {
      id: '/connect/selection'
      path: '/connect/selection'
      fullPath: '/connect/selection'
      preLoaderRoute: typeof ConnectSelectionImport
      parentRoute: typeof rootRoute
    }
    '/connect/success': {
      id: '/connect/success'
      path: '/connect/success'
      fullPath: '/connect/success'
      preLoaderRoute: typeof ConnectSuccessImport
      parentRoute: typeof rootRoute
    }
    '/public-routes/welcome': {
      id: '/public-routes/welcome'
      path: '/public-routes/welcome'
      fullPath: '/public-routes/welcome'
      preLoaderRoute: typeof PublicRoutesWelcomeImport
      parentRoute: typeof rootRoute
    }
    '/connect/': {
      id: '/connect/'
      path: '/connect'
      fullPath: '/connect'
      preLoaderRoute: typeof ConnectIndexImport
      parentRoute: typeof rootRoute
    }
    '/organization/': {
      id: '/organization/'
      path: '/'
      fullPath: '/organization/'
      preLoaderRoute: typeof OrganizationIndexImport
      parentRoute: typeof OrganizationImport
    }
    '/organization/$organizationSlug': {
      id: '/organization/$organizationSlug'
      path: '/$organizationSlug'
      fullPath: '/organization/$organizationSlug'
      preLoaderRoute: typeof OrganizationOrganizationSlugImport
      parentRoute: typeof OrganizationImport
    }
    '/organization/$organizationSlug/_navbar': {
      id: '/organization/$organizationSlug/_navbar'
      path: '/$organizationSlug'
      fullPath: '/organization/$organizationSlug'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarImport
      parentRoute: typeof OrganizationOrganizationSlugRoute
    }
    '/organization/$organizationSlug/onboarding': {
      id: '/organization/$organizationSlug/onboarding'
      path: '/onboarding'
      fullPath: '/organization/$organizationSlug/onboarding'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingImport
      parentRoute: typeof OrganizationOrganizationSlugImport
    }
    '/public-routes/rate-conversation/success': {
      id: '/public-routes/rate-conversation/success'
      path: '/public-routes/rate-conversation/success'
      fullPath: '/public-routes/rate-conversation/success'
      preLoaderRoute: typeof PublicRoutesRateConversationSuccessImport
      parentRoute: typeof rootRoute
    }
    '/_auth/login/': {
      id: '/_auth/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/sign-up/': {
      id: '/_auth/sign-up/'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof AuthSignUpIndexImport
      parentRoute: typeof AuthImport
    }
    '/connect/demo/': {
      id: '/connect/demo/'
      path: '/connect/demo'
      fullPath: '/connect/demo'
      preLoaderRoute: typeof ConnectDemoIndexImport
      parentRoute: typeof rootRoute
    }
    '/connect/signup/': {
      id: '/connect/signup/'
      path: '/connect/signup'
      fullPath: '/connect/signup'
      preLoaderRoute: typeof ConnectSignupIndexImport
      parentRoute: typeof rootRoute
    }
    '/public-routes/athletes-foot-order-upload/': {
      id: '/public-routes/athletes-foot-order-upload/'
      path: '/public-routes/athletes-foot-order-upload'
      fullPath: '/public-routes/athletes-foot-order-upload'
      preLoaderRoute: typeof PublicRoutesAthletesFootOrderUploadIndexImport
      parentRoute: typeof rootRoute
    }
    '/public-routes/rate-conversation/': {
      id: '/public-routes/rate-conversation/'
      path: '/public-routes/rate-conversation'
      fullPath: '/public-routes/rate-conversation'
      preLoaderRoute: typeof PublicRoutesRateConversationIndexImport
      parentRoute: typeof rootRoute
    }
    '/public-routes/test-whatsapp-wa-me-icon/': {
      id: '/public-routes/test-whatsapp-wa-me-icon/'
      path: '/public-routes/test-whatsapp-wa-me-icon'
      fullPath: '/public-routes/test-whatsapp-wa-me-icon'
      preLoaderRoute: typeof PublicRoutesTestWhatsappWaMeIconIndexImport
      parentRoute: typeof rootRoute
    }
    '/organization/$organizationSlug/onboarding/demo-success': {
      id: '/organization/$organizationSlug/onboarding/demo-success'
      path: '/demo-success'
      fullPath: '/organization/$organizationSlug/onboarding/demo-success'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingDemoSuccessImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/_navbar/conversation': {
      id: '/organization/$organizationSlug/_navbar/conversation'
      path: '/conversation'
      fullPath: '/organization/$organizationSlug/conversation'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar': {
      id: '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar'
      path: '/conversation'
      fullPath: '/organization/$organizationSlug/conversation'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarConversationRoute
    }
    '/organization/$organizationSlug/onboarding/help-desk/email': {
      id: '/organization/$organizationSlug/onboarding/help-desk/email'
      path: '/help-desk/email'
      fullPath: '/organization/$organizationSlug/onboarding/help-desk/email'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskEmailImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/help-desk/gorgias': {
      id: '/organization/$organizationSlug/onboarding/help-desk/gorgias'
      path: '/help-desk/gorgias'
      fullPath: '/organization/$organizationSlug/onboarding/help-desk/gorgias'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskGorgiasImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/help-desk/zendesk': {
      id: '/organization/$organizationSlug/onboarding/help-desk/zendesk'
      path: '/help-desk/zendesk'
      fullPath: '/organization/$organizationSlug/onboarding/help-desk/zendesk'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskZendeskImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/knowledge/article': {
      id: '/organization/$organizationSlug/onboarding/knowledge/article'
      path: '/knowledge/article'
      fullPath: '/organization/$organizationSlug/onboarding/knowledge/article'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingKnowledgeArticleImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/knowledge/websites': {
      id: '/organization/$organizationSlug/onboarding/knowledge/websites'
      path: '/knowledge/websites'
      fullPath: '/organization/$organizationSlug/onboarding/knowledge/websites'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingKnowledgeWebsitesImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/platform/woocommerce': {
      id: '/organization/$organizationSlug/onboarding/platform/woocommerce'
      path: '/platform/woocommerce'
      fullPath: '/organization/$organizationSlug/onboarding/platform/woocommerce'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingPlatformWoocommerceImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/_navbar/account-settings/': {
      id: '/organization/$organizationSlug/_navbar/account-settings/'
      path: '/account-settings'
      fullPath: '/organization/$organizationSlug/account-settings'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarAccountSettingsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/demo/': {
      id: '/organization/$organizationSlug/_navbar/demo/'
      path: '/demo'
      fullPath: '/organization/$organizationSlug/demo'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarDemoIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/metrics-old/': {
      id: '/organization/$organizationSlug/_navbar/metrics-old/'
      path: '/metrics-old'
      fullPath: '/organization/$organizationSlug/metrics-old'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarMetricsOldIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/metrics/': {
      id: '/organization/$organizationSlug/_navbar/metrics/'
      path: '/metrics'
      fullPath: '/organization/$organizationSlug/metrics'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarMetricsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/settings/': {
      id: '/organization/$organizationSlug/_navbar/settings/'
      path: '/settings'
      fullPath: '/organization/$organizationSlug/settings'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/setup/': {
      id: '/organization/$organizationSlug/_navbar/setup/'
      path: '/setup'
      fullPath: '/organization/$organizationSlug/setup'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSetupIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/onboarding/features/': {
      id: '/organization/$organizationSlug/onboarding/features/'
      path: '/features'
      fullPath: '/organization/$organizationSlug/onboarding/features'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingFeaturesIndexImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/help-desk/': {
      id: '/organization/$organizationSlug/onboarding/help-desk/'
      path: '/help-desk'
      fullPath: '/organization/$organizationSlug/onboarding/help-desk'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskIndexImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/knowledge/': {
      id: '/organization/$organizationSlug/onboarding/knowledge/'
      path: '/knowledge'
      fullPath: '/organization/$organizationSlug/onboarding/knowledge'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingKnowledgeIndexImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/plan/': {
      id: '/organization/$organizationSlug/onboarding/plan/'
      path: '/plan'
      fullPath: '/organization/$organizationSlug/onboarding/plan'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingPlanIndexImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/platform/': {
      id: '/organization/$organizationSlug/onboarding/platform/'
      path: '/platform'
      fullPath: '/organization/$organizationSlug/onboarding/platform'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingPlatformIndexImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/onboarding/tone/': {
      id: '/organization/$organizationSlug/onboarding/tone/'
      path: '/tone'
      fullPath: '/organization/$organizationSlug/onboarding/tone'
      preLoaderRoute: typeof OrganizationOrganizationSlugOnboardingToneIndexImport
      parentRoute: typeof OrganizationOrganizationSlugOnboardingImport
    }
    '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList': {
      id: '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList'
      path: ''
      fullPath: '/organization/$organizationSlug/conversation'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug'
      path: '/settings/$businessSlug'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar'
      path: '/settings/$businessSlug'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugRoute
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback'
      path: '/feedback'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/feedback'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/': {
      id: '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/'
      path: '/'
      fullPath: '/organization/$organizationSlug/conversation/'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListImport
    }
    '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/': {
      id: '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/'
      path: '/views'
      fullPath: '/organization/$organizationSlug/conversation/views'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new'
      path: '/customer-tags/new'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/customer-tags/new'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business'
      path: '/integrations/fq-google-my-business'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/integrations/fq-google-my-business'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test'
      path: '/integrations/orbio-world-reviews-test'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/integrations/orbio-world-reviews-test'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/': {
      id: '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/'
      path: '/$publicId'
      fullPath: '/organization/$organizationSlug/conversation/$publicId'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListImport
    }
    '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/': {
      id: '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/'
      path: '/new'
      fullPath: '/organization/$organizationSlug/conversation/new'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/'
      path: '/billing'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/billing'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/'
      path: '/bot-config'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/bot-config'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/'
      path: '/business'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/business'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/'
      path: '/custom-flows'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/custom-flows'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/'
      path: '/customer-tags'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/customer-tags'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/'
      path: '/email-rules'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/email-rules'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/'
      path: '/email'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/email'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/'
      path: '/integrations'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/integrations'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/'
      path: '/knowledge'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/'
      path: '/macros'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/macros'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/'
      path: '/message-topics'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/message-topics'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/'
      path: '/product-search'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/product-search'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/'
      path: '/simple-flows'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/simple-flows'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/'
      path: '/team'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/team'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/'
      path: '/threats'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/threats'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/'
      path: '/tickets'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/tickets'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/'
      path: '/web-chat'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/web-chat'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/'
      path: '/xmlParser'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/xmlParser'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements'
      path: '/knowledge/websites/context-elements'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites/context-elements'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/'
      path: '/custom-flows/$flowId'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/custom-flows/$flowId'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/'
      path: '/custom-flows/new'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/custom-flows/new'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/'
      path: '/custom-flows/templates'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/custom-flows/templates'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/'
      path: '/customer-tags/$tagId'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/customer-tags/$tagId'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/'
      path: '/knowledge/articles'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/articles'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/'
      path: '/knowledge/bot-rules'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/bot-rules'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/'
      path: '/knowledge/documents'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/documents'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/'
      path: '/knowledge/products'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/products'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/'
      path: '/knowledge/websites'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit'
      path: '/knowledge/products/$productSlug/edit'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug/edit'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/'
      path: '/knowledge/products/$productSlug'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
    '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/': {
      id: '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/'
      path: '/knowledge/products/new'
      fullPath: '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/new'
      preLoaderRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexImport
      parentRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthLoginIndexRoute: typeof AuthLoginIndexRoute
  AuthSignUpIndexRoute: typeof AuthSignUpIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLoginIndexRoute: AuthLoginIndexRoute,
  AuthSignUpIndexRoute: AuthSignUpIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteChildren {
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute
}

const OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteChildren: OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteChildren =
  {
    OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute:
      OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute,
    OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute:
      OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute,
    OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute:
      OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute,
  }

const OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteWithChildren =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute._addFileChildren(
    OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteChildren,
  )

interface OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteChildren {
  OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteWithChildren
  OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute
}

const OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteChildren: OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteChildren =
  {
    OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRoute:
      OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteWithChildren,
    OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute:
      OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute,
  }

const OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteWithChildren =
  OrganizationOrganizationSlugNavbarConversationConversationSidebarRoute._addFileChildren(
    OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteChildren,
  )

interface OrganizationOrganizationSlugNavbarConversationRouteChildren {
  OrganizationOrganizationSlugNavbarConversationConversationSidebarRoute: typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteWithChildren
}

const OrganizationOrganizationSlugNavbarConversationRouteChildren: OrganizationOrganizationSlugNavbarConversationRouteChildren =
  {
    OrganizationOrganizationSlugNavbarConversationConversationSidebarRoute:
      OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteWithChildren,
  }

const OrganizationOrganizationSlugNavbarConversationRouteWithChildren =
  OrganizationOrganizationSlugNavbarConversationRoute._addFileChildren(
    OrganizationOrganizationSlugNavbarConversationRouteChildren,
  )

interface OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteChildren {
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute
}

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteChildren: OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteChildren =
  {
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute,
  }

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteWithChildren =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute._addFileChildren(
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteChildren,
  )

interface OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteChildren {
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteWithChildren
}

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteChildren: OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteChildren =
  {
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteWithChildren,
  }

const OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteWithChildren =
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugRoute._addFileChildren(
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteChildren,
  )

interface OrganizationOrganizationSlugNavbarRouteChildren {
  OrganizationOrganizationSlugNavbarConversationRoute: typeof OrganizationOrganizationSlugNavbarConversationRouteWithChildren
  OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute: typeof OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute
  OrganizationOrganizationSlugNavbarDemoIndexRoute: typeof OrganizationOrganizationSlugNavbarDemoIndexRoute
  OrganizationOrganizationSlugNavbarMetricsOldIndexRoute: typeof OrganizationOrganizationSlugNavbarMetricsOldIndexRoute
  OrganizationOrganizationSlugNavbarMetricsIndexRoute: typeof OrganizationOrganizationSlugNavbarMetricsIndexRoute
  OrganizationOrganizationSlugNavbarSettingsIndexRoute: typeof OrganizationOrganizationSlugNavbarSettingsIndexRoute
  OrganizationOrganizationSlugNavbarSetupIndexRoute: typeof OrganizationOrganizationSlugNavbarSetupIndexRoute
  OrganizationOrganizationSlugNavbarSettingsBusinessSlugRoute: typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteWithChildren
}

const OrganizationOrganizationSlugNavbarRouteChildren: OrganizationOrganizationSlugNavbarRouteChildren =
  {
    OrganizationOrganizationSlugNavbarConversationRoute:
      OrganizationOrganizationSlugNavbarConversationRouteWithChildren,
    OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute:
      OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute,
    OrganizationOrganizationSlugNavbarDemoIndexRoute:
      OrganizationOrganizationSlugNavbarDemoIndexRoute,
    OrganizationOrganizationSlugNavbarMetricsOldIndexRoute:
      OrganizationOrganizationSlugNavbarMetricsOldIndexRoute,
    OrganizationOrganizationSlugNavbarMetricsIndexRoute:
      OrganizationOrganizationSlugNavbarMetricsIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsIndexRoute:
      OrganizationOrganizationSlugNavbarSettingsIndexRoute,
    OrganizationOrganizationSlugNavbarSetupIndexRoute:
      OrganizationOrganizationSlugNavbarSetupIndexRoute,
    OrganizationOrganizationSlugNavbarSettingsBusinessSlugRoute:
      OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteWithChildren,
  }

const OrganizationOrganizationSlugNavbarRouteWithChildren =
  OrganizationOrganizationSlugNavbarRoute._addFileChildren(
    OrganizationOrganizationSlugNavbarRouteChildren,
  )

interface OrganizationOrganizationSlugOnboardingRouteChildren {
  OrganizationOrganizationSlugOnboardingDemoSuccessRoute: typeof OrganizationOrganizationSlugOnboardingDemoSuccessRoute
  OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute
  OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute
  OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute
  OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute: typeof OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute
  OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute: typeof OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute
  OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute: typeof OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute
  OrganizationOrganizationSlugOnboardingFeaturesIndexRoute: typeof OrganizationOrganizationSlugOnboardingFeaturesIndexRoute
  OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute: typeof OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute
  OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute: typeof OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute
  OrganizationOrganizationSlugOnboardingPlanIndexRoute: typeof OrganizationOrganizationSlugOnboardingPlanIndexRoute
  OrganizationOrganizationSlugOnboardingPlatformIndexRoute: typeof OrganizationOrganizationSlugOnboardingPlatformIndexRoute
  OrganizationOrganizationSlugOnboardingToneIndexRoute: typeof OrganizationOrganizationSlugOnboardingToneIndexRoute
}

const OrganizationOrganizationSlugOnboardingRouteChildren: OrganizationOrganizationSlugOnboardingRouteChildren =
  {
    OrganizationOrganizationSlugOnboardingDemoSuccessRoute:
      OrganizationOrganizationSlugOnboardingDemoSuccessRoute,
    OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute:
      OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute,
    OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute:
      OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute,
    OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute:
      OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute,
    OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute:
      OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute,
    OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute:
      OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute,
    OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute:
      OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute,
    OrganizationOrganizationSlugOnboardingFeaturesIndexRoute:
      OrganizationOrganizationSlugOnboardingFeaturesIndexRoute,
    OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute:
      OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute,
    OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute:
      OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute,
    OrganizationOrganizationSlugOnboardingPlanIndexRoute:
      OrganizationOrganizationSlugOnboardingPlanIndexRoute,
    OrganizationOrganizationSlugOnboardingPlatformIndexRoute:
      OrganizationOrganizationSlugOnboardingPlatformIndexRoute,
    OrganizationOrganizationSlugOnboardingToneIndexRoute:
      OrganizationOrganizationSlugOnboardingToneIndexRoute,
  }

const OrganizationOrganizationSlugOnboardingRouteWithChildren =
  OrganizationOrganizationSlugOnboardingRoute._addFileChildren(
    OrganizationOrganizationSlugOnboardingRouteChildren,
  )

interface OrganizationOrganizationSlugRouteChildren {
  OrganizationOrganizationSlugNavbarRoute: typeof OrganizationOrganizationSlugNavbarRouteWithChildren
  OrganizationOrganizationSlugOnboardingRoute: typeof OrganizationOrganizationSlugOnboardingRouteWithChildren
}

const OrganizationOrganizationSlugRouteChildren: OrganizationOrganizationSlugRouteChildren =
  {
    OrganizationOrganizationSlugNavbarRoute:
      OrganizationOrganizationSlugNavbarRouteWithChildren,
    OrganizationOrganizationSlugOnboardingRoute:
      OrganizationOrganizationSlugOnboardingRouteWithChildren,
  }

const OrganizationOrganizationSlugRouteWithChildren =
  OrganizationOrganizationSlugRoute._addFileChildren(
    OrganizationOrganizationSlugRouteChildren,
  )

interface OrganizationRouteChildren {
  OrganizationIndexRoute: typeof OrganizationIndexRoute
  OrganizationOrganizationSlugRoute: typeof OrganizationOrganizationSlugRouteWithChildren
}

const OrganizationRouteChildren: OrganizationRouteChildren = {
  OrganizationIndexRoute: OrganizationIndexRoute,
  OrganizationOrganizationSlugRoute:
    OrganizationOrganizationSlugRouteWithChildren,
}

const OrganizationRouteWithChildren = OrganizationRoute._addFileChildren(
  OrganizationRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/organization': typeof OrganizationRouteWithChildren
  '/connect/fail': typeof ConnectFailRoute
  '/connect/selection': typeof ConnectSelectionRoute
  '/connect/success': typeof ConnectSuccessRoute
  '/public-routes/welcome': typeof PublicRoutesWelcomeRoute
  '/connect': typeof ConnectIndexRoute
  '/organization/': typeof OrganizationIndexRoute
  '/organization/$organizationSlug': typeof OrganizationOrganizationSlugNavbarRouteWithChildren
  '/organization/$organizationSlug/onboarding': typeof OrganizationOrganizationSlugOnboardingRouteWithChildren
  '/public-routes/rate-conversation/success': typeof PublicRoutesRateConversationSuccessRoute
  '/login': typeof AuthLoginIndexRoute
  '/sign-up': typeof AuthSignUpIndexRoute
  '/connect/demo': typeof ConnectDemoIndexRoute
  '/connect/signup': typeof ConnectSignupIndexRoute
  '/public-routes/athletes-foot-order-upload': typeof PublicRoutesAthletesFootOrderUploadIndexRoute
  '/public-routes/rate-conversation': typeof PublicRoutesRateConversationIndexRoute
  '/public-routes/test-whatsapp-wa-me-icon': typeof PublicRoutesTestWhatsappWaMeIconIndexRoute
  '/organization/$organizationSlug/onboarding/demo-success': typeof OrganizationOrganizationSlugOnboardingDemoSuccessRoute
  '/organization/$organizationSlug/conversation': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteWithChildren
  '/organization/$organizationSlug/onboarding/help-desk/email': typeof OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute
  '/organization/$organizationSlug/onboarding/help-desk/gorgias': typeof OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute
  '/organization/$organizationSlug/onboarding/help-desk/zendesk': typeof OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute
  '/organization/$organizationSlug/onboarding/knowledge/article': typeof OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute
  '/organization/$organizationSlug/onboarding/knowledge/websites': typeof OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute
  '/organization/$organizationSlug/onboarding/platform/woocommerce': typeof OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute
  '/organization/$organizationSlug/account-settings': typeof OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute
  '/organization/$organizationSlug/demo': typeof OrganizationOrganizationSlugNavbarDemoIndexRoute
  '/organization/$organizationSlug/metrics-old': typeof OrganizationOrganizationSlugNavbarMetricsOldIndexRoute
  '/organization/$organizationSlug/metrics': typeof OrganizationOrganizationSlugNavbarMetricsIndexRoute
  '/organization/$organizationSlug/settings': typeof OrganizationOrganizationSlugNavbarSettingsIndexRoute
  '/organization/$organizationSlug/setup': typeof OrganizationOrganizationSlugNavbarSetupIndexRoute
  '/organization/$organizationSlug/onboarding/features': typeof OrganizationOrganizationSlugOnboardingFeaturesIndexRoute
  '/organization/$organizationSlug/onboarding/help-desk': typeof OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute
  '/organization/$organizationSlug/onboarding/knowledge': typeof OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute
  '/organization/$organizationSlug/onboarding/plan': typeof OrganizationOrganizationSlugOnboardingPlanIndexRoute
  '/organization/$organizationSlug/onboarding/platform': typeof OrganizationOrganizationSlugOnboardingPlatformIndexRoute
  '/organization/$organizationSlug/onboarding/tone': typeof OrganizationOrganizationSlugOnboardingToneIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteWithChildren
  '/organization/$organizationSlug/settings/$businessSlug/feedback': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute
  '/organization/$organizationSlug/conversation/': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute
  '/organization/$organizationSlug/conversation/views': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/customer-tags/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute
  '/organization/$organizationSlug/settings/$businessSlug/integrations/fq-google-my-business': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute
  '/organization/$organizationSlug/settings/$businessSlug/integrations/orbio-world-reviews-test': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute
  '/organization/$organizationSlug/conversation/$publicId': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute
  '/organization/$organizationSlug/conversation/new': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/billing': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/bot-config': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/business': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/customer-tags': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/email-rules': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/email': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/integrations': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/macros': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/message-topics': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/product-search': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/simple-flows': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/team': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/threats': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/tickets': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/web-chat': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/xmlParser': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites/context-elements': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows/$flowId': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows/templates': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/customer-tags/$tagId': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/articles': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/bot-rules': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/documents': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug/edit': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/connect/fail': typeof ConnectFailRoute
  '/connect/selection': typeof ConnectSelectionRoute
  '/connect/success': typeof ConnectSuccessRoute
  '/public-routes/welcome': typeof PublicRoutesWelcomeRoute
  '/connect': typeof ConnectIndexRoute
  '/organization': typeof OrganizationIndexRoute
  '/organization/$organizationSlug': typeof OrganizationOrganizationSlugNavbarRouteWithChildren
  '/organization/$organizationSlug/onboarding': typeof OrganizationOrganizationSlugOnboardingRouteWithChildren
  '/public-routes/rate-conversation/success': typeof PublicRoutesRateConversationSuccessRoute
  '/login': typeof AuthLoginIndexRoute
  '/sign-up': typeof AuthSignUpIndexRoute
  '/connect/demo': typeof ConnectDemoIndexRoute
  '/connect/signup': typeof ConnectSignupIndexRoute
  '/public-routes/athletes-foot-order-upload': typeof PublicRoutesAthletesFootOrderUploadIndexRoute
  '/public-routes/rate-conversation': typeof PublicRoutesRateConversationIndexRoute
  '/public-routes/test-whatsapp-wa-me-icon': typeof PublicRoutesTestWhatsappWaMeIconIndexRoute
  '/organization/$organizationSlug/onboarding/demo-success': typeof OrganizationOrganizationSlugOnboardingDemoSuccessRoute
  '/organization/$organizationSlug/conversation': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute
  '/organization/$organizationSlug/onboarding/help-desk/email': typeof OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute
  '/organization/$organizationSlug/onboarding/help-desk/gorgias': typeof OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute
  '/organization/$organizationSlug/onboarding/help-desk/zendesk': typeof OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute
  '/organization/$organizationSlug/onboarding/knowledge/article': typeof OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute
  '/organization/$organizationSlug/onboarding/knowledge/websites': typeof OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute
  '/organization/$organizationSlug/onboarding/platform/woocommerce': typeof OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute
  '/organization/$organizationSlug/account-settings': typeof OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute
  '/organization/$organizationSlug/demo': typeof OrganizationOrganizationSlugNavbarDemoIndexRoute
  '/organization/$organizationSlug/metrics-old': typeof OrganizationOrganizationSlugNavbarMetricsOldIndexRoute
  '/organization/$organizationSlug/metrics': typeof OrganizationOrganizationSlugNavbarMetricsIndexRoute
  '/organization/$organizationSlug/settings': typeof OrganizationOrganizationSlugNavbarSettingsIndexRoute
  '/organization/$organizationSlug/setup': typeof OrganizationOrganizationSlugNavbarSetupIndexRoute
  '/organization/$organizationSlug/onboarding/features': typeof OrganizationOrganizationSlugOnboardingFeaturesIndexRoute
  '/organization/$organizationSlug/onboarding/help-desk': typeof OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute
  '/organization/$organizationSlug/onboarding/knowledge': typeof OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute
  '/organization/$organizationSlug/onboarding/plan': typeof OrganizationOrganizationSlugOnboardingPlanIndexRoute
  '/organization/$organizationSlug/onboarding/platform': typeof OrganizationOrganizationSlugOnboardingPlatformIndexRoute
  '/organization/$organizationSlug/onboarding/tone': typeof OrganizationOrganizationSlugOnboardingToneIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteWithChildren
  '/organization/$organizationSlug/settings/$businessSlug/feedback': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute
  '/organization/$organizationSlug/conversation/views': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/customer-tags/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute
  '/organization/$organizationSlug/settings/$businessSlug/integrations/fq-google-my-business': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute
  '/organization/$organizationSlug/settings/$businessSlug/integrations/orbio-world-reviews-test': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute
  '/organization/$organizationSlug/conversation/$publicId': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute
  '/organization/$organizationSlug/conversation/new': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/billing': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/bot-config': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/business': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/customer-tags': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/email-rules': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/email': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/integrations': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/macros': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/message-topics': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/product-search': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/simple-flows': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/team': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/threats': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/tickets': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/web-chat': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/xmlParser': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites/context-elements': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows/$flowId': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/custom-flows/templates': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/customer-tags/$tagId': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/articles': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/bot-rules': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/documents': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug/edit': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute
  '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/organization': typeof OrganizationRouteWithChildren
  '/connect/fail': typeof ConnectFailRoute
  '/connect/selection': typeof ConnectSelectionRoute
  '/connect/success': typeof ConnectSuccessRoute
  '/public-routes/welcome': typeof PublicRoutesWelcomeRoute
  '/connect/': typeof ConnectIndexRoute
  '/organization/': typeof OrganizationIndexRoute
  '/organization/$organizationSlug': typeof OrganizationOrganizationSlugRouteWithChildren
  '/organization/$organizationSlug/_navbar': typeof OrganizationOrganizationSlugNavbarRouteWithChildren
  '/organization/$organizationSlug/onboarding': typeof OrganizationOrganizationSlugOnboardingRouteWithChildren
  '/public-routes/rate-conversation/success': typeof PublicRoutesRateConversationSuccessRoute
  '/_auth/login/': typeof AuthLoginIndexRoute
  '/_auth/sign-up/': typeof AuthSignUpIndexRoute
  '/connect/demo/': typeof ConnectDemoIndexRoute
  '/connect/signup/': typeof ConnectSignupIndexRoute
  '/public-routes/athletes-foot-order-upload/': typeof PublicRoutesAthletesFootOrderUploadIndexRoute
  '/public-routes/rate-conversation/': typeof PublicRoutesRateConversationIndexRoute
  '/public-routes/test-whatsapp-wa-me-icon/': typeof PublicRoutesTestWhatsappWaMeIconIndexRoute
  '/organization/$organizationSlug/onboarding/demo-success': typeof OrganizationOrganizationSlugOnboardingDemoSuccessRoute
  '/organization/$organizationSlug/_navbar/conversation': typeof OrganizationOrganizationSlugNavbarConversationRouteWithChildren
  '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarRouteWithChildren
  '/organization/$organizationSlug/onboarding/help-desk/email': typeof OrganizationOrganizationSlugOnboardingHelpDeskEmailRoute
  '/organization/$organizationSlug/onboarding/help-desk/gorgias': typeof OrganizationOrganizationSlugOnboardingHelpDeskGorgiasRoute
  '/organization/$organizationSlug/onboarding/help-desk/zendesk': typeof OrganizationOrganizationSlugOnboardingHelpDeskZendeskRoute
  '/organization/$organizationSlug/onboarding/knowledge/article': typeof OrganizationOrganizationSlugOnboardingKnowledgeArticleRoute
  '/organization/$organizationSlug/onboarding/knowledge/websites': typeof OrganizationOrganizationSlugOnboardingKnowledgeWebsitesRoute
  '/organization/$organizationSlug/onboarding/platform/woocommerce': typeof OrganizationOrganizationSlugOnboardingPlatformWoocommerceRoute
  '/organization/$organizationSlug/_navbar/account-settings/': typeof OrganizationOrganizationSlugNavbarAccountSettingsIndexRoute
  '/organization/$organizationSlug/_navbar/demo/': typeof OrganizationOrganizationSlugNavbarDemoIndexRoute
  '/organization/$organizationSlug/_navbar/metrics-old/': typeof OrganizationOrganizationSlugNavbarMetricsOldIndexRoute
  '/organization/$organizationSlug/_navbar/metrics/': typeof OrganizationOrganizationSlugNavbarMetricsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/': typeof OrganizationOrganizationSlugNavbarSettingsIndexRoute
  '/organization/$organizationSlug/_navbar/setup/': typeof OrganizationOrganizationSlugNavbarSetupIndexRoute
  '/organization/$organizationSlug/onboarding/features/': typeof OrganizationOrganizationSlugOnboardingFeaturesIndexRoute
  '/organization/$organizationSlug/onboarding/help-desk/': typeof OrganizationOrganizationSlugOnboardingHelpDeskIndexRoute
  '/organization/$organizationSlug/onboarding/knowledge/': typeof OrganizationOrganizationSlugOnboardingKnowledgeIndexRoute
  '/organization/$organizationSlug/onboarding/plan/': typeof OrganizationOrganizationSlugOnboardingPlanIndexRoute
  '/organization/$organizationSlug/onboarding/platform/': typeof OrganizationOrganizationSlugOnboardingPlatformIndexRoute
  '/organization/$organizationSlug/onboarding/tone/': typeof OrganizationOrganizationSlugOnboardingToneIndexRoute
  '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListRouteWithChildren
  '/organization/$organizationSlug/_navbar/settings/$businessSlug': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugRouteWithChildren
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarRouteWithChildren
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarFeedbackRoute
  '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListIndexRoute
  '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarViewsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsNewRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsFqGoogleMyBusinessRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsOrbioWorldReviewsTestRoute
  '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListPublicIdIndexRoute
  '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/': typeof OrganizationOrganizationSlugNavbarConversationConversationSidebarConversationListNewIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBillingIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBotConfigIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarBusinessIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailRulesIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarEmailIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarIntegrationsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMacrosIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarMessageTopicsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarProductSearchIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarSimpleFlowsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTeamIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarThreatsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarTicketsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarWebChatIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarXmlParserIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesContextElementsRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsFlowIdIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsNewIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomFlowsTemplatesIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarCustomerTagsTagIdIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeArticlesIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeBotRulesIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeDocumentsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeWebsitesIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugEditRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsProductSlugIndexRoute
  '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/': typeof OrganizationOrganizationSlugNavbarSettingsBusinessSlugConfigSidebarKnowledgeProductsNewIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/organization'
    | '/connect/fail'
    | '/connect/selection'
    | '/connect/success'
    | '/public-routes/welcome'
    | '/connect'
    | '/organization/'
    | '/organization/$organizationSlug'
    | '/organization/$organizationSlug/onboarding'
    | '/public-routes/rate-conversation/success'
    | '/login'
    | '/sign-up'
    | '/connect/demo'
    | '/connect/signup'
    | '/public-routes/athletes-foot-order-upload'
    | '/public-routes/rate-conversation'
    | '/public-routes/test-whatsapp-wa-me-icon'
    | '/organization/$organizationSlug/onboarding/demo-success'
    | '/organization/$organizationSlug/conversation'
    | '/organization/$organizationSlug/onboarding/help-desk/email'
    | '/organization/$organizationSlug/onboarding/help-desk/gorgias'
    | '/organization/$organizationSlug/onboarding/help-desk/zendesk'
    | '/organization/$organizationSlug/onboarding/knowledge/article'
    | '/organization/$organizationSlug/onboarding/knowledge/websites'
    | '/organization/$organizationSlug/onboarding/platform/woocommerce'
    | '/organization/$organizationSlug/account-settings'
    | '/organization/$organizationSlug/demo'
    | '/organization/$organizationSlug/metrics-old'
    | '/organization/$organizationSlug/metrics'
    | '/organization/$organizationSlug/settings'
    | '/organization/$organizationSlug/setup'
    | '/organization/$organizationSlug/onboarding/features'
    | '/organization/$organizationSlug/onboarding/help-desk'
    | '/organization/$organizationSlug/onboarding/knowledge'
    | '/organization/$organizationSlug/onboarding/plan'
    | '/organization/$organizationSlug/onboarding/platform'
    | '/organization/$organizationSlug/onboarding/tone'
    | '/organization/$organizationSlug/settings/$businessSlug'
    | '/organization/$organizationSlug/settings/$businessSlug/feedback'
    | '/organization/$organizationSlug/conversation/'
    | '/organization/$organizationSlug/conversation/views'
    | '/organization/$organizationSlug/settings/$businessSlug/customer-tags/new'
    | '/organization/$organizationSlug/settings/$businessSlug/integrations/fq-google-my-business'
    | '/organization/$organizationSlug/settings/$businessSlug/integrations/orbio-world-reviews-test'
    | '/organization/$organizationSlug/conversation/$publicId'
    | '/organization/$organizationSlug/conversation/new'
    | '/organization/$organizationSlug/settings/$businessSlug/billing'
    | '/organization/$organizationSlug/settings/$businessSlug/bot-config'
    | '/organization/$organizationSlug/settings/$businessSlug/business'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows'
    | '/organization/$organizationSlug/settings/$businessSlug/customer-tags'
    | '/organization/$organizationSlug/settings/$businessSlug/email-rules'
    | '/organization/$organizationSlug/settings/$businessSlug/email'
    | '/organization/$organizationSlug/settings/$businessSlug/integrations'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge'
    | '/organization/$organizationSlug/settings/$businessSlug/macros'
    | '/organization/$organizationSlug/settings/$businessSlug/message-topics'
    | '/organization/$organizationSlug/settings/$businessSlug/product-search'
    | '/organization/$organizationSlug/settings/$businessSlug/simple-flows'
    | '/organization/$organizationSlug/settings/$businessSlug/team'
    | '/organization/$organizationSlug/settings/$businessSlug/threats'
    | '/organization/$organizationSlug/settings/$businessSlug/tickets'
    | '/organization/$organizationSlug/settings/$businessSlug/web-chat'
    | '/organization/$organizationSlug/settings/$businessSlug/xmlParser'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites/context-elements'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows/$flowId'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows/new'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows/templates'
    | '/organization/$organizationSlug/settings/$businessSlug/customer-tags/$tagId'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/articles'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/bot-rules'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/documents'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug/edit'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/new'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/connect/fail'
    | '/connect/selection'
    | '/connect/success'
    | '/public-routes/welcome'
    | '/connect'
    | '/organization'
    | '/organization/$organizationSlug'
    | '/organization/$organizationSlug/onboarding'
    | '/public-routes/rate-conversation/success'
    | '/login'
    | '/sign-up'
    | '/connect/demo'
    | '/connect/signup'
    | '/public-routes/athletes-foot-order-upload'
    | '/public-routes/rate-conversation'
    | '/public-routes/test-whatsapp-wa-me-icon'
    | '/organization/$organizationSlug/onboarding/demo-success'
    | '/organization/$organizationSlug/conversation'
    | '/organization/$organizationSlug/onboarding/help-desk/email'
    | '/organization/$organizationSlug/onboarding/help-desk/gorgias'
    | '/organization/$organizationSlug/onboarding/help-desk/zendesk'
    | '/organization/$organizationSlug/onboarding/knowledge/article'
    | '/organization/$organizationSlug/onboarding/knowledge/websites'
    | '/organization/$organizationSlug/onboarding/platform/woocommerce'
    | '/organization/$organizationSlug/account-settings'
    | '/organization/$organizationSlug/demo'
    | '/organization/$organizationSlug/metrics-old'
    | '/organization/$organizationSlug/metrics'
    | '/organization/$organizationSlug/settings'
    | '/organization/$organizationSlug/setup'
    | '/organization/$organizationSlug/onboarding/features'
    | '/organization/$organizationSlug/onboarding/help-desk'
    | '/organization/$organizationSlug/onboarding/knowledge'
    | '/organization/$organizationSlug/onboarding/plan'
    | '/organization/$organizationSlug/onboarding/platform'
    | '/organization/$organizationSlug/onboarding/tone'
    | '/organization/$organizationSlug/settings/$businessSlug'
    | '/organization/$organizationSlug/settings/$businessSlug/feedback'
    | '/organization/$organizationSlug/conversation/views'
    | '/organization/$organizationSlug/settings/$businessSlug/customer-tags/new'
    | '/organization/$organizationSlug/settings/$businessSlug/integrations/fq-google-my-business'
    | '/organization/$organizationSlug/settings/$businessSlug/integrations/orbio-world-reviews-test'
    | '/organization/$organizationSlug/conversation/$publicId'
    | '/organization/$organizationSlug/conversation/new'
    | '/organization/$organizationSlug/settings/$businessSlug/billing'
    | '/organization/$organizationSlug/settings/$businessSlug/bot-config'
    | '/organization/$organizationSlug/settings/$businessSlug/business'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows'
    | '/organization/$organizationSlug/settings/$businessSlug/customer-tags'
    | '/organization/$organizationSlug/settings/$businessSlug/email-rules'
    | '/organization/$organizationSlug/settings/$businessSlug/email'
    | '/organization/$organizationSlug/settings/$businessSlug/integrations'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge'
    | '/organization/$organizationSlug/settings/$businessSlug/macros'
    | '/organization/$organizationSlug/settings/$businessSlug/message-topics'
    | '/organization/$organizationSlug/settings/$businessSlug/product-search'
    | '/organization/$organizationSlug/settings/$businessSlug/simple-flows'
    | '/organization/$organizationSlug/settings/$businessSlug/team'
    | '/organization/$organizationSlug/settings/$businessSlug/threats'
    | '/organization/$organizationSlug/settings/$businessSlug/tickets'
    | '/organization/$organizationSlug/settings/$businessSlug/web-chat'
    | '/organization/$organizationSlug/settings/$businessSlug/xmlParser'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites/context-elements'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows/$flowId'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows/new'
    | '/organization/$organizationSlug/settings/$businessSlug/custom-flows/templates'
    | '/organization/$organizationSlug/settings/$businessSlug/customer-tags/$tagId'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/articles'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/bot-rules'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/documents'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/websites'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug/edit'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug'
    | '/organization/$organizationSlug/settings/$businessSlug/knowledge/products/new'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/organization'
    | '/connect/fail'
    | '/connect/selection'
    | '/connect/success'
    | '/public-routes/welcome'
    | '/connect/'
    | '/organization/'
    | '/organization/$organizationSlug'
    | '/organization/$organizationSlug/_navbar'
    | '/organization/$organizationSlug/onboarding'
    | '/public-routes/rate-conversation/success'
    | '/_auth/login/'
    | '/_auth/sign-up/'
    | '/connect/demo/'
    | '/connect/signup/'
    | '/public-routes/athletes-foot-order-upload/'
    | '/public-routes/rate-conversation/'
    | '/public-routes/test-whatsapp-wa-me-icon/'
    | '/organization/$organizationSlug/onboarding/demo-success'
    | '/organization/$organizationSlug/_navbar/conversation'
    | '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar'
    | '/organization/$organizationSlug/onboarding/help-desk/email'
    | '/organization/$organizationSlug/onboarding/help-desk/gorgias'
    | '/organization/$organizationSlug/onboarding/help-desk/zendesk'
    | '/organization/$organizationSlug/onboarding/knowledge/article'
    | '/organization/$organizationSlug/onboarding/knowledge/websites'
    | '/organization/$organizationSlug/onboarding/platform/woocommerce'
    | '/organization/$organizationSlug/_navbar/account-settings/'
    | '/organization/$organizationSlug/_navbar/demo/'
    | '/organization/$organizationSlug/_navbar/metrics-old/'
    | '/organization/$organizationSlug/_navbar/metrics/'
    | '/organization/$organizationSlug/_navbar/settings/'
    | '/organization/$organizationSlug/_navbar/setup/'
    | '/organization/$organizationSlug/onboarding/features/'
    | '/organization/$organizationSlug/onboarding/help-desk/'
    | '/organization/$organizationSlug/onboarding/knowledge/'
    | '/organization/$organizationSlug/onboarding/plan/'
    | '/organization/$organizationSlug/onboarding/platform/'
    | '/organization/$organizationSlug/onboarding/tone/'
    | '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback'
    | '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/'
    | '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test'
    | '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/'
    | '/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/'
    | '/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  OrganizationRoute: typeof OrganizationRouteWithChildren
  ConnectFailRoute: typeof ConnectFailRoute
  ConnectSelectionRoute: typeof ConnectSelectionRoute
  ConnectSuccessRoute: typeof ConnectSuccessRoute
  PublicRoutesWelcomeRoute: typeof PublicRoutesWelcomeRoute
  ConnectIndexRoute: typeof ConnectIndexRoute
  PublicRoutesRateConversationSuccessRoute: typeof PublicRoutesRateConversationSuccessRoute
  ConnectDemoIndexRoute: typeof ConnectDemoIndexRoute
  ConnectSignupIndexRoute: typeof ConnectSignupIndexRoute
  PublicRoutesAthletesFootOrderUploadIndexRoute: typeof PublicRoutesAthletesFootOrderUploadIndexRoute
  PublicRoutesRateConversationIndexRoute: typeof PublicRoutesRateConversationIndexRoute
  PublicRoutesTestWhatsappWaMeIconIndexRoute: typeof PublicRoutesTestWhatsappWaMeIconIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  OrganizationRoute: OrganizationRouteWithChildren,
  ConnectFailRoute: ConnectFailRoute,
  ConnectSelectionRoute: ConnectSelectionRoute,
  ConnectSuccessRoute: ConnectSuccessRoute,
  PublicRoutesWelcomeRoute: PublicRoutesWelcomeRoute,
  ConnectIndexRoute: ConnectIndexRoute,
  PublicRoutesRateConversationSuccessRoute:
    PublicRoutesRateConversationSuccessRoute,
  ConnectDemoIndexRoute: ConnectDemoIndexRoute,
  ConnectSignupIndexRoute: ConnectSignupIndexRoute,
  PublicRoutesAthletesFootOrderUploadIndexRoute:
    PublicRoutesAthletesFootOrderUploadIndexRoute,
  PublicRoutesRateConversationIndexRoute:
    PublicRoutesRateConversationIndexRoute,
  PublicRoutesTestWhatsappWaMeIconIndexRoute:
    PublicRoutesTestWhatsappWaMeIconIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/organization",
        "/connect/fail",
        "/connect/selection",
        "/connect/success",
        "/public-routes/welcome",
        "/connect/",
        "/public-routes/rate-conversation/success",
        "/connect/demo/",
        "/connect/signup/",
        "/public-routes/athletes-foot-order-upload/",
        "/public-routes/rate-conversation/",
        "/public-routes/test-whatsapp-wa-me-icon/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/login/",
        "/_auth/sign-up/"
      ]
    },
    "/organization": {
      "filePath": "organization.tsx",
      "children": [
        "/organization/",
        "/organization/$organizationSlug"
      ]
    },
    "/connect/fail": {
      "filePath": "connect/fail.tsx"
    },
    "/connect/selection": {
      "filePath": "connect/selection.tsx"
    },
    "/connect/success": {
      "filePath": "connect/success.tsx"
    },
    "/public-routes/welcome": {
      "filePath": "public-routes/welcome.tsx"
    },
    "/connect/": {
      "filePath": "connect/index.tsx"
    },
    "/organization/": {
      "filePath": "organization/index.tsx",
      "parent": "/organization"
    },
    "/organization/$organizationSlug": {
      "filePath": "organization/$organizationSlug",
      "parent": "/organization",
      "children": [
        "/organization/$organizationSlug/_navbar",
        "/organization/$organizationSlug/onboarding"
      ]
    },
    "/organization/$organizationSlug/_navbar": {
      "filePath": "organization/$organizationSlug/_navbar.tsx",
      "parent": "/organization/$organizationSlug",
      "children": [
        "/organization/$organizationSlug/_navbar/conversation",
        "/organization/$organizationSlug/_navbar/account-settings/",
        "/organization/$organizationSlug/_navbar/demo/",
        "/organization/$organizationSlug/_navbar/metrics-old/",
        "/organization/$organizationSlug/_navbar/metrics/",
        "/organization/$organizationSlug/_navbar/settings/",
        "/organization/$organizationSlug/_navbar/setup/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug"
      ]
    },
    "/organization/$organizationSlug/onboarding": {
      "filePath": "organization/$organizationSlug/onboarding.tsx",
      "parent": "/organization/$organizationSlug",
      "children": [
        "/organization/$organizationSlug/onboarding/demo-success",
        "/organization/$organizationSlug/onboarding/help-desk/email",
        "/organization/$organizationSlug/onboarding/help-desk/gorgias",
        "/organization/$organizationSlug/onboarding/help-desk/zendesk",
        "/organization/$organizationSlug/onboarding/knowledge/article",
        "/organization/$organizationSlug/onboarding/knowledge/websites",
        "/organization/$organizationSlug/onboarding/platform/woocommerce",
        "/organization/$organizationSlug/onboarding/features/",
        "/organization/$organizationSlug/onboarding/help-desk/",
        "/organization/$organizationSlug/onboarding/knowledge/",
        "/organization/$organizationSlug/onboarding/plan/",
        "/organization/$organizationSlug/onboarding/platform/",
        "/organization/$organizationSlug/onboarding/tone/"
      ]
    },
    "/public-routes/rate-conversation/success": {
      "filePath": "public-routes/rate-conversation/success.tsx"
    },
    "/_auth/login/": {
      "filePath": "_auth/login/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/sign-up/": {
      "filePath": "_auth/sign-up/index.tsx",
      "parent": "/_auth"
    },
    "/connect/demo/": {
      "filePath": "connect/demo/index.tsx"
    },
    "/connect/signup/": {
      "filePath": "connect/signup/index.tsx"
    },
    "/public-routes/athletes-foot-order-upload/": {
      "filePath": "public-routes/athletes-foot-order-upload/index.tsx"
    },
    "/public-routes/rate-conversation/": {
      "filePath": "public-routes/rate-conversation/index.tsx"
    },
    "/public-routes/test-whatsapp-wa-me-icon/": {
      "filePath": "public-routes/test-whatsapp-wa-me-icon/index.tsx"
    },
    "/organization/$organizationSlug/onboarding/demo-success": {
      "filePath": "organization/$organizationSlug/onboarding/demo-success.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/_navbar/conversation": {
      "filePath": "organization/$organizationSlug/_navbar/conversation",
      "parent": "/organization/$organizationSlug/_navbar",
      "children": [
        "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar"
      ]
    },
    "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar": {
      "filePath": "organization/$organizationSlug/_navbar/conversation/_conversationSidebar.tsx",
      "parent": "/organization/$organizationSlug/_navbar/conversation",
      "children": [
        "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList",
        "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/"
      ]
    },
    "/organization/$organizationSlug/onboarding/help-desk/email": {
      "filePath": "organization/$organizationSlug/onboarding/help-desk/email.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/help-desk/gorgias": {
      "filePath": "organization/$organizationSlug/onboarding/help-desk/gorgias.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/help-desk/zendesk": {
      "filePath": "organization/$organizationSlug/onboarding/help-desk/zendesk.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/knowledge/article": {
      "filePath": "organization/$organizationSlug/onboarding/knowledge/article.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/knowledge/websites": {
      "filePath": "organization/$organizationSlug/onboarding/knowledge/websites.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/platform/woocommerce": {
      "filePath": "organization/$organizationSlug/onboarding/platform/woocommerce.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/_navbar/account-settings/": {
      "filePath": "organization/$organizationSlug/_navbar/account-settings/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar"
    },
    "/organization/$organizationSlug/_navbar/demo/": {
      "filePath": "organization/$organizationSlug/_navbar/demo/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar"
    },
    "/organization/$organizationSlug/_navbar/metrics-old/": {
      "filePath": "organization/$organizationSlug/_navbar/metrics-old/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar"
    },
    "/organization/$organizationSlug/_navbar/metrics/": {
      "filePath": "organization/$organizationSlug/_navbar/metrics/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar"
    },
    "/organization/$organizationSlug/_navbar/settings/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar"
    },
    "/organization/$organizationSlug/_navbar/setup/": {
      "filePath": "organization/$organizationSlug/_navbar/setup/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar"
    },
    "/organization/$organizationSlug/onboarding/features/": {
      "filePath": "organization/$organizationSlug/onboarding/features/index.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/help-desk/": {
      "filePath": "organization/$organizationSlug/onboarding/help-desk/index.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/knowledge/": {
      "filePath": "organization/$organizationSlug/onboarding/knowledge/index.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/plan/": {
      "filePath": "organization/$organizationSlug/onboarding/plan/index.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/platform/": {
      "filePath": "organization/$organizationSlug/onboarding/platform/index.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/onboarding/tone/": {
      "filePath": "organization/$organizationSlug/onboarding/tone/index.tsx",
      "parent": "/organization/$organizationSlug/onboarding"
    },
    "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList": {
      "filePath": "organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList.tsx",
      "parent": "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar",
      "children": [
        "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/",
        "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/",
        "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/"
      ]
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug",
      "parent": "/organization/$organizationSlug/_navbar",
      "children": [
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
      ]
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug",
      "children": [
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/",
        "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/"
      ]
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/": {
      "filePath": "organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList"
    },
    "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/": {
      "filePath": "organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/new.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/": {
      "filePath": "organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList"
    },
    "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/": {
      "filePath": "organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/new/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/edit.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    },
    "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/": {
      "filePath": "organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/new/index.tsx",
      "parent": "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar"
    }
  }
}
ROUTE_MANIFEST_END */
