import { toast } from "sonner";
import { Route } from "../edit";
import { NewProductProps } from "../edit";

export function useSubmitForm() {
  const navigate = Route.useNavigate();
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const createMutation = trpc.dashboard.products.create.useMutation({
    onSuccess: () => {
      void navigate({
        to: "/organization/$organizationSlug/settings/$businessSlug/knowledge/products",
        params: { organizationSlug, businessSlug },
      });
      toast.success("Successfully saved product.");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to save product. Please try again.");
    },
  });

  return {
    onSubmit: (data: NewProductProps) => {
      createMutation.mutate({
        businessSlug,
        ...data,
      });
    },
    onError: (errors: unknown) => {
      console.error(errors);
      if (errors instanceof Error) {
        toast.error(errors.message || "Failed to save product.");
      } else {
        toast.error("Failed to save product.");
      }
    },
  };
}
