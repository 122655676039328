import { Route } from "../..";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { MessageSquareText } from "lucide-react";

export function TopicsFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [topics] =
    trpc.dashboard.conversations.messageTopics.getOrgTopics.useSuspenseQuery({
      organizationSlug,
      includeDeleted: true,
    });

  const options = topics.map((topic) => ({
    label: topic,
    value: topic,
  }));

  return (
    <CommandMultiSelect
      filterType="topics"
      optionGroups={[{ options }]}
      icon={MessageSquareText}
    />
  );
}
