import { createFileRoute } from "@tanstack/react-router";
import { exhaustive } from "@dashboard/utils/other";
import { z } from "zod";
import { ChannelName } from "@be/modules/conversations/conversations.types";
import { Topbar } from "./-components/Topbar";
import { Card } from "./-components/Card/Card";
import StatsCard from "./-components/StatsCard";
import { Chart } from "./-components/Chart";
import Breakdown from "./-components/Breakdown";
import { ChartPie } from "./-components/ChartPie";
import { useAvgConversationsPerDay } from "./-hooks/useAvgConversationsPerDay";
import { useAvgMessagesPerConversation } from "./-hooks/useAvgMessagesPerConversation";
import { useHandedOffChartData } from "./-hooks/useHandedOffChartData";
import { useHandedOffPercentage } from "./-hooks/useHandedOffPercentage";
import { useConversationChartData } from "./-hooks/useConversationChartData";
import { useConversationsByChannels } from "./-hooks/useConversationsByChannels";
import { differenceInDays, differenceInHours, format, subDays } from "date-fns";
import { periodSchema, periodToRange } from "./-utils/periodToRange";
import { useConversationsByTopics } from "./-hooks/useConversationsByTopics";
import { useTopicHandoffData } from "./-hooks/useTopicHandoffData";
import { useClosedConversationsChartData } from "./-hooks/useClosedConversationsChartData";
import { AssigneeSelect } from "./-components/Select/AssigneeSelect";
import { useAssignedConversationsChartData } from "./-hooks/useAssignedConversationChartData";
import { useAvgReplyTime } from "./-hooks/useAvgReplyTime";
import { useAvgTimeToClose } from "./-hooks/useAvgTimeToClose";
import { IntervalSelect } from "./-components/Select/IntervalSelect";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/metrics-old/",
)({
  validateSearch: z.object({
    period: periodSchema,
    newConversationsInterval: z
      .union([z.literal("day"), z.literal("hour")])
      .optional(),
    businessSlug: z.string().optional(),
    closedAssignee: z.string().optional(),
    assignedAssignee: z.string().optional(),
    avgReplyAssignee: z.string().optional(),
    avgCloseAssignee: z.string().optional(),
  }),
  loaderDeps: ({
    search: {
      period,
      businessSlug,
      closedAssignee,
      assignedAssignee,
      avgReplyAssignee,
      avgCloseAssignee,
    },
  }) => ({
    period,
    businessSlug,
    closedAssignee,
    assignedAssignee,
    avgReplyAssignee,
    avgCloseAssignee,
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
    deps: {
      period,
      businessSlug,
      closedAssignee,
      assignedAssignee,
      avgReplyAssignee,
      avgCloseAssignee,
    },
  }) => {
    const range = periodToRange(period);
    const days = differenceInDays(range.to, range.from);
    const prevRange = {
      from: subDays(range.from, days),
      to: range.from,
    };

    await Promise.all([
      // Current period data
      trpcClientUtils.dashboard.metrics.get.ensureData({
        organizationSlug,
        businessSlug,
        from: range.from,
        to: range.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getClosedConversationCounts.ensureData({
        organizationSlug,
        businessSlug,
        assignee: closedAssignee,
        from: range.from,
        to: range.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getAssignedConversationCounts.ensureData(
        {
          organizationSlug,
          businessSlug,
          assignee: assignedAssignee,
          from: range.from,
          to: range.to,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      ),
      trpcClientUtils.dashboard.metrics.getAvgReplyTime.ensureData({
        organizationSlug,
        businessSlug,
        assignee: avgReplyAssignee,
        from: range.from,
        to: range.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getAvgTimeToClose.ensureData({
        organizationSlug,
        businessSlug,
        assignee: avgCloseAssignee,
        from: range.from,
        to: range.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),

      // Previous period data
      trpcClientUtils.dashboard.metrics.get.ensureData({
        organizationSlug,
        businessSlug,
        from: prevRange.from,
        to: prevRange.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getClosedConversationCounts.ensureData({
        organizationSlug,
        businessSlug,
        assignee: closedAssignee,
        from: prevRange.from,
        to: prevRange.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getAssignedConversationCounts.ensureData(
        {
          organizationSlug,
          businessSlug,
          assignee: assignedAssignee,
          from: prevRange.from,
          to: prevRange.to,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      ),
      trpcClientUtils.dashboard.metrics.getAvgReplyTime.ensureData({
        organizationSlug,
        businessSlug,
        assignee: avgReplyAssignee,
        from: prevRange.from,
        to: prevRange.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      trpcClientUtils.dashboard.metrics.getAvgTimeToClose.ensureData({
        organizationSlug,
        businessSlug,
        assignee: avgCloseAssignee,
        from: prevRange.from,
        to: prevRange.to,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    ]);
  },
  component: MetricsPage,
});

// TODO: this can be centralized in the channel module
const channelDisplayName: Record<ChannelName, string> = {
  web: "Website chatbot",
  email: "Email",
  "google-business-messages": "Google Business Messages",
  whatsapp: "WhatsApp",
  messenger: "Messenger",
  brymec: "Twilio WhatsApp",
  telegram: "Telegram",
  instagram: "Instagram",
  facebookComment: "Facebook Comment",
  instagramComment: "Instagram Comment",
  echo: "Echo",
  "zoom-transcript": "Zoom Transcript",
  "google-reviews": "Google Reviews",
  "phone-call": "Phone Call",
  "trustpilot-reviews": "Trustpilot Reviews",
  ebay: "eBay",
  "test-review": "Test Review",
  aircall: "Aircall",
};

function MetricsPage() {
  const { newConversationsInterval, period } = Route.useSearch();
  const { hasExternalTicketConfig } = Route.useRouteContext();

  const avgConversationsPerDay = useAvgConversationsPerDay();
  const avgMessagesPerConversation = useAvgMessagesPerConversation();
  const handedOffsPercent = useHandedOffPercentage();
  const conversationsChartData = useConversationChartData();
  const handedOffsChartData = useHandedOffChartData();
  const conversationsByChannels = useConversationsByChannels();
  const conversationsByTopics = useConversationsByTopics();
  const topicHandoffData = useTopicHandoffData();
  const closedConversationsChartData = useClosedConversationsChartData();
  const assignedConversationsChartData = useAssignedConversationsChartData();
  const avgReplyTime = useAvgReplyTime();
  const avgTimeToClose = useAvgTimeToClose();

  const comparedTo = {
    days: 7,
    from: format(new Date(), "PPp"),
    to: format(new Date(), "PPp"),
  };

  const currentRange = periodToRange(period);
  const currentRangeLength = differenceInHours(
    currentRange.to,
    currentRange.from,
  );

  const hourlyDataAvailable = currentRangeLength <= 72;

  const hourlyDataSelected = newConversationsInterval === "hour";

  const newConversationsData =
    hourlyDataAvailable && hourlyDataSelected
      ? conversationsChartData.conversationsPerHour
      : conversationsChartData.conversationsPerDay;

  return (
    <>
      <Topbar />
      <div className="mx-auto w-full max-w-7xl px-10 pb-10">
        {/* stats row */}
        <div
          className="mt-10 flex max-w-7xl flex-row gap-6"
          style={{
            marginTop: "80px",
          }}
        >
          {/* Average conversations per day */}
          <Card
            title="Average conversations per day"
            tooltipText="This represents the average number of distinct conversations initiated by customers each day."
          >
            <StatsCard
              higherIsBetter
              value={avgConversationsPerDay.value}
              diff={avgConversationsPerDay.diff}
              comparedTo={comparedTo}
            />
          </Card>

          {/* Average messages per chat*/}
          <Card
            title="Average messages per chat"
            tooltipText="This represents the average number of messages exchanged in each conversation with a customer."
          >
            <StatsCard
              higherIsBetter={false}
              value={avgMessagesPerConversation.value}
              diff={avgMessagesPerConversation.diff}
              comparedTo={comparedTo}
            />
          </Card>

          {/* Handed off conversations */}
          <Card
            title="Handed off conversations"
            tooltipText="This indicates the percentage of conversations that were transferred from the AI system to a human agent"
          >
            <StatsCard
              higherIsBetter={false}
              value={handedOffsPercent.value + "%"}
              diff={handedOffsPercent.diff}
              unit="%"
              comparedTo={comparedTo}
            />
          </Card>
        </div>

        {/* graph 1 */}
        <Card
          className="mt-10"
          title="New Conversations"
          tooltipText={`The chart displays the total number of new conversations initiated on each ${
            hourlyDataAvailable && hourlyDataSelected ? "hour" : "day"
          } within the selected period.`}
          filter={hourlyDataAvailable ? <IntervalSelect /> : undefined}
        >
          <Chart
            value={conversationsChartData.conversationsCount}
            trendingIndicator={{
              higherIsBetter: true,
              diff: conversationsChartData.conversationsCountDiff,
              comparedTo,
            }}
            data={newConversationsData}
            yTitle="Conversations"
          />
        </Card>

        {/* graph 2 */}
        <Card
          className="mt-10"
          title="Handed off conversations"
          tooltipText="The chart displays the total number of conversations that were transferred from the AI system to a human agent on each day within the selected period."
        >
          <div className="flex flex-row gap-x-16">
            <Chart
              value={handedOffsChartData.handedOffsCount}
              trendingIndicator={{
                higherIsBetter: false,
                diff: handedOffsChartData.handedOffsCountDiff,
                comparedTo,
              }}
              data={handedOffsChartData.handedOffsPerDay}
              yTitle="Conversations"
            />
          </div>
        </Card>

        {!hasExternalTicketConfig && (
          <>
            {/* graph 3 */}
            <Card
              className="mt-10"
              title="Assigned conversations"
              tooltipText="The chart displays the total number of conversations assigned on each day within the selected period."
              filter={<AssigneeSelect chart="assigned" />}
            >
              <div className="flex flex-row gap-x-16">
                <Chart
                  value={assignedConversationsChartData.count}
                  trendingIndicator={{
                    higherIsBetter: true,
                    diff: assignedConversationsChartData.countDiff,
                    comparedTo,
                  }}
                  data={assignedConversationsChartData.perDay}
                  yTitle="Conversations"
                />
              </div>
            </Card>

            {/* graph 4 */}
            <Card
              className="mt-10"
              title="Closed conversations"
              tooltipText="The chart displays the total number of conversations closed on each day within the selected period."
              filter={<AssigneeSelect chart="closed" />}
            >
              <div className="flex flex-row gap-x-16">
                <Chart
                  value={closedConversationsChartData.count}
                  trendingIndicator={{
                    higherIsBetter: true,
                    diff: closedConversationsChartData.countDiff,
                    comparedTo,
                  }}
                  data={closedConversationsChartData.perDay}
                  yTitle="Conversations"
                />
              </div>
            </Card>

            {/* Average Reply Time graph */}
            <Card
              className="mt-10"
              title="Average Reply Time"
              tooltipText="The average time it takes to reply to a handed off conversation."
              filter={<AssigneeSelect chart="avgReply" />}
            >
              <div className="flex flex-row gap-x-16">
                <Chart
                  value={avgReplyTime.value}
                  trendingIndicator={{
                    higherIsBetter: false,
                    diff: avgReplyTime.diff,
                    comparedTo,
                  }}
                  data={avgReplyTime.perDay}
                  yTitle={avgReplyTime.yTitle}
                />
              </div>
            </Card>

            {/* Average Time to Close graph */}
            <Card
              className="mt-10"
              title="Average Time to Close"
              tooltipText="The average time it takes to close a conversation after it has been handed off."
              filter={<AssigneeSelect chart="avgClose" />}
            >
              <div className="flex flex-row gap-x-16">
                <Chart
                  value={avgTimeToClose.value}
                  trendingIndicator={{
                    higherIsBetter: false,
                    diff: avgTimeToClose.diff,
                    comparedTo,
                  }}
                  data={avgTimeToClose.perDay}
                  yTitle={avgTimeToClose.yTitle}
                />
              </div>
            </Card>
          </>
        )}

        {/* Channel card */}
        <Card
          className="mt-10"
          title="Channels"
          tooltipText="The chart displays the total number of new conversations initiated on each day within the selected period."
        >
          <div className="flex flex-row gap-x-16">
            <ChartPie
              data={conversationsByChannels.map(({ channel, count }) => ({
                x: channelDisplayName[channel],
                y: count,
              }))}
            />
            <Breakdown
              title="Breakdown by channel type"
              data={conversationsByChannels.map(
                ({ channel, percentage, diff }) => ({
                  name: channelDisplayName[channel],
                  percentage,
                  tooltipContent: getChannelTooltip({ channel }),
                  trendingIndicator: {
                    value: diff,
                    unit: "%",
                    higherIsBetter: true,
                  },
                }),
              )}
            />
          </div>
        </Card>

        {/* Topic card */}
        {conversationsByTopics.length > 0 && (
          <Card
            className="mt-10"
            title="Topics"
            tooltipText="The chart displays the breakdown of conversations by topic."
          >
            <div className="flex flex-row items-center gap-x-16">
              <ChartPie
                data={conversationsByTopics
                  .map(({ topic, count }) => ({
                    x: topic,
                    y: count,
                  }))
                  .slice(0, 8)}
              />
              <Breakdown
                title="Breakdown by topic"
                data={conversationsByTopics.map(
                  ({ topic, percentage, diff }) => ({
                    name: topic,
                    percentage,
                    tooltipContent: topic,
                    trendingIndicator: {
                      value: diff,
                      unit: "%",
                      higherIsBetter: true,
                    },
                  }),
                )}
              />
            </div>
          </Card>
        )}

        {/* Topic handoff rate card */}
        {conversationsByTopics.length > 0 && (
          <Card
            className="mt-10"
            title="Handoff Rates Per Topic"
            tooltipText="The handoff rate per topic"
          >
            <Breakdown
              data={topicHandoffData.map(({ topic, percentage, diff }) => ({
                name: topic,
                percentage,
                tooltipContent: topic,
                trendingIndicator: {
                  value: diff,
                  unit: "%",
                  higherIsBetter: false,
                },
              }))}
            />
          </Card>
        )}
      </div>
    </>
  );
}

function getChannelTooltip(x: {
  channel: ChannelName;
  type?: "attention-grabber";
}) {
  if (x.channel === "web") {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if ("type" in x && x.type === "attention-grabber") {
      return "This indicates the percentage of visitors who engaged with this attention grabber.";
    }

    return "This indicates the percentage of people who opened the chatbot and initiated a conversation.";
  } else if (x.channel === "email") {
    return "This indicates the percentage of people who initiated a conversation through the email channel.";
  } else if (x.channel === "google-business-messages") {
    return "This indicates the percentage of people who initiated a conversation through google business messaging.";
  } else if (x.channel === "whatsapp") {
    return "This indicates the percentage of people who initiated a conversation through a whatsapp message.";
  } else if (x.channel === "brymec") {
    return "This indicates the percentage of people who initiated a conversation through the brymec channel.";
  } else if (x.channel === "messenger") {
    return "This indicates the percentage of people who initiated a conversation through a messenger message.";
  } else if (x.channel === "telegram") {
    return "This indicates the percentage of people who ininitated a conversation through a telegram message.";
  } else if (x.channel === "facebookComment") {
    return "This indicates the percentage of people who initiated a conversation through a facebook comment.";
  } else if (x.channel === "instagramComment") {
    return "This indicates the percentage of people who initiated a conversation through an instagram comment.";
  } else if (x.channel === "echo") {
    return "This indicates the percentage of people who initiated a conversation through an echo.";
  } else if (x.channel === "zoom-transcript") {
    return "This indicates the percentage of people who initiated a conversation through a zoom transcript.";
  } else if (x.channel === "instagram") {
    return "This indicates the percentage of people who initiated a conversation through an instagram message.";
  } else if (x.channel === "google-reviews") {
    return "This indicates the percentage of people who initiated a conversation through a google review.";
  } else if (x.channel === "trustpilot-reviews") {
    return "This indicates the percentage of people who initiated a conversation through a trustpilot review.";
  } else if (x.channel === "phone-call") {
    return "This indicates the percentage of people who initiated a conversation through a phone call.";
  } else if (x.channel === "test-review") {
    return "This indicates the percentage of people who initiated a conversation through a test review.";
  } else if (x.channel === "aircall") {
    return "This indicates the percentage of people who initiated a conversation through an aircall call.";
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (x.channel === "ebay") {
    return "This indicates the percentage of people who initiated a conversation through an ebay message.";
  }

  throw exhaustive(x.channel);
}
