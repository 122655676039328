import { toast } from "sonner";
import { variantExportSchema } from "../-utils/variantExportSchema";
import { z } from "zod";

export function useExportVariant() {
  return {
    exportVariant: (variant: z.infer<typeof variantExportSchema>) => {
      const jsonString = JSON.stringify(variant, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `${variant.title} ${timestamp}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success("Variant exported successfully");
    },
  };
}
