export function getStatusColor(status?: string) {
  if (!status) return "bg-gray-100 text-gray-600";

  const statusMap: Record<string, string> = {
    PENDING: "bg-yellow-100 text-yellow-800",
    FULFILLED: "bg-purple-100 text-purple-800",
    DELIVERED: "bg-green-100 text-green-800",
    CANCELED: "bg-red-100 text-red-800",
    RETURNING: "bg-orange-100 text-orange-800",
    RETURNED: "bg-blue-100 text-blue-800",
  };

  return statusMap[status.toUpperCase()] || "bg-gray-100 text-gray-600";
}
