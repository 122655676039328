import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@dashboard/common/ui/dialog";
import { EditRuleForm } from "./EditRuleForm";

interface EditRuleDialogContentProps {
  id: string;
  type: "email" | "domain";
  value: string;
  action: "handoff" | "spam" | "ignore";
  isActive: boolean;
  organization: boolean;
  setOpen: (open: boolean) => void;
}

export function EditRuleDialogContent({
  id,
  type,
  value,
  action,
  isActive,
  organization,
  setOpen,
}: EditRuleDialogContentProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Edit rule</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Edit the details of the email rule you want to update.
      </DialogDescription>
      <EditRuleForm
        id={id}
        type={type}
        value={value}
        action={action}
        isActive={isActive}
        organization={organization}
        setOpen={setOpen}
      />
    </>
  );
}
