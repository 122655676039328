import { z } from "zod";

export const orderFetchingMethodsSchema = z.enum([
  "getByOrderId",
  "getByEmail",
  "getByOrderIdAndEmail",
]);

export const courierEventSchema = z.object({
  description: z.string().optional(),
  timestamp: z.string().optional(),
  location: z.string().optional(),
  status: z.string().optional(),
});

export const orderCancelReasonSchema = z.enum([
  "CUSTOMER",
  "DECLINED",
  "FRAUD",
  "INVENTORY",
  "OTHER",
  "STAFF",
]);

export const cancelationDataSchema = z.object({
  reason: orderCancelReasonSchema,
  refund: z.boolean(),
  restock: z.boolean(),
  notifyCustomer: z.boolean(),
  staffNote: z.string().nullish(),
});

export const fulfillmentHoldReasonSchema = z.enum([
  "AWAITING_PAYMENT",
  "AWAITING_RETURN_ITEMS",
  "HIGH_RISK_OF_FRAUD",
  "INCORRECT_ADDRESS",
  "INVENTORY_OUT_OF_STOCK",
  "ONLINE_STORE_POST_PURCHASE_CROSS_SELL",
  "UNKNOWN_DELIVERY_DATE",
  "OTHER",
]);

export const holdDataSchema = z.object({
  notifyMerchant: z.boolean().optional(),
  reason: fulfillmentHoldReasonSchema.optional(),
  staffNote: z.string().nullish(),
});

export const refundDataSchema = z.object({
  notifyCustomer: z.boolean(),
  amount: z.string(),
  staffNote: z.string().nullish(),
  lineItems: z.array(
    z.object({
      externalId: z.string(),
      quantity: z.number(),
    }),
  ),
  parentTransactionId: z.string().optional(),
  parentTransactionGateway: z.string().optional(),
});

export const addressDataSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  countryCode: z.string(),
  provinceCode: z.string().optional(),
  zip: z.string(),
  phone: z.string().optional(),
});
