import { Link } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SignUpFormSchema, signUpFormSchema } from "../-utils/signUpFormSchema";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { useSignUp } from "../-hooks/useSignUp";
import {
  SelectRoot as Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@dashboard/common/ui/select";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import { env } from "@dashboard/env";

export function SignUpForm() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const form = useForm<SignUpFormSchema>({
    resolver: zodResolver(signUpFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      organization: "",
      storeUrl: "",
      usage: "for-my-own-business",
      requestDemo: false,
      tz: userTimeZone,
    },
  });

  const setCustomError = (error: string) => {
    form.setError("root", { message: error });
  };

  const { signUp, isLoading } = useSignUp({ setError: setCustomError });

  async function onSubmit(values: SignUpFormSchema) {
    await signUp(values);
  }

  const isCreatingAccount = isLoading || form.formState.isSubmitting;

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="flex w-full max-w-xl flex-col gap-2"
      >
        <div className="flex w-full items-center gap-4">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input
                    className="h-11 py-2"
                    placeholder="Enter your first name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input
                    className="h-11 py-2"
                    placeholder="Enter your last name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  className="h-11 py-2"
                  placeholder="name@example.com"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  className="h-11 py-2"
                  type="password"
                  placeholder="Enter your password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="organization"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company</FormLabel>
              <FormControl>
                <Input
                  className="h-11 py-2"
                  placeholder="Enter the name of your company"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="storeUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Store URL</FormLabel>
              <FormControl>
                <Input
                  className="h-11 py-2"
                  placeholder="www.mystore.com"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="usage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>How will you be using Octocom?</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="h-11 py-2">
                    <SelectValue placeholder="Select usage" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="for-my-own-business">
                    For my own business
                  </SelectItem>
                  <SelectItem value="for-my-clients">For my clients</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="requestDemo"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 py-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>I would like a live demo of Octocom</FormLabel>
              </div>
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="mt-4 h-11 bg-[#041627] text-white hover:bg-gray-800"
          disabled={isCreatingAccount}
        >
          {isCreatingAccount ? "Creating account..." : "Create account"}
        </Button>
      </form>

      {form.formState.errors.root && (
        <div className="mt-4 text-center text-sm text-red-500">
          {form.formState.errors.root.message}
        </div>
      )}

      <div className="mt-4 text-center text-sm">
        By submitting this form, you agree to the{" "}
        <a
          href={`${env.DASHBOARD_LANDING_URL}/terms-of-service.html`}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={`${env.DASHBOARD_LANDING_URL}/privacy-policy.html`}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
      <div className="mt-8 text-center text-sm">
        Already have an account?{" "}
        <Link to="/login" className="font-medium hover:underline">
          Log in
        </Link>
      </div>
    </Form>
  );
}
