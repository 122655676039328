import { toast } from "sonner";
import { Route } from "..";
import { requestAlertDialogDisplay } from "@dashboard/common/GlobalAlertDialog/GlobalAlertDialog";
import { botRuleExportSchema } from "../-utils/botRuleExportSchema";

function getJsonString() {
  return new Promise<string | null>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result !== "string") {
          reject(new Error("File is not a string when using readAsText"));
          return;
        }
        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      reader.readAsText(file);
    };

    input.oncancel = () => {
      resolve(null);
    };

    input.click();
  });
}

function validateAndParseJsonString(jsonString: string) {
  let parsedJson: unknown;
  try {
    parsedJson = JSON.parse(jsonString) as unknown;
  } catch (error) {
    return;
  }

  const jsonValidationResult = botRuleExportSchema.safeParse(parsedJson);

  if (!jsonValidationResult.success) {
    return;
  }

  return jsonValidationResult.data;
}

export function useImportBotRules() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const [currentRules] = trpc.dashboard.bots.getRules.useSuspenseQuery({
    businessSlug,
  });

  const importRulesMutation = trpc.dashboard.bots.createMany.useMutation();

  return {
    importBotRules: () => {
      void getJsonString().then((jsonString) => {
        if (!jsonString) return;
        const data = validateAndParseJsonString(jsonString);
        if (!data) {
          toast.error("Import error: Invalid JSON provided");
          return;
        }

        if (data.length === 0) {
          toast.error("Import error: No bot rules to import");
          return;
        }

        const rulesToCreate: typeof data = [];
        for (const rule of data) {
          const identicalRule = currentRules.find(
            (r) => r.title === rule.title && r.rule === rule.rule,
          );
          if (identicalRule) {
            continue;
          }

          rulesToCreate.push({
            title: rule.title,
            rule: rule.rule,
          });
        }

        if (rulesToCreate.length === 0) {
          toast.success("No new bot rules to import");
          return;
        }

        requestAlertDialogDisplay({
          title: "Import bot rules",
          description: `Do you confirm you want to import ${
            rulesToCreate.length
          } bot rule${
            rulesToCreate.length === 1 ? "" : "s"
          } from the provided file?`,
          onAction: () => {
            void importRulesMutation
              .mutateAsync({
                businessSlug,
                rules: rulesToCreate,
              })
              .then(async () => {
                await trpcUtils.dashboard.bots.getRules.invalidate({
                  businessSlug,
                });
                toast.success("Bot rules imported successfully");
              });
          },
          actionText: "Import",
        });
      });
    },
  };
}
