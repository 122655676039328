import { User } from "lucide-react";
import { Route } from "../..";
import { CommandMultiSelect } from "./general/CommandMultiSelect";

export function AssigneesFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const assignmentStatusOptions = [
    { label: "Assigned", value: "assigned" },
    { label: "Unassigned", value: "unassigned" },
  ];

  return (
    <CommandMultiSelect
      filterType="assignees"
      optionGroups={[
        {
          groupName: "Assignees",
          options: users.map((user) => ({
            label: `${user.fullName} (${user.email})`,
            value: user.id,
          })),
        },
      ]}
      exclusiveOptionGroup={{
        groupName: "Assignment Status",
        options: assignmentStatusOptions,
      }}
      icon={User}
    />
  );
}
