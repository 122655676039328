import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { useEnableCustomMessageTopics } from "../-hooks/useEnableCustomMessageTopics";

export function EnableCustomMessageTopicsDialog() {
  const { enableCustomMessageTopics } = useEnableCustomMessageTopics();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="mx-10 mt-4">Enable Custom Topics</Button>
      </DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Enable Custom Message Topics</DialogTitle>
          <DialogDescription>
            You are about to enable custom message topics for this business.
            This will:
            <ul className="mt-4 list-disc space-y-2 pl-6">
              <li>Allow you to define your own message topics</li>
              <li>Replace the default message topics with your custom ones</li>
              <li>
                Your custom list will initialize with the current default topics
              </li>
              <li>This action cannot be undone</li>
            </ul>
          </DialogDescription>
        </DialogHeader>

        <div className="flex justify-end gap-3">
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button variant="publish" onClick={enableCustomMessageTopics}>
            Enable Custom Topics
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
