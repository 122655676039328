import type { ProductField } from "./types";

export function getPathsFromObject(obj: unknown, parentPath = ""): string[] {
  if (!obj || typeof obj !== "object") {
    return [parentPath].filter(Boolean);
  }

  return Object.entries(obj).flatMap(([key, value]) => {
    const currentPath = parentPath ? `${parentPath}.${key}` : key;

    if (Array.isArray(value)) {
      return value.length > 0
        ? getPathsFromObject(value[0], `${currentPath}.0`)
        : [currentPath];
    }
    return value && typeof value === "object"
      ? getPathsFromObject(value, currentPath)
      : [currentPath];
  });
}

export const PRODUCT_FIELDS: ProductField[] = [
  { key: "name", label: "Name", required: true },
  { key: "slug", label: "Slug", required: true },
  { key: "url", label: "Url", required: true },
  { key: "longDescription", label: "Long Description", required: false },
  { key: "shortDescription", label: "Short Description", required: false },
  { key: "images.0.url", label: "Main Image Url", required: true },
  { key: "images.0.altText", label: "Main Image Alt Text", required: true },
  {
    key: "additionalImages",
    label: "Additional Images Array",
    required: false,
    isArray: true,
  },
  {
    key: "additionalImages.altText",
    label: "Additional Images Alt Text Array",
    required: false,
  },
  { key: "collections.0.name", label: "Collection Name", required: false },
  { key: "collections.0.slug", label: "Collection Slug", required: false },
  {
    key: "variants.0.title",
    label: "Variant Title",
    required: false,
  },
  {
    key: "variants.0.sku",
    label: "Variant SKU",
    required: false,
  },
  {
    key: "variants.0.inStock",
    label: "Variant In Stock",
    required: false,
  },
  {
    key: "variants.0.quantity",
    label: "Variant Quantity",
    required: false,
  },
];

// Example JSON data for initial state
export const EXAMPLE_JSON = {
  products: [
    {
      id: "1",
      name: "Example Product",
      price: 99.99,
      categories: ["Electronics", "Gadgets"],
      specifications: {
        weight: "1.5kg",
        dimensions: {
          width: 10,
          height: 15,
          depth: 5,
        },
      },
    },
  ],
};
