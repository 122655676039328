import { ClipboardList } from "lucide-react";
import { Route } from "../../_conversationSidebar";
import { CommandFilter } from "./CommandFilter";

export function TaskNameFilter() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [data] = trpc.dashboard.v2Bot.getTaskNames.useSuspenseQuery({
    organizationSlug,
  });

  console.log("Task Names", data);

  if (data.taskNames.length === 0) {
    return null;
  }

  const options = data.taskNames.map((taskName) => ({
    label: taskName,
    value: taskName,
  }));

  return (
    <CommandFilter
      options={options}
      valueIcon={ClipboardList}
      name="Task Name"
      filterKey="taskName"
    />
  );
}
