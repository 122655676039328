import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  WooCommerceForm,
  wooCommerceFormSchema,
} from "../-utils/wooCommerceFormSchema";
import { useIntegration } from "../-hooks/useIntegration";
import { Route } from "..";
import { env } from "@dashboard/env";

export function WooCommerceSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "woocommerce" });
  const { business } = Route.useRouteContext();

  const defaultValues = {
    domain: integration?.domain ?? "",
  };

  const form = useForm<WooCommerceForm>({
    resolver: zodResolver(wooCommerceFormSchema),
    defaultValues,
  });

  const onSubmit = (data: WooCommerceForm) => {
    const params = new URLSearchParams({
      businessId: business.id,
      website: data.domain,
    });

    const url = `${
      env.DASHBOARD_BASE_API_URL
    }/woocommerce/install?${params.toString()}`;

    console.log("Redirecting to", url);

    window.location.href = url;
  };

  function Description() {
    return (
      <div className="flex flex-col items-start gap-4 pb-4">
        <h1 className="text-2xl font-semibold">
          WooCommerce Integration Settings
        </h1>

        <p>
          Connect your WooCommerce store to Octocom to automatically sync your
          orders and product data. Simply enter your shop's website URL below.
        </p>

        <p>
          Make sure you have administrator access to your WooCommerce store as
          you'll need to approve the connection in the next step.
        </p>

        <div className="bg-accent flex flex-col gap-4 rounded-lg p-4">
          <p className="text-justify text-gray-800">
            <span className="font-medium">Note:</span> After connecting your
            WooCommerce store, you'll need to install the Octocom chat widget
            plugin. Please follow our detailed installation guide to complete
            the setup process.
          </p>

          <Button type="button" variant="publish" className="w-full">
            <a
              href="https://octocom.gitbook.io/integrations/woocommerce/chat-widget-tutorial"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Installation Guide
            </a>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Description />
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}>
          <div className="pb-4">
            <FormField
              control={form.control}
              name="domain"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Shop Website URL</FormLabel>
                  <FormControl>
                    <Input placeholder="https://your-store.com" {...field} />
                  </FormControl>
                  <FormDescription>
                    Enter the URL of your WooCommerce store
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex w-full justify-end pt-4">
            <Button type="submit">Connect Store</Button>
          </div>
        </form>
      </Form>
    </>
  );
}
