import { Route } from "..";
import { toast } from "sonner";
import { useCustomerConversations } from "./useCustomerConversations.hook";

type Part = ReturnType<
  typeof useCustomerConversations
>["customerConversations"][number]["parts"][number];
type PreviewMessage = Extract<Part, { type: "message" }>;

export function usePreviewTranslation({
  setUseTranslation,
  messages,
}: {
  setUseTranslation: (useTranslation: boolean) => void;
  messages: PreviewMessage[];
}) {
  const { publicId, organizationSlug } = Route.useParams();
  const { trpc, user } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const translationMutation =
    trpc.dashboard.conversations.translateMessage.useMutation({
      onSuccess: () => {
        toast.success("Conversation translated successfully");
        void trpcUtils.dashboard.conversations.get.invalidate({
          organizationSlug,
          publicId,
        });
        setUseTranslation(true);
      },
      onError: () => {
        toast.error("Failed to translate conversation");
      },
    });

  const translate = (publicId: string) => {
    if (
      messages.every((message) =>
        message.translations.some(
          (translation) =>
            translation.translatedTo === user.translationLanguage,
        ),
      )
    ) {
      setUseTranslation(true);
    } else {
      translationMutation.mutate({
        organizationSlug,
        publicId,
        translationLanguage: user.translationLanguage,
      });
    }
  };

  return { translate };
}
