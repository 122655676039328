import { z } from "zod";

export const simpleFlowOptionSchema = z.enum([
  "disabled",
  "notAllowed",
  "handOff",
  "holdAndHandOff",
  "automatic",
  "automaticNoEmail",
  "resist",
  "handOffWithoutQuestions",
]);

export const simpleFlowConfigurationSchema = z.object({
  orderTracking: simpleFlowOptionSchema,
  orderCancelation: simpleFlowOptionSchema,
  shippingAddressUpdate: simpleFlowOptionSchema,
  contactDetailsUpdate: simpleFlowOptionSchema,
  orderItemsUpdate: simpleFlowOptionSchema,
  handoff: simpleFlowOptionSchema,
  returnRequests: simpleFlowOptionSchema,
  returnStatus: simpleFlowOptionSchema,
  emailTicketRecipients: z.array(z.string()),
  createUnfulfilledTag: z.boolean(),
  urgentTextString: z.string().nullable(),
});

export const simpleFlowConfigurationUpdateSchema = simpleFlowConfigurationSchema
  .omit({ emailTicketRecipients: true })
  .partial();
