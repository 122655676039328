import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import { UseFormReturn } from "react-hook-form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { MacroForm } from "../-utils/macroFormSchema";
import { Select } from "@dashboard/common/ui/select";
import { Route } from "..";
import { ActionsFormField } from "./Actions/ActionsFormField";
import { AttachmentsFormField } from "./AttachmentsFormField";

interface MacroFormBodyProps {
  form: UseFormReturn<MacroForm>;
  onSubmit: (data: MacroForm) => void;
  onError: (errors: unknown) => void;
}

export function MacroFormBody({ form, onSubmit, onError }: MacroFormBodyProps) {
  const { user, businesses } = Route.useRouteContext();

  const availableForOptions = [
    { value: "everyone", label: "Everyone" },
    { value: user.id, label: "Myself" },
  ];

  const businessOptions = [
    { value: "all", label: "All" },
    ...businesses.map((business) => ({
      value: business.id,
      label: business.internalName,
    })),
  ];

  const showBusinessSelector = businesses.length > 1;

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Enter the Macro title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Content</FormLabel>
              <FormControl>
                <Textarea
                  rows={8}
                  placeholder="Enter the Macro content (optional)"
                  name={field.name}
                />
              </FormControl>
              <FormDescription>
                {
                  "Use double curly braces to insert variables, e.g. {{customer.name}}"
                }
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <ActionsFormField />
        <AttachmentsFormField control={form.control} />

        <div className="flex w-full items-center gap-4">
          <FormField
            control={form.control}
            name="availableFor"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Available For</FormLabel>
                <FormControl>
                  <Select
                    className="w-full"
                    variant="dropdown"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Who has access to this Macro?"
                    options={availableForOptions}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {showBusinessSelector && (
            <FormField
              control={form.control}
              name="businessId"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Business</FormLabel>
                  <FormControl>
                    <Select
                      className="w-full"
                      variant="dropdown"
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Select a business"
                      options={businessOptions}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <DialogFooter className="lg:space-x-2.5">
          {user.isAdmin && (
            <Button
              variant="secondary"
              type="button"
              onClick={() => console.log(form.getValues())}
            >
              Show Values
            </Button>
          )}
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
