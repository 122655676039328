import { Table } from "@dashboard/common/Table/Table";
import { createFileRoute } from "@tanstack/react-router";
import { useDeleteMessageTopics } from "./-hooks/useDeleteMessageTopics";
import { CreateMessageTopicsDialogContent } from "./-components/CreateMessageTopicsDialogContent";
import { DEFAULT_MESSAGE_TOPICS } from "@be/modules/conversations/messageTopics/messageTopics.const";
import { EnableCustomMessageTopicsDialog } from "./-components/EnableCustomMessageTopicsDialog";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/message-topics/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.conversations.messageTopics.getConfig.ensureData(
      {
        businessSlug,
      },
    );
  },
  component: MessageTopicsPage,
});

function MessageTopicsPage() {
  const { trpc, business } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [topics] =
    trpc.dashboard.conversations.messageTopics.getConfig.useSuspenseQuery({
      businessSlug,
    });

  const records = topics.map((topic) => ({
    id: topic.id,
    topic: topic.topic,
  }));

  const { deleteMessageTopics } = useDeleteMessageTopics();

  if (!business.useCustomMessageTopics) {
    const records = DEFAULT_MESSAGE_TOPICS.map((topic) => ({
      id: topic,
      topic,
    }));

    return (
      <div className="flex h-full flex-col">
        <Table
          title="Message Topics"
          records={records}
          columns={[
            {
              header: "Topic",
              accessor: "topic",
            },
          ]}
          recordName={{ singular: "Topic", plural: "Topics" }}
          noRecordsText="No topics available"
          customComponent={<EnableCustomMessageTopicsDialog />}
        />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <Table
        title="Message Topics"
        records={records}
        columns={[
          {
            header: "Topic",
            accessor: "topic",
          },
        ]}
        recordName={{ singular: "Topic", plural: "Topics" }}
        noRecordsText="No topics available"
        addRecordDialogContent={(setOpen) => (
          <CreateMessageTopicsDialogContent setOpen={setOpen} />
        )}
        deleteRecords={deleteMessageTopics}
      />
    </div>
  );
}
