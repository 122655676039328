import { z } from "zod";

export const channelNameSchema = z.union([
  z.literal("web"),
  z.literal("email"),
  z.literal("google-business-messages"),
  z.literal("whatsapp"),
  z.literal("messenger"),
  z.literal("brymec"),
  z.literal("telegram"),
  z.literal("instagram"),
  z.literal("instagramComment"),
  z.literal("facebookComment"),
  z.literal("echo"),
  z.literal("zoom-transcript"),
  z.literal("google-reviews"),
  z.literal("phone-call"),
  z.literal("trustpilot-reviews"),
  z.literal("ebay"),
  z.literal("test-review"),
  z.literal("aircall"),
]);
