import ModalFooter from "../Modal/ModalFooter";
import ModalTopBar from "../Modal/ModalTopBar";
import {
  ArrowPathIcon,
  ArchiveBoxXMarkIcon,
} from "@heroicons/react/24/outline";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { useConversations } from "@chatbot/hooks/conversations/conversations.hooks";
import { KeyboardEvent, useRef, useEffect, useState } from "react";

export default function SettingsModalContent() {
  const { closeSettings, reset } = useUiState();
  const { closeConversation, clearHistory, activeConversationId } =
    useConversations();

  const [selectedElementIndex, setSelectedElementIndex] = useState(0);

  const closeRef = useRef<HTMLDivElement>(null);
  const startNewChatRef = useRef<HTMLDivElement>(null);
  const clearHistoryRef = useRef<HTMLDivElement>(null);
  const octocomLtdLinkRef = useRef<HTMLAnchorElement>(null);
  const privacyPolicyLinkRef = useRef<HTMLAnchorElement>(null);
  const toSLinkRef = useRef<HTMLAnchorElement>(null);

  const handleKeyStartNewChat = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      closeConversation();
      closeSettings();
    }
  };

  const handleKeyClearHistory = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      clearHistory();
      closeSettings();
      reset();
    }
  };

  useEffect(() => {
    const focusableElements = [
      closeRef.current,
      startNewChatRef.current,
      clearHistoryRef.current,
      octocomLtdLinkRef.current,
      privacyPolicyLinkRef.current,
      toSLinkRef.current,
    ];

    if (focusableElements[selectedElementIndex]) {
      focusableElements[selectedElementIndex]?.focus();
    }

    const handleFocusLoop = (event: KeyboardEvent) => {
      if (event.key === "Tab" && !event.shiftKey) {
        event.preventDefault();
        setSelectedElementIndex((current) => {
          const newIndex = (current + 1) % focusableElements.length;
          focusableElements[newIndex]?.focus();
          return newIndex;
        });
      }
    };

    document.addEventListener(
      "keydown",
      handleFocusLoop as unknown as EventListener,
    );

    return () => {
      document.removeEventListener(
        "keydown",
        handleFocusLoop as unknown as EventListener,
      );
    };
  }, [selectedElementIndex]);

  return (
    <>
      <ModalTopBar
        title="Settings"
        titleTooltip={activeConversationId}
        onClickClose={closeSettings}
        closeRef={closeRef}
      />

      <div className="border-b p-2">
        <div
          tabIndex={0}
          aria-label="Start new chat"
          className="flex cursor-pointer items-center gap-3 rounded-xl p-3 hover:bg-gray-100"
          onKeyDown={handleKeyStartNewChat}
          onClick={() => {
            closeConversation();
            closeSettings();
          }}
          ref={startNewChatRef}
        >
          <ArrowPathIcon className="h-5 w-5" />
          <p>Start new chat</p>
        </div>
      </div>

      <div className="p-2">
        <div
          tabIndex={0}
          aria-label="Clear chat history"
          className="flex cursor-pointer items-center gap-3 rounded-xl p-3 hover:bg-gray-100"
          onKeyDown={handleKeyClearHistory}
          onClick={() => {
            clearHistory();
            closeSettings();
            reset();
          }}
          ref={clearHistoryRef}
        >
          <ArchiveBoxXMarkIcon className="h-5 w-5" />
          <p>Clear history</p>
        </div>
      </div>

      <ModalFooter
        octocomLtdLinkRef={octocomLtdLinkRef}
        privacyPolicyLinkRef={privacyPolicyLinkRef}
        toSLinkRef={toSLinkRef}
      />
    </>
  );
}
