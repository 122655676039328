import { z } from "zod";
import { periodSchema } from "./periodToRange";
import { filtersRouterSchema as backendFiltersSchema } from "@be/modules/dashboard/metrics/metrics.schemas";

export const filtersSchema = backendFiltersSchema
  .omit({
    from: true,
    to: true,
    totalOrderAmountFrom: true,
    totalOrderAmountTo: true,
  })
  .extend({
    period: periodSchema,
    totalOrderAmountRange: z
      .object({
        from: z.number().optional(),
        to: z.number().optional(),
      })
      .optional(),
  });

export type Filters = z.infer<typeof filtersSchema>;
