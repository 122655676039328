import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { useState } from "react";

import { Inbox, Settings, BarChart4, Circle, FlaskRound } from "lucide-react";
import logo from "../../../assets/side-navbar-logo.svg";

import { NavbarButton } from "./-components/NavbarButton";
import { AvailableAgentsIndicator } from "./-components/AvailableAgentsIndicator";
import { UserButton } from "./-components/UserButton";
import { ChooseOrganizationDialog } from "./-components/ChooseOrganizationDialog";

import { useNotificationEffect } from "./-hooks/useNotificationEffect";

import { getInitialsComponent } from "./-utils/getInitialsComponent";
import { renderNavbarLink } from "./-utils/renderNavbarLink";
import { integrateRedirect } from "@dashboard/routes/connect/demo/-utils/redirect";
import { BackToSetup } from "./-components/BackToSetup";
import { Banner } from "./-components/Banner";
import { useBanner } from "./-hooks/useBanner";

export const Route = createFileRoute("/organization/$organizationSlug/_navbar")(
  {
    beforeLoad: async ({
      params: { organizationSlug },
      context: { trpcClientUtils, user },
    }) => {
      const { organizations } = user;
      const organization = organizations.find(
        (organization) => organization.slug === organizationSlug,
      );

      if (!organization) {
        throw redirect({
          to: "/organization/$organizationSlug/conversation",
          params: {
            organizationSlug: organizations[0].slug,
          },
          replace: true,
        });
      }

      integrateRedirect();

      if (
        organization.onboardingStatus !== "COMPLETED" &&
        organization.onboardingStatus !== "SETUP"
      ) {
        const url = `/organization/${organizationSlug}/onboarding/${organization.onboardingStatus.toLowerCase()}`;
        window.location.href = url;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, businesses, externalTicketConfig] = //I know that you can just put a comma here, but I want to be explicit and it looks cleaner
        await Promise.all([
          trpcClientUtils.dashboard.users.getAvailableAgents.ensureData({
            organizationSlug,
          }),

          trpcClientUtils.dashboard.business.getAll.ensureData({
            organizationSlug,
          }),

          trpcClientUtils.dashboard.externalTickets.getConfiguration.ensureData(
            {
              organizationSlug,
            },
          ),
        ]);

      let isMockConfig = false;

      if (businesses.length === 1) {
        isMockConfig =
          await trpcClientUtils.dashboard.orders.isMockConfig.ensureData({
            businessSlug: businesses[0].slug,
          });
      }

      if (
        isMockConfig &&
        window.location.pathname ===
          `/organization/${organizationSlug}/conversation` &&
        !sessionStorage.getItem("hasVisited")
      ) {
        sessionStorage.setItem("hasVisited", "true");
        throw redirect({
          to: "/organization/$organizationSlug/demo",
          params: {
            organizationSlug,
          },
          replace: true,
        });
      }

      sessionStorage.setItem("hasVisited", "true");

      return {
        organization,
        // TODO: Find out why we're returning ensuredData
        businesses,
        hasExternalTicketConfig: !!externalTicketConfig,
        isMockConfig,
      };
    },
    component: NavbarLayout,
  },
);

function NavbarLayout() {
  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();

  return (
    <div className="grid h-screen grid-rows-[auto_1fr] overflow-hidden">
      <Banner />
      <div
        className="relative flex flex-grow overflow-auto"
        style={{
          height: bannerVisible ? `calc(100vh - ${bannerHeight})` : "100vh",
        }}
      >
        <Navbar />
        <BackToSetup />
        <div className="flex flex-grow flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function Navbar() {
  const [openChooseOrganization, setOpenChooseOrganization] = useState(false);
  const { organizationSlug } = Route.useParams();

  const { user, hasExternalTicketConfig, organization, isMockConfig } =
    Route.useRouteContext();

  useNotificationEffect({});

  const onboardingCompleted = organization.onboardingStatus === "COMPLETED";

  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();

  return (
    <>
      <div>
        <div className="w-11" />
        <div
          className="bg-gray-light fixed left-0 top-0 z-50 h-full w-11"
          style={{
            height: bannerVisible ? `calc(100% - ${bannerHeight})` : "100%",
            top: bannerVisible ? bannerHeight : "0px",
          }}
        >
          <div className="flex h-full flex-col items-center pb-2 pt-5">
            <img alt="Octocom logo" className="h-7 w-7" src={logo} />

            <div className="group mt-5 flex w-full flex-grow flex-col">
              {isMockConfig && (
                <NavbarButton
                  render={renderNavbarLink({
                    to: "/organization/$organizationSlug/demo",
                  })}
                  tooltipText="Demo"
                  icon={FlaskRound}
                />
              )}
              {!onboardingCompleted && (
                <NavbarButton
                  render={renderNavbarLink({
                    to: "/organization/$organizationSlug/setup",
                  })}
                  tooltipText="Setup"
                  icon={Circle}
                />
              )}

              <NavbarButton
                render={renderNavbarLink({
                  to: "/organization/$organizationSlug/conversation",
                })}
                tooltipText="Help Desk"
                icon={Inbox}
              />

              <NavbarButton
                render={renderNavbarLink({
                  to: "/organization/$organizationSlug/metrics",
                  search: {
                    filters: {
                      period: "last-30-days",
                    },
                  },
                  active:
                    window.location.pathname.includes("metrics") &&
                    !window.location.pathname.includes("metrics-old"),
                })}
                tooltipText="Metrics"
                icon={BarChart4}
              />

              {(user.isManager || user.isAdmin) && (
                <NavbarButton
                  render={renderNavbarLink({
                    to: "/organization/$organizationSlug/settings",
                  })}
                  tooltipText="Settings"
                  icon={Settings}
                />
              )}
            </div>

            {!hasExternalTicketConfig && <AvailableAgentsIndicator />}

            {user.organizations.length > 1 && (
              <NavbarButton
                tooltipText="Select Organization"
                icon={getInitialsComponent(organizationSlug)}
                render={({ className, inactiveClassName, children }) => (
                  <button
                    type="button"
                    className={`${className} ${inactiveClassName}`}
                    onClick={() => setOpenChooseOrganization(true)}
                  >
                    {children}
                  </button>
                )}
              />
            )}

            <UserButton />
          </div>
        </div>
      </div>

      <ChooseOrganizationDialog
        open={openChooseOrganization}
        onOpenChange={setOpenChooseOrganization}
      />
    </>
  );
}
