import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/reamaze.png";

import { Separator } from "@dashboard/common/ui/separator";
import { ReamazeSettingsForm } from "./ReamazeSettingsForm";
import { useRemoveIntegration } from "../-hooks/useRemoveIntegration";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Re:amaze Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Streamline your customer support operations by integrating Re:amaze with
        Octocom. This seamless integration allows you to pass unhandled customer
        queries directly to the Re:amaze help desk, ensuring that your support
        team can manage and resolve issues effectively across all customer
        communication channels.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Easy Query Escalation:</span>{" "}
          Automatically forward unhandled customer queries from Octocom to
          Re:amaze, ensuring that every issue is promptly addressed and
          resolved.
        </li>
        <li>
          <span className="font-medium">Centralized Support Management:</span>{" "}
          Manage all customer interactions through Octocom, with the flexibility
          to escalate unresolved queries to Re:amaze for thorough follow-up and
          resolution.
        </li>
        <li>
          <span className="font-medium">Multi-Channel Customer Support:</span>{" "}
          Take advantage of Re:amaze’s multi-channel support capabilities to
          respond to customer queries across different platforms, ensuring
          timely and accurate assistance that enhances customer satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to deliver exceptional
        customer service by combining the centralized management tools of
        Octocom with Re:amaze’s powerful multi-channel support features, leading
        to quicker issue resolution and higher customer satisfaction.
      </p>
    </div>
  );
}

export function ReamazeIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  const removeIntegration = useRemoveIntegration();

  return (
    <>
      <IntegrationBlock
        integrationName="reamaze"
        title="Re:amaze"
        shortDescription="Pass unhandled customer support queries to Re:amaze."
        longDescription={<Description />}
        imageSrc={icon}
        installed={installed}
        settingsForm={<ReamazeSettingsForm />}
        onRemove={() =>
          removeIntegration({
            integrationServiceName: "reamaze",
          })
        }
      />
    </>
  );
}
