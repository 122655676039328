import { createFileRoute } from "@tanstack/react-router";
import { useState, useCallback } from "react";
import * as XLSX from "xlsx";
import { useUploadOrders } from "./-hooks/useUploadOrders";
import { AthletesFootOrderSchema } from "@be/modules/integrations/clients/athletesFoot/athletesFoot.schemas";
import { Button } from "@dashboard/common/ui/button";
import { TailSpin } from "react-loader-spinner";
import { format } from "date-fns";

export const Route = createFileRoute(
  "/public-routes/athletes-foot-order-upload/",
)({
  component: ExcelUploader,
  loader: async ({ context: { trpcClientUtils } }) => {
    await trpcClientUtils.dashboard.athletesFoot.getLastOrderSyncDate.ensureData();
  },
});

function ExcelUploader() {
  const { trpc } = Route.useRouteContext();

  const [lastSyncDate] =
    trpc.dashboard.athletesFoot.getLastOrderSyncDate.useSuspenseQuery();

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>("");
  const [isDragging, setIsDragging] = useState(false);
  const [email, setEmail] = useState<string>("");

  const [uploading, setUploading] = useState(false);

  const { uploadFile, uploadOrders } = useUploadOrders(setUploading);

  const validateFile = (file: File): boolean => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
    ];
    return allowedTypes.includes(file.type);
  };

  const handleFile = (file: File) => {
    if (validateFile(file)) {
      setFile(file);
      setError("");
    } else {
      setFile(null);
      setError("Please select a valid Excel file (.xlsx or .xls)");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) handleFile(selectedFile);
  };

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave") {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    handleFile(droppedFile);
  }, []);

  const convertExcelToJson = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert headers to camelCase and remove spaces
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: true,
      });

      if (jsonData.length < 2) {
        setError("Excel file appears to be empty");
        return null;
      }

      const headers = (jsonData[0] as string[]).map((header: string) =>
        header
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr: string) => chr.toUpperCase())
          .replace(/[^a-zA-Z0-9]/g, ""),
      );

      const rows = jsonData.slice(1);
      const formattedData = rows.map((row) =>
        headers.reduce<Record<string, unknown>>((obj, header, index) => {
          obj[header] = (row as unknown[])[index];
          return obj;
          // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
        }, {} as Record<string, unknown>),
      );
      return formattedData;
    } catch (err) {
      setError("Error processing Excel file");
      console.error(err);
      return null;
    }
  };

  const handleUpload = async () => {
    setError("");

    if (!file) return;

    try {
      const json = await convertExcelToJson(file);
      if (json) {
        const parsed = AthletesFootOrderSchema.array().parse(json);
        await uploadFile(file);
        uploadOrders(parsed, email);
      }
    } catch (err) {
      setError("Error uploading file");
      console.error(err);
    }
  };

  return (
    <div
      className={`flex min-h-screen w-full items-center justify-center p-4
        ${isDragging ? "bg-blue-50" : "bg-white"}`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
    >
      <div className="w-full max-w-md bg-white p-8">
        <h1 className="mb-6 text-center text-2xl font-bold">Order Upload</h1>
        {lastSyncDate && (
          <div className="mb-6 text-center">
            <p className="text-sm text-gray-600">
              Last sync completed:{" "}
              <span className="font-medium text-gray-900">
                {format(new Date(lastSyncDate), "PPp")}
              </span>
            </p>
          </div>
        )}
        <div className="space-y-4">
          <div className="text-center">
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileChange}
              className="hidden"
              id="file-upload"
            />
            <Button asChild variant="secondary">
              <label htmlFor="file-upload" className="cursor-pointer">
                Choose Excel File
              </label>
            </Button>
            <p className="mt-2 text-sm text-gray-500">
              or drag and drop anywhere on the page
            </p>
          </div>

          {error && <p className="text-center text-red-500">{error}</p>}
          {(file ?? uploading) && (
            <div className="text-center">
              {file && (
                <>
                  <p className="text-green-600">Selected file: {file.name}</p>
                  <div className="mt-4">
                    <p className="mb-2 text-sm text-gray-600">
                      If you'd like to be updated when the orders are fully
                      uploaded, enter your email here:
                    </p>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="your@email.com"
                      className="mb-4 w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                </>
              )}
              <Button
                type="button"
                variant="publish"
                onClick={() => void handleUpload()}
                className="mt-4"
                disabled={uploading}
              >
                {uploading ? (
                  <div className="flex items-center gap-2">
                    <TailSpin color="#6b7280" height={20} width={20} />
                    Synchronizing orders, you may close this page.
                  </div>
                ) : (
                  "Upload File"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
