import { Button } from "@dashboard/common/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { Tooltip, TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { cn } from "@dashboard/utils/ui";
import { ElementType, useEffect, useState } from "react";
import { Route } from "../../_conversationSidebar";
import { FilterKeys } from "@be/modules/dashboard/conversations/conversations.types";
import { X } from "lucide-react";

export function CommandFilter({
  options,
  valueIcon: ValueIcon,
  name,
  filterKey,
}: {
  options: { label: string; value: string }[];
  valueIcon?: ElementType;
  name: string;
  filterKey: Exclude<FilterKeys, "timeRange">;
}) {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const { filters } = search;

  const [value, setValue] = useState(filters ? filters[filterKey] ?? "" : "");
  const [open, setOpen] = useState(false);

  const selectedClasses = "text-blue bg-blue-light border border-blue";

  const setSearch = (value: string | undefined) => {
    setValue(value ?? "");
    setOpen(false);

    const updatedFilters = { ...search.filters, [filterKey]: value };

    if (
      Object.values<unknown>(updatedFilters).every(
        (value) => value === undefined || value === null,
      )
    ) {
      void navigate({
        search: { ...search, filters: undefined },
      });
    } else {
      void navigate({
        search: { ...search, filters: updatedFilters },
      });
    }
  };

  useEffect(() => {
    setValue(filters ? filters[filterKey] ?? "" : "");
  }, [filters, filterKey]);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className="group relative flex w-full items-center justify-between gap-2">
      {value !== "" && (
        <>
          <div className="group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
          <Tooltip
            triggerAsChild
            content="Remove filter"
            side="top"
            trigger={
              <X
                className="group-hover:bg-accent group-hover:text-blue absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors"
                onClick={() => setSearch(undefined)}
              />
            }
          />
        </>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "group-hover:text-blue group-hover:bg-blue-light bg-gray-light h-auto w-full justify-start gap-2 rounded-lg border border-transparent text-sm font-normal",
              "flex items-center overflow-hidden",
              value !== "" && selectedClasses,
            )}
          >
            {ValueIcon && <ValueIcon className="h-4 w-4 shrink-0" />}
            <TruncatedTooltip
              text={selectedOption ? selectedOption.label : name}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
          <Command>
            <CommandInput placeholder={`Search ${name.toLowerCase()}...`} />
            <CommandList>
              <CommandEmpty>No {name.toLowerCase()} found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.label}
                    onSelect={() => setSearch(option.value)}
                  >
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
