import { toast } from "sonner";
import { Route } from "..";
import { useInvalidateConversationData } from "../../../-hooks/useInvalidateConversationData";

export function useSendDelayedMessage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const invalidate = useInvalidateConversationData({ publicId });

  const sendDelayedMessageMutation =
    trpc.dashboard.conversations.sendDelayedMessage.useMutation({
      onSuccess: async () => {
        toast.success("Sending delayed message");
        await invalidate();
      },
      onError: (error) => {
        console.error("Error sending delayed message", error);
        toast.error("Error sending delayed message");
      },
    });

  return {
    sendDelayedMessage: () =>
      sendDelayedMessageMutation.mutate({
        organizationSlug,
        publicId,
      }),
  };
}
