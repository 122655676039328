import { CircleSlash } from "lucide-react";
import { SelectFilter } from "./general/SelectFilter";

export function ClosedFilter() {
  const options = [
    { value: "true", label: "Closed" },
    { value: "false", label: "Open" },
  ];

  return (
    <SelectFilter filterType="isClosed" options={options} icon={CircleSlash} />
  );
}
