import { createFileRoute } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";

import { Button } from "@dashboard/common/ui/button";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { Switch } from "@dashboard/common/ui/switch";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  EmailSettingsForm,
  emailSettingsFormSchema,
} from "./-utils/emailSettingsSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSubmitForm } from "./-hooks/useSubmitForm";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.emails.getConfig.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.emails.getInstalledIntegrations.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.emails.getEmailAddress.ensureData({
      businessSlug,
    });
  },

  component: EmailSettingsPage,
});

function EmailSettingsPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, businessSlug } = Route.useParams();
  const [config] = trpc.dashboard.emails.getConfig.useSuspenseQuery({
    businessSlug,
  });

  const [integrations] =
    trpc.dashboard.emails.getInstalledIntegrations.useSuspenseQuery({
      businessSlug,
    });

  const [emailAddress] = trpc.dashboard.emails.getEmailAddress.useSuspenseQuery(
    {
      businessSlug,
    },
  );

  const integrationNames = {
    microsoft: "Outlook",
    smtp_imap: "Smtp Imap",
    gmail: "Gmail",
    octocom_mail: "Octocom Mail",
  };

  const form = useForm<EmailSettingsForm>({
    resolver: zodResolver(emailSettingsFormSchema),
    defaultValues: config ?? undefined,
  });

  useEffect(() => {
    form.reset(config ?? undefined);
  }, [config]);

  const { onSubmit, onError } = useSubmitForm();

  if (!config || integrations.length === 0) {
    return (
      <div className="relative py-0">
        <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
          <h1 className="text-xl font-medium text-black">Email Settings</h1>
        </div>
        <div className="flex flex-col gap-5 px-10 py-5">
          No email integrations installed. Please install an email integration
          to configure email settings.
          <Link
            from="/organization/$organizationSlug/settings/$businessSlug/email"
            to="/organization/$organizationSlug/settings/$businessSlug/integrations"
            params={{ organizationSlug, businessSlug }}
          >
            <Button className="w-40">Integrations</Button>
          </Link>
        </div>
      </div>
    );
  }

  function handleSubmit(data: EmailSettingsForm) {
    onSubmit({
      data,
      configId: config?.id ?? "",
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={(e) => void form.handleSubmit(handleSubmit, onError)(e)}>
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">Email Settings</h1>
            <Button variant="publish" className="ml-auto" type="submit">
              {form.formState.isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </div>
        </div>

        <div className="px-10 py-5">
          <div className="flex w-full max-w-xl flex-col gap-5">
            <FormField
              control={form.control}
              name="system"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email provider</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select the email provider" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {integrations.map((integration) => (
                        <SelectItem key={integration} value={integration}>
                          {integrationNames[integration]}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex flex-col gap-3">
              <FormLabel>Connected email address</FormLabel>
              <Input
                value={emailAddress ?? ""}
                disabled
                className="disabled:cursor-default disabled:opacity-100"
              />
            </div>

            <FormField
              control={form.control}
              name="maxCustomerEmailsPerThread"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Max customer emails per thread</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="emailResponseDelayMinutes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email response delay (minutes)</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="footer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Footer</FormLabel>
                  <FormControl>
                    {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                    <Textarea
                      placeholder="Type your footer here (Optional)"
                      rows={5}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="respondToChainsInitiatedByCustomers"
              render={({ field }) => (
                <FormItem className="flex items-center justify-start gap-4 space-y-0">
                  <FormLabel>
                    Respond to conversations initiated by customers
                  </FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="ignoreChainsInitiatedByCustomers"
              render={({ field }) => (
                <FormItem className="flex items-center justify-start gap-4 space-y-0">
                  <FormLabel>Ignore chains initiated by customers</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableOctocomBranding"
              render={({ field }) => (
                <FormItem className="flex items-center justify-start gap-4 space-y-0">
                  <FormLabel>Enable Octocom branding</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </form>
    </Form>
  );
}
