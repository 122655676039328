import { Route } from "..";
import { createContext, useContext, ReactNode, useState } from "react";
import { Filters } from "../-utils/filtersSchema";

type BaseFilters = Omit<Filters, "period">;

export type FilterType = keyof BaseFilters;
export type FilterValue<T extends FilterType> = BaseFilters[T];

export interface Filter<T extends FilterType> {
  type: T;
  value: FilterValue<T>;
  isActive: boolean;
}

interface FiltersContextType {
  filters: Filter<FilterType>[];
  addFilter: ({ type }: { type: FilterType }) => void;
  removeFilter: ({ type }: { type: FilterType }) => void;
  updateFilter: ({
    type,
    value,
    active,
  }: {
    type: FilterType;
    value: FilterValue<FilterType>;
    active: boolean;
  }) => void;
  clearFilters: () => void;
  lastAddedFilter: FilterType | null;
  setLastAddedFilter: (type: FilterType | null) => void;
}

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

export function FiltersProvider({ children }: { children: ReactNode }) {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const [filters, setFilters] = useState<Filter<FilterType>[]>(
    Object.keys(search.filters)
      .filter((type) => type !== "period")
      .map((type) => ({
        type: type as FilterType,
        value: search.filters[type as FilterType],
        isActive: true,
      })),
  );
  const [lastAddedFilter, setLastAddedFilter] = useState<FilterType | null>(
    null,
  );

  const addFilter = ({ type }: { type: FilterType }) => {
    setFilters((current) => [
      ...current,
      {
        type,
        value: undefined,
        isActive: false,
      },
    ]);
  };

  const removeFilter = ({ type }: { type: FilterType }) => {
    const filter = filters.find((f) => f.type === type);
    if (!filter) return;

    setFilters((current) => current.filter((f) => f.type !== type));

    if (filter.isActive) {
      const updatedFilters = { ...search.filters };
      updatedFilters[type] = undefined;

      void navigate({
        search: { ...search, filters: updatedFilters },
      });
    }
  };

  const updateFilter = ({
    type,
    value,
    active,
  }: {
    type: FilterType;
    value: FilterValue<FilterType>;
    active: boolean;
  }) => {
    const filter = filters.find((f) => f.type === type);
    if (!filter) return;

    setFilters((current) =>
      current.map((f) => {
        if (f.type === type) {
          return {
            type,
            value,
            isActive: active,
          };
        }
        return f;
      }),
    );

    void navigate({
      search: { ...search, filters: { ...search.filters, [type]: value } },
    });
  };

  const clearFilters = () => {
    setFilters([]);
    void navigate({
      search: { ...search, filters: { period: search.filters.period } },
    });
  };

  const value: FiltersContextType = {
    filters,
    addFilter,
    removeFilter,
    updateFilter,
    clearFilters,
    lastAddedFilter,
    setLastAddedFilter,
  };

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
}
