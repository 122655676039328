import { z } from "zod";

export const botConfigFormSchema = z.object({
  configurationType: z.enum(["standard", "custom"]),
  name: z.string().optional(),
  customMessageCompositionRules: z.array(
    z.object({
      rule: z.string(),
    }),
  ),
  customConfiguration: z.string().optional(),
  tone: z.string().nullable(),
  enableEmojis: z.boolean(),
  autoCloseDelayMin: z.coerce.number().min(1),
  articleRelevanceCutoff: z.coerce.number().min(0).max(1),
});

export type BotConfigForm = z.infer<typeof botConfigFormSchema>;
