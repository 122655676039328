import { z } from "zod";

export const reamazeFormSchema = z.object({
  apiKey: z
    .string({
      required_error: "API Key is required",
      invalid_type_error: "API Key is required",
    })
    .min(1, "API Key is required"),
  subdomain: z
    .string({
      required_error: "Subdomain is required",
      invalid_type_error: "Subdomain is required",
    })
    .min(1, "Subdomain is required"),
  agentEmail: z
    .string({
      required_error: "Agent Email is required",
      invalid_type_error: "Agent Email is required",
    })
    .min(1, "Agent Email is required"),
});

export type ReamazeForm = z.infer<typeof reamazeFormSchema>;
