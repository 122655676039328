import { Button } from "@dashboard/common/ui/button";
import { Input } from "@dashboard/common/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { useState, useEffect } from "react";
import type { LucideIcon } from "lucide-react";
import type { Filter } from "../../../-context/FiltersContext";
import { useFilters } from "../../../-context/FiltersContext";
import { FilterTrigger } from "./FilterTrigger";

export type MonetaryRangeFilterTypes = "totalOrderAmountRange";

const FILTER_METADATA: Record<
  MonetaryRangeFilterTypes,
  { objectName: string; placeholder: string }
> = {
  totalOrderAmountRange: {
    objectName: "Total Order Amount",
    placeholder: "Select Total Order Amount",
  },
};

interface MonetaryRangeValue {
  from?: number;
  to?: number;
}

interface MonetaryRangeFilterProps {
  filterType: MonetaryRangeFilterTypes;
  icon?: LucideIcon;
}

export function MonetaryRangeFilter({
  filterType,
  icon: Icon,
}: MonetaryRangeFilterProps) {
  const [open, setOpen] = useState(false);
  const {
    filters,
    addFilter,
    updateFilter,
    removeFilter,
    lastAddedFilter,
    setLastAddedFilter,
  } = useFilters();

  const { placeholder, objectName } = FILTER_METADATA[filterType];

  const filter = filters.find((f) => f.type === filterType) as
    | Filter<MonetaryRangeFilterTypes>
    | undefined;

  const [values, setValues] = useState<MonetaryRangeValue>(
    filter?.value ?? { from: undefined, to: undefined },
  );

  useEffect(() => {
    if (lastAddedFilter === filterType) {
      setOpen(true);
      setLastAddedFilter(null);
    }
  }, [lastAddedFilter, filterType, setLastAddedFilter]);

  if (!filter) {
    addFilter({ type: filterType });
    return null;
  }

  const handleFromChange = (value: string) => {
    const numValue = value === "" ? undefined : Number(value);
    setValues((prev) => ({ ...prev, from: numValue }));
  };

  const handleToChange = (value: string) => {
    const numValue = value === "" ? undefined : Number(value);
    setValues((prev) => ({ ...prev, to: numValue }));
  };

  const handleConfirm = () => {
    if (!values.from && !values.to) {
      updateFilter({ type: filterType, value: undefined, active: false });
    } else {
      updateFilter({ type: filterType, value: values, active: true });
    }
    setOpen(false);
  };

  const getSelectionLabel = () => {
    if (!values.from && !values.to) {
      return {
        prefix: placeholder,
        value: undefined,
      };
    }

    const value =
      values.from && values.to
        ? `${values.from} to ${values.to}`
        : values.from
        ? `from ${values.from}`
        : `up to ${values.to}`;

    return {
      prefix: `${objectName} is`,
      value,
    };
  };

  const { prefix, value } = getSelectionLabel();

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      handleConfirm();
    }
    setOpen(isOpen);
  };

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger>
        <FilterTrigger
          isOpen={open}
          isActive={filter.isActive}
          onRemove={() => {
            removeFilter({ type: filterType });
            setOpen(false);
          }}
          icon={Icon}
          labelPrefix={prefix}
          labelValue={value}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-[300px] p-4" align="start">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="from" className="text-sm font-medium">
                From
              </label>
              <Input
                id="from"
                type="number"
                min={0}
                step="0.01"
                className="w-[120px] [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                placeholder="Min amount"
                value={values.from ?? ""}
                onChange={(e) => handleFromChange(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="to" className="text-sm font-medium">
                To
              </label>
              <Input
                id="to"
                type="number"
                min={0}
                step="0.01"
                className="w-[120px] [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                placeholder="Max amount"
                value={values.to ?? ""}
                onChange={(e) => handleToChange(e.target.value)}
              />
            </div>
          </div>
          <Button onClick={handleConfirm} className="w-full">
            Confirm
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
