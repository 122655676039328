import { Route } from "..";
import { toast } from "sonner";
import { RecommendationSettingsForm } from "../-utils/recommendationSettingsFormSchema";

export function useSubmitForm() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const upsertMutation = trpc.dashboard.products.upsertConfig.useMutation({
    onSuccess: () => {
      toast.success("Successfully updated recommendation settings.");
    },
    onError: () => {
      toast.error(
        "Failed to update the recommendation settings. Please try again.",
      );
    },
  });

  return {
    onSubmit: (data: RecommendationSettingsForm) => {
      const payload = {
        businessSlug,
        collections: data.collections,
        metafields: data.metafields,
        nameHints: data.nameHints.map((hint) => hint.hint),
        productPickingHints: data.productPickingHints.map((hint) => hint.hint),
        collectionPickingHints: data.collectionPickingHints.map(
          (hint) => hint.hint,
        ),
        recommendationWorkflowHints: data.recommendationWorkflowHints.map(
          (hint) => hint.hint,
        ),
      };

      upsertMutation.mutate(payload);
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.error("Failed to update recommendation settings.");
    },
  };
}
