import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { ReamazeForm, reamazeFormSchema } from "../-utils/reamazeFormSchema";
import { useIntegration } from "../-hooks/useIntegration";
import { useSubmitForm } from "../-hooks/useSubmitForm";

export function ReamazeSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "reamaze" });

  const defaultValues = {
    apiKey: integration?.apiKey ?? "",
    subdomain: integration?.subdomain ?? "",
    agentEmail: integration?.agentEmail ?? "",
  };

  const form = useForm<ReamazeForm>({
    resolver: zodResolver(reamazeFormSchema),
    defaultValues: integration ?? defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({
    integrationServiceName: "reamaze",
  });

  function Description() {
    return (
      <div className="flex flex-col gap-4 leading-normal">
        <h1 className="text-2xl font-semibold">Reamaze Integration Settings</h1>
        <p>
          Instructions on how to get your API key can be found here:{" "}
          <a
            href="https://www.reamaze.com/api"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 underline hover:text-blue-800"
          >
            Reamaze Authentication
          </a>
        </p>
        <p>
          Subdomain is the value <span className="font-medium">abc</span> from{" "}
          <span className="font-medium">abc</span>
          .reamaze.com
        </p>
      </div>
    );
  }

  return (
    <Form {...form}>
      <Description />
      <div className="mb-1 mt-3">
        <h2 className="text-base font-semibold">Credentials</h2>
      </div>
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <FormField
          control={form.control}
          name="apiKey"
          render={({ field }) => (
            <FormItem>
              <FormLabel>API Key</FormLabel>
              <FormControl>
                <Input placeholder="Enter the API Key" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="mt-4">
          <FormField
            control={form.control}
            name="subdomain"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Subdomain</FormLabel>
                <FormControl>
                  <Input placeholder="Enter the Subdomain" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="mt-4">
          <FormField
            control={form.control}
            name="agentEmail"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Agent Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter the Agent Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex w-full justify-end pt-4">
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Form>
  );
}
