import { format } from "date-fns";
import { Route } from "..";
import { periodToRange } from "../-utils/periodToRange";

export function useSalesData() {
  const { filters } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const range = periodToRange(filters.period);

  const [data] = trpc.dashboard.metrics.getSalesData.useSuspenseQuery({
    organizationSlug,
    filters: {
      ...filters,
      from: range.from,
      to: range.to,
      totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
      totalOrderAmountTo: filters.totalOrderAmountRange?.to,
    },
  });

  const total = data.reduce((acc, { value }) => acc + value, 0);
  const average = total / data.length;

  return {
    perDay: data.map(({ datetime, value }) => ({
      x: format(new Date(datetime), "PPp"),
      y: Number(value.toFixed(2)),
    })),
    total,
    average,
  };
}
