import { PathSelect } from "./PathSelect";
import type { MetafieldSectionProps } from "../-utils/types";

export function MetafieldSection({
  type,
  metafields,
  onAdd,
  onUpdate,
  onRemove,
  availablePaths,
}: MetafieldSectionProps) {
  const title =
    type === "product" ? "Product Metafields" : "Variant Metafields";

  const handlePathSelectChange = (
    fieldId: string,
    value: string,
    transforms: { id: string; params?: Record<string, string> }[],
  ) => {
    console.log("MetafieldSection handlePathSelectChange:", {
      fieldId,
      value,
      transforms,
    });
    // Explicitly spread the transforms array to ensure it's passed
    onUpdate(fieldId, "valuePath", value, [...transforms]);
  };

  return (
    <div className="mt-8">
      <h3 className="mb-4 text-lg font-semibold">{title}</h3>
      {metafields.map((field) => (
        <div key={field.id} className="mb-4 flex items-center gap-2">
          <input
            type="text"
            value={field.key}
            onChange={(e) => onUpdate(field.id, "key", e.target.value)}
            placeholder="Metafield key"
            className="w-1/3 rounded border p-2 text-sm"
          />
          <PathSelect
            value={field.valuePath}
            transforms={field.transforms ?? []}
            onChange={(value, transforms) =>
              handlePathSelectChange(field.id, value, transforms)
            }
            placeholder="Select value path"
            availablePaths={availablePaths}
          />
          <button
            onClick={() => onRemove(field.id)}
            className="rounded bg-red-500 px-2 py-1 text-white hover:bg-red-600"
          >
            ✕
          </button>
        </div>
      ))}
      <button
        onClick={onAdd}
        className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
      >
        Add {type === "product" ? "Product" : "Variant"} Metafield
      </button>
    </div>
  );
}
