import { z } from "zod";
import { channelNameSchema } from "../../conversations/conversations.schemas";

export const filtersSchema = z.object({
  // Basic
  businessIds: z.array(z.string()).min(1),
  type: z.array(z.enum(["customer", "external"])).optional(),

  // Date range
  from: z.string().datetime(),
  to: z.string().datetime(),

  // Conversation status
  isHandedOff: z.boolean().optional(),
  isClosed: z.boolean().optional(),
  isSnoozed: z.boolean().optional(),

  // Other filters
  channels: z.array(channelNameSchema).optional(),
  languages: z.array(z.string()).optional(),
  assignees: z
    .union([
      z.literal("assigned"),
      z.literal("unassigned"),
      z.array(z.string()),
    ])
    .optional(),
  tags: z.array(z.string()).optional(),
  topics: z.array(z.string()).optional(),
  senders: z.array(z.string()).optional(),
  taskNames: z.array(z.string()).optional(),

  // Order filters
  orderStatuses: z.array(z.string()).optional(),
  orderCanceled: z.boolean().optional(),

  totalOrderAmountFrom: z.number().optional(),
  totalOrderAmountTo: z.number().optional(),

  orderShippingMethods: z.array(z.string()).optional(),

  orderRecipientCities: z.array(z.string()).optional(),
  orderRecipientStates: z.array(z.string()).optional(),
  orderRecipientCountries: z.array(z.string()).optional(),

  orderBillingCities: z.array(z.string()).optional(),
  orderBillingStates: z.array(z.string()).optional(),
  orderBillingCountries: z.array(z.string()).optional(),

  // Order fulfillment filters
  orderFulfillmentStatuses: z.array(z.string()).optional(),
  orderCouriers: z.array(z.string()).optional(),
});

export const filtersRouterSchema = filtersSchema.extend({
  businessIds: z.array(z.string()).optional(),
});

export type Filters = z.infer<typeof filtersSchema>;
export type FiltersRouter = z.infer<typeof filtersRouterSchema>;
