import { toast } from "sonner";
import { Route } from "..";
import { useInvalidateInstalledIntegrations } from "./useInvalidateInstalledIntegrations";
import { IntegrationServiceName } from "@be/modules/integrations/integrations.types";

export function useRemoveIntegration() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const invalidate = useInvalidateInstalledIntegrations();

  const removeMutation = trpc.dashboard.integrations.uninstall.useMutation({
    onSuccess: async (_, data) => {
      await trpcUtils.dashboard.integrations.getIntegration.invalidate({
        integrationServiceName: data.integrationServiceName,
        businessSlug,
      });
      await invalidate();
      toast.success("Successfully uninstalled integration.");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to uninstall integration. Please try again.");
    },
  });

  return ({
    integrationServiceName,
  }: {
    integrationServiceName: IntegrationServiceName;
  }) => {
    removeMutation.mutate({ businessSlug, integrationServiceName });
  };
}
