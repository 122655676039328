import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { format } from "date-fns";
import { EmptyState } from "./EmptyState";

interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

function CustomTick({ x, y, payload }: CustomTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={8}
        fontSize={10}
        fontFamily="Arial"
        fontWeight="light"
        textAnchor="middle"
        fill="#6a6a75"
      >
        {payload?.value}
      </text>
    </g>
  );
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    name?: string;
    value?: number;
    color?: string;
  }[];
  label?: string;
  yTitle?: string;
}

function CustomTooltip({ active, payload, label, yTitle }: CustomTooltipProps) {
  if (active && payload?.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-300 bg-white px-3 py-2.5 shadow-sm">
        {payload.map((series, index) => (
          <p
            key={index}
            style={{ color: series.color }}
            className="font-medium"
          >
            {`${series.name}: ${series.value} ${yTitle}`}
          </p>
        ))}
        <p className="text-gray-700">{label ?? ""}</p>
      </div>
    );
  }
  return null;
}

interface Series {
  name: string;
  data: {
    x: string;
    y: number;
  }[];
  style: {
    color: string;
    dot?:
      | boolean
      | {
          r?: number;
          fill?: string;
          stroke?: string;
        };
  };
}

interface CombinedDataPoint {
  x: string;
  [key: string]: string | number;
}

interface ChartProps {
  value?: number | string;
  yTitle: string;
  series: Series[];
  yDomain?: [number, number];
  showLegend?: boolean;
}

export function LineChart({
  series,
  yTitle,
  value,
  yDomain,
  showLegend,
}: ChartProps) {
  // Check if there's any data at all, regardless of values
  const hasData = series.some((s) => s.data.length > 0);
  if (!hasData) {
    return (
      <div className="w-full">
        {value && (
          <div className="mt-4 flex flex-row items-center gap-x-4">
            <span className="text-2xl text-black">
              {value} {yTitle}
            </span>
          </div>
        )}
        <div className="mt-10 h-[280px]">
          <EmptyState />
        </div>
      </div>
    );
  }

  const allData = series[0].data.map((point) => {
    const combinedPoint: CombinedDataPoint = {
      x: format(new Date(point.x), "EEE, MMM d"),
    };
    series.forEach((s) => {
      const matchingPoint = s.data.find((p) => p.x === point.x);
      combinedPoint[s.name] = matchingPoint?.y ?? 0;
    });
    return combinedPoint;
  });

  return (
    <div className="w-full">
      {value && (
        <div className="mt-4 flex flex-row items-center gap-x-4">
          <span className="text-2xl text-black">
            {value} {yTitle}
          </span>
        </div>
      )}
      <div className="mt-10 h-[280px]">
        <ResponsiveContainer width="100%" height="100%">
          <RechartsLineChart
            width={500}
            height={300}
            data={allData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid
              stroke="#F2F2F2"
              horizontal={true}
              vertical={false}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={yDomain}
              tick={{
                dx: -15,
                style: {
                  fontFamily: "Arial",
                  fontSize: 11,
                  fontWeight: "light",
                },
              }}
            />
            <XAxis
              dataKey="x"
              axisLine={false}
              tickLine={false}
              tick={<CustomTick />}
            />
            <Tooltip<number, string>
              content={(props) => <CustomTooltip {...props} yTitle={yTitle} />}
              cursor={{ fill: "transparent" }}
            />

            {series.map((s, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={s.name}
                stroke={s.style.color}
                name={s.name}
                dot={s.style.dot}
              />
            ))}
            {showLegend && <Legend />}
          </RechartsLineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
