import { Button } from "@dashboard/common/ui/button";
import { Check } from "lucide-react";
import { useCompleteOnboardingStep } from "../../-hooks/useCompleteOnboardingStep";
import { Route } from "../..";
import { Link } from "@tanstack/react-router";

interface LaunchBotProps {
  completed: boolean;
}

export function LaunchBot({ completed }: LaunchBotProps) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const completeStep = useCompleteOnboardingStep();

  const [installedIntegrations] =
    trpc.dashboard.integrations.getInstalledIntegrations.useSuspenseQuery({
      businessSlug: organizationSlug,
    });

  let platform = "";

  if (installedIntegrations.includes("woocommerce")) {
    platform = "woocommerce";
  } else if (installedIntegrations.includes("shopify")) {
    platform = "shopify";
  }

  return (
    <div className="space-y-6">
      <p>
        Launch your AI chatbot to provide 24/7 automated support for your
        customers. Add the chat widget to your website and start delivering
        instant, intelligent responses to customer inquiries.
      </p>
      <div className="flex flex-col gap-4">
        {platform === "woocommerce" ? (
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/web-chat"
            params={{
              organizationSlug,
              businessSlug: organizationSlug,
            }}
            search={{
              tab: "deployment",
            }}
            className="w-full"
          >
            <Button className="w-full">Launch Your Chatbot</Button>
          </Link>
        ) : (
          <a
            href="https://octocom.gitbook.io/integrations/"
            className="w-full"
            target="_blank"
            rel="noreferrer"
          >
            <Button className="w-full">Launch Your Chatbot</Button>
          </a>
        )}

        {!completed ? (
          <Button
            onClick={() => completeStep({ step: "launch" })}
            variant="secondary"
            className="w-full"
          >
            Complete
          </Button>
        ) : (
          <Button className="w-full">
            <Check className="mr-2 h-4 w-4" />
            Completed
          </Button>
        )}
      </div>
    </div>
  );
}
