import { Route } from "..";
import { toast } from "sonner";
import { z } from "zod";

const emailRuleExportSchema = z.object({
  rules: z.array(
    z.object({
      id: z.string().optional(),
      value: z.string(),
      type: z.enum(["email", "domain"]),
      action: z.enum(["handoff", "spam", "ignore"]),
      isActive: z.boolean(),
      organization: z.boolean(),
    }),
  ),
});

export function useExportRules() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [rules] = trpc.dashboard.emails.inboundRules.getRules.useSuspenseQuery({
    businessSlug,
  });

  return {
    exportRules: (ids: string[]) => {
      const selectedRules = rules.filter((rule) => ids.includes(rule.id));

      const data: z.infer<typeof emailRuleExportSchema> = {
        rules: selectedRules.map((rule) => ({
          ...rule,
          id: undefined,
          businessId: undefined,
          organizationId: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          organization: rule.organizationId !== null,
        })),
      };

      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `octocom-email-rules-${businessSlug}-${timestamp}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success("Email rules exported successfully");
    },
  };
}
