import { toast } from "sonner";
import { Route } from "..";
import { Business } from "@be/modules/businesses/businesses.types";

export const useRemoveExternalTicketConfig = () => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const removeMutation =
    trpc.dashboard.externalTickets.removeConfiguration.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.externalTickets.getConfiguration.invalidate();
        toast.success("External ticket configuration updated.");
      },
      onError: (error) => {
        toast.error("Failed to update external ticket configuration.");
        console.error(error);
      },
    });

  const updateBusinessMutation = trpc.dashboard.business.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.business.getOrThrow.invalidate();
    },
    onError: (error) => {
      toast.error("Failed to update business configuration.");
      console.error(error);
    },
  });

  return {
    // ? Why are we updating business config within a remove mutation?
    removeConfig: ({
      responseText,
      autoAssignmentPolicy,
    }: {
      responseText: string;
      autoAssignmentPolicy: Business["autoAssignmentPolicy"];
    }) => {
      removeMutation.mutate({ businessSlug });

      updateBusinessMutation.mutate({
        businessSlug,
        data: {
          responseText,
          autoAssignmentPolicy,
        },
      });
    },
  };
};
