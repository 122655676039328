interface StatsCardProps {
  value: string | number;
  subtitle?: string;
}

export function StatsCard({ value, subtitle }: StatsCardProps) {
  return (
    <div className="mt-5 flex w-full flex-row items-center justify-between">
      <div className="text-4xl font-normal text-black">{value}</div>
      {subtitle && (
        <div className="text-muted-foreground text-sm">{subtitle}</div>
      )}
    </div>
  );
}
