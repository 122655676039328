import { cn } from "@dashboard/utils/ui";
import {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import { Separator } from "@dashboard/common/ui/separator";
import { Input } from "@dashboard/common/ui/input";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "@dashboard/common/ui/button";
import { Route } from "../..";
import { Select } from "@dashboard/common/ui/select";
import { useCreateConversations } from "../-hooks/useCreateConversations";
import { MacroAction } from "@be/modules/macros/macros.types";
import { MacroCommand } from "../../-components/Macros/MacroCommand";
import { DashboardAction } from "../../-components/Macros/DashboardAction";
import { AttachmentButton } from "../../-components/FileUpload/AttachmentButton";
import { AttachedFile } from "../../-components/FileUpload/AttachedFile";
import { z } from "zod";
import { toast } from "sonner";
import { macroActionSchema } from "@be/modules/macros/macros.schemas";
import { BulkSendDialog } from "./BulkSendDialog";
import { MailX } from "lucide-react";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { useFileUpload } from "../../-hooks/useFileUpload";
import { MessageIcon } from "../../$publicId/-components/Input/components/MessageIcon";
import { LanguageCommand } from "./LanguageCommand";

export function MessageInput() {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [inputText, setInputText] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [businessId, setBusinessId] = useState("");
  const { filters } = Route.useSearch();
  const [actions, setActions] = useState<MacroAction[]>([]);
  const [attachments, setAttachments] = useState<
    {
      fileId: string;
      filename: string;
    }[]
  >([]);

  const [bulkEmails, setBulkEmails] = useState<string[]>([]);

  const createConversations = useCreateConversations();

  const onClickSendMessage = () => {
    const data = {
      businessId,
      customerEmails: bulkEmails.length > 0 ? bulkEmails : [email],
      text: inputText,
      subject,
      actions,
      fileIds: attachments.map((attachment) => attachment.fileId),
    };

    const result = z
      .object({
        businessId: z.string().uuid(),
        customerEmails: z.array(z.string().email()),
        text: z.string().min(1),
        subject: z.string().min(1),
        actions: z.array(macroActionSchema),
        fileIds: z.array(z.string().uuid()),
      })
      .safeParse(data);

    if (result.success) {
      createConversations(data);
    } else {
      toast.error("Invalid input");
      console.log(result.error.errors);
    }
  };

  const handleKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    const isModifierKeyPressed = event.metaKey || event.ctrlKey;

    if (event.key === "Enter" && isModifierKeyPressed) {
      onClickSendMessage();
      event.preventDefault();
    }
  };

  const businessIdFromFilters = filters?.businessId ?? undefined;

  useEffect(() => {
    if (businessIdFromFilters) {
      setBusinessId(businessIdFromFilters);
    }
  }, [businessIdFromFilters]);

  const { businesses } = Route.useRouteContext();
  const options = businesses.map((business) => ({
    label: business.internalName,
    value: business.id,
  }));

  if (businesses.length === 1 && businessId === "") {
    setBusinessId(businesses[0].id);
  }

  let businessEmail: string | null = null;
  let businessSlug: string | undefined = undefined;
  let allowOrderIds = false;

  if (businessId !== "") {
    const selectedBusiness = businesses.find(
      (business) => business.id === businessId,
    );

    if (selectedBusiness) {
      businessEmail = selectedBusiness.emailAddress;
      businessSlug = selectedBusiness.slug;
      allowOrderIds = selectedBusiness.emailTrackingAvailable;
    }
  }

  const onAttachmentUpload = (file: { fileId: string; filename: string }) => {
    setAttachments((prev) => [...prev, file]);
  };

  const onAttachmentRemove = ({ fileId }: { fileId: string }) => {
    setAttachments((prev) => prev.filter((f) => f.fileId !== fileId));
  };

  const handleBusinessChange = (value: string) => {
    setBusinessId(value);
    setAttachments([]);
  };

  const { uploadFiles } = useFileUpload();

  const handlePaste = async (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const items = e.clipboardData.items;

    const files = Array.from(items)
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile()) as File[];

    if (files.length === 0) {
      return;
    }

    e.preventDefault();

    if (businessId === "") {
      toast.error("Select a business before uploading files.");
      return;
    }

    const uploadedFiles = await uploadFiles({
      files,
      businessId,
    });

    setAttachments((prev) =>
      prev.concat(
        uploadedFiles.map((file) => ({
          fileId: file.fileId,
          filename: file.filename,
        })),
      ),
    );
  };

  return (
    <div className="bg-background relative flex-grow">
      <div className="absolute bottom-3 w-full px-7">
        <div
          className={cn(
            "flex flex-col overflow-x-hidden break-words rounded-2xl border border-gray-100 px-4 pb-2 pt-3",
            inputIsFocused ? "shadow-xl" : "shadow-md",
          )}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center gap-2">
              <MessageIcon />
              <span className="ml-1 text-sm font-medium">Email</span>
            </div>
            {businesses.length > 1 && (
              <>
                <Select
                  variant="dropdown"
                  placeholder="Select business"
                  value={businessId}
                  onChange={handleBusinessChange}
                  options={options}
                />
              </>
            )}
          </div>

          <div>
            <div className="flex h-9 items-center">
              <div className="w-16 shrink-0 text-sm text-gray-500">From</div>
              <span
                className={cn(
                  "flex items-center px-3 py-1 text-sm text-black",
                  !businessEmail && "text-red-500",
                )}
              >
                {businessEmail ?? "Email integration not set up."}
              </span>
            </div>
            <Separator />
            <div className="flex items-center justify-between">
              <div className="flex h-9 items-center">
                <div className="w-16 shrink-0 text-sm text-gray-500">To</div>
                {bulkEmails.length > 0 ? (
                  <div className="px-3 py-1 text-sm text-black">
                    {bulkEmails.length} recipients
                  </div>
                ) : (
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setInputIsFocused(true)}
                    onBlur={() => setInputIsFocused(false)}
                    className="border-0 text-sm text-black shadow-none focus-visible:ring-0"
                  />
                )}
              </div>
              {businessSlug && (
                <div className="flex items-center">
                  {bulkEmails.length > 0 && (
                    <Tooltip
                      triggerAsChild
                      trigger={
                        <Button
                          variant="ghost"
                          className="h-8 p-2"
                          onClick={() => setBulkEmails([])}
                        >
                          <MailX className="h-4 w-4" />
                        </Button>
                      }
                      content="Remove recipients"
                    />
                  )}
                  <BulkSendDialog
                    allowOrderIds={allowOrderIds}
                    businessSlug={businessSlug}
                    processedEmails={bulkEmails}
                    setProcessedEmails={setBulkEmails}
                  />
                </div>
              )}
            </div>
            <Separator />
            <div className="flex h-9 items-center">
              <div className="w-16 shrink-0 text-sm text-gray-500">Subject</div>

              <Input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                onFocus={() => setInputIsFocused(true)}
                onBlur={() => setInputIsFocused(false)}
                className="border-0 text-sm text-black shadow-none focus-visible:ring-0"
              />
            </div>
          </div>
          <Separator />
          <TextareaAutosize
            minRows={4}
            maxRows={10}
            className="block w-full resize-none border-0 px-0 py-3 text-sm text-black outline-none focus:ring-0"
            placeholder="Type here..."
            value={inputText}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setInputText(e.target.value)
            }
            onPaste={(event) => void handlePaste(event)}
            onKeyDown={handleKeyDown}
            onFocus={() => setInputIsFocused(true)}
            onBlur={() => setInputIsFocused(false)}
          />
          {actions.length > 0 && (
            <div className="flex flex-row flex-wrap items-center justify-start gap-2 pb-3 pt-3 text-sm">
              {actions.map((action, index) => (
                <DashboardAction
                  key={index}
                  action={action}
                  remove={() => {
                    setActions((prev) => prev.filter((_, i) => i !== index));
                  }}
                />
              ))}
            </div>
          )}
          {attachments.length > 0 && (
            <div className="flex flex-row flex-wrap items-center justify-start gap-2 pb-3 pt-3 text-sm">
              {attachments.map((attachment, index) => (
                <AttachedFile
                  key={index}
                  filename={attachment.filename}
                  fileId={attachment.fileId}
                  remove={onAttachmentRemove}
                />
              ))}
            </div>
          )}
          <div className="flex h-8 flex-row items-center justify-between">
            <div className="flex items-center gap-2">
              <MacroCommand
                setInput={setInputText}
                setActions={setActions}
                setFiles={setAttachments}
                businessId={businessId === "" ? undefined : businessId}
              />
              {businessId !== "" && (
                <AttachmentButton
                  onUpload={onAttachmentUpload}
                  businessId={businessId}
                />
              )}
              <LanguageCommand
                inputText={inputText}
                setInputText={setInputText}
                originalText={originalText}
                setOriginalText={setOriginalText}
              />
            </div>
            <Button
              disabled={
                inputText === "" ||
                (email === "" && bulkEmails.length < 1) ||
                subject === "" ||
                businessId === "" ||
                !businessEmail
              }
              className="h-full rounded-lg px-3 font-semibold shadow-none transition duration-100 ease-in-out disabled:bg-transparent disabled:text-gray-500 disabled:shadow-none"
              onClick={onClickSendMessage}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
