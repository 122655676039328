import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

interface ToastProps {
  message: string;
  duration?: number;
  onClose: () => void;
  type?: "error" | "success";
}

export function Toast({
  message,
  duration = 3000,
  onClose,
  type = "error",
}: ToastProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Start animation after mount
    requestAnimationFrame(() => setIsVisible(true));
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div
      className={`
        fixed bottom-24 left-1/2 flex max-w-[300px] 
        -translate-x-1/2 items-center gap-3 rounded-lg bg-white p-4 text-sm
        shadow-lg transition-all duration-300 ease-in-out
        ${isVisible ? "translate-y-0 opacity-100" : "translate-y-4 opacity-0"}
      `}
    >
      {type === "error" && (
        <ExclamationCircleIcon className="h-6 w-6 shrink-0 text-black" />
      )}
      <span className="truncate whitespace-nowrap">{message}</span>
    </div>
  );
}

interface ToastManagerProps {
  containerRef: React.RefObject<HTMLElement>;
}

export function ToastManager({ containerRef }: ToastManagerProps) {
  const [toasts, setToasts] = useState<{ id: number; message: string }[]>([]);

  useEffect(() => {
    const handleShowToast = (e: CustomEvent<string>) => {
      const id = Date.now();
      setToasts((current) => [...current, { id, message: e.detail }]);
    };

    document.addEventListener(
      "show-toast" as keyof DocumentEventMap,
      handleShowToast as EventListener,
    );
    return () => {
      document.removeEventListener(
        "show-toast" as keyof DocumentEventMap,
        handleShowToast as EventListener,
      );
    };
  }, []);

  const removeToast = (id: number) => {
    setToasts((current) => current.filter((toast) => toast.id !== id));
  };

  // Only create portal if container ref exists
  if (!containerRef.current) return null;

  return createPortal(
    <div className="absolute inset-x-0 z-50">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </div>,
    containerRef.current,
  );
}

export const toast = {
  show: (message: string) => {
    const event = new CustomEvent("show-toast", { detail: message });
    document.dispatchEvent(event);
  },
};
