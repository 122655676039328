import { createFileRoute } from "@tanstack/react-router";
import { WhatsappLogo } from "@dashboard/common/svgs/WhatsappLogo";

export const Route = createFileRoute(
  "/public-routes/test-whatsapp-wa-me-icon/",
)({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <a
      href="https://wa.me/37061011110"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-6 right-6 z-50 flex h-14 w-14 items-center justify-center rounded-full bg-[#25D366] text-white shadow-lg transition-transform hover:scale-110"
      aria-label="Contact us on WhatsApp"
    >
      <WhatsappLogo className="h-8 w-8" />
    </a>
  );
}
