import { toast } from "sonner";
import { Route } from "..";
import { UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { NewProductProps } from "../index";

type ImageFieldName = "imageUrl" | `variants.${number}.imageUrl`;

export function useImageUpload(
  setValue: UseFormSetValue<NewProductProps>,
  getValues: UseFormGetValues<NewProductProps>,
) {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const uploadFileMutation = trpc.dashboard.files.uploadFile.useMutation();

  const handleImageUpload = async (file: File, fieldName: ImageFieldName) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const buffer = Array.from(uint8Array);

      const publicUrl = await uploadFileMutation.mutateAsync({
        businessSlug,
        buffer,
        filename: file.name,
        contentType: file.type,
      });

      if (fieldName === "imageUrl") {
        setValue("imageUrl", publicUrl);
      } else {
        const [, indexStr] = fieldName.split(".");
        const index = parseInt(indexStr);

        // Get current variants
        const variants = getValues("variants");
        const updatedVariants = [...variants];
        updatedVariants[index] = {
          ...updatedVariants[index],
          imageUrl: publicUrl,
        };

        // Update the entire variants array
        setValue("variants", updatedVariants);
      }
    } catch (error) {
      toast.error("Failed to upload image");
    }
  };

  return {
    handleImageUpload,
    isUploading: uploadFileMutation.isPending,
  };
}
