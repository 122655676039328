import { Button } from "@dashboard/common/ui/button";
import { Tooltip, TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { ChevronDown, X, type LucideIcon } from "lucide-react";
import { cn } from "@dashboard/utils/ui";
import { MouseEvent } from "react";

interface FilterTriggerProps {
  isOpen: boolean;
  isActive: boolean;
  onRemove: (e: MouseEvent) => void;
  icon?: LucideIcon;
  labelPrefix: string;
  labelValue?: string;
  className?: string;
}

export function FilterTrigger({
  isOpen,
  isActive,
  onRemove,
  icon: Icon,
  labelPrefix,
  labelValue,
  className,
}: FilterTriggerProps) {
  return (
    <div className="group relative flex items-center justify-between gap-2">
      <div className="group-hover:bg-accent absolute right-3 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
      <Tooltip
        triggerAsChild
        content="Remove this filter"
        side="top"
        trigger={
          <X
            className="group-hover:bg-accent group-hover:text-blue absolute right-3 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent"
            onClick={(e) => {
              e.stopPropagation();
              onRemove(e);
            }}
          />
        }
      />
      <Button
        variant="secondary"
        className={cn(
          "ring-offset-background placeholder:text-muted-foreground border-gray-light border",
          "h-9 whitespace-nowrap bg-transparent px-3 py-2 text-sm focus:outline-none",
          "disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
          "flex w-auto select-none items-center justify-between rounded-xl font-medium shadow-none",
          "group-hover:text-blue group-hover:bg-blue-light max-w-[400px]",
          isOpen && "text-blue bg-blue-light",
          !isActive && !isOpen && "border-0 bg-red-100",
          className,
        )}
      >
        {Icon && <Icon className="h-4 w-4 shrink-0" />}
        <span className="shrink-0 pl-2 pr-1">{labelPrefix}</span>
        <TruncatedTooltip text={labelValue} className="pr-2 font-normal" />
        <ChevronDown className="h-4 w-4 shrink-0" />
      </Button>
    </div>
  );
}
