import { toast } from "sonner";
import { Route } from "..";
import { ExternalTicketConfigForm } from "../-utils/formSchema";

export const useUpsertExternalTicketConfig = () => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const upsertMutation =
    trpc.dashboard.externalTickets.upsertConfiguration.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.externalTickets.getConfiguration.invalidate();
        toast.success("External ticket configuration updated.");
      },
      onError: (error) => {
        toast.error("Failed to update external ticket configuration.");
        console.error(error);
      },
    });

  const updateBusinessMutation = trpc.dashboard.business.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.business.getOrThrow.invalidate();
    },
    onError: (error) => {
      toast.error("Failed to update business configuration.");
      console.error(error);
    },
  });

  return {
    upsertConfig: (data: ExternalTicketConfigForm) => {
      const { system, emailRecipients, ...rest } = data;

      if (system === "none") {
        throw new Error("System cannot be none");
      }

      // TODO: Make this a single mutation
      void updateBusinessMutation
        .mutateAsync({
          businessSlug,
          data: {
            responseText: rest.responseTimeText,
          },
        })
        .then(() => {
          upsertMutation.mutate({
            businessSlug,
            system,
            emailRecipients: emailRecipients?.map(
              (recipient) => recipient.email,
            ),
            ...rest,
          });
        });
    },
  };
};
