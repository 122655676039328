import { useInitializeConfig } from "@chatbot/hooks/config.hooks";
import { useInitializeBrowserSession } from "@chatbot/hooks/browserSessions.hooks";
import { Coordinator } from "@chatbot/components/Coordinator";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { env } from "@chatbot/env";
import { trpc } from "@chatbot/utils/trpc";
import { httpBatchLink } from "@trpc/client/links/httpBatchLink";

export function BrowserSessionProvider({
  presetConfigId,
  containerMode,
}: {
  presetConfigId?: string;
  containerMode?: boolean;
}) {
  const [currentPathname, setCurrentPathname] = useState(
    window.location.pathname,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.pathname !== currentPathname) {
        setCurrentPathname(window.location.pathname);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [currentPathname]);

  const config = useInitializeConfig({ presetConfigId });

  const browserSessionId = useInitializeBrowserSession({
    configId: config?.id,
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        retry: false,
        networkMode: "always",
      },
      mutations: {
        networkMode: "always",
        retry: false,
      },
    },
  });

  // Create only the HTTP client in this component
  const httpClient = trpc.createClient({
    links: [
      httpBatchLink({
        url: env.CHATBOT_TRPC_HTTP_URL,
        headers: () => {
          return {
            "Content-Type": "application/json",
          };
        },
        fetch: (url, options) => {
          const cleanHeaders = new Headers();
          cleanHeaders.set("Content-Type", "application/json");

          // Copy any other headers except Content-Type
          if (options?.headers) {
            const originalHeaders = new Headers(options.headers);
            originalHeaders.delete("Content-Type"); // Remove any existing Content-Type
            originalHeaders.forEach((value, key) => {
              cleanHeaders.set(key, value);
            });
          }

          return fetch(url, {
            ...options,
            headers: cleanHeaders,
          });
        },
      }),
    ],
  });

  if (!config || !browserSessionId) {
    return null;
  }

  if (!presetConfigId) {
    const currentHost = new URL(window.location.href).hostname.replace(
      /^www\./,
      "",
    );

    const deployment = config.deployments.find(
      (d) => d.hostname?.replace(/^www\./, "") === currentHost,
    );

    if (
      deployment?.pathRegex &&
      !new RegExp(deployment.pathRegex).test(currentPathname)
    ) {
      return null;
    }
  }

  return (
    <trpc.Provider client={httpClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Coordinator
          browserSessionId={browserSessionId}
          config={config}
          containerMode={containerMode}
        />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
