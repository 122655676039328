import { Button } from "@dashboard/common/ui/button";
import type { LucideIcon } from "lucide-react";
import type { Filter } from "../../../-context/FiltersContext";
import { useFilters } from "../../../-context/FiltersContext";
import { RadioGroup, RadioGroupItem } from "@dashboard/common/ui/radio-group";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { useState, useEffect } from "react";
import { FilterTrigger } from "./FilterTrigger";

export type SelectFilterTypes =
  | "isHandedOff"
  | "isClosed"
  | "isSnoozed"
  | "orderCanceled";

const FILTER_METADATA: Record<
  SelectFilterTypes,
  { objectName: string; placeholder: string }
> = {
  isHandedOff: {
    objectName: "Handed Off Status",
    placeholder: "Select Handed Off Status",
  },
  isClosed: {
    objectName: "Conversation Status",
    placeholder: "Select Conversation Status",
  },
  isSnoozed: {
    objectName: "Snoozed Status",
    placeholder: "Select Snoozed Status",
  },
  orderCanceled: {
    objectName: "Order Cancellation",
    placeholder: "Select Order Cancellation Status",
  },
};

interface Option {
  label: string;
  value: string;
}

interface SelectFilterProps {
  filterType: SelectFilterTypes;
  options: Option[];
  icon?: LucideIcon;
}

export function SelectFilter({
  filterType,
  options,
  icon: Icon,
}: SelectFilterProps) {
  const [open, setOpen] = useState(false);
  const {
    filters,
    addFilter,
    updateFilter,
    removeFilter,
    lastAddedFilter,
    setLastAddedFilter,
  } = useFilters();

  const metadata = FILTER_METADATA[filterType];
  const { placeholder } = metadata;

  const filter = filters.find((f) => f.type === filterType) as
    | Filter<SelectFilterTypes>
    | undefined;

  const [selectedValue, setSelectedValue] = useState<string>(
    (filter?.value === true
      ? "true"
      : filter?.value === false
      ? "false"
      : undefined) as string,
  );

  useEffect(() => {
    if (lastAddedFilter === filterType) {
      setOpen(true);
      setLastAddedFilter(null);
    }
  }, [lastAddedFilter, filterType, setLastAddedFilter]);

  if (!filter) {
    addFilter({ type: filterType });
    return null;
  }

  const handleSelect = (value: string) => {
    setSelectedValue(value);
  };

  const handleConfirm = () => {
    if (!selectedValue) {
      updateFilter({ type: filterType, value: undefined, active: false });
    } else {
      updateFilter({
        type: filterType,
        value: selectedValue === "true",
        active: true,
      });
    }
    setOpen(false);
  };

  const getSelectionLabel = () => {
    if (!selectedValue) {
      return {
        prefix: placeholder,
        value: undefined,
      };
    }

    const label = options.find((opt) => opt.value === selectedValue)?.label;

    return {
      prefix: `${metadata.objectName} is`,
      value: label,
    };
  };

  const { prefix, value } = getSelectionLabel();

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      handleConfirm();
    }
    setOpen(isOpen);
  };

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger>
        <FilterTrigger
          isOpen={open}
          isActive={filter.isActive}
          onRemove={() => {
            removeFilter({ type: filterType });
            setOpen(false);
          }}
          icon={Icon}
          labelPrefix={prefix}
          labelValue={value}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-[240px] p-0" align="start">
        <RadioGroup
          value={selectedValue}
          onValueChange={handleSelect}
          className="flex flex-col gap-2 py-2"
        >
          {options.map((option) => (
            <div
              key={option.value}
              className="hover:bg-muted flex cursor-pointer items-center space-x-2 rounded-sm px-2 py-1.5"
            >
              <RadioGroupItem
                value={option.value}
                id={`radio-${option.value}`}
              />
              <label
                htmlFor={`radio-${option.value}`}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {option.label}
              </label>
            </div>
          ))}
        </RadioGroup>
        <div className="border-t p-2">
          <Button onClick={handleConfirm} className="w-full">
            Confirm
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
