import { toast } from "sonner";
import { Route } from "..";
import { CustomerForm } from "../-utils/customerFormSchema";
import { useInvalidateConversationData } from "../../../-hooks/useInvalidateConversationData";

export function useUpsertCustomer({
  setIsEditing,
}: {
  setIsEditing: (isEditing: boolean) => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();

  const invalidate = useInvalidateConversationData({
    publicId,
  });

  const upsertMutation =
    trpc.dashboard.conversations.upsertCustomer.useMutation({
      onSuccess: async () => {
        await invalidate();
        toast.success("Customer details updated");
        setIsEditing(false);
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to update customer details");
      },
    });

  const onSubmit = (data: CustomerForm) => {
    upsertMutation.mutate({
      organizationSlug,
      publicId,
      email: data.email,
      name: data.name ?? undefined,
      phone: data.phone ?? undefined,
    });
  };

  return {
    onSubmit,
    isLoading: upsertMutation.isPending,
  };
}
