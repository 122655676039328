import { useFormContext } from "react-hook-form";
import ImageLauncher from "./ImageLauncher";
import IconTextLauncher from "./IconTextLauncher";
import IconLauncher from "./IconLauncher";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";
import { cn } from "@dashboard/utils/ui";

export interface LauncherProps {
  positionSide: string;
  selectedSide: string;
  launcherText?: string;
  launcherBackgroundColor?: string;
  launcherTextColor?: string;
  launcherIconColor?: string;
  launcherSize?: string;
  launcherIconSize?: string;
  launcherIconSrc?: string;
  launcherImageSrc?: string;
  isChatWindowOpen: boolean;
  isExpanded?: boolean;
  expandedType?: "desktop" | "mobile";
}

function Launcher({
  isChatWindowOpen,
  onClick,
  isExpanded,
  expandedType,
}: {
  isChatWindowOpen: boolean;
  onClick: () => void;
  isExpanded?: boolean;
  expandedType?: "desktop" | "mobile";
}) {
  const { watch } = useFormContext<WebChatConfigForm>();

  const props = {
    selectedSide: watch("selectedSide"),
    positionSide: watch("positionSide") ?? formDefaults.positionSide,
    launcherType: watch("launcherType"),
    launcherText: watch("launcherText") ?? undefined,
    launcherBackgroundColor:
      watch("launcherBackgroundColor") ?? formDefaults.launcherBackgroundColor,
    launcherTextColor:
      watch("launcherTextColor") ?? formDefaults.launcherTextColor,
    launcherIconColor:
      watch("launcherIconColor") ?? formDefaults.launcherIconColor,
    launcherSize: watch("launcherSize") ?? formDefaults.launcherSize,
    launcherIconSize:
      watch("launcherIconSize") ?? formDefaults.launcherIconSize,
    launcherIconSrc: watch("launcherIconSrc") ?? formDefaults.launcherIconSrc,
    launcherImageSrc:
      watch("launcherImageSrc") ?? formDefaults.launcherImageSrc,
    isChatWindowOpen,
    isExpanded,
    expandedType,
  };

  function renderLauncher() {
    if (props.launcherType === "image" && props.launcherImageSrc) {
      return <ImageLauncher {...props} />;
    } else if (props.launcherType === "icon_text") {
      return <IconTextLauncher {...props} />;
    } else {
      return <IconLauncher {...props} />;
    }
  }

  const getValues = () => {
    let sideValue = "";
    let bottomValue = "";

    if (expandedType === "desktop") {
      sideValue = watch("positionSide") ?? formDefaults.positionSide;
      bottomValue = watch("positionBottom") ?? formDefaults.positionBottom;
    } else if (expandedType === "mobile") {
      sideValue =
        watch("mobilePositionSide") ?? formDefaults.mobilePositionSide;
      bottomValue =
        watch("mobilePositionBottom") ?? formDefaults.mobilePositionBottom;
    }

    return { side: sideValue, bottom: bottomValue };
  };

  return (
    <div>
      <div
        onClick={onClick}
        style={{
          [props.selectedSide]: isExpanded ? "100px" : "20px",
          bottom: isExpanded ? "100px" : "20px",
          height: isExpanded ? "100px" : props.launcherSize,
          minWidth: isExpanded ? "100px" : props.launcherSize,
          backgroundColor:
            props.launcherType === "image" && props.launcherImageSrc
              ? "transparent"
              : props.launcherBackgroundColor,
          transition: "all 0.2s ease-in-out",
        }}
        className="absolute z-[2147483648] flex cursor-pointer items-center justify-center rounded-full hover:scale-105"
      >
        {renderLauncher()}
      </div>
      {isExpanded && (
        <>
          {/* Side measurement */}
          <div
            className={cn(
              "absolute z-[2147483647]",
              props.selectedSide === "left" ? "left-0" : "right-0",
            )}
            style={{
              bottom: "150px",
              transform: "translateY(50%)",
            }}
          >
            {/* Horizontal line */}
            <div
              className="absolute bg-gray-400"
              style={{
                height: "1px",
                width: "100px",
                [props.selectedSide]: "0",
              }}
            />
            {/* Value */}
            <div
              className="absolute whitespace-nowrap text-sm text-gray-600"
              style={{
                [props.selectedSide]: "10px",
                transform: "translateY(-150%)",
              }}
            >
              {getValues().side}
            </div>
          </div>

          {/* Bottom measurement */}
          <div
            className={cn(
              "absolute z-[2147483647]",
              props.selectedSide === "left" ? "left-[150px]" : "right-[150px]",
            )}
            style={{
              bottom: "0",
            }}
          >
            {/* Vertical line */}
            <div
              className="absolute bg-gray-400"
              style={{
                width: "1px",
                height: "100px",
                bottom: "0",
              }}
            />
            {/* Value */}
            <div
              className="absolute whitespace-nowrap text-sm text-gray-600"
              style={{
                bottom: "10px",
                left: "50%",
                transform: "translateX(50%)",
              }}
            >
              {getValues().bottom}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Launcher;
