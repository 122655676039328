import { Link } from "@tanstack/react-router";
import { ArrowLeftCircle } from "lucide-react";
import { Route } from "../_navbar";
import { Button } from "@dashboard/common/ui/button";
import { cn } from "@dashboard/utils/ui";

export function BackToSetup() {
  const { organizationSlug } = Route.useParams();
  const { organization } = Route.useRouteContext();

  const onboardingCompleted = organization.onboardingStatus === "COMPLETED";

  const inSetup = window.location.pathname.includes("setup");
  const inWebChatSettings =
    window.location.pathname.includes("web-chat") &&
    window.location.pathname.includes("settings");
  if (inSetup || onboardingCompleted || inWebChatSettings) {
    return null;
  }

  const isLocalHost = window.location.hostname.includes("localhost");

  return (
    <div
      className={cn(
        "fixed bottom-[20px] right-4 z-50",
        isLocalHost ? "bottom-[44px]" : "bottom-[20px]",
      )}
    >
      <Link
        to="/organization/$organizationSlug/setup"
        params={{ organizationSlug }}
      >
        <Button
          variant="outline"
          className="flex items-center gap-2 border border-black bg-white text-base"
        >
          <ArrowLeftCircle className="h-4 w-4" strokeWidth={2} />
          <span>Return to Setup</span>
        </Button>
      </Link>
    </div>
  );
}
