import { MessageSquareDashed } from "lucide-react";
import { FilterSelect } from "./FilterSelect";

export function Channel({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const options = [
    {
      label: "Web",
      value: "web",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Instagram",
      value: "instagram",
    },
    {
      label: "Instagram Comments",
      value: "instagramComment",
    },
    {
      label: "Messenger",
      value: "messenger",
    },
    {
      label: "Facebook Comments",
      value: "facebookComment",
    },
    {
      label: "Zoom Transcript",
      value: "zoom-transcript",
    },
    {
      label: "Google Reviews",
      value: "google-reviews",
    },
    {
      label: "Phone Call",
      value: "phone-call",
    },
    {
      label: "eBay",
      value: "ebay",
    },
    {
      label: "Trustpilot",
      value: "trustpilot-reviews",
    },
  ];

  return (
    <FilterSelect
      filterName="Channel"
      valueIcon={MessageSquareDashed}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
