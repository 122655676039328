import { createFileRoute } from "@tanstack/react-router";
import { BotConfigForm, botConfigFormSchema } from "./-utils/formSchema";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSaveBotConfig } from "./-hooks/useSave";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@dashboard/common/ui/form";
import { Button } from "@dashboard/common/ui/button";
import { Input } from "@dashboard/common/ui/input";
import { useEffect } from "react";
import { Trash2 } from "lucide-react";
import { Textarea } from "@dashboard/common/ui/textarea";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import {
  Check,
  Heart,
  ClipboardList,
  Briefcase,
  Smile,
  Scale,
} from "lucide-react";
import { Switch } from "@dashboard/common/ui/switch";
import { cn } from "@dashboard/utils/ui";
import { TooltipButton } from "@dashboard/common/TooltipButton/TooltipButton";
import { Separator } from "@dashboard/common/ui/separator";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/bot-config/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.bots.getConfiguration.ensureData({
      businessSlug,
    });
  },
  component: BotConfigPage,
});

function BotConfigPage() {
  const { trpc, user } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [config] = trpc.dashboard.bots.getConfiguration.useSuspenseQuery({
    businessSlug,
  });

  const defaultValues: BotConfigForm = {
    configurationType: config.customConfiguration ? "custom" : "standard",
    name: config.name,
    customMessageCompositionRules: config.customMessageCompositionRules,
    customConfiguration: config.customConfiguration ?? "",
    tone: config.tone,
    enableEmojis: config.enableEmojis,
    autoCloseDelayMin: config.autoCloseDelayMin,
    articleRelevanceCutoff: config.articleRelevanceCutoff,
  };

  const form = useForm<BotConfigForm>({
    resolver: zodResolver(botConfigFormSchema),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "customMessageCompositionRules",
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [businessSlug]);

  const { saveConfig } = useSaveBotConfig();

  const onSubmit = (values: BotConfigForm) => {
    saveConfig(values);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          void form.handleSubmit(onSubmit)(e);
        }}
        className="space-y-8"
      >
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Bot Configuration
            </h1>
            <Button
              variant="publish"
              className="ml-auto"
              type="submit"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="configurationType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Configuration Type</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select configuration type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="standard">
                          Standard configuration
                        </SelectItem>
                        <SelectItem value="custom">
                          Custom configuration
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.watch("configurationType") === "standard" ? (
                <>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Bot name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                        <FormDescription>
                          If the bot name is empty, the bot will not have a
                          name.
                        </FormDescription>
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="tone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Bot Tone</FormLabel>
                        <FormControl>
                          <div className="grid grid-cols-2 gap-4">
                            {[
                              {
                                name: "friendly",
                                label: "Friendly",
                                description:
                                  "Warm and welcoming, making customers feel appreciated",
                                icon: Heart,
                              },
                              {
                                name: "matter-of-fact",
                                label: "Matter of Fact",
                                description:
                                  "Direct and factual, focusing on clear information",
                                icon: ClipboardList,
                              },
                              {
                                name: "professional",
                                label: "Professional",
                                description:
                                  "Courteous and maintaining business etiquette",
                                icon: Briefcase,
                              },
                              {
                                name: "humorous",
                                label: "Humorous",
                                description:
                                  "Light and cheerful while remaining professional",
                                icon: Smile,
                              },
                              {
                                name: "neutral",
                                label: "Neutral",
                                description:
                                  "Balanced, neither too casual nor too formal",
                                icon: Scale,
                              },
                            ].map((option) => (
                              <TooltipButton
                                key={option.name}
                                onClick={() => field.onChange(option.name)}
                                tooltipContent={option.description}
                                variant="outline"
                                buttonClassName={cn(
                                  "flex h-auto items-center gap-3 px-4 py-3 relative",
                                  field.value === option.name &&
                                    "border-blue text-blue",
                                )}
                              >
                                <option.icon
                                  className="h-5 w-5"
                                  strokeWidth={1.5}
                                />
                                <span className="font-medium">
                                  {option.label}
                                </span>
                                {field.value === option.name && (
                                  <Check
                                    className="absolute right-3 h-4 w-4"
                                    strokeWidth={1.5}
                                  />
                                )}
                              </TooltipButton>
                            ))}
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Separator />

                  <FormField
                    control={form.control}
                    name="enableEmojis"
                    render={({ field }) => (
                      <FormItem className="flex items-center justify-start gap-4 space-y-0">
                        <FormLabel>Enable Emojis</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Separator />

                  <div className="flex flex-col gap-2">
                    <FormLabel>Message Composition Rules</FormLabel>
                    {fields.map((field, index) => (
                      <FormField
                        key={field.id}
                        control={form.control}
                        name={`customMessageCompositionRules.${index}.rule`}
                        render={({ field }) => (
                          <FormItem>
                            <div className="flex w-full items-center gap-2">
                              <FormControl>
                                <Input placeholder="Enter a rule" {...field} />
                              </FormControl>
                              <Button
                                type="button"
                                variant="destructive"
                                onClick={() => remove(index)}
                                className="px-2 py-2"
                              >
                                <Trash2 className="h-4 w-4" />
                              </Button>
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                    {form.formState.errors.customMessageCompositionRules && (
                      <FormMessage>
                        {
                          form.formState.errors.customMessageCompositionRules
                            .message
                        }
                      </FormMessage>
                    )}

                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      onClick={() => append({ rule: "" })}
                      className="mt-2"
                    >
                      Add Rule
                    </Button>
                  </div>

                  <Separator />

                  <FormField
                    control={form.control}
                    name="autoCloseDelayMin"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Auto Close Delay (minutes)</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {user.isAdmin && (
                    <FormField
                      control={form.control}
                      name="articleRelevanceCutoff"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Article Relevance Cutoff</FormLabel>
                          <FormControl>
                            <Input type="number" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                </>
              ) : (
                <FormField
                  control={form.control}
                  name="customConfiguration"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Custom Configuration</FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          className="min-h-[200px]"
                          placeholder="Enter your custom configuration here..."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
