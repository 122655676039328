import { Link, createFileRoute } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { Button } from "@dashboard/common/ui/button";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { Switch } from "@dashboard/common/ui/switch";
import { FlowForm, flowFormSchema } from "../-utils/flowFormSchema";
import { Separator } from "@dashboard/common/ui/separator";
import {
  SelectRoot as Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import { useState } from "react";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import { useExportVariant } from "./-hooks/useExportVariant";
import { useImportVariant } from "./-hooks/useImportVariant";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/$flowId/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug, flowId },
  }) => {
    await Promise.all([
      trpcClientUtils.dashboard.flows.get.ensureData({
        businessSlug,
        id: flowId,
      }),
      trpcClientUtils.dashboard.flows.getAllActions.ensureData({
        businessSlug,
      }),
      trpcClientUtils.dashboard.flows.getAllConditions.ensureData({
        businessSlug,
      }),
      trpcClientUtils.dashboard.threats.getAll.ensureData({
        businessSlug,
      }),
    ]);
  },
  component: FlowPage,
});

function FlowPage() {
  const { organizationSlug, businessSlug, flowId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [actions] = trpc.dashboard.flows.getAllActions.useSuspenseQuery({
    businessSlug,
  });

  const [{ variantConditions, workflowConditions }] =
    trpc.dashboard.flows.getAllConditions.useSuspenseQuery({
      businessSlug,
    });

  const [flow] = trpc.dashboard.flows.get.useSuspenseQuery({
    businessSlug,
    id: flowId,
  });

  const [threats] = trpc.dashboard.threats.getAll.useSuspenseQuery({
    businessSlug,
  });

  const defaultValues: FlowForm = { ...flow, id: flowId };

  const form = useForm<FlowForm>({
    resolver: zodResolver(flowFormSchema),
    defaultValues,
  });

  const { fields, append, remove, update, insert } = useFieldArray({
    control: form.control,
    name: "variants",
    keyName: "formId",
  });

  const { onSubmit, onError } = useSubmitForm();

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

  const { exportVariant } = useExportVariant();
  const { importVariant } = useImportVariant();

  if (fields.length === 0) {
    throw new Error("At least one variant is required");
  }

  return (
    <Form {...form}>
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <div className="relative py-0">
          <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">{flow.title}</h1>
            <div className="flex items-center gap-2">
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/custom-flows"
                params={{ organizationSlug, businessSlug }}
              >
                <Button
                  variant="secondary"
                  className="flex items-center"
                  type="button"
                >
                  Back to flows
                </Button>
              </Link>

              <Button
                variant="publish"
                className="flex items-center"
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>

          <div className="flex gap-10 px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Flow Title</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Give a unique and descriptive title to the flow, e.g.
                      &quot;Order Tracking&quot;, &quot;Quality
                      Complaints&quot;, etc.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isLive"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Live Flow</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="orderIdRequired"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Order ID Required</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="subscriptionIdRequired"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Subscription ID Required</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="priority"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Priority</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      The priority of the flow. Higher priority flows are chosen
                      first in case of ambiguity.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {workflowConditions.length > 0 && (
                <FormField
                  control={form.control}
                  name={
                    `variants.${selectedVariantIndex}.requiredConditions` as const
                  }
                  render={() => (
                    <FormItem>
                      <div className="mb-4">
                        <FormLabel className="text-base">
                          Workflow Conditions
                        </FormLabel>
                        <FormDescription>
                          Select the conditions that must be met for this
                          workflow to be available for choosing.
                        </FormDescription>
                      </div>
                      {workflowConditions.map((condition) => (
                        <FormField
                          key={condition.id}
                          control={form.control}
                          name={
                            `variants.${selectedVariantIndex}.requiredConditions` as const
                          }
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={condition.id}
                                className="flex flex-row items-start space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    checked={field.value.includes(condition.id)}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([
                                            ...field.value,
                                            condition.id,
                                          ])
                                        : field.onChange(
                                            field.value.filter(
                                              (value) => value !== condition.id,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {condition.human}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="whenToFollow"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>When should this flow be followed?</FormLabel>
                    <FormControl>
                      <Textarea rows={2} {...field} />
                    </FormControl>
                    <FormDescription>
                      Provide a description of when this flow should be
                      followed. You may provide example scenarios and customer
                      inquiries.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {fields.length > 1 && (
                <div className="flex items-center gap-4">
                  <Select
                    value={selectedVariantIndex.toString()}
                    onValueChange={(value) =>
                      setSelectedVariantIndex(Number(value))
                    }
                  >
                    <SelectTrigger className="w-auto">
                      <SelectValue placeholder="Select a variant" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Variants</SelectLabel>
                        {fields.map((field, index) => (
                          <SelectItem key={field.id} value={index.toString()}>
                            {field.title}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      size="icon"
                      type="button"
                      onClick={() => {
                        if (selectedVariantIndex > 0) {
                          const curVal = fields[selectedVariantIndex];
                          const prevVal = fields[selectedVariantIndex - 1];
                          update(selectedVariantIndex - 1, curVal);
                          update(selectedVariantIndex, prevVal);
                          setSelectedVariantIndex((prev) => prev - 1);
                        }
                      }}
                      disabled={selectedVariantIndex === 0}
                    >
                      ↑
                    </Button>
                    <Button
                      variant="outline"
                      size="icon"
                      type="button"
                      onClick={() => {
                        if (selectedVariantIndex < fields.length - 1) {
                          const curVal = fields[selectedVariantIndex];
                          const nextVal = fields[selectedVariantIndex + 1];
                          update(selectedVariantIndex + 1, curVal);
                          update(selectedVariantIndex, nextVal);
                          setSelectedVariantIndex((prev) => prev + 1);
                        }
                      }}
                      disabled={selectedVariantIndex === fields.length - 1}
                    >
                      ↓
                    </Button>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-2">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    append({
                      title: "New variant",
                      associatedThreatIds: [],
                      actionNames: [],
                      ignoredThreatIds: [],
                      requiredConditions: [],
                      botInstructions: "",
                      instantHandoff: false,
                    });
                    // ? Does anyone know how to do this better?
                    setTimeout(() => {
                      setSelectedVariantIndex(fields.length);
                    }, 0);
                  }}
                >
                  Add variant
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    if (fields[selectedVariantIndex]) {
                      const currentVariant = fields[selectedVariantIndex];
                      const newVariant = {
                        ...currentVariant,
                        id: undefined,
                        title: `${currentVariant.title} (Copy)`,
                      };
                      insert(selectedVariantIndex + 1, newVariant);
                      setSelectedVariantIndex(selectedVariantIndex + 1);
                    }
                  }}
                >
                  Duplicate variant
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    importVariant((variant) => {
                      insert(selectedVariantIndex + 1, variant);
                      setSelectedVariantIndex(selectedVariantIndex + 1);
                    });
                  }}
                >
                  Import variant
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    const currentVariant = fields[selectedVariantIndex];
                    exportVariant(currentVariant);
                  }}
                >
                  Export variant
                </Button>
              </div>

              {fields[selectedVariantIndex] && (
                <div
                  key={fields[selectedVariantIndex].id}
                  className="flex flex-col gap-4"
                >
                  {fields.length > 1 && (
                    <FormField
                      control={form.control}
                      name={`variants.${selectedVariantIndex}.title` as const}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Variant Title</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={form.control}
                    name={
                      `variants.${selectedVariantIndex}.delayResponseInMinutes` as const
                    }
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Delay Response in Minutes</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          If this is set, the bot will delay its response by the
                          number of minutes set here whenever this variant is
                          chosen by the bot.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name={
                      `variants.${selectedVariantIndex}.instantHandoff` as const
                    }
                    render={({ field }) => (
                      <FormItem className="flex items-center justify-start gap-4 space-y-0">
                        <FormLabel>Instant Handoff</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {actions.length > 0 && (
                    <FormField
                      control={form.control}
                      name={
                        `variants.${selectedVariantIndex}.actionNames` as const
                      }
                      render={() => (
                        <FormItem>
                          <div className="mb-4">
                            <FormLabel className="text-base">
                              Available Actions
                            </FormLabel>
                            <FormDescription>
                              Select the actions that the bot can use in this
                              variant.
                            </FormDescription>
                          </div>
                          {actions.map((action) => (
                            <FormField
                              key={action.name}
                              control={form.control}
                              name={
                                `variants.${selectedVariantIndex}.actionNames` as const
                              }
                              render={({ field }) => {
                                return (
                                  <FormItem
                                    key={action.name}
                                    className="flex flex-row items-start space-x-3 space-y-0"
                                  >
                                    <FormControl>
                                      <Checkbox
                                        checked={field.value.includes(
                                          action.name,
                                        )}
                                        onCheckedChange={(checked) => {
                                          return checked
                                            ? field.onChange([
                                                ...field.value,
                                                action.name,
                                              ])
                                            : field.onChange(
                                                field.value.filter(
                                                  (value) =>
                                                    value !== action.name,
                                                ),
                                              );
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="text-sm font-normal">
                                      {action.humanName}
                                    </FormLabel>
                                  </FormItem>
                                );
                              }}
                            />
                          ))}
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  {variantConditions.length > 0 && (
                    <FormField
                      control={form.control}
                      name={
                        `variants.${selectedVariantIndex}.requiredConditions` as const
                      }
                      render={() => (
                        <FormItem>
                          <div className="mb-4">
                            <FormLabel className="text-base">
                              Variant Conditions
                            </FormLabel>
                            <FormDescription>
                              Select the conditions that must be met for this
                              variant to be followed.
                            </FormDescription>
                          </div>
                          {variantConditions.map((condition) => (
                            <FormField
                              key={condition.id}
                              control={form.control}
                              name={
                                `variants.${selectedVariantIndex}.requiredConditions` as const
                              }
                              render={({ field }) => {
                                return (
                                  <FormItem
                                    key={condition.id}
                                    className="flex flex-row items-start space-x-3 space-y-0"
                                  >
                                    <FormControl>
                                      <Checkbox
                                        checked={field.value.includes(
                                          condition.id,
                                        )}
                                        onCheckedChange={(checked) => {
                                          return checked
                                            ? field.onChange([
                                                ...field.value,
                                                condition.id,
                                              ])
                                            : field.onChange(
                                                field.value.filter(
                                                  (value) =>
                                                    value !== condition.id,
                                                ),
                                              );
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="text-sm font-normal">
                                      {condition.human}
                                    </FormLabel>
                                  </FormItem>
                                );
                              }}
                            />
                          ))}
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  {threats.length > 0 && (
                    <>
                      <FormField
                        control={form.control}
                        name={
                          `variants.${selectedVariantIndex}.associatedThreatIds` as const
                        }
                        render={() => (
                          <FormItem>
                            <div className="mb-4">
                              <FormLabel className="text-base">
                                Associated Threats
                              </FormLabel>
                              <FormDescription>
                                If no threats from the chosen list are made,
                                then this variant will be ignored. If any
                                threats outside of this list are made, then this
                                variant will be ignored, unless the threats are
                                from the ignored list.
                              </FormDescription>
                            </div>
                            {threats.map((threat) => (
                              <FormField
                                key={threat.id}
                                control={form.control}
                                name={
                                  `variants.${selectedVariantIndex}.associatedThreatIds` as const
                                }
                                render={({ field }) => {
                                  return (
                                    <FormItem
                                      key={threat.id}
                                      className="flex flex-row items-start space-x-3 space-y-0"
                                    >
                                      <FormControl>
                                        <Checkbox
                                          checked={field.value.includes(
                                            threat.id,
                                          )}
                                          onCheckedChange={(checked) => {
                                            return checked
                                              ? field.onChange([
                                                  ...field.value,
                                                  threat.id,
                                                ])
                                              : field.onChange(
                                                  field.value.filter(
                                                    (value) =>
                                                      value !== threat.id,
                                                  ),
                                                );
                                          }}
                                        />
                                      </FormControl>
                                      <FormLabel className="text-sm font-normal">
                                        {threat.title}
                                      </FormLabel>
                                    </FormItem>
                                  );
                                }}
                              />
                            ))}
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name={
                          `variants.${selectedVariantIndex}.ignoredThreatIds` as const
                        }
                        render={() => (
                          <FormItem>
                            <div className="mb-4">
                              <FormLabel className="text-base">
                                Ignored Threats
                              </FormLabel>
                              <FormDescription>
                                If any threats that are not included in the
                                associated threats are made by the customer,
                                then the variant will not be applicable. If you
                                wish to ignore some threats and still have the
                                variant stay applicable, you should select them
                                here.
                              </FormDescription>
                            </div>
                            {threats.map((threat) => (
                              <FormField
                                key={threat.id}
                                control={form.control}
                                name={
                                  `variants.${selectedVariantIndex}.ignoredThreatIds` as const
                                }
                                render={({ field }) => {
                                  return (
                                    <FormItem
                                      key={threat.id}
                                      className="flex flex-row items-start space-x-3 space-y-0"
                                    >
                                      <FormControl>
                                        <Checkbox
                                          checked={field.value.includes(
                                            threat.id,
                                          )}
                                          onCheckedChange={(checked) => {
                                            return checked
                                              ? field.onChange([
                                                  ...field.value,
                                                  threat.id,
                                                ])
                                              : field.onChange(
                                                  field.value.filter(
                                                    (value) =>
                                                      value !== threat.id,
                                                  ),
                                                );
                                          }}
                                        />
                                      </FormControl>
                                      <FormLabel className="text-sm font-normal">
                                        {threat.title}
                                      </FormLabel>
                                    </FormItem>
                                  );
                                }}
                              />
                            ))}
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}

                  <FormField
                    control={form.control}
                    name={
                      `variants.${selectedVariantIndex}.botInstructions` as const
                    }
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Bot Instructions</FormLabel>
                        <FormControl>
                          <Textarea rows={20} {...field} />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  {fields.length > 1 && (
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => remove(selectedVariantIndex)}
                    >
                      Remove this variant
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="border-gray-light flex max-w-96 flex-col items-start gap-4 rounded-lg border p-6">
              <p>
                The bot can carry out a large number of actions during the flow,
                to tell the bot that it should carry out an action include this
                instruction:
              </p>
              <p>
                call the <span className="font-medium">`botAction`</span>{" "}
                function with <span className="font-medium">argument1</span>,{" "}
                <span className="font-medium">argument2</span>... as the
                arguments
              </p>
              <Separator />
              <p className="font-medium">Available actions:</p>
              <ul>
                {actions.map((action) => (
                  <li
                    className="m-1 inline-block rounded-lg bg-[#DCE1F9] p-1 px-2 text-sm font-medium text-[#334AFA]"
                    key={action.name}
                  >
                    {action.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
