import { createFileRoute } from "@tanstack/react-router";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray } from "react-hook-form";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Switch } from "@dashboard/common/ui/switch";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import {
  ExternalTicketConfigForm,
  externalTicketConfigFormSchema,
} from "./-utils/formSchema";
import { useRemoveExternalTicketConfig } from "./-hooks/useRemove";
import { useUpsertExternalTicketConfig } from "./-hooks/useUpsert";
import { Trash2 } from "lucide-react";
import { systemLabels } from "./-utils/systemLabels";
import { ExternalTicketsSystemName } from "@be/modules/externalTickets/externalTickets.types";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/tickets/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug, businessSlug },
  }) => {
    await trpcClientUtils.dashboard.business.getOrThrow.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.externalTickets.getConfiguration.ensureData(
      {
        organizationSlug,
      },
    );

    await trpcClientUtils.dashboard.externalTickets.getInstalledExternalSystems.ensureData(
      {
        businessSlug,
      },
    );
  },
  component: TicketsConfigPage,
});

function TicketsConfigPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, businessSlug } = Route.useParams();

  const [config] =
    trpc.dashboard.externalTickets.getConfiguration.useSuspenseQuery({
      organizationSlug,
    });

  const [business] = trpc.dashboard.business.getOrThrow.useSuspenseQuery({
    businessSlug,
  });

  const { responseText, autoAssignmentPolicy } = business;

  const defaultValues: ExternalTicketConfigForm = config
    ? {
        system: config.system as ExternalTicketsSystemName,
        createOnHandoff: config.createOnHandoff,
        createOnClose: config.createOnClose,
        createInPlayground: config.createInPlayground,
        botSenderName: config.botSenderName,
        emailRecipients: config.emailRecipients.map((email) => ({ email })),
        responseTimeText: responseText,
        autoAssignmentPolicy,
      }
    : {
        system: "none",
        botSenderName: "bot",
        createOnHandoff: false,
        createOnClose: false,
        createInPlayground: false,
        emailRecipients: [],
        responseTimeText: responseText,
        autoAssignmentPolicy,
      };

  const form = useForm<ExternalTicketConfigForm>({
    resolver: zodResolver(externalTicketConfigFormSchema),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "emailRecipients",
  });

  const [installedSystems] =
    trpc.dashboard.externalTickets.getInstalledExternalSystems.useSuspenseQuery(
      {
        businessSlug,
      },
    );

  const options = [
    {
      label: "Octocom",
      value: "none",
    },
    ...installedSystems.map((system) => ({
      label: systemLabels[system],
      value: system,
    })),
  ];

  const { upsertConfig } = useUpsertExternalTicketConfig();
  const { removeConfig } = useRemoveExternalTicketConfig();

  const onSubmit = (values: ExternalTicketConfigForm) => {
    if (values.system === "none") {
      // ? Why are we updating business config within a remove mutation?
      // TODO: Clean this up
      removeConfig({
        responseText: values.responseTimeText,
        autoAssignmentPolicy: values.autoAssignmentPolicy,
      });
    } else {
      if (
        values.system === "email" &&
        (!values.emailRecipients || values.emailRecipients.length === 0)
      ) {
        form.setError("emailRecipients", {
          type: "manual",
          message: "At least one email recipient is required for email system",
        });
        return;
      }
      upsertConfig(values);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-8"
      >
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Ticket Routing Configuration
            </h1>
            <Button
              variant="publish"
              className="ml-auto"
              type="submit"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="system"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ticket System</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a system" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {options.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      Select the system you want to route handed off
                      conversations to.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {form.watch("system") !== "none" ? (
                <>
                  <FormField
                    control={form.control}
                    name="botSenderName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Bot Sender Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          The display name of your bot, as written in the
                          conversation transcript.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="autoAssignmentPolicy"
                    render={() => <></>}
                  />

                  <FormField
                    control={form.control}
                    name="responseTimeText"
                    render={({ field }) => (
                      <FormItem className="space-y-2">
                        <FormLabel>When will the agent respond?</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="The agent will respond to your message within 10 minutes."
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="createOnHandoff"
                    render={({ field }) => (
                      <FormItem className="flex items-center justify-start gap-4 space-y-0">
                        <FormLabel>Create on Handoff</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="createOnClose"
                    render={({ field }) => (
                      <FormItem className="flex items-center justify-start gap-4 space-y-0">
                        <FormLabel>Create on Close</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="createInPlayground"
                    render={({ field }) => (
                      <FormItem className="flex items-center justify-start gap-4 space-y-0">
                        <FormLabel>Create in Playground</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {form.watch("system") === "email" && (
                    <div className="flex flex-col gap-2">
                      <FormLabel>Email Recipients</FormLabel>
                      {fields.map((field, index) => (
                        <FormField
                          key={field.id}
                          control={form.control}
                          name={`emailRecipients.${index}.email`}
                          render={({ field }) => (
                            <FormItem>
                              <div className="flex w-full items-center gap-2">
                                <FormControl>
                                  <Input
                                    placeholder="Enter email address"
                                    name={field.name}
                                    value={field.value}
                                    defaultValue={field.value}
                                  />
                                </FormControl>
                                <Button
                                  type="button"
                                  variant="destructive"
                                  onClick={() => remove(index)}
                                  className="px-2 py-2"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                      {form.formState.errors.emailRecipients && (
                        <FormMessage>
                          {form.formState.errors.emailRecipients.message}
                        </FormMessage>
                      )}
                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        onClick={() => append({ email: "" })}
                        className="mt-2"
                      >
                        Add Email Recipient
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p className="text-sm">
                    All handed off tickets can be managed in the Octocom help
                    desk.
                  </p>

                  <FormField
                    control={form.control}
                    name="autoAssignmentPolicy"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Auto Assignment Policy</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a policy" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="noAutoAssignment">
                              No auto assignment
                            </SelectItem>
                            <SelectItem value="roundRobin">
                              Round Robin
                            </SelectItem>
                            <SelectItem value="balanced">Balanced</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="responseTimeText"
                    render={({ field }) => (
                      <FormItem className="space-y-2">
                        <FormLabel>When will the agent respond?</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="The agent will respond to your message within 10 minutes."
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
