import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import { Input } from "@dashboard/common/ui/input";
import { useFormContext } from "react-hook-form";
import { WebChatConfigForm } from "../../-utils/formSchema";
import SettingsLabel from "../../../-components/SettingsLabel";
import SettingsSection from "../SettingsSection";
import { Tabs, TabsList, TabsTrigger } from "@dashboard/common/ui/tabs";
import SettingsDivider from "../../../-components/SettingsDivider";
import { formDefaults } from "../../-utils/formDefaults";

interface PositionSettingsProps {
  onFocusChange: (isFocused: boolean, type: "desktop" | "mobile") => void;
}

export function PositionSettings({ onFocusChange }: PositionSettingsProps) {
  const { setValue, watch } = useFormContext<WebChatConfigForm>();
  const selectedSide = watch("selectedSide");

  return (
    <AccordionItem value="item-5" variant="tabs">
      <AccordionTrigger>
        <h2 className="text-base font-medium">Position</h2>
      </AccordionTrigger>
      <AccordionContent>
        <SettingsSection>
          <div className="flex w-full items-center">
            <div className="flex-1">
              <SettingsLabel label="Z-index" />
              <Input
                name="zIndex"
                type="number"
                className="flex h-8 w-full flex-row text-black"
              />
            </div>
          </div>

          <SettingsDivider />

          {/* Desktop Section */}
          <SettingsLabel
            label="Desktop Position"
            className="text-lg font-medium"
          />
          <div className="space-y-4">
            <Tabs
              className="w-full"
              defaultValue={selectedSide}
              onValueChange={(value) => {
                setValue("selectedSide", value as "left" | "right");
              }}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="left">Left</TabsTrigger>
                <TabsTrigger value="right">Right</TabsTrigger>
              </TabsList>
            </Tabs>

            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Side spacing" />
                <Input
                  name="positionSide"
                  defaultValue={formDefaults.positionSide}
                  className="flex h-8 w-[77px] flex-row text-black"
                  onFocus={() => onFocusChange(true, "desktop")}
                  onBlur={() => onFocusChange(false, "desktop")}
                />
              </div>

              <div className="ml-2 flex-1">
                <SettingsLabel label="Bottom spacing" />
                <Input
                  name="positionBottom"
                  defaultValue={formDefaults.positionBottom}
                  className="flex h-8 w-[77px] flex-row text-black"
                  onFocus={() => onFocusChange(true, "desktop")}
                  onBlur={() => onFocusChange(false, "desktop")}
                />
              </div>
            </div>
          </div>

          <SettingsDivider />

          {/* Mobile Section */}
          <SettingsLabel
            label="Mobile Position"
            className="text-lg font-medium"
          />
          <div className="space-y-4">
            <Tabs
              className="w-full"
              defaultValue={selectedSide}
              onValueChange={(value) => {
                setValue("mobileSelectedSide", value as "left" | "right");
              }}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="left">Left</TabsTrigger>
                <TabsTrigger value="right">Right</TabsTrigger>
              </TabsList>
            </Tabs>

            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Side spacing" />
                <Input
                  name="mobilePositionSide"
                  defaultValue={formDefaults.mobilePositionSide}
                  className="flex h-8 w-[77px] flex-row text-black"
                  onFocus={() => onFocusChange(true, "mobile")}
                  onBlur={() => onFocusChange(false, "mobile")}
                />
              </div>

              <div className="ml-2 flex-1">
                <SettingsLabel label="Bottom spacing" />
                <Input
                  name="mobilePositionBottom"
                  defaultValue={formDefaults.mobilePositionBottom}
                  className="flex h-8 w-[77px] flex-row text-black"
                  onFocus={() => onFocusChange(true, "mobile")}
                  onBlur={() => onFocusChange(false, "mobile")}
                />
              </div>
            </div>
          </div>
        </SettingsSection>
      </AccordionContent>
    </AccordionItem>
  );
}
