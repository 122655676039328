import { toast } from "sonner";
import { variantExportSchema } from "../-utils/variantExportSchema";
import { z } from "zod";

export function useImportVariant() {
  return {
    importVariant: (
      onImport: (variant: z.infer<typeof variantExportSchema>) => void,
    ) => {
      void getJsonString().then((jsonString) => {
        if (!jsonString) return;
        const data = validateAndParseJsonString(jsonString);
        if (!data) {
          toast.error("Import error: Invalid JSON provided");
          return;
        }
        onImport(data);
      });
    },
  };
}

function getJsonString() {
  return new Promise<string | null>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result !== "string") {
          reject(new Error("File is not a string when using readAsText"));
          return;
        }
        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      reader.readAsText(file);
    };

    input.oncancel = () => {
      resolve(null);
    };

    input.click();
  });
}

function validateAndParseJsonString(jsonString: string) {
  let parsedJson: unknown;
  try {
    parsedJson = JSON.parse(jsonString) as unknown;
  } catch (error) {
    return;
  }

  const jsonValidationResult = variantExportSchema.safeParse(parsedJson);

  if (!jsonValidationResult.success) {
    return;
  }

  return jsonValidationResult.data;
}
