import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { useDeleteRules } from "./-hooks/useDeleteRules";
import { CreateRuleDialogContent } from "./-components/CreateRuleDialogContent";
import { EditRuleDialogContent } from "./-components/EditRuleDialogContent";
import { cn } from "@dashboard/utils/ui";
import { useExportRules } from "./-hooks/useExportRules";
import { useImportRules } from "./-hooks/useImportRules";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/email-rules/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.emails.inboundRules.getRules.ensureData({
      businessSlug,
    });
  },
  component: EmailRulesPage,
});

function EmailRulesPage() {
  const { trpc, business, businesses } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [rules] = trpc.dashboard.emails.inboundRules.getRules.useSuspenseQuery({
    businessSlug,
  });

  const { deleteRules } = useDeleteRules();
  const { exportRules } = useExportRules();
  const { importRules } = useImportRules();

  const hasMultipleBusinesses = businesses.length > 1;

  const records = rules.map((rule) => ({
    id: rule.id,
    value: rule.value,
    type: rule.type,
    action: rule.action,
    isActive: rule.isActive,
    organization: rule.organizationId !== null,
    createdAt: new Date(rule.createdAt).toLocaleDateString(),
    updatedAt: new Date(rule.updatedAt).toLocaleDateString(),
  }));

  return (
    <div className="flex h-full flex-col">
      <Table
        title="Email Rules"
        subtitle="You can set email addresses or domains to be automatically handed off, marked as spam, or ignored."
        businessName={business.internalName}
        records={records}
        columns={[
          {
            header: "Type",
            accessor: "type",
            render: (value) => (value === "email" ? "Email" : "Domain"),
          },
          {
            header: "Value",
            accessor: "value",
          },
          {
            header: "Action",
            accessor: "action",
            render: (value) => {
              switch (value) {
                case "handoff":
                  return "Hand off";
                case "spam":
                  return "Mark as spam";
                case "ignore":
                  return "Ignore";
                default:
                  return value;
              }
            },
          },
          ...(hasMultipleBusinesses
            ? [
                {
                  header: "Scope",
                  accessor: "organization" as const,
                  // @ts-expect-error - TypeScript is not smart enough to know that the accessor is a boolean
                  render: (value) => (value ? "Organization" : business.name),
                },
              ]
            : []),
          {
            header: "Status",
            accessor: "isActive",
            render: (value) => (
              <span className="flex items-center gap-2">
                <span
                  className={cn(
                    "h-2 w-2 rounded-full",
                    value ? "bg-green-500" : "bg-red-500",
                  )}
                />
                {value ? "Active" : "Inactive"}
              </span>
            ),
          },
          {
            header: "Added on",
            accessor: "createdAt",
          },
          {
            header: "Updated on",
            accessor: "updatedAt",
          },
        ]}
        addRecordDialogContent={(setOpen) => (
          <CreateRuleDialogContent setOpen={setOpen} />
        )}
        editRecordDialogContent={(record, setOpen) => (
          <EditRuleDialogContent {...record} setOpen={setOpen} />
        )}
        deleteRecords={deleteRules}
        recordName={{ singular: "Rule", plural: "Rules" }}
        noRecordsText="No email rules configured"
        topBarActions={[
          {
            label: "Import rules",
            action: () => void importRules(),
          },
        ]}
        headerActions={[
          {
            label: "Export",
            action: exportRules,
          },
        ]}
        contextActions={[
          {
            label: "Export",
            action: exportRules,
          },
        ]}
      />
    </div>
  );
}
