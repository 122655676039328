import { Route } from "../../onboarding";

export function useBusiness() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [business] = trpc.dashboard.business.getOrThrow.useSuspenseQuery({
    businessSlug: organizationSlug,
  });

  return business;
}
