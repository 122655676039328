import { toast } from "sonner";
import { Route } from "..";
import { AthletesFootOrder } from "@be/modules/integrations/clients/athletesFoot/athletesFoot.schemas";
import { env } from "@dashboard/env";

export function useUploadOrders(setUploading: (loading: boolean) => void) {
  const { trpc } = Route.useRouteContext();

  const uploadOrders = trpc.dashboard.athletesFoot.uploadOrders.useMutation({
    onSuccess: async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setUploading(false);
      toast.success("Orders uploaded successfully");
    },
    onError: async (error) => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setUploading(false);
      toast.error(error.message);
    },
  });

  return {
    uploadOrders: (orders: AthletesFootOrder[], email: string) => {
      setUploading(true);
      uploadOrders.mutate({
        orders,
        email: email === "" ? undefined : email,
      });
    },
    uploadFile: async (file: File) => {
      setUploading(true);

      const formData = new FormData();
      formData.append("file", file);

      await fetch(`${env.DASHBOARD_BASE_API_URL}/athletes-foot/upload-file`, {
        method: "POST",
        body: formData,
      });

      setUploading(false);
    },
  };
}
