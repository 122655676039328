export function useCustomerMessagesPerChat({
  customerMessagesCounts,
  conversationsCounts,
}: {
  customerMessagesCounts: {
    datetime: string;
    value: number;
  }[];
  conversationsCounts: {
    datetime: string;
    value: number;
  }[];
}) {
  const perDay = customerMessagesCounts.map((messageData, index) => {
    const conversationCount = conversationsCounts[index].value;
    const avgForDay =
      conversationCount > 0 ? messageData.value / conversationCount : 0;

    return {
      datetime: messageData.datetime,
      count: Number(avgForDay.toFixed(2)),
    };
  });

  const totalCustomerMessages = customerMessagesCounts.reduce(
    (sum, day) => sum + Number(day.value),
    0,
  );
  const totalConversations = conversationsCounts.reduce(
    (sum, day) => sum + Number(day.value),
    0,
  );

  const average =
    totalConversations > 0
      ? Number((totalCustomerMessages / totalConversations).toFixed(2))
      : 0;

  return {
    perDay,
    average,
  };
}
