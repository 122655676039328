import { Route } from "..";
import { periodToRange } from "../-utils/periodToRange";

export function useTBYSHandoffData() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { filters } = Route.useSearch();

  const range = periodToRange(filters.period);

  const [data] = trpc.dashboard.metrics.getTBYSHandoffData.useSuspenseQuery({
    organizationSlug,
    filters: {
      ...filters,
      from: range.from,
      to: range.to,
      totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
      totalOrderAmountTo: filters.totalOrderAmountRange?.to,
    },
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return data;
}
