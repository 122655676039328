import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/orbio-world-reviews-test",
)({
  component: ReviewTestComponent,
});

function ReviewTestComponent() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const [reviewId, setReviewId] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [result, setResult] = useState<string | null>(null);
  const [isResultVisible, setIsResultVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const orbioWorldTestMutation =
    trpc.dashboard.integrations.orbioWorld.getReviewReply.useMutation({
      onSuccess: (data) => {
        setResult(data);
        setIsResultVisible(true);
        setIsLoading(false);
      },
      onError: (error) => {
        toast.error(error.message);
        setIsLoading(false);
      },
    });

  const handleInputChange = (field: "id" | "text", value: string) => {
    if (field === "id") {
      setReviewId(value);
      setReviewText(""); // Clear other field
    } else {
      setReviewText(value);
      setReviewId(""); // Clear other field
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsResultVisible(false);
    setIsLoading(true);
    orbioWorldTestMutation.mutate({
      businessSlug,
      reviewId: reviewId === "" ? undefined : reviewId,
      reviewText: reviewText === "" ? undefined : reviewText,
    });
  };

  const formatTextWithLinks = (text: string) => {
    // Regex for URLs - matches http(s)://, www., and common TLDs
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+\.[^\s]+)/g;

    const parts = text.split(urlRegex);
    return parts.map((part, i) => {
      if (urlRegex.test(part)) {
        const href = part.startsWith("www.") ? `https://${part}` : part;
        return (
          <a
            key={i}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="p-4">
      <h2 className="mb-4 text-xl font-bold">Orbio World Reviews Test</h2>

      <div className="mb-6 rounded-lg bg-blue-50 p-4">
        <h3 className="mb-2 font-semibold">How to use:</h3>
        <p className="text-gray-700">
          Test how our system processes reviews by entering{" "}
          <strong>either</strong>:
        </p>
        <ul className="ml-6 mt-2 list-disc text-gray-700">
          <li>A specific review ID from Orbio World</li>
          <li>Your own review text to test the analysis</li>
        </ul>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label className="mb-2 block">
            Option 1: Review ID
            <input
              type="text"
              value={reviewId}
              onChange={(e) => handleInputChange("id", e.target.value)}
              className={`block w-full rounded border p-2 ${
                reviewText || isLoading ? "bg-gray-100" : "bg-white"
              }`}
              placeholder="Enter review ID"
              disabled={!!reviewText || isLoading}
            />
          </label>
        </div>

        <div className="mt-4 text-center text-gray-500">- OR -</div>

        <div>
          <label className="mb-2 block">
            Option 2: Review Text
            <textarea
              value={reviewText}
              onChange={(e) => handleInputChange("text", e.target.value)}
              className={`block w-full rounded border p-2 ${
                reviewId || isLoading ? "bg-gray-100" : "bg-white"
              }`}
              placeholder="Enter review text"
              rows={4}
              disabled={!!reviewId || isLoading}
            />
          </label>
        </div>

        <button
          type="submit"
          className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:bg-blue-300"
          disabled={(!reviewId && !reviewText) || isLoading}
        >
          {isLoading ? (
            <span className="flex items-center gap-2">
              <svg
                className="h-4 w-4 animate-spin"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Processing...
            </span>
          ) : (
            "Test Review"
          )}
        </button>
      </form>

      {isResultVisible && result && (
        <div className="mt-6 overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
          <div className="border-b border-gray-200 bg-gray-50 px-4 py-2">
            <h3 className="font-semibold text-gray-700">Analysis Result</h3>
          </div>
          <div className="p-4">
            <p className="whitespace-pre-wrap text-gray-600">
              {formatTextWithLinks(result)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
