import { toast } from "sonner";
import { Route } from "..";
import { requestAlertDialogDisplay } from "@dashboard/common/GlobalAlertDialog/GlobalAlertDialog";
import { z } from "zod";

const emailRuleExportSchema = z.object({
  rules: z.array(
    z.object({
      id: z.string().optional(),
      value: z.string(),
      type: z.enum(["email", "domain"]),
      action: z.enum(["handoff", "spam", "ignore"]),
      isActive: z.boolean(),
      organization: z.boolean(),
    }),
  ),
});

function validateAndParseJsonString(jsonString: string) {
  let parsedJson: unknown;
  try {
    parsedJson = JSON.parse(jsonString) as unknown;
  } catch (error) {
    return;
  }

  const jsonValidationResult = emailRuleExportSchema.safeParse(parsedJson);

  if (!jsonValidationResult.success) {
    return;
  }

  return jsonValidationResult.data;
}

function getJsonString(): Promise<string | undefined> {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) {
        resolve(undefined);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsText(file);
    };
    input.click();
  });
}

export function useImportRules() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const [currentRules] =
    trpc.dashboard.emails.inboundRules.getRules.useSuspenseQuery({
      businessSlug,
    });

  const importRulesMutation =
    trpc.dashboard.emails.inboundRules.importRules.useMutation();

  return {
    importRules: async () => {
      const jsonString = await getJsonString();
      if (!jsonString) return;

      const data = validateAndParseJsonString(jsonString);
      if (!data) {
        toast.error("Import error: Invalid JSON provided");
        return;
      }

      const { rules: importedRules } = data;

      // Check for duplicate rules
      const rulesToCreate = importedRules.map((rule) => {
        let value = rule.value;
        let counter = 1;
        while (currentRules.some((r) => r.value === value)) {
          value = `${rule.value} (${counter})`;
          counter++;
        }
        return {
          ...rule,
          value,
        };
      });

      if (rulesToCreate.length === 0) {
        toast.error("Import error: No rules to import");
        return;
      }

      let alertDescription = "The following rules will be created:\n";
      for (const rule of rulesToCreate) {
        alertDescription += `- ${rule.value} (${rule.type})\n`;
      }

      requestAlertDialogDisplay({
        title: "Import email rules",
        description: alertDescription,
        onAction: () => {
          void importRulesMutation
            .mutateAsync({
              businessSlug,
              rules: rulesToCreate,
            })
            .then(async () => {
              await trpcUtils.dashboard.emails.inboundRules.getRules.invalidate(
                {
                  businessSlug,
                },
              );
              toast.success("Email rules imported successfully");
            })
            .catch((error) => {
              toast.error("Failed to import email rules");
              console.error(error);
            });
        },
        actionText: "Import",
      });
    },
  };
}
