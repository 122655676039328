import { PreviewMessage } from "./PreviewMessage";
import { Event } from "../Event";
import { ConversationPreviewTopBar } from "./ConversationPreviewTopBar";
import { useState, useMemo, ReactNode } from "react";
import { Route } from "../..";
import { Note } from "../Note";
import { useCustomerConversations } from "../../-hooks/useCustomerConversations.hook";

type CustomerConversation = ReturnType<
  typeof useCustomerConversations
>["customerConversations"][number];

interface ConversationPreviewProps {
  conversation: CustomerConversation;
  mergingEnabled: boolean;
  mergeButton?: ReactNode;
}

export function ConversationPreview({
  conversation,
  mergingEnabled,
  mergeButton,
}: ConversationPreviewProps) {
  const { user } = Route.useRouteContext();
  const [useTranslation, setUseTranslation] = useState(false);

  const showTranslation = useMemo(() => {
    return (
      conversation.enableTranslation &&
      conversation.parts
        .filter(
          (p): p is Extract<typeof p, { type: "message" }> =>
            p.type === "message",
        )
        .some((m) => m.language !== user.translationLanguage)
    );
  }, [
    conversation.parts,
    user.translationLanguage,
    conversation.enableTranslation,
  ]);

  return (
    <div className="bg-background flex h-full max-h-[80vh] w-[800px] max-w-[50vw] flex-col overflow-y-auto">
      <ConversationPreviewTopBar
        conversation={conversation}
        createdAt={conversation.createdAt}
        useTranslation={useTranslation}
        setUseTranslation={setUseTranslation}
        showTranslation={showTranslation}
        mergingEnabled={mergingEnabled}
        mergeButton={mergeButton}
      />
      <div className="flex-grow overflow-auto px-2 pb-5 pt-3">
        {conversation.parts.map((part) => {
          if (part.type === "message") {
            return (
              <PreviewMessage
                key={part.id}
                message={part}
                useTranslation={useTranslation}
              />
            );
          } else if (part.type === "note") {
            return (
              <Note
                key={part.id}
                text={part.text}
                createdAt={part.createdAt}
                agentName={part.agentName}
                files={part.files}
              />
            );
          } else {
            return (
              <Event
                key={part.id}
                event={part.event}
                createdAt={part.createdAt}
                metadata={part.metadata}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
