import { Route } from "../..";
import { ArrowRight, CalendarRange, Check, ChevronDown } from "lucide-react";
import { format, subDays, subMonths } from "date-fns";

import { Button } from "@dashboard/common/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { useState } from "react";
import { Calendar } from "@dashboard/common/ui/calendar";
import { cn } from "@dashboard/utils/ui";
import { DateRange } from "react-day-picker";
import { Input } from "@dashboard/common/ui/input";
import { periodToRange } from "../../-utils/periodToRange";

const dateFormat = "dd MMM yyyy";

export function RangeSelect() {
  const search = Route.useSearch();

  const { period } = search.filters;

  const currentRange = periodToRange(period);

  const [value, setValue] = useState<string | undefined>(
    period.startsWith("custom") ? "custom" : period,
  );
  const [range, setRange] = useState<DateRange | undefined>({
    from: new Date(currentRange.from),
    to: subDays(currentRange.to, 1),
  });

  const [open, setOpen] = useState(false);

  let content = "Time Range";
  if (value === "custom") {
    const { from, to } = periodToRange(period);

    content = `${format(from, dateFormat)} - ${format(
      subDays(to, 1),
      dateFormat,
    )}`;
  } else if (value) {
    content =
      timeRanges.find((range) => range.value === value)?.label ??
      "Custom Date Range";
  }

  const openClasses = "text-blue bg-blue-light";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          className={cn(
            "ring-offset-background placeholder:text-muted-foreground border-gray-light border",
            "h-9 whitespace-nowrap bg-transparent px-3 py-2 text-sm focus:outline-none",
            "disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
            "flex w-auto select-none items-center justify-between gap-2 rounded-xl font-medium shadow-none",
            "hover:text-blue hover:bg-blue-light",
            open && openClasses,
          )}
        >
          <CalendarRange className="h-4 w-4" />
          <span style={{ pointerEvents: "none" }}>{content}</span>
          <ChevronDown className="h-4 w-4 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <TimeRangeComponent
          range={range}
          value={value}
          setRange={setRange}
          setValue={setValue}
          setOpen={setOpen}
        />
      </PopoverContent>
    </Popover>
  );
}

function TimeRangeComponent({
  range,
  value,
  setRange,
  setValue,
  setOpen,
}: {
  range: DateRange | undefined;
  value: string | undefined;
  setRange: (range: DateRange | undefined) => void;
  setValue: (value: string | undefined) => void;
  setOpen: (open: boolean) => void;
}) {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const [from, setFrom] = useState<string | undefined>(
    range?.from ? format(range.from, dateFormat) : undefined,
  );
  const [to, setTo] = useState<string | undefined>(
    range?.to ? format(range.to, dateFormat) : undefined,
  );

  const [fromError, setFromError] = useState<string | undefined>(undefined);
  const [toError, setToError] = useState<string | undefined>(undefined);

  const handleSelect = (rangeValue: DateRange | undefined) => {
    const isRangeSelected = !!(range?.from && range.to);

    setFromError(undefined);
    setToError(undefined);

    if (isRangeSelected) {
      let newFrom: Date | undefined;

      if (rangeValue?.from && range.from && rangeValue.from < range.from) {
        newFrom = rangeValue.from;
      } else {
        newFrom = rangeValue?.to;
      }

      setFrom(newFrom ? format(newFrom, dateFormat) : "");
      setTo("");

      setRange({ from: newFrom, to: undefined });
    } else {
      setRange(rangeValue);
      setFrom(rangeValue?.from ? format(rangeValue.from, dateFormat) : "");
      setTo(rangeValue?.to ? format(rangeValue.to, dateFormat) : "");

      if (rangeValue?.from && rangeValue.to) {
        setValue("custom");

        void navigate({
          search: {
            ...search,
            filters: {
              ...search.filters,
              period: `custom:${format(rangeValue.from, "yyyy-MM-dd")}.${format(
                rangeValue.to,
                "yyyy-MM-dd",
              )}`,
            },
          },
        });
      }
    }
  };

  const handleCancel = () => {
    const { from, to } = periodToRange(search.filters.period);

    setFrom(format(from, dateFormat));
    setTo(format(to, dateFormat));

    setRange({ from: new Date(from), to: new Date(to) });
    setOpen(false);
  };

  const handleApply = () => {
    if (!to) {
      setToError("Please select an end date");
    } else {
      setToError(undefined);
    }

    if (!from) {
      setFromError("Please select a start date");
    } else {
      setFromError(undefined);
    }

    if (range?.from && range.to) {
      setOpen(false);
    }
  };

  return (
    <div className="flex divide-x">
      <div className="flex flex-col items-center justify-center p-4">
        <div className="w-full flex-grow">
          <h3 className="mb-4 font-medium">Relative</h3>
        </div>
        <div className="w-full flex-grow">
          <RelativeTimeRanges
            value={value}
            setRange={setRange}
            setValue={setValue}
            setFrom={setFrom}
            setTo={setTo}
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-4">
        <div className="w-full flex-grow">
          <h3 className="mb-4 font-medium">Custom Date Range</h3>
        </div>
        <div className="w-full flex-grow">
          <div className="mb-2 flex items-center justify-between gap-2">
            <div className="flex w-full flex-col">
              <Input
                placeholder="From"
                value={from}
                disabled={true}
                className={cn(
                  "cursor-default disabled:cursor-default",
                  fromError && "border-red-500",
                )}
              />
              {fromError && (
                <p className="mt-1 text-sm text-red-500">{fromError}</p>
              )}
            </div>
            <ArrowRight className="text-gray h-4 w-4 shrink-0" />
            <div className="flex w-full flex-col">
              <Input
                placeholder="To"
                value={to}
                disabled={true}
                className={cn(
                  "cursor-default disabled:cursor-default",
                  toError && "border-red-500",
                )}
              />
              {toError && (
                <p className="mt-1 text-sm text-red-500">{toError}</p>
              )}
            </div>
          </div>
          <Calendar
            selected={range}
            onSelect={handleSelect}
            defaultMonth={subMonths(new Date(), 1)}
            mode="range"
            numberOfMonths={2}
            showOutsideDays={false}
            className="border-gray-light rounded-lg border"
            disabled={{ after: new Date() }}
          />
        </div>
        <div className="mt-4 flex w-full items-center justify-end gap-2">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="default" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

const timeRanges = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "this-week" },
  { label: "This Month", value: "this-month" },
  { label: "Last 2 Days", value: "last-2-days" },
  { label: "Last 7 Days", value: "last-7-days" },
  { label: "Last 14 Days", value: "last-14-days" },
  { label: "Last 30 Days", value: "last-30-days" },
  { label: "Last 90 Days", value: "last-90-days" },
] as const;

function RelativeTimeRanges({
  value,
  setRange,
  setValue,
  setFrom,
  setTo,
}: {
  value: string | undefined;
  setRange: (range: DateRange | undefined) => void;
  setValue: (value: string | undefined) => void;
  setFrom: (from: string | undefined) => void;
  setTo: (to: string | undefined) => void;
}) {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const handleClick = (value: (typeof timeRanges)[number]["value"]) => {
    setValue(value);

    const { from, to } = periodToRange(value);

    setFrom(format(from, dateFormat));
    setTo(format(subDays(to, 1), dateFormat));

    setRange({
      from: new Date(from),
      to: subDays(to, 1),
    });

    void navigate({
      search: {
        ...search,
        filters: {
          ...search.filters,
          period: value,
        },
      },
    });
  };

  const currentRange = timeRanges.find((range) => range.value === value);

  return (
    <div className="flex w-40 flex-col gap-2">
      {timeRanges.map((range) => (
        <Button
          key={range.value}
          onClick={() => handleClick(range.value)}
          variant="outline"
          className="flex w-full items-center justify-between pr-2"
        >
          {range.label}
          {currentRange?.value === range.value && <Check className="h-4 w-4" />}
        </Button>
      ))}
    </div>
  );
}
