import { useUiState } from "@chatbot/hooks/uiState.hooks";
import clsxm from "@chatbot/utils/clsxm";
import { DisclaimerModal } from "./Disclaimer/DisclaimerModal";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { KeyboardEvent, useRef } from "react";
//import { Toaster } from "sonner";
import { TopBar } from "./TopBar";
import { Input } from "./Input";
import { SettingsModal } from "./Settings/SettingsModal";
import Container from "./Container";
import { ToastManager } from "../Toast";

export function ChatWindow() {
  const {
    loadedIntoContainer,
    isMobile,
    windowHeight,
    closeChatWindow,
    displaySettings,
  } = useUiState();

  const config = useConfig();

  const isLhs =
    (isMobile && !!config.mobilePositionLeft) ||
    (!isMobile && !!config.positionLeft);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape" && !displaySettings) {
      closeChatWindow();
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={containerRef}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        aria-label="Customer support chat window"
        style={{
          height: loadedIntoContainer
            ? "100%"
            : isMobile
            ? windowHeight
            : "704px",
          maxHeight: isMobile || loadedIntoContainer ? undefined : "80vh",
          width: isMobile || loadedIntoContainer ? "100%" : "400px",
        }}
        className={clsxm(
          "relative flex flex-col overflow-hidden rounded-none bg-white shadow-xl sm:rounded-xl",
          // Mobile is full screen, above the launcher and attention grabbers, or above all the content if loaded into container
          isMobile ? "fixed bottom-0 right-0 z-10" : "",
          loadedIntoContainer ? "" : `animate-open-chat${isLhs ? "-lhs" : ""}`,
        )}
      >
        {!loadedIntoContainer && <ToastManager containerRef={containerRef} />}
        <TopBar />
        <Container />
        <Input />
        <SettingsModal />
        {config.disclaimerText && <DisclaimerModal />}
      </div>
    </>
  );
}
