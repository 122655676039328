import clsxm from "@chatbot/utils/clsxm";
import { differenceInMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { baseUrl } from "@chatbot/utils/baseUrl";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { useConfig } from "@chatbot/hooks/config.hooks";

export default function AttentionGrabbers() {
  const {
    botIconFileUrl,
    botName,
    attentionGrabberMessage,
    attentionGrabberSuggestionBubbleBackgroundColor,
    attentionGrabberSuggestionBubbleHoverBackgroundColor,
    attentionGrabberSuggestionBubbleHoverTextColor,
    attentionGrabberSuggestionBubbleTextColor,
    attentionGrabberSuggestions,
    positionLeft,
  } = useConfig();
  const {
    displayAttentionGrabbers,
    openChatWindow,
    openChatWindowWithMessage,
    closeAttentionGrabbers,
  } = useUiState();

  const [hoverOverMessage, setHoverOverMessage] = useState(false);

  const [startTime] = useState(new Date());
  const [timeLabel, setTimeLabel] = useState("1m");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const minutesDiff = differenceInMinutes(now, startTime);

      if (minutesDiff < 60) {
        setTimeLabel(`${minutesDiff + 1}m`);
      } else {
        setTimeLabel("1h+");
      }
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startTime]);

  const [hoverIndex, setHoverIndex] = useState<null | number>(null);

  if (!displayAttentionGrabbers || !attentionGrabberMessage) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => {
        setHoverOverMessage(true);
      }}
      onMouseLeave={() => {
        setHoverOverMessage(false);
      }}
    >
      <div className="relative">
        {/* bot message */}
        <div className="relative flex flex-row">
          {/* eslint-disable-next-line */}
          <img
            alt="bot icon"
            className="ml-auto mr-3 mt-auto h-7 w-7"
            src={botIconFileUrl ?? `${baseUrl}/bot.svg`}
          />
          {/* message */}
          <div
            className="relative flex min-w-[290px] max-w-[350px] flex-col rounded-r-xl rounded-tl-xl bg-white px-5 py-[15px] after:absolute after:-left-[5px] after:bottom-0 after:h-0 after:w-0 after:border-b-[13px] after:border-l-[5px] after:border-r-0 after:border-t-0 after:border-solid after:border-x-transparent after:border-b-white after:border-t-transparent"
            style={{ boxShadow: "rgba(35, 47, 53, 0.09) 0px 2px 8px 0px" }}
          >
            <div className="flex flex-row items-center justify-center">
              <button
                className={clsxm(
                  "absolute -top-4 z-10 h-[32px] cursor-pointer rounded-full bg-[#67788f] px-5 text-[13px] font-light text-white",
                  hoverOverMessage ? "" : "hidden",
                )}
                onClick={() => openChatWindow()}
              >
                View more
              </button>
              <button
                className={clsxm(
                  "absolute -right-3 -top-4 z-10 h-[32px] w-[32px] cursor-pointer rounded-full bg-[#67788f] p-2.5",
                  hoverOverMessage ? "" : "hidden",
                )}
                onClick={() => closeAttentionGrabbers()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="white"
                  className="h-full w-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-row">
              <span className="text-sm text-gray-500">{botName ?? "Octo"}</span>
              <span className="mx-1 text-sm text-gray-500">•</span>
              <span className="text-sm text-gray-500">{timeLabel} ago</span>
            </div>
            <span className="mt-2 text-sm">{attentionGrabberMessage}</span>
          </div>
        </div>
        {/* question options */}
        {attentionGrabberSuggestions.length > 0 && (
          <div
            className={clsxm(
              "mt-5 flex flex-col items-end space-y-3 overflow-hidden",
              positionLeft ? "items-start" : "items-end",
            )}
          >
            {attentionGrabberSuggestions.map((suggestion, index) => {
              const isHovered = hoverIndex === index;

              // Default colors
              let backgroundColor =
                attentionGrabberSuggestionBubbleBackgroundColor ?? "#e5eeff";
              let color =
                attentionGrabberSuggestionBubbleTextColor ?? "#003499";

              // Hover colors
              if (isHovered) {
                backgroundColor =
                  attentionGrabberSuggestionBubbleHoverBackgroundColor ??
                  "#0057ff";
                color =
                  attentionGrabberSuggestionBubbleHoverTextColor ?? "#ffffff";
              }

              return (
                <button
                  key={index}
                  className="w-fit max-w-[470px] rounded-lg px-2.5 py-2.5 text-sm"
                  style={{
                    backgroundColor,
                    color,
                  }}
                  onMouseEnter={() => {
                    setHoverIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoverIndex(null);
                  }}
                  onClick={() => {
                    openChatWindowWithMessage(suggestion.message);
                  }}
                >
                  {suggestion.displayText}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
