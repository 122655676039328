import { Route } from "../..";
import { MultiSelect } from "./general/MultiSelect";
import { BriefcaseBusiness } from "lucide-react";

export function BusinessFilter() {
  const { businesses } = Route.useRouteContext();

  const options = businesses
    .map((business) => ({
      label: business.internalName,
      value: business.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <MultiSelect
      filterType="businessIds"
      options={options}
      icon={BriefcaseBusiness}
    />
  );
}
