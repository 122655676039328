import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { UseFormReturn } from "react-hook-form";
import { Textarea } from "@dashboard/common/ui/textarea";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { MessageTopicForm } from "../-utils/messageTopicFormSchema";

interface MessageTopicsFormBodyProps {
  form: UseFormReturn<MessageTopicForm>;
  onSubmit: (data: MessageTopicForm) => void;
  onError: (errors: unknown) => void;
}

export function MessageTopicsFormBody({
  form,
  onSubmit,
  onError,
}: MessageTopicsFormBodyProps) {
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="topics"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Topics</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter topics separated by commas"
                  {...field}
                  rows={4}
                />
              </FormControl>
              <FormDescription>
                Enter topics separated by commas (e.g. Billing, Technical
                Support, Feature Request)
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
