import { toast } from "sonner";
import { Route } from "..";
import { RuleForm } from "../-utils/ruleFormSchema";

export function useCreateRule({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const createRuleMutation =
    trpc.dashboard.emails.inboundRules.createRule.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.emails.inboundRules.getRules.invalidate({
          businessSlug,
        });

        toast.success("Rule created successfully.");
        setOpen(false);
      },
      onError: (error) => {
        toast.error("Failed to create rule.");
        console.error(error);
      },
    });

  return {
    createRule: (data: RuleForm) => {
      createRuleMutation.mutate({
        businessSlug,
        ...data,
      });
    },
  };
}
