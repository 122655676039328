import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug, productSlug },
  }) => {
    await trpcClientUtils.dashboard.products.get.ensureData({
      businessSlug,
      productSlug,
    });
  },
  component: ProductPage,
});

export function ProductPage() {
  const { businessSlug, productSlug, organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const navigate = Route.useNavigate();

  const [product] = trpc.dashboard.products.get.useSuspenseQuery({
    businessSlug,
    productSlug,
  });

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="mx-auto max-w-5xl space-y-6 p-6">
      {/* Back Button */}
      <button
        onClick={() =>
          void navigate({
            to: "/organization/$organizationSlug/settings/$businessSlug/knowledge/products",
            params: {
              organizationSlug,
              businessSlug,
            },
          })
        }
        className="mb-4 flex items-center gap-2 text-gray-600 hover:text-gray-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
        Back to Products
      </button>

      {/* Header Section */}
      <div className="rounded-lg bg-white p-6 shadow">
        <h3 className="mb-4 text-2xl font-bold">{product.contextual.name}</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
              ID: <span className="text-gray-900">{product.id}</span>
            </p>
            <p className="text-gray-600">
              Slug: <span className="text-gray-900">{product.slug}</span>
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              Price Range:{" "}
              <span className="text-gray-900">
                {Number(product.contextual.minPrice).toFixed(2)} -{" "}
                {Number(product.contextual.maxPrice).toFixed(2)}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-gray-600">
            Short Description:{" "}
            <span className="text-gray-900">{product.shortDescription}</span>
          </p>
          <p className="mt-2 text-gray-600">
            Long Description:{" "}
            <span className="text-gray-900">{product.longDescription}</span>
          </p>
        </div>
      </div>

      {/* URLs Section */}
      <div className="rounded-lg bg-white p-6 shadow">
        <h4 className="mb-4 text-xl font-semibold">URLs</h4>
        <div className="grid gap-2">
          {product.url.map((u) => (
            <div key={u.url} className="rounded bg-gray-50 p-3">
              <p className="flex items-center gap-2">
                <span className="text-gray-600">URL:</span>
                <a
                  href={u.url}
                  className="text-blue-600 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {u.url}
                </a>
                <span className="text-sm text-gray-500">
                  ({u.language} - {u.salesChannel})
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Variants Section */}
      <div className="rounded-lg bg-white p-6 shadow">
        <h4 className="mb-4 text-xl font-semibold">Variants</h4>
        <div className="grid gap-6">
          {product.variants.map((variant) => (
            <div key={variant.id} className="rounded-lg border p-4">
              <h4 className="mb-3 text-lg font-semibold">
                Variant: {variant.title}
              </h4>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-600">
                    SKU: <span className="text-gray-900">{variant.sku}</span>
                  </p>
                  <p className="text-gray-600">
                    Stock:{" "}
                    <span className="text-gray-900">
                      {variant.stockQuantity ?? "Unknown"}
                    </span>
                  </p>
                  <p className="text-gray-600">
                    Can sell out of stock:{" "}
                    <span className="text-gray-900">
                      {nullableBooleanToText(variant.canSellOutOfStock)}
                    </span>
                  </p>
                  <p className="text-gray-600">
                    In stock:{" "}
                    <span className="text-gray-900">
                      {nullableBooleanToText(variant.inStock)}
                    </span>
                  </p>
                </div>
                {variant.contextual.pricing?.map((p) => (
                  <div key={p.currentPrice} className="rounded bg-gray-50 p-3">
                    <p className="text-gray-600">
                      Current price:{" "}
                      <span className="font-medium">
                        {Number(p.currentPrice).toFixed(2)}
                      </span>
                    </p>
                    <p className="text-gray-600">
                      Regular price:{" "}
                      <span className="text-gray-900">
                        {p.regularPrice
                          ? Number(p.regularPrice).toFixed(2)
                          : "Unknown"}
                      </span>
                    </p>
                    <p className="text-gray-600">
                      On sale:{" "}
                      <span
                        className={`text-gray-900 ${
                          p.onSale ? "text-green-600" : ""
                        }`}
                      >
                        {p.onSale ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              <p className="mt-3 text-gray-600">
                Description:{" "}
                <span className="text-gray-900">{variant.longDescription}</span>
              </p>

              {variant.metafields.length > 0 && (
                <div className="mt-4">
                  <h5 className="mb-2 font-medium">Variant Metafields</h5>
                  <div className="grid gap-2">
                    {variant.metafields.map((metafield) => (
                      <p key={metafield.key} className="rounded bg-gray-50 p-2">
                        <span className="font-medium">{metafield.key}:</span>{" "}
                        {metafield.value.slice(0, 100)}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Collections Section */}
      <div className="rounded-lg bg-white p-6 shadow">
        <h4 className="mb-4 text-xl font-semibold">Collections</h4>
        <div className="flex flex-wrap gap-2">
          {product.collections.map((collection) => (
            <span
              key={collection.slug}
              className="rounded-full bg-gray-100 px-3 py-1 text-gray-700"
            >
              {collection.slug}
            </span>
          ))}
        </div>
      </div>

      {/* Metafields Section */}
      <div className="rounded-lg bg-white p-6 shadow">
        <h4 className="mb-4 text-xl font-semibold">Metafields</h4>
        <div className="grid gap-2">
          {product.metafields.map((metafield) => (
            <div key={metafield.key} className="rounded bg-gray-50 p-3">
              <span className="font-medium">{metafield.key}:</span>{" "}
              {metafield.value.slice(0, 100)}
            </div>
          ))}
        </div>
      </div>

      {/* Images Section */}
      <div className="rounded-lg bg-white p-6 shadow">
        <h4 className="mb-4 text-xl font-semibold">Images</h4>
        <div className="grid grid-cols-2 gap-4">
          {product.images.map((image) => (
            <div key={image.url} className="relative">
              <img
                src={image.url}
                alt={image.altText ?? "Unknown"}
                className="w-full rounded-lg object-cover"
              />
              {image.altText && (
                <p className="mt-1 text-sm text-gray-500">{image.altText}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function nullableBooleanToText(value: boolean | null) {
  if (value === null) {
    return "Unknown";
  }

  return value ? "Yes" : "No";
}
