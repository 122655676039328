import { Check, Copy, MoreVertical, EyeOff } from "lucide-react";
import { useGetKnowledgeSummary } from "../-hooks/useGetKnowledgeSummary";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import { useState } from "react";
import { cn } from "@dashboard/utils/ui";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@dashboard/common/ui/dropdown-menu";
import { Button } from "@dashboard/common/ui/button";
import { CircularProgressbar } from "react-circular-progressbar";
import { TooltipButton } from "@dashboard/common/TooltipButton/TooltipButton";
import { Link, useNavigate } from "@tanstack/react-router";
import { useMarkQuestionAsIrrelevant } from "../-hooks/useMarkQuestionAsIrrelevant";
import { useMarkTopicAsIrrelevant } from "../-hooks/useMarkTopicAsIrrelevant";
import { FlowGrid } from "./SimpleFlows/FlowGrid";
import { ProductKnowledge } from "./ProductKnowledge";
import { Route } from "..";

export function TestYourBot({
  organizationSlug,
  businessSlug,
}: {
  organizationSlug: string;
  businessSlug: string;
}) {
  const { user } = Route.useRouteContext();
  const navigate = useNavigate();
  const knowledge = useGetKnowledgeSummary();

  const { markQuestionAsIrrelevant } = useMarkQuestionAsIrrelevant();
  const { markTopicAsIrrelevant } = useMarkTopicAsIrrelevant();

  const [copiedIndex, setCopiedIndex] = useState<string | null>(null);

  function addArticle({
    question,
    category,
  }: {
    question: string;
    category: string;
  }) {
    void navigate({
      to: "/organization/$organizationSlug/settings/$businessSlug/knowledge/articles",
      params: {
        organizationSlug,
        businessSlug,
      },
      search: {
        q: {
          category,
          question,
        },
      },
    });
  }

  function handleMarkQuestionAsIrrelevant({
    questionId,
  }: {
    questionId: string;
  }) {
    markQuestionAsIrrelevant({ questionId });
  }

  function handleMarkTopicAsIrrelevant({ topic }: { topic: string }) {
    markTopicAsIrrelevant({ topic });
  }

  const copyToClipboard = async (text: string, index: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 1000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const totalPercentage = Math.round(
    (knowledge.totalAnsweredQuestionCount / knowledge.totalQuestionCount) * 100,
  );

  return (
    <div className="flex w-full flex-col gap-4">
      <div>
        <h2 className="text-2xl font-semibold">Test Your AI Chatbot</h2>
        <p className="mt-2 text-gray-600">
          Try having a conversation with your bot to see how it handles customer
          queries. This will help you understand its capabilities and ensure it
          provides helpful responses to your customers.
        </p>
      </div>

      {user.isAdmin && <ProductKnowledge />}

      <FlowGrid businessSlug={businessSlug} />

      <div className="rounded-lg border bg-white p-4">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            What Your AI Knows
          </h3>
          <div className="flex items-center gap-2">
            <div className="text-sm text-gray-600">
              {knowledge.totalAnsweredQuestionCount} /{" "}
              {knowledge.totalQuestionCount} Questions Answered
            </div>
            <span className="text-sm font-semibold text-gray-700">·</span>
            <div className="h-8 w-8">
              <CircularProgressbar
                value={totalPercentage}
                strokeWidth={24}
                styles={{
                  root: {
                    transform: "scale(0.8)",
                  },
                  path: {
                    stroke: "#10B981",
                    strokeWidth: 24,
                    strokeLinecap: "round",
                  },
                  trail: {
                    stroke: "#E5E7EB",
                    strokeWidth: 24,
                    strokeLinecap: "round",
                  },
                  text: {
                    fill: "#10B981",
                    fontSize: "24px",
                    fontWeight: "600",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="text-sm text-gray-600">
            <div className="grid grid-cols-3 gap-4">
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/articles"
                params={{
                  organizationSlug,
                  businessSlug,
                }}
                className="flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
              >
                <span className="text-xl font-semibold text-gray-900">
                  {knowledge.articleCount}
                </span>
                <span className="text-sm text-gray-600">
                  Article{Number(knowledge.articleCount) !== 1 && "s"}
                </span>
              </Link>
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/documents"
                params={{
                  organizationSlug,
                  businessSlug,
                }}
                className="flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
              >
                <span className="text-xl font-semibold text-gray-900">
                  {knowledge.documentCount}
                </span>
                <span className="text-sm text-gray-600">
                  Document{Number(knowledge.documentCount) !== 1 && "s"}
                </span>
              </Link>
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/websites"
                params={{
                  organizationSlug,
                  businessSlug,
                }}
                className="flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
              >
                <span className="text-xl font-semibold text-gray-900">
                  {knowledge.websiteCount}
                </span>
                <span className="text-sm text-gray-600">
                  Website{Number(knowledge.websiteCount) !== 1 && "s"}
                </span>
              </Link>
            </div>
          </div>
          <Accordion type="single" collapsible className="mt-4 space-y-2">
            {knowledge.knowledgeSummary.map((item, index) => {
              const answeredCount = item.questions.filter(
                (q) => q.isAnswered,
              ).length;
              const totalQuestions = item.questions.length;
              const percentage = Math.round(
                (answeredCount / totalQuestions) * 100,
              );

              return (
                <AccordionItem
                  key={index}
                  value={`item-${index}`}
                  className="rounded-lg border border-gray-200 bg-white shadow-sm transition-colors hover:bg-gray-50"
                >
                  <AccordionTrigger hideChevron className="px-4 py-2">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-base font-medium text-gray-900">
                        {item.topic}
                      </span>
                      <div className="flex items-center gap-2">
                        <span
                          className={cn(
                            "inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium ring-1 ring-inset",
                            percentage <= 30 &&
                              "bg-red-50 text-red-700 ring-red-700/10",
                            percentage > 30 &&
                              percentage < 80 &&
                              "bg-yellow-50 text-yellow-700 ring-yellow-700/10",
                            percentage >= 80 &&
                              "bg-green-50 text-green-700 ring-green-700/10",
                          )}
                        >
                          {answeredCount}/{totalQuestions} ({percentage}%)
                        </span>
                        <TooltipButton
                          variant="ghost"
                          buttonClassName="h-4 w-4 p-0 ml-2"
                          size="sm"
                          onClick={() =>
                            handleMarkTopicAsIrrelevant({ topic: item.topic })
                          }
                          tooltipContent="Mark as irrelevant"
                        >
                          <EyeOff className="h-4 w-4" />
                        </TooltipButton>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="mt-2 px-4 pb-3">
                    <div className="space-y-2">
                      {item.questions.map((q) => {
                        return (
                          <div
                            key={q.id}
                            onClick={() =>
                              void copyToClipboard(q.question, q.id)
                            }
                            className="group flex cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-white px-2 py-1.5 shadow-sm transition-all hover:border-gray-300 hover:bg-gray-50"
                          >
                            <div className="flex items-center gap-2 text-sm text-gray-700">
                              {copiedIndex === q.id ? (
                                <Check className="h-3.5 w-3.5 text-gray-500" />
                              ) : (
                                <Copy className="h-3.5 w-3.5 text-gray-400" />
                              )}
                              <span>{q.question}</span>
                            </div>
                            <div className="flex items-center gap-1.5">
                              <div
                                className={cn(
                                  "inline-flex items-center gap-1 rounded-full px-1.5 py-0.5 text-xs font-medium",
                                  q.isAnswered
                                    ? "bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20"
                                    : "bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/20",
                                )}
                              >
                                {q.isAnswered ? (
                                  <>
                                    <Check className="h-3 w-3" />
                                    Answered
                                  </>
                                ) : (
                                  "Not Answered"
                                )}
                              </div>
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-6 w-6 p-0"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <MoreVertical className="h-3.5 w-3.5" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                  <DropdownMenuItem
                                    onSelect={() =>
                                      handleMarkQuestionAsIrrelevant({
                                        questionId: q.id,
                                      })
                                    }
                                  >
                                    Mark as Irrelevant
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    onSelect={() =>
                                      addArticle({
                                        question: q.question,
                                        category: item.topic,
                                      })
                                    }
                                  >
                                    Add Article
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
