import { toast } from "sonner";
import { Route } from "..";
import { BotConfigForm } from "../-utils/formSchema";

export const useSaveBotConfig = () => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const upsertMutation = trpc.dashboard.bots.saveConfiguration.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.bots.getConfiguration.invalidate();
      toast.success("Sucessfully updated bot configuration");
    },
    onError: (error) => {
      toast.error("Failed to update bot configuration.");
      console.error(error);
    },
  });

  return {
    saveConfig: (data: BotConfigForm) => {
      const {
        name,
        customMessageCompositionRules,
        customConfiguration,
        tone,
        enableEmojis,
        autoCloseDelayMin,
        articleRelevanceCutoff,
      } = data;

      upsertMutation.mutate({
        businessSlug,
        name,
        customMessageCompositionRules,
        customConfiguration,
        tone,
        enableEmojis,
        autoCloseDelayMin,
        articleRelevanceCutoff,
      });
    },
  };
};
