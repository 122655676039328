import { toast } from "sonner";
import { Route } from "..";
import { useInvalidateInstalledIntegrations } from "./useInvalidateInstalledIntegrations";
import { IntegrationServiceName } from "@be/modules/integrations/integrations.types";

export function useSubmitForm({
  integrationServiceName,
}: {
  integrationServiceName: IntegrationServiceName;
}) {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const invalidate = useInvalidateInstalledIntegrations();

  const installMutation = trpc.dashboard.integrations.install.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.integrations.getIntegration.invalidate({
        integrationServiceName,
        businessSlug,
      });
      await invalidate();
      toast.success(
        `Successfully saved ${integrationServiceName} integration.`,
      );
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to saved ${integrationServiceName} integration. Please try again.`,
      );
    },
  });

  return {
    onSubmit: (integrationData: unknown) => {
      installMutation.mutate({
        businessSlug: businessSlug,
        integrationServiceName,
        integrationData,
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
    },
  };
}
