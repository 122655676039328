import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import { CircleHelp, Plus, Trash2 } from "lucide-react";
import { Input } from "@dashboard/common/ui/input";
import { Switch } from "@dashboard/common/ui/switch";
import { useFieldArray, useFormContext } from "react-hook-form";
import { WebChatConfigForm } from "../../-utils/formSchema";
import SettingsLabel from "../../../-components/SettingsLabel";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";
import { Button } from "@dashboard/common/ui/button";
import { Card, CardContent } from "@dashboard/common/ui/card";
import { Separator } from "@dashboard/common/ui/separator";

export function DeploymentSettings() {
  const { setValue, control } = useFormContext<WebChatConfigForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "deployments",
  });

  const addNewDomain = () => {
    append({ hostname: "", pathRegex: "", enableDeployment: false });
  };

  return (
    <AccordionItem value="deployment" variant="tabs">
      <AccordionTrigger>
        <h2 className="text-base font-medium">Deployment</h2>
      </AccordionTrigger>
      <AccordionContent>
        <div className="flex flex-col gap-4">
          {fields.map((field, index) => (
            <Card key={field.id}>
              <CardContent className="p-4">
                <div className="space-y-4">
                  <div>
                    <div className="flex items-center gap-2">
                      <SettingsLabel label="Domain" />
                    </div>
                    <div className="relative flex items-center justify-between gap-2">
                      <Input
                        name={`deployments.${index}.hostname`}
                        placeholder="www.domain.ai"
                        // @ts-expect-error - field.hostname is nullable
                        defaultValue={field.hostname}
                        className="pr-8"
                      />
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="absolute right-0 flex h-9 w-9 items-center justify-center hover:bg-transparent"
                          >
                            <CircleHelp
                              className="text-muted-foreground h-5 w-5 shrink-0"
                              strokeWidth={1.5}
                            />
                          </Button>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-80" side="top">
                          <div className="space-y-2">
                            <h4 className="font-semibold">
                              Domain Configuration
                            </h4>
                            <p className="text-sm">
                              Enter the domain where your chatbot will be
                              displayed. Do not include the protocol (http or
                              https). For example:
                            </p>
                            <ul className="text-muted-foreground list-inside list-disc space-y-1 text-sm">
                              <li>yourdomain.com</li>
                              <li>app.yourdomain.com</li>
                              <li>subdomain.yourdomain.com</li>
                            </ul>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center gap-2">
                      <SettingsLabel label="Path Pattern" />
                    </div>
                    <div className="relative flex items-center justify-between gap-2">
                      <Input
                        name={`deployments.${index}.pathRegex`}
                        placeholder="Enter path pattern (optional)"
                        // @ts-expect-error - field.pathRegex is nullable
                        defaultValue={field.pathRegex}
                        className="pr-8"
                      />
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="absolute right-0 flex h-9 w-9 items-center justify-center hover:bg-transparent"
                          >
                            <CircleHelp
                              className="text-muted-foreground h-5 w-5 shrink-0"
                              strokeWidth={1.5}
                            />
                          </Button>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-80" side="top">
                          <div className="space-y-2">
                            <h4 className="font-semibold">
                              Path Pattern Configuration
                            </h4>
                            <p className="text-sm">
                              Specify the URL paths where the chatbot should
                              appear. Examples:
                            </p>
                            <ul className="text-muted-foreground list-inside list-disc space-y-1 text-sm">
                              <li>/docs/* - Match all paths under /docs</li>
                              <li>/blog/* - Match all paths under /blog</li>
                            </ul>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </div>

                  <div className="flex items-center justify-between gap-2 py-2">
                    <SettingsLabel label="Enable Deployment" className="mr-2" />
                    <Switch
                      onCheckedChange={(value) => {
                        setValue(
                          `deployments.${index}.enableDeployment`,
                          value,
                        );
                      }}
                      defaultChecked={field.enableDeployment}
                    />
                  </div>

                  <Separator />

                  <Button
                    type="button"
                    variant="ghost"
                    className="hover:bg-destructive/10 h-9 w-full"
                    onClick={() => remove(index)}
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    Remove Domain
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>

        <Button
          type="button"
          variant="secondary"
          className="mt-4 w-full"
          onClick={addNewDomain}
        >
          <Plus className="mr-2 h-4 w-4" />
          Add Domain
        </Button>
      </AccordionContent>
    </AccordionItem>
  );
}
