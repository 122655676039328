import { Button } from "@dashboard/common/ui/button";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { ScrollArea } from "@dashboard/common/ui/scroll-area";
import { useState, useEffect } from "react";
import type { LucideIcon } from "lucide-react";
import type { Filter } from "../../../-context/FiltersContext";
import { useFilters } from "../../../-context/FiltersContext";
import { FilterTrigger } from "./FilterTrigger";

export type MultiSelectFilterTypes = "type" | "businessIds" | "channels";

export const FILTER_METADATA: Record<
  MultiSelectFilterTypes,
  { objectName: string; placeholder: string }
> = {
  type: { objectName: "Type", placeholder: "Select Types" },
  businessIds: { objectName: "Business", placeholder: "Select Businesses" },
  channels: { objectName: "Channel", placeholder: "Select Channels" },
};

interface Option {
  label: string;
  value: string;
}

interface MultiSelectFilterProps {
  filterType: MultiSelectFilterTypes;
  options: Option[];
  icon?: LucideIcon;
  maxHeight?: number;
}

export function MultiSelect({
  filterType,
  options,
  icon: Icon,
  maxHeight = 200,
}: MultiSelectFilterProps) {
  const [open, setOpen] = useState(false);
  const {
    filters,
    addFilter,
    updateFilter,
    removeFilter,
    lastAddedFilter,
    setLastAddedFilter,
  } = useFilters();
  const { objectName, placeholder } = FILTER_METADATA[filterType];

  const filter = filters.find((f) => f.type === filterType) as
    | Filter<MultiSelectFilterTypes>
    | undefined;

  const [selectedValues, setSelectedValues] = useState<string[]>(
    filter?.value ?? [],
  );

  useEffect(() => {
    if (lastAddedFilter === filterType) {
      setOpen(true);
      setLastAddedFilter(null);
    }
  }, [lastAddedFilter, filterType, setLastAddedFilter]);

  if (!filter) {
    addFilter({ type: filterType });
    return null;
  }

  const handleSelect = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    setSelectedValues(newSelectedValues);
  };

  const handleConfirm = () => {
    if (selectedValues.length === 0) {
      updateFilter({ type: filterType, value: undefined, active: false });
    } else {
      updateFilter({ type: filterType, value: selectedValues, active: true });
    }
    setOpen(false);
  };

  const handleSelectAll = () => {
    if (selectedValues.length === options.length) {
      setSelectedValues([]);
    } else {
      setSelectedValues(options.map((opt) => opt.value));
    }
  };

  const getSelectAllButtonText = () => {
    return selectedValues.length === options.length
      ? "Deselect All"
      : "Select All";
  };

  const getSelectionLabel = () => {
    if (selectedValues.length === 0) {
      return {
        prefix: placeholder,
        value: undefined,
      };
    }

    const selectedLabels = selectedValues
      .map((value) => options.find((opt) => opt.value === value)?.label)
      .filter(Boolean);

    return {
      prefix: `${objectName} is`,
      value: selectedLabels.join(", "),
    };
  };

  const { prefix, value } = getSelectionLabel();

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      handleConfirm();
    }
    setOpen(isOpen);
  };

  const hasOptions = options.length > 0;

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger>
        <FilterTrigger
          isOpen={open}
          isActive={filter.isActive}
          onRemove={() => {
            removeFilter({ type: filterType });
            setOpen(false);
          }}
          icon={Icon}
          labelPrefix={prefix}
          labelValue={value}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-[200px] p-0" align="start">
        {hasOptions ? (
          <>
            <div className="border-b p-2">
              <Button
                variant="secondary"
                onClick={handleSelectAll}
                className="w-full"
              >
                {getSelectAllButtonText()}
              </Button>
            </div>
            <ScrollArea className={`h-[${maxHeight}px]`}>
              <div className="p-2">
                {options.map((option) => (
                  <div
                    key={option.value}
                    className="hover:bg-muted flex cursor-pointer items-center space-x-2 rounded-sm px-2 py-1.5"
                  >
                    <Checkbox
                      id={`checkbox-${option.value}`}
                      checked={selectedValues.includes(option.value)}
                      onCheckedChange={() => handleSelect(option.value)}
                    />
                    <label
                      htmlFor={`checkbox-${option.value}`}
                      className="cursor-pointer text-sm"
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </ScrollArea>
            <div className="border-t p-2">
              <Button onClick={handleConfirm} className="w-full">
                Confirm
              </Button>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center p-4">
            <p className="text-muted-foreground text-center text-sm">
              No {objectName.toLowerCase()} available
            </p>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
