import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import SettingsSection from "../SettingsSection";
import ColorPicker from "../ColorPicker";
import LogoUploader from "../LogoUploader";
import { Input } from "@dashboard/common/ui/input";
import { Select } from "@dashboard/common/ui/select";
import { Button } from "@dashboard/common/ui/button";
import { formDefaults } from "../../-utils/formDefaults";
import defaultIcon from "@dashboard/assets/chat-bubble-oval-left.png";
import { WebChatConfigForm } from "../../-utils/formSchema";
import SettingsLabel from "../../../-components/SettingsLabel";
import SettingsDivider from "../../../-components/SettingsDivider";
import { useFormContext } from "react-hook-form";

export function LauncherSettings() {
  const { setValue, watch } = useFormContext<WebChatConfigForm>();
  const launcherType = watch("launcherType");
  const launcherIconSrc = watch("launcherIconSrc");

  const removeImage = (
    name: "topBarImage" | "botIcon" | "launcherIcon" | "launcherImage",
  ) => {
    setValue(`${name}Src`, undefined);
    setValue(name, null);
  };

  const handleLauncherTypeChange = (value: "icon" | "icon_text" | "image") => {
    setValue("launcherType", value);
  };

  return (
    <AccordionItem value="item-4" variant="tabs">
      <AccordionTrigger>
        <h2 className="text-base font-medium">Launcher</h2>
      </AccordionTrigger>
      <AccordionContent>
        <SettingsSection>
          <SettingsLabel label="Launcher type" />
          <Select
            variant="dropdown"
            value={launcherType}
            onChange={handleLauncherTypeChange}
            renderValue={(valueJsx) => <>{valueJsx}</>}
            options={[
              {
                label: "Icon",
                value: "icon",
              },
              {
                label: "Icon + Text",
                value: "icon_text",
              },
              {
                label: "Image",
                value: "image",
              },
            ]}
          />

          <SettingsDivider />

          {launcherType !== "image" && (
            <>
              <div className="flex w-full flex-wrap items-center justify-between gap-4">
                <div className="min-w-[180px] flex-1">
                  <SettingsLabel label="Launcher background color" />
                  <ColorPicker
                    defaultValue={formDefaults.launcherBackgroundColor}
                    name="launcherBackgroundColor"
                  />
                </div>
                {!launcherIconSrc && (
                  <div className="min-w-[180px] flex-1">
                    <SettingsLabel label="Launcher icon color" />
                    <ColorPicker
                      defaultValue={formDefaults.launcherIconColor}
                      name="launcherIconColor"
                    />
                  </div>
                )}
              </div>

              <SettingsDivider />
            </>
          )}

          <div className="flex w-full items-center justify-between">
            <div className="mr-2 flex-1">
              <SettingsLabel label="Launcher size" />
              <Input
                name="launcherSize"
                defaultValue={formDefaults.launcherSize}
                className="flex h-8 w-[77px] flex-row text-black"
              />
            </div>

            {launcherType !== "image" && (
              <div className="ml-2 flex-1">
                <SettingsLabel label="Launcher icon size" />
                <Input
                  name="launcherIconSize"
                  defaultValue={formDefaults.launcherIconSize}
                  className="flex h-8 w-[77px] flex-row text-black"
                />
              </div>
            )}
          </div>

          <SettingsDivider />

          {launcherType === "icon_text" && (
            <>
              <SettingsLabel label="Launcher text" />
              <Input
                name="launcherText"
                className="flex h-8 flex-row text-black"
              />

              <SettingsDivider />

              <SettingsLabel label="Launcher text color" />
              <ColorPicker
                defaultValue={formDefaults.launcherTextColor}
                name="launcherTextColor"
              />

              <SettingsDivider />
            </>
          )}

          {launcherType === "image" ? (
            <>
              <SettingsLabel label="Launcher image" />
              <LogoUploader name="launcherImage" />
            </>
          ) : (
            <>
              <SettingsLabel label="Launcher icon" />
              <LogoUploader name="launcherIcon" img={defaultIcon} />
              {launcherIconSrc && (
                <Button
                  className="mt-2"
                  type="button"
                  variant="destructive"
                  onClick={() => removeImage("launcherIcon")}
                >
                  Restore default
                </Button>
              )}
            </>
          )}
        </SettingsSection>
      </AccordionContent>
    </AccordionItem>
  );
}
