import { Route } from "..";
import { toast } from "sonner";
import { Dispatch, SetStateAction } from "react";

export const useGetXmlPreview = (
  setPreviewData: Dispatch<SetStateAction<unknown>>,
) => {
  const { trpc } = Route.useRouteContext();

  const getPreviewMutation = trpc.dashboard.xmlParser.parseUrl.useMutation({
    onSuccess: (data) => {
      setPreviewData(data);
    },
    onError: (error) => {
      toast.error("Failed to get XML preview");
      console.error(error);
    },
  });

  return {
    getPreviewMutation,
  };
};
