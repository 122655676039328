import { SelectFilter } from "./general/SelectFilter";
import { AlarmClock } from "lucide-react";

export function SnoozedFilter() {
  const options = [
    { value: "true", label: "Snoozed" },
    { value: "false", label: "Not Snoozed" },
  ];

  return (
    <SelectFilter filterType="isSnoozed" options={options} icon={AlarmClock} />
  );
}
