import { useMemo } from "react";

export function useAvgMessagesPerChat({
  messagesCounts,
  conversationsCounts,
}: {
  messagesCounts: {
    totalCount: { value: number; datetime: string }[];
  };
  conversationsCounts: {
    totalCount: { value: number; datetime: string }[];
  };
}) {
  const avgMessagesPerChat = useMemo(() => {
    const totalMessages = messagesCounts.totalCount.reduce(
      (acc, curr) => acc + Number(curr.value),
      0,
    );
    const totalConversations = conversationsCounts.totalCount.reduce(
      (acc, curr) => acc + Number(curr.value),
      0,
    );

    if (totalConversations === 0) return 0;
    return totalMessages / totalConversations;
  }, [messagesCounts.totalCount, conversationsCounts.totalCount]);

  return avgMessagesPerChat;
}
