import { SelectFilter } from "./general/SelectFilter";
import { ArrowUpRight } from "lucide-react";

export function HandedOffFilter() {
  const options = [
    { value: "true", label: "Handed Off" },
    { value: "false", label: "Not Handed Off" },
  ];

  return (
    <SelectFilter
      filterType="isHandedOff"
      options={options}
      icon={ArrowUpRight}
    />
  );
}
