import { Truck } from "lucide-react";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderShippingMethodsFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.shippingMethod.map((method) => ({
    label: method,
    value: method,
  }));

  return (
    <CommandMultiSelect
      filterType="orderShippingMethods"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Truck}
    />
  );
}
