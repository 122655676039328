import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { CreateTemplateDialogContent } from "./-components/CreateTemplateDialogContent";
import { useDeleteTemplates } from "./-hooks/useDeleteTemplates";
import { TemplateDialog } from "./-components/TemplateDialog";
import { EditTemplateContent } from "./-components/EditTemplateContent";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/custom-flows/templates/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.flowTemplates.getAll.ensureData({
      businessSlug,
    });
  },
  component: FlowTemplatesPage,
});

function FlowTemplatesPage() {
  const { trpc, business } = Route.useRouteContext();

  const { businessSlug } = Route.useParams();

  const [templates] = trpc.dashboard.flowTemplates.getAll.useSuspenseQuery({
    businessSlug,
  });

  const { deleteTemplates: deleteRules } = useDeleteTemplates();

  const records = templates.map((template) => ({
    id: template.id,
    templateDialog: TemplateDialog({
      name: template.name,
      content: template.content,
    }),
    name: template.name,
    content: template.content,
  }));

  return (
    <div className="flex h-full flex-col">
      <Table
        title="Flow Templates"
        businessName={business.internalName}
        records={records}
        columns={[
          {
            header: "Name",
            accessor: "templateDialog",
          },
        ]}
        addRecordDialogContent={(setOpen) => (
          <CreateTemplateDialogContent setOpen={setOpen} />
        )}
        editRecordDialogContent={(record, setOpen) => (
          <EditTemplateContent {...record} setOpen={setOpen} />
        )}
        deleteRecords={deleteRules}
        recordName={{ singular: "Template", plural: "Templates" }}
        noRecordsText="No templates available"
      />
    </div>
  );
}
