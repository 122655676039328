import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { PackageCheck } from "lucide-react";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderFulfillmentStatusesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.fulfillmentStatus.map((status) => ({
    label: status,
    value: status,
  }));

  return (
    <CommandMultiSelect
      filterType="orderFulfillmentStatuses"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={PackageCheck}
    />
  );
}
