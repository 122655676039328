import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { MessageSquarePlus, MessageSquare, Loader2 } from "lucide-react";
import { ShopifySubscription, useSubscribe } from "./-hooks/useSubscribe";
import { Separator } from "@dashboard/common/ui/separator";
import {
  BASIC_PLAN as SHOPIFY_BASIC_PLAN,
  OVERAGE_CONVERSATION_INCREMENT as SHOPIFY_OVERAGE_CONVERSATION_INCREMENT,
  TRIAL_DAYS as SHOPIFY_TRIAL_DAYS,
} from "@be/modules/integrations/thirdParty/shopify/shopify.const";
import {
  BASIC_PLAN as WOO_BASIC_PLAN,
  OVERAGE_CONVERSATION_INCREMENT as WOO_OVERAGE_CONVERSATION_INCREMENT,
  TRIAL_DAYS as WOO_TRIAL_DAYS,
} from "@be/modules/integrations/thirdParty/woocommerce/woocommerce.const";
import { useOrganization } from "../-hooks/useOrganization";
import { getStripePaymentLink } from "./-utils/getStripePaymentLink";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/plan/",
)({
  component: PlanPage,
});

function PlanPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const organization = useOrganization();

  const { subscribe, isLoading } = useSubscribe();

  const [productConfig] = trpc.dashboard.products.getConfig.useSuspenseQuery({
    businessSlug: organizationSlug,
  });

  const activePlatform = productConfig?.provider;

  let plan = SHOPIFY_BASIC_PLAN;
  let trialDays = SHOPIFY_TRIAL_DAYS;
  let overageConversationIncrement = SHOPIFY_OVERAGE_CONVERSATION_INCREMENT;

  if (activePlatform === "woocommerce") {
    plan = WOO_BASIC_PLAN;
    trialDays = WOO_TRIAL_DAYS;
    overageConversationIncrement = WOO_OVERAGE_CONVERSATION_INCREMENT;
  }

  const handleShopifySubscribe = () => {
    subscribe({ subscriptionType: plan.id as ShopifySubscription });
  };

  const handleStripeSubscribe = () => {
    const url = getStripePaymentLink({
      organizationId: organization.id,
      isTest: organization.slug === "octocom-onboarding-test",
    });

    window.location.href = url;
  };

  return (
    <div className="text-blue-dark flex h-full w-full flex-col items-center justify-center">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Start your {trialDays}-day free trial
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Experience the full power of Octocom risk-free
      </h2>

      <div className="flex w-full max-w-2xl flex-col gap-4 py-8">
        <div className="rounded-lg border p-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-xl font-medium">Octocom AI</h3>
            <div>
              <span className="text-lg font-medium">${plan.price}</span>
              <span className="text-gray-500">/month</span>
            </div>
          </div>

          <Separator />

          <div className="flex flex-col gap-3 pt-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <MessageSquare className="text-blue-dark h-5 w-5" />
                <div>
                  <div>Monthly Conversations</div>
                  <div className="text-sm text-gray-500">
                    AI-powered interactions
                  </div>
                </div>
              </div>
              <span className="text-lg font-medium">
                {plan.maxConversations}
              </span>
            </div>

            <Separator />

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <MessageSquarePlus className="text-blue-dark h-5 w-5" />
                <div>
                  <div>Additional Conversations</div>
                  <div className="text-sm text-gray-500">
                    Expand your usage flexibly
                  </div>
                </div>
              </div>
              <span className="text-lg font-medium">
                ${plan.overageFee} per {overageConversationIncrement}
              </span>
            </div>
          </div>
        </div>
        <Button
          variant="default"
          className="bg-blue-dark h-10 w-full justify-center font-semibold"
          onClick={
            activePlatform === "woocommerce"
              ? handleStripeSubscribe
              : handleShopifySubscribe
          }
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Processing...</span>
            </div>
          ) : activePlatform === "woocommerce" ? (
            "Start Trial"
          ) : (
            "Confirm with Shopify"
          )}
        </Button>
        <div className="flex flex-col gap-2 text-center">
          <p className="text-sm text-gray-600">
            Your trial starts today and ends in {trialDays} days. You can cancel
            anytime.
          </p>
          {activePlatform === "shopify" && (
            <p className="text-sm text-gray-600">
              We&apos;ll use your existing Shopify account for billing after the
              trial.
            </p>
          )}
        </div>

        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/features"
          isNextEnabled={false}
        />
      </div>
    </div>
  );
}
