import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RuleForm, ruleFormSchema } from "../-utils/ruleFormSchema";
import { toast } from "sonner";
import { RuleFormBody } from "./RuleFormBody";
import { useUpdateRule } from "../-hooks/useUpdateRule";

interface EditRuleFormProps {
  id: string;
  type: "email" | "domain";
  value: string;
  action: "handoff" | "spam" | "ignore";
  isActive: boolean;
  organization: boolean;
  setOpen: (open: boolean) => void;
}

export function EditRuleForm({
  id,
  type,
  value,
  action,
  isActive,
  organization,
  setOpen,
}: EditRuleFormProps) {
  const form = useForm<RuleForm>({
    resolver: zodResolver(ruleFormSchema),
    defaultValues: {
      type,
      value,
      action,
      isActive,
      organization,
    },
  });

  const { updateRule } = useUpdateRule({
    setOpen,
  });

  const onSubmit = (data: RuleForm) => {
    updateRule({ id, ...data });
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to update rule");
  };

  return <RuleFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
