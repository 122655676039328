import { LineItem } from "@be/modules/orders/orders.types";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { Box } from "lucide-react";
import { formatCurrency } from "@dashboard/utils/currency";

interface FulfillmentItemsSectionProps {
  items?: LineItem[];
}

export function FulfillmentItemsSection({
  items,
}: FulfillmentItemsSectionProps) {
  return (
    <div>
      <div className="flex items-center justify-between py-1">
        <span className="text-xs text-gray-500">
          Items ({items?.length ?? 0})
        </span>
      </div>
      {!items || items.length === 0 ? (
        <div className="py-1 text-xs text-gray-500">
          Item information was not found for this fulfillment.
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          {items.map((item, index) => (
            <div key={index} className="flex items-center text-xs">
              <div className="mr-2 h-8 w-8 flex-shrink-0 overflow-hidden rounded-md">
                {item.image ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-full w-full object-cover"
                    onError={(e) => {
                      e.currentTarget.style.display = "none";
                      const nextElement = e.currentTarget
                        .nextElementSibling as HTMLElement;
                      nextElement.style.display = "block";
                    }}
                  />
                ) : (
                  <Box className="h-full w-full" strokeWidth={1} />
                )}
              </div>
              <div className="mr-2 min-w-0 flex-grow">
                <TruncatedTooltip
                  className="block truncate text-left text-black"
                  text={item.name}
                />
              </div>
              <div className="flex-shrink-0 text-right">
                {item.discountedUnitPrice &&
                parseFloat(item.discountedUnitPrice.amount) <
                  parseFloat(item.originalUnitPrice?.amount ?? "0") ? (
                  <>
                    <p className="text-gray-400 line-through">
                      {formatCurrency({
                        amount: parseFloat(
                          item.originalUnitPrice?.amount ?? "0",
                        ),
                        currency: item.originalUnitPrice?.currencyCode ?? "USD",
                      })}{" "}
                      x {item.quantity}
                    </p>
                    <p>
                      {formatCurrency({
                        amount: parseFloat(item.discountedUnitPrice.amount),
                        currency: item.discountedUnitPrice.currencyCode,
                      })}{" "}
                      x {item.quantity}
                    </p>
                  </>
                ) : (
                  <p>
                    {formatCurrency({
                      amount: parseFloat(item.originalUnitPrice?.amount ?? "0"),
                      currency: item.originalUnitPrice?.currencyCode ?? "USD",
                    })}{" "}
                    x {item.quantity}
                  </p>
                )}
                <p>
                  {formatCurrency({
                    amount: parseFloat(item.totalPrice?.amount ?? "0"),
                    currency: item.totalPrice?.currencyCode ?? "USD",
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
