import { secondsToMilliseconds } from "date-fns";
import { Route } from "..";

export function useGetProductSyncStatus() {
  const { trpc, business } = Route.useRouteContext();

  const [syncStatus] =
    trpc.dashboard.products.getProductSyncStatus.useSuspenseQuery(
      {
        businessSlug: business.slug,
      },
      {
        refetchInterval: secondsToMilliseconds(30),
      },
    );

  return syncStatus;
}
