import { createFileRoute, useNavigate } from "@tanstack/react-router";
import shopifyLogo from "@dashboard/assets/shopify.svg";
import wooLogo from "@dashboard/assets/woo.svg";
import { Button } from "@dashboard/common/ui/button";
import { Check } from "lucide-react";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/platform/",
)({
  component: PlatformPage,
});

function PlatformPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const navigate = useNavigate();

  const [productConfig] = trpc.dashboard.products.getConfig.useSuspenseQuery({
    businessSlug: organizationSlug,
  });

  const installedPlatform = productConfig?.provider ?? null;

  const platforms = [
    {
      id: "shopify",
      name: "Shopify",
      icon: shopifyLogo,
      action: () =>
        (window.location.href = "https://apps.shopify.com/ai-support"),
    },
    {
      id: "woocommerce",
      name: "WooCommerce",
      icon: wooLogo,
      action: () =>
        navigate({
          to: "/organization/$organizationSlug/onboarding/platform/woocommerce",
          params: { organizationSlug },
        }),
    },
  ];

  return (
    <div className="text-blue-dark flex h-full w-full flex-col items-center justify-center">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect your E-commerce store
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Octocom will connect with your E-commerce store to get real-time product
        and order data.
      </h2>
      <div className="w-full max-w-3xl py-8">
        <div className="flex flex-col gap-4">
          {platforms.map((platform) => {
            const isInstalled = installedPlatform === platform.id;
            const Icon = platform.icon;

            return (
              <Button
                key={platform.id}
                variant="outline"
                className={`border-blue-dark text-blue-dark inline-flex h-10 w-full items-center justify-between rounded-md border bg-white px-4 text-sm font-semibold ${
                  isInstalled ? "cursor-default" : "hover:bg-gray-50"
                }`}
                onClick={isInstalled ? undefined : platform.action}
              >
                <div className="flex items-center">
                  <img
                    src={Icon}
                    alt={`${platform.name} Logo`}
                    className="mr-4 h-6 w-6"
                  />
                  {isInstalled
                    ? `${platform.name} has been successfully connected`
                    : `Connect ${platform.name}`}
                </div>
                {isInstalled && <Check className="h-6 w-6" strokeWidth={1.5} />}
              </Button>
            );
          })}
        </div>
        <OnboardingNavigation
          nextPath="/organization/$organizationSlug/onboarding/tone"
          isNextEnabled={!!installedPlatform}
        />
      </div>
    </div>
  );
}
