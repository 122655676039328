import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";
import { FormField, FormItem, FormLabel } from "@dashboard/common/ui/form";

import { useState } from "react";
import { ViewForm } from "../../-utils/viewFormSchema";
import { AddFilter } from "./AddFilter";
import { Filter } from "./Filter";
import { FilterKeys } from "@be/modules/dashboard/views/views.types";

interface FiltersProps {
  control: Control<ViewForm>;
  watch: UseFormWatch<ViewForm>;
  setValue: UseFormSetValue<ViewForm>;
  errors: FieldErrors<ViewForm>;
  clearErrors: UseFormClearErrors<ViewForm>;
}

const filterLabels = {
  timeRange: "Time Range",
  status: "Status",
  handedOff: "Handed Off",
  channel: "Channel",
  language: "Language",
  assignee: "Assignee",
  businessId: "Business",
  tag: "Tag",
  type: "Type",
  taskName: "Task Name",
};

const initialOptions = [
  { value: "timeRange", label: "Time Range" },
  { value: "status", label: "Status" },
  { value: "handedOff", label: "Handed Off" },
  { value: "channel", label: "Channel" },
  { value: "language", label: "Language" },
  { value: "assignee", label: "Assignee" },
  { value: "businessId", label: "Business" },
  { value: "tag", label: "Tag" },
  { value: "type", label: "Type" },
  { value: "taskName", label: "Task Name" },
];

export function FiltersFormField({
  control,
  watch,
  setValue,
  errors,
  clearErrors,
}: FiltersProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "filters",
  });

  const filters = watch("filters");
  const filterAvailableFilters = () => {
    return initialOptions.filter(
      (option) => !filters.some((filter) => filter.key === option.value),
    );
  };
  const [options, setOptions] = useState(filterAvailableFilters);

  const handleRemove = (index: number, key: FilterKeys) => {
    remove(index);
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: key, label: filterLabels[key] },
    ]);
  };

  const handleAdd = (key: FilterKeys) => {
    // Initial value is null to ensure a filter without an added value is invalid
    append({ key, value: null as unknown as string });
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== key),
    );
  };

  const setFormValue = (value: string, index: number) => {
    setValue(`filters.${index}.value`, value);
  };

  return (
    <div className="flex min-h-10 flex-col flex-wrap items-start justify-center gap-2.5">
      <FormLabel>Filters</FormLabel>
      {fields.map((field, index) => (
        <FormField
          key={field.id}
          control={control}
          name={`filters.${index}`}
          render={({ field }) => (
            <FormItem>
              <Filter
                filterKey={field.value.key}
                data={field.value.value}
                remove={() => handleRemove(index, field.value.key)}
                setFormValue={(value: string) => setFormValue(value, index)}
                error={!!errors.filters?.[index]?.value}
                clearErrors={() => clearErrors(`filters.${index}`)}
              />
            </FormItem>
          )}
        />
      ))}
      <AddFilter add={handleAdd} options={options} />
    </div>
  );
}
