import { Building } from "lucide-react";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderRecipientCitiesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.recipientCity.map((city) => ({
    label: city,
    value: city,
  }));

  return (
    <CommandMultiSelect
      filterType="orderRecipientCities"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Building}
    />
  );
}
