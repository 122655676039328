import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { AlertTriangle, ArrowRight } from "lucide-react";
import { useMergeConversation } from "../../-hooks/useMergeConversation";
import { useConversation } from "../../-hooks/useConversation.hook";
import { ConversationCard } from "../ConversationCard";
import { Switch } from "@dashboard/common/ui/switch";
import { useState } from "react";
import { useCustomerConversations } from "../../-hooks/useCustomerConversations.hook";

type RelatedConversationPreview = ReturnType<
  typeof useCustomerConversations
>["customerConversations"][number];

interface MergeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  sourceConversation: RelatedConversationPreview;
}

export function MergeDialog({
  open,
  onOpenChange,
  sourceConversation,
}: MergeDialogProps) {
  const { merge, isLoading } = useMergeConversation({ onOpenChange });
  const conversation = useConversation();
  const [reverseConfirmed, setReverseConfirmed] = useState(false);

  const isSourceActiveWebChat =
    !sourceConversation.isClosed &&
    !sourceConversation.isHandedOff &&
    sourceConversation.latestChannel === "web";

  const handleMerge = () => {
    if (isSourceActiveWebChat && reverseConfirmed) {
      merge({
        sourceId: conversation.publicId,
        targetId: sourceConversation.publicId,
      });
    } else {
      merge({
        sourceId: sourceConversation.publicId,
        targetId: conversation.publicId,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl font-semibold">
            Merge Conversations
          </DialogTitle>
        </DialogHeader>
        <div>
          <div className="rounded-lg border-yellow-200 bg-yellow-50 p-4 text-yellow-900">
            {isSourceActiveWebChat ? (
              <>
                <p className="mb-2">
                  The preview conversation is an active web chat and cannot be
                  merged into the currently open conversation. You can instead
                  merge the currently open conversation into the preview. This
                  cannot be undone.
                </p>
                <ul className="mb-4 ml-4 list-disc text-sm">
                  <li>
                    All messages will be combined and sorted chronologically
                  </li>
                  <li>
                    Any new communications will come into the preview
                    conversation
                  </li>
                  <li>
                    The currently open conversation will be permanently deleted
                  </li>
                </ul>
                <p className="mt-4 flex w-full items-center justify-center gap-2 text-center text-sm text-yellow-900">
                  <AlertTriangle className="h-4 w-4" />
                  The preview conversation will be handed off after merging.
                </p>
                <div className="mt-4 flex items-center gap-2">
                  <Switch
                    checked={reverseConfirmed}
                    onCheckedChange={setReverseConfirmed}
                  />
                  <label className="text-sm">
                    I understand and want to merge the currently open
                    conversation into the preview
                  </label>
                </div>
              </>
            ) : (
              <>
                <p className="mb-2">
                  This action will merge the preview conversation into your
                  currently open conversation. This cannot be undone.
                </p>
                <ul className="ml-4 list-disc text-sm">
                  <li>
                    All messages will be combined and sorted chronologically
                  </li>
                  <li>
                    Any new communications will come into this conversation
                  </li>
                  <li>The preview conversation will be permanently deleted</li>
                </ul>
              </>
            )}
            {!conversation.isHandedOff && !isSourceActiveWebChat && (
              <p className="mt-8 flex w-full items-center justify-center gap-2 text-center text-sm text-yellow-900">
                <AlertTriangle className="h-4 w-4" />
                The current conversation will be handed off after merging.
              </p>
            )}
          </div>
          <div className="py-6">
            <div className="mx-auto w-fit">
              <div className="mb-4 flex gap-14">
                <p className="text-muted-foreground w-64 text-sm font-medium">
                  {isSourceActiveWebChat
                    ? "Current Conversation"
                    : "Preview Conversation"}
                  <span className="block text-xs">
                    {isSourceActiveWebChat
                      ? conversation.publicId
                      : sourceConversation.publicId}
                  </span>
                </p>

                <p className="text-muted-foreground w-64 text-sm font-medium">
                  {isSourceActiveWebChat
                    ? "Preview Conversation"
                    : "Current Conversation"}
                  <span className="block text-xs">
                    {isSourceActiveWebChat
                      ? sourceConversation.publicId
                      : conversation.publicId}
                  </span>
                </p>
              </div>

              <div className="flex items-center gap-4">
                <ConversationCard
                  isClosed={
                    isSourceActiveWebChat
                      ? conversation.isClosed
                      : sourceConversation.isClosed
                  }
                  createdAt={
                    new Date(
                      isSourceActiveWebChat
                        ? conversation.createdAt
                        : sourceConversation.createdAt,
                    )
                  }
                  channel={
                    isSourceActiveWebChat
                      ? conversation.latestChannel
                      : sourceConversation.latestChannel
                  }
                  orderId={
                    isSourceActiveWebChat
                      ? conversation.orderId
                      : sourceConversation.orderId
                  }
                  className="bg-primary/5 w-64"
                />

                <ArrowRight className="text-muted-foreground h-6 w-6" />

                <ConversationCard
                  isClosed={
                    isSourceActiveWebChat
                      ? sourceConversation.isClosed
                      : conversation.isClosed
                  }
                  createdAt={
                    new Date(
                      isSourceActiveWebChat
                        ? sourceConversation.createdAt
                        : conversation.createdAt,
                    )
                  }
                  channel={
                    isSourceActiveWebChat
                      ? sourceConversation.latestChannel
                      : conversation.latestChannel
                  }
                  orderId={
                    isSourceActiveWebChat
                      ? sourceConversation.orderId
                      : conversation.orderId
                  }
                  className="bg-muted w-64"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="flex gap-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                Cancel
              </Button>
              <Button
                variant="default"
                className="w-44"
                onClick={handleMerge}
                disabled={
                  isLoading || (isSourceActiveWebChat && !reverseConfirmed)
                }
              >
                {isLoading ? (
                  <>
                    <span className="mr-2">Merging...</span>
                    <span className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
                  </>
                ) : (
                  "Confirm and Merge"
                )}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
