import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { EmptyState } from "./EmptyState";

interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

function CustomTick({ x, y, payload }: CustomTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        fontSize={11}
        fontFamily="Arial"
        fontWeight="light"
        textAnchor="end"
        fill="#6a6a75"
      >
        {payload?.value}
      </text>
    </g>
  );
}

interface DataPoint {
  x: string;
  y: number;
  color: string;
}

interface DataSeries {
  data: DataPoint[];
  style: {
    barSize: number;
  };
}

interface ChartProps {
  series: DataSeries;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    value?: number;
    color?: string;
  }[];
  label?: string;
}

function CustomTooltip({ active, payload, label }: CustomTooltipProps) {
  if (active && payload?.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-300 bg-white px-3 py-2.5 shadow-sm">
        <p className="font-medium" style={{ color: payload[0].color }}>
          {`${payload[0].value} messages`}
        </p>
        <p className="text-gray-700">{label}</p>
      </div>
    );
  }
  return null;
}

export function HorizontalBarChart({ series }: ChartProps) {
  const data = series.data;

  // Check if there's any data at all, regardless of values
  const hasData = data.length > 0;
  if (!hasData) {
    return (
      <div className="w-full">
        <div className="mt-10" style={{ height: "280px" }}>
          <EmptyState />
        </div>
      </div>
    );
  }

  const baseHeight = 100;
  const dynamicHeight = baseHeight + data.length * (series.style.barSize + 10);

  const maxValue = Math.max(...data.map((d) => d.y));

  return (
    <div className="w-full">
      <div className="mt-10" style={{ height: `${dynamicHeight}px` }}>
        <ResponsiveContainer width="100%" height="100%">
          <RechartsBarChart
            data={data}
            layout="vertical"
            margin={{
              top: 0,
              right: 0,
              left: 110,
              bottom: 0,
            }}
          >
            <CartesianGrid
              stroke="#F2F2F2"
              horizontal={false}
              vertical={true}
            />
            <XAxis
              type="number"
              axisLine={false}
              tickLine={false}
              domain={[0, maxValue]}
              tick={{
                style: {
                  fontFamily: "Arial",
                  fontSize: 11,
                  fontWeight: "light",
                },
              }}
            />
            <YAxis
              dataKey="x"
              type="category"
              axisLine={false}
              tickLine={false}
              tick={<CustomTick />}
              width={110}
            />
            <Tooltip<number, string>
              content={(props) => <CustomTooltip {...props} />}
              cursor={{ fill: "transparent" }}
            />
            <Bar dataKey="y" barSize={series.style.barSize} name="Message">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
