import { BriefcaseBusiness } from "lucide-react";
import { Route } from "../../_conversationSidebar";
import { SelectFilter } from "./SelectFilter";

export function BusinessFilter() {
  const { businesses } = Route.useRouteContext();

  const options = businesses.map((business) => ({
    label: business.internalName,
    value: business.id,
  }));

  if (options.length <= 1) {
    return null;
  }

  return (
    <SelectFilter
      options={options}
      valueIcon={BriefcaseBusiness}
      name="Business"
      filterKey="businessId"
    />
  );
}
