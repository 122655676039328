import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { ConfigurationAccordionItem } from "./ConfigurationAccordionItem";
import { Route } from "../_config-sidebar";

import { TicketIcon, BookmarkIcon, MailWarningIcon } from "lucide-react";

export function HelpDesk() {
  const { hasExternalTicketConfig } = Route.useRouteContext();

  return (
    <ConfigurationAccordionItem value="help-desk" title="Help Desk">
      <SidebarButton
        icon={TicketIcon}
        text="Ticket Routing"
        to="/organization/$organizationSlug/settings/$businessSlug/tickets"
      />

      {!hasExternalTicketConfig && (
        <SidebarButton
          icon={BookmarkIcon}
          text="Macros"
          to="/organization/$organizationSlug/settings/$businessSlug/macros"
        />
      )}

      <SidebarButton
        icon={MailWarningIcon}
        text="Email Rules"
        to="/organization/$organizationSlug/settings/$businessSlug/email-rules"
      />
    </ConfigurationAccordionItem>
  );
}
