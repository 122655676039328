import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@dashboard/common/ui/dialog";
import { CreateRuleForm } from "./CreateRuleForm";

interface CreateRuleDialogContentProps {
  setOpen: (open: boolean) => void;
}

export function CreateRuleDialogContent({
  setOpen,
}: CreateRuleDialogContentProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Add rule</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Add a new email rule to handle incoming emails.
      </DialogDescription>
      <CreateRuleForm setOpen={setOpen} />
    </>
  );
}
