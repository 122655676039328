import { Accordion } from "@dashboard/common/ui/accordion";
import { Channels } from "./Channels";
import { AiAndAutomation } from "./AiAndAutomation";
import { HelpDesk } from "./HelpDesk";
import { Workspace } from "./Workspace";

export function ConfigurationAccordion() {
  return (
    <Accordion
      type="multiple"
      className="w-full"
      defaultValue={["workspace", "help-desk", "ai", "channels"]}
    >
      <Workspace />
      <HelpDesk />
      <AiAndAutomation />
      <Channels />
    </Accordion>
  );
}
