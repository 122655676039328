import { TableHead, TableRow } from "@dashboard/common/ui/table";
import { CircleHelp } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";
import { StatusDefinitions } from "./StatusDefinitions";

export function TableHeaderContent() {
  return (
    <TableRow className="bg-gray-lightest hover:bg-gray-100">
      <TableHead className="py-4 text-sm font-semibold text-gray-900">
        Order ID
      </TableHead>
      <TableHead className="py-4 text-sm font-semibold text-gray-900">
        Customer Name
      </TableHead>
      <TableHead className="py-4 text-sm font-semibold text-gray-900">
        Customer Email
      </TableHead>
      <TableHead className="py-4 text-sm font-semibold text-gray-900">
        <div className="flex items-center gap-2">
          Order Status
          <HoverCard>
            <HoverCardTrigger>
              <CircleHelp
                className="hover:text-primary h-4 w-4 text-gray-500 transition-colors"
                strokeWidth={1.5}
              />
            </HoverCardTrigger>
            <HoverCardContent
              side="right"
              className="w-full max-w-2xl rounded-lg border-none bg-white p-0 shadow-xl"
            >
              <StatusDefinitions />
            </HoverCardContent>
          </HoverCard>
        </div>
      </TableHead>
    </TableRow>
  );
}
