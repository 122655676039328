import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { MapPin } from "lucide-react";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderBillingStatesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.billingState.map((state) => ({
    label: state,
    value: state,
  }));

  return (
    <CommandMultiSelect
      filterType="orderBillingStates"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={MapPin}
    />
  );
}
