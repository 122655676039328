import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Globe2 } from "lucide-react";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderBillingCountriesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.billingCountry.map((country) => ({
    label: country,
    value: country,
  }));

  return (
    <CommandMultiSelect
      filterType="orderBillingCountries"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Globe2}
    />
  );
}
