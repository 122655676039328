import { proxy, useSnapshot } from "valtio";
import type {} from "@redux-devtools/extension";
import { devtools } from "valtio/utils";
import { WebChatMessage } from "@be/modules/channels/webChat/webChat.types";
import { DeepReadonlyObject } from "@chatbot/utils/types.utils";
import { trpc } from "@chatbot/utils/trpc";
import { useEffect } from "react";
import { useMarketId } from "../hacks.hooks";
import { recordGtmEvent } from "@chatbot/utils/ga4";
import { z } from "zod";
import { useDisableInput } from "../browserSessions.hooks";
import {
  newPendingConversation,
  useUpdateConversationStore,
  useWebSocketSubscriptions,
} from "./conversations.hooks.utils";
import { useConfig } from "../config.hooks";
import { toast } from "@chatbot/components/Toast";
export interface Conversation {
  createdAt: string;
  messages: [WebChatMessage, ...WebChatMessage[]];
  publicId?: string;
}

interface PendingConversation {
  status: "pending";
}

interface ActiveConversation extends Conversation {
  status: "active";
  id: string;
  publicId: string | undefined;
}

type CurrentConversation = PendingConversation | ActiveConversation;

export const conversationStore = proxy({
  conversations: undefined as
    | undefined
    | {
        email?: string;
        agreedToTermsAndConditions?: boolean;
        initialBotMessage: string;
        browserSessionId: string;
        current: CurrentConversation;
        past: Conversation[];
        isLoading: boolean;
        isHandedOff: boolean;
        hide: boolean;
        publicId?: string;
      },
});

export const forceUpdateStore = proxy({
  count: 0,
});

export const incrementForceUpdate = () => {
  forceUpdateStore.count += 1;
};

devtools(conversationStore, {
  name: "Conversation Store",
  enabled: process.env.NODE_ENV === "development",
});

export function useInitializeConversations({
  configId,
  browserSessionId,
}: {
  configId: string | undefined;
  browserSessionId: string;
}) {
  useWebSocketSubscriptions({ browserSessionId });

  // Use the extracted conversation store update hook
  useUpdateConversationStore({ browserSessionId, configId });

  const { conversations } = useSnapshot(conversationStore);

  const conversationsManager = useConversationsOptional();

  useEffect(() => {
    const handleCustomEvent = (event: CustomEvent) => {
      const parsedDetail = z
        .object({ content: z.string() })
        .safeParse(event.detail);

      if (parsedDetail.success && conversationsManager?.isReadyToSendMessage) {
        console.log(
          "[useInitializeConversations] Handling custom event:",
          parsedDetail.data.content,
        );
        conversationsManager.sendMessage({
          text: parsedDetail.data.content,
          fileIds: [],
        });
      }
    };

    document.addEventListener(
      "sendChatMessageEvent",
      handleCustomEvent as EventListener,
    );

    return () => {
      document.removeEventListener(
        "sendChatMessageEvent",
        handleCustomEvent as EventListener,
      );
    };
  }, [conversationsManager]);

  return !!conversations;
}

function useConversationsOptional() {
  const { conversations } = conversationStore;

  const marketId = useMarketId();

  const trpcUtils = trpc.useUtils();

  const configId = useConfig().id;

  const newConversationMutation = trpc.webChat.newConversation.useMutation();

  const closeActiveConversationMutation =
    trpc.webChat.closeActiveConversation.useMutation();

  const sendMessageMutation = trpc.webChat.sendMessage.useMutation();

  const clearHistoryMutation = trpc.webChat.clearHistory.useMutation();

  const inputDisabled = useDisableInput();

  if (!conversations) {
    return undefined;
  }

  const {
    current,
    past,
    browserSessionId,
    initialBotMessage,
    isLoading,
    email,
    agreedToTermsAndConditions,
    isHandedOff,
  } = conversations;

  return {
    browserSessionId,
    email,
    agreedToTermsAndConditions,

    setEmail: (email: string) => {
      conversations.email = email;
    },

    setAgreedToTermsAndConditions: (agreedToTermsAndConditions: boolean) => {
      conversations.agreedToTermsAndConditions = agreedToTermsAndConditions;
    },

    isLoading,

    get activeConversationId() {
      return current.status === "active" ? current.id : undefined;
    },

    get activeConversationPublicId() {
      const host = window.location.hostname;
      if (host === "localhost" || host === "www.octocom.ai") {
        return current.status === "active" ? current.publicId : undefined;
      }
      return undefined;
    },

    get conversations() {
      const conversations: DeepReadonlyObject<Conversation>[] = [...past];

      if (current.status === "pending") {
        // Pending conversations do not have any user messages yet (and are not persisted on the backend).
        conversations.push({
          createdAt: new Date().toISOString(),
          messages: [
            {
              sender: "bot",
              text: initialBotMessage,
            },
          ],
        });
      } else {
        if (
          current.messages.at(-1)?.sender !== "customer" ||
          current.messages.find((m) => m.sender === "agent")
        ) {
          // No active bot response, just include the current conversation as is
          conversations.push(current);
        } else {
          // Include the active bot response as a part of the current conversation
          conversations.push({
            ...current,
            messages: [
              ...current.messages,
              {
                sender: "bot",
                text: "",
              },
            ],
          });
        }
      }

      return conversations;
    },

    get isActive(): boolean {
      return current.status === "active";
    },

    get isReadyToSendMessage(): boolean {
      return (
        (current.status === "pending" ||
          current.messages.at(-1)?.sender !== "customer" ||
          isHandedOff) &&
        !inputDisabled
      );
    },

    get isGeneratingBotResponse(): boolean {
      return (
        current.status === "active" &&
        current.messages.at(-1)?.sender === "customer" &&
        !current.messages.find((m) => m.sender === "agent")
      );
    },

    sendMessage: ({ text, fileIds }: { text: string; fileIds: string[] }) => {
      console.log("[sendMessage] Sending message:", text);
      recordGtmEvent({ eventAction: "Send Message" });

      if (current.status === "pending") {
        console.log("[sendMessage] Creating new conversation");
        conversations.current = {
          status: "active",
          id: "new",
          createdAt: new Date().toISOString(),
          publicId: undefined,
          messages: [
            { sender: "bot", text: conversations.initialBotMessage },
            {
              sender: "customer",
              text,
            },
          ],
        };

        const timeoutPromise = new Promise<never>((_, reject) => {
          setTimeout(() => reject(new Error("Request timed out")), 15000);
        });

        void Promise.race([
          newConversationMutation.mutateAsync({
            browserSessionId,
            firstCustomerMessage: text,
            metadata: {
              url: window.location.href,
              attentionGrabberId: undefined,
              attentionGrabberSuggestionId: undefined,
              marketId,
            },
            email: conversations.email,
            fileIds,
            hide: conversations.hide,
            customerUrl: window.location.href,
          }),
          timeoutPromise,
        ])
          .then(({ conversationId, sentMessage, publicId }) => {
            console.log(
              "[sendMessage] New conversation created:",
              conversationId,
            );
            conversations.current = {
              status: "active",
              id: conversationId,
              createdAt: new Date().toISOString(),
              publicId,
              messages: [
                {
                  sender: "bot",
                  text: conversations.initialBotMessage,
                },
                {
                  sender: "customer",
                  text,
                  files: sentMessage.files,
                  messageId: sentMessage.id,
                },
              ],
            };
          })
          .catch((error) => {
            console.error("[sendMessage] Error creating conversation:", error);
            // Reset to pending state
            conversations.current = { status: "pending" };

            toast.show("Sending your message has failed");
          });
      } else {
        console.log(
          "[sendMessage] Adding message to existing conversation:",
          current.id,
        );

        current.messages.push({
          sender: "customer",
          text,
        });

        const timeoutPromise = new Promise<never>((_, reject) => {
          setTimeout(() => reject(new Error("Request timed out")), 15000);
        });

        void Promise.race([
          sendMessageMutation.mutateAsync({
            conversationId: current.id,
            text,
            fileIds,
            customerUrl: window.location.href,
          }),
          timeoutPromise,
        ])
          .then(({ sentMessage }) => {
            console.log("[sendMessage] Message sent, waiting for bot response");
            current.messages[current.messages.length - 1].messageId =
              sentMessage.id;
            current.messages[current.messages.length - 1].files =
              sentMessage.files;
          })
          .catch((error) => {
            console.error("[sendMessage] Error sending message:", error);
            // Remove the pending message from the UI
            current.messages.pop();

            toast.show("Sending your message has failed");
          });
      }
    },

    closeConversation: () => {
      if (current.status === "pending") {
        return;
      }
      conversations.isLoading = true;

      console.log("[closeConversation] Closing conversation:", current.id);

      void closeActiveConversationMutation
        .mutateAsync({
          browserSessionId,
        })
        .then(() => {
          newPendingConversation();
          conversations.isLoading = false;
        })
        .catch((error) => {
          console.error(
            "[closeConversation] Error closing conversation:",
            error,
          );
          toast.show("Error closing conversation");
          conversations.isLoading = false;
        });
    },

    clearHistory: () => {
      if (current.status === "pending" && past.length === 0) {
        console.log(
          "[clearHistory] Skipping clear history - no conversation to clear",
        );
        return;
      }
      conversations.isLoading = true;

      console.log("[clearHistory] Clearing conversation history");

      void clearHistoryMutation
        .mutateAsync({
          browserSessionId,
          configId,
          url: window.location.href,
        })
        .then(async () => {
          // Invalidate and wait for the cache to be cleared
          await trpcUtils.webChat.getConversationData.invalidate();

          // Force TRPC to fetch fresh data and wait for it to be cached
          const freshData = await trpcUtils.webChat.getConversationData.fetch({
            browserSessionId,
            configId,
            url: window.location.href,
          });

          const {
            activeConversation,
            pastConversations,
            initialBotMessage,
            email,
          } = freshData;

          const oldCurrent = conversationStore.conversations?.current;
          if (
            oldCurrent?.status === "active" &&
            oldCurrent.id === activeConversation?.id &&
            oldCurrent.messages.length === activeConversation.messages.length
          ) {
            console.log(
              "[useUpdateConversationStore] Skipping update - conversation is the same",
            );
            return;
          }

          const shouldHideConversations =
            new URL(window.location.href).searchParams.get(
              "octocom-no-bill",
            ) !== null;

          console.log(
            "[useUpdateConversationStore] Updating conversation store",
            performance.now(),
          );

          conversationStore.conversations = {
            email,
            agreedToTermsAndConditions: activeConversation ? true : undefined,
            initialBotMessage,
            browserSessionId,
            current: activeConversation
              ? {
                  status: "active",
                  ...activeConversation,
                }
              : { status: "pending" },
            past: pastConversations,
            isLoading: false,
            isHandedOff: activeConversation?.isHandedOff ?? false,
            hide: shouldHideConversations,
          };

          conversations.isLoading = false;
        })
        .catch((error) => {
          console.error("[clearHistory] Error clearing history:", error);
          toast.show("Error clearing conversation history");
          conversations.isLoading = false;
        });
    },
  };
}

export function useConversations() {
  const conversations = useConversationsOptional();

  if (!conversations) {
    throw new Error("Conversations not initialized");
  }

  return conversations;
}
