import { PackageX } from "lucide-react";
import { SelectFilter } from "./general/SelectFilter";

export function OrderCanceledFilter() {
  const options = [
    { label: "Order Cancelled", value: "true" },
    { label: "Order Not Cancelled", value: "false" },
  ];

  return (
    <SelectFilter
      filterType="orderCanceled"
      options={options}
      icon={PackageX}
    />
  );
}
