import { useMemo, useState } from "react";
import { useConversation } from "../../-hooks/useConversation.hook";
import { Route } from "../..";
import { MessageAvatar } from "./MessageAvatar";
import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { differenceInDays } from "date-fns";
import { cn } from "@dashboard/utils/ui";
import {
  CircleCheck,
  Clock,
  MessageSquareText,
  XCircle,
  Bug,
} from "lucide-react";
import { StatusAndTimeTooltip } from "./StatusAndTimeTooltip";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";
import { DebugModal } from "./-components/DebugModal";
import { RouterOutput } from "@be/exports";
import { ExtractUnionMember } from "@dashboard/utils/types";
import { Button } from "@dashboard/common/ui/button";

type MessageType = ReturnType<typeof useConversation>["messages"][number];

export type BotMessage = ExtractUnionMember<
  ExtractUnionMember<
    RouterOutput["dashboard"]["conversations"]["get"]["parts"][0],
    "type",
    "message"
  >,
  "sender",
  "bot"
>;

export function Message({ message }: { message: MessageType }) {
  const { organization, user } = Route.useRouteContext();
  const conversation = useConversation();
  const [debugModalOpen, setDebugModalOpen] = useState(false);

  const isCustomer = message.sender === "customer";
  const isExternal = message.sender === "external";

  let statusIcon = null;
  let statusText = null;
  if (message.sender === "bot") {
    if (message.isSent) {
      statusIcon = <CircleCheck className="text-gray h-3 w-3" />;
      statusText = "Sent";
    } else {
      if (conversation.isHandedOff) {
        statusIcon = <XCircle className="text-gray h-3 w-3" />;
        statusText = "Cancelled";
      } else {
        statusIcon = <Clock className="text-gray h-3 w-3" />;
        statusText = "Scheduled";
      }
    }
  }

  const filesMd = useMemo(() => {
    const ageInDays = differenceInDays(Date.now(), new Date(message.createdAt));
    if (organization.slug === "gls-pharmaceuticals" && ageInDays > 3) {
      // This is a hacky way to prevent showing images in old messages for GLS.
      // We don't want to actually delete the images, just hide them.
      // We will generalize this later.
      return "";
    }

    return message.files
      .map((file) => {
        if (file.contentType.startsWith("image")) {
          return `![${file.name}](${file.url})`;
        } else {
          return `[${file.name}](${file.url})`;
        }
      })
      .join("\n\n");
  }, [message, organization.slug]);

  const { useTranslation } = Route.useSearch();

  let translation: string | undefined;

  if (useTranslation === undefined || useTranslation) {
    translation = message.translations.find(
      (t) => t.translatedTo === user.translationLanguage,
    )?.translation;
  }

  const text = translation ?? message.text;

  const content = `${text}\n\n${filesMd}`;

  const agentName = message.sender === "agent" ? message.agentName : undefined;
  const externalSenderEmail =
    message.sender === "external" ? message.externalSenderEmail : undefined;

  return (
    <>
      <div
        className={cn("relative flex flex-row-reverse gap-x-2 p-2", {
          "flex-row": isCustomer || isExternal,
        })}
      >
        <div className="flex flex-col-reverse pb-2">
          <MessageAvatar
            sender={message.sender}
            agentName={agentName}
            externalSenderEmail={externalSenderEmail}
          />
        </div>

        <div
          className={cn(
            "group relative max-w-md rounded-lg px-4 pb-2 pt-3 xl:max-w-lg",
            !isCustomer && !isExternal
              ? "bg-[#DCE1F9] hover:bg-[#CED6F7]"
              : "bg-gray-lightest hover:bg-gray-light",
          )}
        >
          {message.sender === "bot" && (
            <div className="absolute -right-2 -top-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
              <HoverCard>
                <HoverCardTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 rounded-full bg-white shadow-sm hover:bg-gray-100"
                    onClick={() => setDebugModalOpen(true)}
                  >
                    <Bug className="h-4 w-4 text-gray-600" />
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent className="w-48" side="top">
                  <div className="text-sm">Debug this message</div>
                </HoverCardContent>
              </HoverCard>
            </div>
          )}
          <MarkdownContent content={content} className="break-words text-sm" />
          <div className="mt-1 flex flex-row items-center justify-between gap-1">
            <span className="text-gray text-[13px]">
              <StatusAndTimeTooltip
                date={message.createdAt}
                side="bottom"
                extraText={`via ${message.channel}`}
                statusIcon={statusIcon}
                statusText={statusText}
              />
            </span>
            {user.isAdmin && message.topics && message.topics.length > 0 && (
              <span className="text-gray flex flex-row items-center gap-1 text-[13px]">
                <MessageSquareText className="h-3 w-3" />
                {message.topics.join(", ")}
              </span>
            )}
          </div>
        </div>
      </div>

      {message.sender === "bot" && (
        <DebugModal
          open={debugModalOpen}
          setOpen={setDebugModalOpen}
          message={message as BotMessage}
        />
      )}
    </>
  );
}
