import { toast } from "sonner";
import { Route } from "..";

export function useUpdateRule({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const updateRuleMutation =
    trpc.dashboard.emails.inboundRules.updateRule.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.emails.inboundRules.getRules.invalidate({
          businessSlug,
        });

        toast.success("Rule updated successfully.");
        setOpen(false);
      },
      onError: (error) => {
        toast.error("Failed to update rule.");
        console.error(error);
      },
    });

  return {
    updateRule: ({
      id,
      type,
      value,
      action,
      isActive,
      organization,
    }: {
      id: string;
      type: "email" | "domain";
      value: string;
      action: "handoff" | "spam" | "ignore";
      isActive: boolean;
      organization: boolean;
    }) => {
      updateRuleMutation.mutate({
        businessSlug,
        id,
        type,
        value,
        action,
        isActive,
        organization,
      });
    },
  };
}
