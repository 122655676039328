import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import ReactJson from "react-json-view";
import { useGetXmlPreview } from "./-hooks/useGetXmlPreview";
import { useApplyMapping } from "./-hooks/useApplyMapping";
import { PathSelect } from "./-components/PathSelect";
import { MetafieldSection } from "./-components/MetafieldSection";
import { getPathsFromObject, PRODUCT_FIELDS } from "./-utils/utils";
import type {
  MetafieldMapping,
  FieldMapping,
  PriceMapping,
} from "./-utils/types";
import { useSaveMapping } from "./-hooks/useSaveMapping";
import { Button } from "@dashboard/common/ui/button";
import { Input } from "@dashboard/common/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { ScrollArea } from "@dashboard/common/ui/scroll-area";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@dashboard/common/ui/card";

// Example JSON data (we'll replace this with real data later)
const EXAMPLE_JSON = {
  products: [
    {
      id: "1",
      name: "Example Product",
      price: 99.99,
      categories: ["Electronics", "Gadgets"],
      specifications: {
        weight: "1.5kg",
        dimensions: {
          width: 10,
          height: 15,
          depth: 5,
        },
      },
    },
    // ... more products
  ],
};

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/xmlParser/",
)({
  component: XMLParserRoute,
});

function XMLParserRoute() {
  const [url, setUrl] = useState("");
  const [jsonData, setJsonData] = useState<unknown>(EXAMPLE_JSON);
  const [mappings, setMappings] = useState<Record<string, FieldMapping>>({});
  const [productMetafields, setProductMetafields] = useState<
    MetafieldMapping[]
  >([]);
  const [variantMetafields, setVariantMetafields] = useState<
    MetafieldMapping[]
  >([]);
  const [availablePaths, setAvailablePaths] = useState<string[]>([]);
  const [previewData, setPreviewData] = useState<object | null>(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [prices, setPrices] = useState<PriceMapping[]>([
    {
      id: crypto.randomUUID(),
      currentPrice: "",
      regularPrice: "",
      onSale: "",
      applicableTo: "",
      currentPriceTransforms: [],
      regularPriceTransforms: [],
      onSaleTransforms: [],
      applicableToTransforms: [],
    },
  ]);

  const { businessSlug } = Route.useParams();

  const { getPreviewMutation } = useGetXmlPreview((data) => {
    setJsonData(data);
    setAvailablePaths(getPathsFromObject(data));
  });

  const { applyMappingMutation } = useApplyMapping((preview) => {
    setPreviewData(preview);
    setIsPreviewModalOpen(true);
  });

  const { saveMappingMutation } = useSaveMapping();

  // Reset preview data when any mapping changes
  const handleMappingChange = (
    field: string,
    path: string,
    transforms: { id: string; params?: Record<string, string> }[] = [],
  ) => {
    setPreviewData(null); // Reset preview when mappings change
    setMappings((prev) => ({
      ...prev,
      [field]: {
        path,
        transforms,
      },
    }));
  };

  // Update the updateMetafield function to handle transforms
  const updateMetafield = (
    type: "product" | "variant",
    id: string,
    field: "key" | "valuePath",
    value: string,
    transforms: { id: string; params?: Record<string, string> }[] = [],
  ) => {
    console.log("Updating metafield:", { type, id, field, value, transforms });

    setPreviewData(null);
    if (type === "product") {
      setProductMetafields((prev) =>
        prev.map((item) =>
          item.id === id
            ? {
                ...item,
                [field]: value,
                transforms:
                  field === "valuePath" ? transforms : item.transforms ?? [],
              }
            : item,
        ),
      );
    } else {
      setVariantMetafields((prev) =>
        prev.map((item) =>
          item.id === id
            ? {
                ...item,
                [field]: value,
                transforms:
                  field === "valuePath" ? transforms : item.transforms ?? [],
              }
            : item,
        ),
      );
    }
  };

  // Reset preview when URL changes
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewData(null);
    setUrl(e.target.value);
  };

  // Add these helper functions
  const addMetafield = (type: "product" | "variant") => {
    const newField = {
      id: crypto.randomUUID(),
      key: "",
      valuePath: "",
      transforms: [],
    };

    if (type === "product") {
      setProductMetafields((prev) => [...prev, newField]);
    } else {
      setVariantMetafields((prev) => [...prev, newField]);
    }
  };

  const removeMetafield = (type: "product" | "variant", id: string) => {
    if (type === "product") {
      setProductMetafields((prev) => prev.filter((item) => item.id !== id));
    } else {
      setVariantMetafields((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const addPrice = () => {
    setPrices((prev) => [
      ...prev,
      {
        id: crypto.randomUUID(),
        currentPrice: "",
        regularPrice: "",
        onSale: "",
        applicableTo: "",
        currentPriceTransforms: [],
        regularPriceTransforms: [],
        onSaleTransforms: [],
        applicableToTransforms: [],
      },
    ]);
  };

  const removePrice = (id: string) => {
    setPrices((prev) => {
      if (prev.length <= 1) return prev;
      return prev.filter((price) => price.id !== id);
    });
  };

  const updatePrice = (
    id: string,
    field: keyof Omit<PriceMapping, "id">,
    value: string,
    transforms: { id: string; params?: Record<string, string> }[] = [],
  ) => {
    setPreviewData(null);
    setPrices((prev) =>
      prev.map((price) =>
        price.id === id
          ? {
              ...price,
              [field]: value,
              [`${field}Transforms`]: transforms,
            }
          : price,
      ),
    );
  };

  return (
    <div className="flex h-screen flex-col">
      <div className="flex flex-1">
        {/* Left Panel */}
        <Card className="w-1/3 border-r">
          <ScrollArea className="h-full">
            <CardContent className="p-4">
              {/* URL Input Section */}
              <section className="space-y-4">
                <h2 className="mb-4 text-xl font-semibold">XML Data Source</h2>
                <div className="space-y-4">
                  <Input
                    type="url"
                    value={url}
                    onChange={handleUrlChange}
                    placeholder="Enter XML data source URL"
                  />
                  <Button
                    onClick={() =>
                      getPreviewMutation.mutate({ url, businessSlug })
                    }
                    disabled={getPreviewMutation.isPending}
                  >
                    {getPreviewMutation.isPending ? "Loading..." : "Load Data"}
                  </Button>
                </div>
              </section>

              {/* Field Mapping Section */}
              <section className="mt-8">
                <h3 className="mb-4 text-lg font-semibold">Field Mapping</h3>
                <div className="space-y-4">
                  {PRODUCT_FIELDS.map((field) => (
                    <div key={field.key} className="flex flex-col space-y-1">
                      <label className="text-sm font-medium">
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      <PathSelect
                        value={
                          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                          mappings[field.key] ? mappings[field.key].path : ""
                        }
                        transforms={
                          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                          mappings[field.key]
                            ? mappings[field.key].transforms
                            : []
                        }
                        onChange={(path, transforms) =>
                          handleMappingChange(field.key, path, transforms)
                        }
                        placeholder={`Select path for ${field.label}`}
                        required={field.required}
                        availablePaths={availablePaths}
                        isArrayField={field.isArray}
                      />
                    </div>
                  ))}
                </div>
              </section>

              {/* Price Mappings */}
              <section className="mt-8">
                <div className="flex items-center justify-between">
                  <h3 className="mb-4 text-lg font-semibold">Price Mappings</h3>
                  <Button variant="outline" size="sm" onClick={addPrice}>
                    Add Price
                  </Button>
                </div>
                <div className="space-y-6">
                  {prices.map((price) => (
                    <Card key={price.id} className="p-4">
                      <div className="space-y-4">
                        <div className="flex justify-end">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => removePrice(price.id)}
                            disabled={prices.length <= 1}
                          >
                            Remove
                          </Button>
                        </div>
                        <div className="space-y-4">
                          <div className="flex flex-col space-y-1">
                            <label className="text-sm font-medium">
                              Current Price
                            </label>
                            <PathSelect
                              value={price.currentPrice}
                              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                              transforms={price.currentPriceTransforms ?? []}
                              onChange={(path, transforms) =>
                                updatePrice(
                                  price.id,
                                  "currentPrice",
                                  path,
                                  transforms,
                                )
                              }
                              placeholder="Select path for current price"
                              required={true}
                              availablePaths={availablePaths}
                            />
                          </div>
                          <div className="flex flex-col space-y-1">
                            <label className="text-sm font-medium">
                              Regular Price
                            </label>
                            <PathSelect
                              value={price.regularPrice}
                              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                              transforms={price.regularPriceTransforms ?? []}
                              onChange={(path, transforms) =>
                                updatePrice(
                                  price.id,
                                  "regularPrice",
                                  path,
                                  transforms,
                                )
                              }
                              placeholder="Select path for regular price"
                              availablePaths={availablePaths}
                            />
                          </div>
                          <div className="flex flex-col space-y-1">
                            <label className="text-sm font-medium">
                              On Sale
                            </label>
                            <PathSelect
                              value={price.onSale}
                              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                              transforms={price.onSaleTransforms ?? []}
                              onChange={(path, transforms) =>
                                updatePrice(
                                  price.id,
                                  "onSale",
                                  path,
                                  transforms,
                                )
                              }
                              placeholder="Select path for on sale status"
                              availablePaths={availablePaths}
                            />
                          </div>
                          <div className="flex flex-col space-y-1">
                            <label className="text-sm font-medium">
                              Applicable To
                            </label>
                            <PathSelect
                              value={price.applicableTo ?? ""}
                              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                              transforms={price.applicableToTransforms ?? []}
                              onChange={(path, transforms) =>
                                updatePrice(
                                  price.id,
                                  "applicableTo",
                                  path,
                                  transforms,
                                )
                              }
                              placeholder="Select path for price applicable to"
                              availablePaths={availablePaths}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </section>

              {/* Metafields Sections */}
              <MetafieldSection
                type="product"
                metafields={productMetafields}
                onAdd={() => addMetafield("product")}
                onUpdate={(id, field, value, transforms) =>
                  updateMetafield("product", id, field, value, transforms)
                }
                onRemove={(id) => removeMetafield("product", id)}
                availablePaths={availablePaths}
              />
              <MetafieldSection
                type="variant"
                metafields={variantMetafields}
                onAdd={() => addMetafield("variant")}
                onUpdate={(id, field, value, transforms) =>
                  updateMetafield("variant", id, field, value, transforms)
                }
                onRemove={(id) => removeMetafield("variant", id)}
                availablePaths={availablePaths}
              />

              {/* Action Buttons */}
              <div className="mt-4 space-y-2">
                <Button
                  className="w-full"
                  variant="default"
                  onClick={() =>
                    applyMappingMutation.mutate({
                      url,
                      mappings,
                      businessSlug,
                      productMetafields,
                      variantMetafields,
                      prices,
                    })
                  }
                  disabled={applyMappingMutation.isPending}
                >
                  {applyMappingMutation.isPending
                    ? "Processing..."
                    : "Preview Mapping"}
                </Button>

                <Button
                  className="w-full"
                  variant="secondary"
                  onClick={() =>
                    saveMappingMutation.mutate({
                      url,
                      mappings,
                      businessSlug,
                      productMetafields,
                      variantMetafields,
                      prices,
                    })
                  }
                  disabled={!previewData || saveMappingMutation.isPending}
                >
                  Save Mapping
                </Button>
              </div>
            </CardContent>
          </ScrollArea>
        </Card>

        {/* Right Panel - JSON Viewer */}
        <Card className="w-2/3">
          <CardHeader>
            <CardTitle>Data Structure</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[calc(100vh-140px)]">
              <ReactJson src={jsonData as object} />
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      {/* Preview Modal */}
      <Dialog open={isPreviewModalOpen} onOpenChange={setIsPreviewModalOpen}>
        <DialogContent className="max-h-[90vh] w-[90vw] max-w-4xl">
          <DialogHeader>
            <DialogTitle>Mapping Preview</DialogTitle>
          </DialogHeader>
          <ScrollArea className="mt-4">
            <ReactJson
              src={previewData ?? {}}
              theme="rjv-default"
              collapsed={1}
            />
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </div>
  );
}
