import {
  Table as BaseTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@dashboard/common/ui/table";
import { Checkbox } from "@dashboard/common/ui/checkbox";

import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { DeleteRecordDialog } from "./components/DeleteRecordDialog";
import { RecordActions } from "./components/RecordActions";
import { AddRecordDialog } from "./components/AddRecordDialog";
import { SearchBar } from "./components/SearchBar";
import { Button } from "../ui/button";

interface Column<Record extends RecordWithId> {
  header: string;
  accessor: keyof Record;
  headerClassName?: string;
  cellClassName?: string;
  render?: (value: Record[keyof Record], record: Record) => ReactNode;
}

export interface RecordWithId {
  id: string;
  enableDelete?: boolean;
  enableEdit?: boolean;
  enableContextActions?: Record<string, boolean>;
}

interface TableProps<Record extends RecordWithId> {
  title?: ReactNode;
  subtitle?: ReactNode;
  businessName?: string;
  records: Record[];
  totalCount?: number;
  columns: Column<Record>[];
  topBarActions?: { label: string; action: () => void }[];
  addRecordDialogContent?: (
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => ReactNode;
  initialAddRecordDialogOpen?: boolean;
  editRecordDialogContent?: (
    record: Record,
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => ReactNode;
  deleteRecords?: (ids: string[]) => void;
  headerActions?: { label: string; action: (ids: string[]) => void }[];
  contextActions?: { label: string; action: (ids: string[]) => void }[];
  recordName?: { singular: string; plural: string };
  noRecordsText?: string;
  search?: {
    setSearch: (value: string | undefined) => void;
    searchQuery: string | undefined;
  };
  customComponent?: ReactNode;
  actionLabels?: {
    edit?: string;
    delete?: string;
  };
}

export function Table<Record extends RecordWithId>({
  title,
  subtitle,
  businessName,
  records,
  totalCount,
  columns,
  topBarActions,
  addRecordDialogContent,
  initialAddRecordDialogOpen,
  editRecordDialogContent,
  deleteRecords,
  headerActions,
  contextActions,
  recordName = { singular: "Record", plural: "Records" },
  noRecordsText = "No records available",
  search,
  customComponent,
  actionLabels,
}: TableProps<Record>) {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const actionsEnabled = Boolean(deleteRecords ?? editRecordDialogContent);

  const handleSelectAll = (checked: boolean | string) => {
    if (checked) {
      setSelectedIds(records.map((record) => record.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelect = (id: string, checked: boolean | string) => {
    if (checked) {
      setSelectedIds((ids) => [...ids, id]);
    } else {
      setSelectedIds((ids) => ids.filter((selectedId) => selectedId !== id));
    }
  };

  return (
    <div className="flex flex-col pb-10">
      {/* Table top bar */}
      {title && (
        <div className="border-gray-light flex w-full flex-col border-b px-10 py-4">
          <div className="flex flex-row justify-between">
            <h1 className="text-xl font-medium text-black">
              {title}
              {businessName && (
                <span className="text-gray"> {`for ${businessName}`}</span>
              )}
            </h1>
            <div className="flex items-center gap-4">
              {topBarActions?.map(({ label, action }) => (
                <Button key={label} onClick={action} variant="secondary">
                  {label}
                </Button>
              ))}
              {addRecordDialogContent && (
                <AddRecordDialog
                  recordNameSingular={recordName.singular}
                  initialOpen={initialAddRecordDialogOpen}
                >
                  {addRecordDialogContent}
                </AddRecordDialog>
              )}
            </div>
          </div>
          {subtitle && <p className="mt-2 text-sm text-gray-600">{subtitle}</p>}
        </div>
      )}

      {customComponent}

      <div className="mx-auto flex w-full flex-col px-10">
        {/* Table search bar */}
        {/* TODO: Add search functionality */}
        {search && (
          <>
            <SearchBar search={search} />
            <div className="bg-gray-light h-[1px] w-full" />
          </>
        )}

        {/* Table tool bar */}
        <div className="flex h-14 flex-row items-center justify-start gap-x-5 pt-5">
          {selectedIds.length > 0 ? (
            <>
              <h2 className="text-lg text-black">
                {`${selectedIds.length} ${
                  selectedIds.length === 1
                    ? recordName.singular
                    : recordName.plural
                } selected`}
                <span className="text-gray">
                  {" "}
                  of {totalCount ?? records.length}
                </span>
              </h2>

              <div className="flex items-center gap-3">
                {deleteRecords && (
                  <DeleteRecordDialog
                    ids={selectedIds}
                    recordName={recordName}
                    setSelectedIds={setSelectedIds}
                    deleteRecords={deleteRecords}
                  />
                )}

                {headerActions?.map(({ label, action }) => (
                  <Button
                    key={label}
                    onClick={() => action(selectedIds)}
                    variant="secondary"
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            <h2 className="text-medium text-lg text-black">
              {`${records.length} of ${totalCount ?? records.length} ${
                (totalCount ?? records.length) === 1
                  ? recordName.singular
                  : recordName.plural
              }`}
            </h2>
          )}
        </div>

        {/* Table */}
        <BaseTable>
          <TableHeader>
            <TableRow className="h-12 hover:bg-transparent">
              <TableHead className="w-16 first:pl-0">
                <Checkbox
                  onCheckedChange={(checked) => handleSelectAll(checked)}
                />
              </TableHead>
              {columns.map((column) => (
                <TableHead
                  key={column.accessor as string}
                  className={`${column.headerClassName}`}
                >
                  {column.header}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {records.length === 0 ? (
            <TableBody>
              <TableRow className="hover:bg-transparent">
                <TableCell colSpan={columns.length + 2}>
                  <div className="flex items-center justify-center py-16">
                    {noRecordsText}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {records.map((record) => (
                <TableRow key={record.id} className="h-12 hover:bg-transparent">
                  <TableCell className="first:pl-0">
                    <Checkbox
                      checked={selectedIds.includes(record.id)}
                      onCheckedChange={(checked) =>
                        handleSelect(record.id, checked)
                      }
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.accessor as string}
                      className={column.cellClassName}
                    >
                      {column.render
                        ? column.render(record[column.accessor], record)
                        : (record[column.accessor] as ReactNode)}
                    </TableCell>
                  ))}
                  {actionsEnabled && (
                    <TableCell className="text-right">
                      <RecordActions
                        id={record.id}
                        record={record}
                        recordName={recordName}
                        setSelectedIds={setSelectedIds}
                        deleteRecords={deleteRecords}
                        editRecordDialogContent={editRecordDialogContent}
                        contextActions={contextActions}
                        actionLabels={actionLabels}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </BaseTable>
      </div>
    </div>
  );
}
