import { Route } from "..";
import { toast } from "sonner";

export function useDeleteMessageTopics() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation =
    trpc.dashboard.conversations.messageTopics.deleteMany.useMutation({
      onSuccess: async (_, variables) => {
        await trpcUtils.dashboard.conversations.messageTopics.getConfig.invalidate(
          {
            businessSlug,
          },
        );

        const { ids } = variables;
        const message =
          ids.length > 1
            ? "Message topics deleted successfully."
            : "Message topic deleted successfully.";

        toast.success(message);
      },
      onError: (error, variables) => {
        const { ids } = variables;
        const message =
          ids.length > 1
            ? "Failed to delete message topics."
            : "Failed to delete message topic.";

        toast.error(message);
        console.error(error);
      },
    });

  return {
    deleteMessageTopics: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids });
    },
  };
}
