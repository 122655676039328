import { Route } from "../..";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { ClipboardList } from "lucide-react";

export function TaskNamesFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [taskNameData] = trpc.dashboard.v2Bot.getTaskNames.useSuspenseQuery({
    organizationSlug,
  });

  const options = taskNameData.taskNames.map((taskName) => ({
    label: taskName,
    value: taskName,
  }));

  return (
    <CommandMultiSelect
      filterType="taskNames"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={ClipboardList}
    />
  );
}
