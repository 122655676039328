import { Tag } from "lucide-react";
import { Route } from "../../_conversationSidebar";
import { CommandFilter } from "./CommandFilter";

export function TagsFilter() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [tags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  const options = tags.map((tag) => ({
    label: tag.title,
    value: tag.id,
  }));

  return (
    <CommandFilter
      options={options}
      valueIcon={Tag}
      name="Tag"
      filterKey="tag"
    />
  );
}
