import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

export function useMarketId() {
  const { data } = useQuery({
    queryKey: ["getMarketId"],
    queryFn: async () => {
      const r = await fetch("https://be.green/webservices/vendors/octocom/dc", {
        credentials: "include",
      });

      if (!r.ok) {
        throw new Error("Failed to get market id");
      }

      const { delivery_country: marketId } = z
        .object({ delivery_country: z.string() })
        .parse(await r.json());

      console.log("Got market ID", marketId);

      return marketId;
    },
    enabled:
      new URL(window.location.href).hostname === "be.green" &&
      !new URL(window.location.href).pathname.endsWith("plant-doctor"),
  });

  return data;
}

export function useSuntouchedHack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const styleEl = document.createElement("style");
    styleEl.innerHTML = ".richpanel-micro { display: none !important; }";
    document.head.appendChild(styleEl);

    const removeWidget = () => {
      const elements = document.getElementsByClassName("richpanel-micro");
      if (elements.length > 0) {
        console.log("Removed Richpanel widget");
        elements[0].remove();
      }
    };

    // Check immediately
    removeWidget();

    const observer = new MutationObserver(removeWidget);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [enabled]);
}

export function useZendeskHack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const hideWidget = () => {
      const element = document.getElementById("launcher");
      if (element?.parentElement?.parentElement) {
        console.log("Hidden Zendesk widget");
        element.parentElement.parentElement.style.visibility = "hidden";
      }
    };

    // Check immediately
    hideWidget();

    const observer = new MutationObserver(hideWidget);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [enabled]);
}

export function useFurniture1Hack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const intervalId = setInterval(() => {
      const elements = document.querySelectorAll(
        'iframe[id^="chat-widget"], iframe[title="chat widget"]',
      );
      elements.forEach((el) => el.remove());
    }, 500);

    return () => clearInterval(intervalId);
  }, [enabled]);
}

export function useFDHack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const intervalId = setInterval(() => {
      const elements = document.querySelectorAll(
        'iframe[id^="chat-widget"], iframe[title="chat widget"], iframe[src*="freshdesk"], iframe[src*="fd-chat"], #freshworks-container, .freshworks-container',
      );
      elements.forEach((el) => el.remove());
    }, 500);

    return () => clearInterval(intervalId);
  }, [enabled]);
}

export function useDeleteChatWidgetsHack({
  isInSupportTimeWindow,
}: {
  isInSupportTimeWindow: boolean;
}) {
  const hostname = new URL(window.location.href).hostname;

  useSuntouchedHack({
    enabled: hostname === "suntouched.co",
  });

  useZendeskHack({
    enabled: hostname === "drinklemonkind.com",
  });

  useFurniture1Hack({
    enabled: hostname === "www.arredamento1.it" && isInSupportTimeWindow,
  });

  useFDHack({
    enabled: hostname.includes("varle.lt") && isInSupportTimeWindow,
  });
}
