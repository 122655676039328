import { z } from "zod";

export const variantExportSchema = z.object({
  title: z.string(),
  associatedThreatIds: z.array(z.string()),
  actionNames: z.array(z.string()),
  ignoredThreatIds: z.array(z.string()),
  requiredConditions: z.array(z.string()),
  botInstructions: z.string(),
  instantHandoff: z.boolean(),
});
