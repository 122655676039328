import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Truck } from "lucide-react";

export function OrderCouriersFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.fulfillmentCourier.map((courier) => ({
    label: courier,
    value: courier,
  }));

  return (
    <CommandMultiSelect
      filterType="orderCouriers"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Truck}
    />
  );
}
