import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Globe } from "lucide-react";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderRecipientCountriesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.recipientCountry.map((country) => ({
    label: country,
    value: country,
  }));

  return (
    <CommandMultiSelect
      filterType="orderRecipientCountries"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Globe}
    />
  );
}
