import { Route } from "..";
import { periodToRange } from "../-utils/periodToRange";

export function useConversationsByBusiness() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { filters } = Route.useSearch();

  const range = periodToRange(filters.period);

  const [conversationsByBusiness] =
    trpc.dashboard.metrics.getConversationsByBusiness.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  const businessTotalCounts = new Map<string, number>();

  conversationsByBusiness.forEach((item) => {
    item.businesses.forEach((business) => {
      const currentTotal = businessTotalCounts.get(business.id) ?? 0;
      businessTotalCounts.set(business.id, currentTotal + business.count);
    });
  });

  const sortedBusinessIds = Array.from(businessTotalCounts.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([id]) => id);

  const sortedData = conversationsByBusiness.map((item) => ({
    ...item,
    businesses: [...item.businesses].sort((a, b) => {
      const aIndex = sortedBusinessIds.indexOf(a.id);
      const bIndex = sortedBusinessIds.indexOf(b.id);
      return aIndex - bIndex;
    }),
  }));

  return sortedData;
}
