import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Building2 } from "lucide-react";

export function OrderBillingCitiesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.billingCity.map((city) => ({
    label: city,
    value: city,
  }));

  return (
    <CommandMultiSelect
      filterType="orderBillingCities"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Building2}
    />
  );
}
