import { Route } from "..";
import { toast } from "sonner";

export function useExportBotRules() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [botRules] = trpc.dashboard.bots.getRules.useSuspenseQuery({
    businessSlug,
  });

  return {
    exportBotRules: (ids: string[]) => {
      const selectedRules = botRules.filter((rule) => ids.includes(rule.id));

      const data = selectedRules.map((rule) => ({
        title: rule.title,
        rule: rule.rule,
      }));

      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `octocom-bot-rules-${businessSlug}-${timestamp}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success("Bot rules exported successfully");
    },
  };
}
