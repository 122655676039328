import { useState } from "react";
import { BarChart, type DataSeries } from "./BarChart";
import { ChartPie } from "./ChartPie";
import { Breakdown } from "./Breakdown";
import { Button } from "@dashboard/common/ui/button";
import { cn } from "@dashboard/utils/ui";

type ChartType = "bar" | "pieWithBreakdown";

interface ChartSwitcherProps {
  // Bar chart props
  barChartSeries?: DataSeries[];
  barChartValue?: number;
  barChartYTitle?: string;
  barChartStacked?: boolean;
  barChartShowLegend?: boolean;
  barChartHeight?: number;

  // Pie chart props
  pieChartData?: {
    x: string;
    y: number;
    style: {
      color: string;
    };
  }[];

  // Breakdown props
  breakdownData?: {
    name: string;
    percentage: number;
    tooltipContent?: string;
    style: {
      color: string;
    };
  }[];
  breakdownTitle?: string;

  // Common props
  defaultChartType?: ChartType;
}

export function ChartSwitcher({
  // Bar chart props
  barChartSeries = [],
  barChartValue = 0,
  barChartYTitle = "",
  barChartStacked = false,
  barChartShowLegend = false,
  barChartHeight = 280,

  // Pie chart props
  pieChartData = [],

  // Breakdown props
  breakdownData = [],
  breakdownTitle = "Breakdown",

  // Common props
  defaultChartType = "bar",
}: ChartSwitcherProps) {
  const [chartType, setChartType] = useState<ChartType>(defaultChartType);

  return (
    <div className="w-full">
      <div className="mb-4 flex justify-end space-x-2">
        <Button
          type="button"
          variant="secondary"
          onClick={() => setChartType("bar")}
          className={cn(chartType === "bar" && "bg-blue-light")}
          size="sm"
        >
          Bar Chart
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={() => setChartType("pieWithBreakdown")}
          className={cn(chartType === "pieWithBreakdown" && "bg-blue-light")}
          size="sm"
        >
          Breakdown
        </Button>
      </div>

      {chartType === "bar" ? (
        <BarChart
          series={barChartSeries}
          value={barChartValue}
          yTitle={barChartYTitle}
          stacked={barChartStacked}
          showLegend={barChartShowLegend}
          height={barChartHeight}
        />
      ) : (
        <div className="flex flex-row items-center gap-x-16">
          <ChartPie
            data={pieChartData}
            showLegend={false} // Never show legend when we have breakdown
          />
          <Breakdown title={breakdownTitle} data={breakdownData} />
        </div>
      )}
    </div>
  );
}
