import { MultiSelect } from "./general/MultiSelect";
import { Network } from "lucide-react";

export function TypeFilter() {
  const options = [
    { label: "Customer", value: "customer" },
    { label: "External", value: "external" },
  ];

  return <MultiSelect filterType="type" options={options} icon={Network} />;
}
