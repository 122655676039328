interface EmptyStateProps {
  message?: string;
}

export function EmptyState({ message = "No data available" }: EmptyStateProps) {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="text-gray-500">{message}</div>
    </div>
  );
}
