import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";
import { Link } from "@tanstack/react-router";
import { CircleHelp } from "lucide-react";
import { ElementType, ReactNode } from "react";

export function Flow({
  organizationSlug,
  businessSlug,
  flowName,
  flowSlug,
  flowOption,
  icon: Icon,
  description,
}: {
  organizationSlug: string;
  businessSlug: string;
  flowName: string;
  flowSlug:
    | "orderTracking"
    | "orderCancelation"
    | "shippingAddressUpdate"
    | "contactDetailsUpdate"
    | "orderItemsUpdate"
    | "handoff";
  flowOption: string;
  icon: ElementType;
  description: ReactNode;
}) {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          search={{
            open: flowSlug,
          }}
          className="flex items-center justify-between gap-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
        >
          <div className="flex items-center gap-4">
            <div className="bg-primary/10 flex shrink-0 items-center justify-center rounded-lg p-3">
              <Icon className="text-primary h-5 w-5" />
            </div>
            <div className="flex flex-col">
              <span className="font-medium text-gray-900">{flowName}</span>
              <p className="text-sm text-gray-600">{flowOption}</p>
            </div>
          </div>
          <CircleHelp className="h-6 w-6 text-gray-600" strokeWidth={1.5} />
        </Link>
      </HoverCardTrigger>
      <HoverCardContent
        className="w-96 overflow-hidden rounded-lg p-0"
        side="right"
      >
        {description}
      </HoverCardContent>
    </HoverCard>
  );
}
