import { format } from "date-fns";
import { CheckCircle2, Loader2, AlertCircle, CircleCheck } from "lucide-react";
import { useGetProductSyncStatus } from "../-hooks/useGetProductSyncStatus";
import { Progress } from "@dashboard/common/ui/progress";
import { Separator } from "@dashboard/common/ui/separator";
import { cn } from "@dashboard/utils/ui";

export function ProductKnowledgeError() {
  return (
    <div className="rounded-lg border bg-white p-4">
      <h3 className="text-lg font-medium text-gray-900">Product Knowledge</h3>
      <div className="mt-2">
        <div className="flex items-center gap-2">
          <AlertCircle className="h-5 w-5 text-red-500" />
          <span className="text-sm text-gray-600">
            There was an issue with the product sync
          </span>
        </div>
        <p className="mt-2 text-sm text-gray-600">
          Please reach out to{" "}
          <a
            href="mailto:info@octocom.ai"
            className="font-medium text-blue-600 hover:underline"
          >
            info@octocom.ai
          </a>{" "}
          for assistance.
        </p>
      </div>
    </div>
  );
}

export function ProductKnowledgeProcessing() {
  return (
    <div className="rounded-lg border bg-white p-4">
      <h3 className="text-lg font-medium text-gray-900">Product Knowledge</h3>
      <div className="mt-2">
        <div className="flex items-center gap-2">
          <Loader2 className="text-primary h-5 w-5 animate-spin" />
          <span className="text-sm text-gray-600">
            Preparing to sync your products
          </span>
        </div>
        <p className="mt-2 text-sm text-gray-600">
          We're getting everything ready to sync your product data. This should
          only take a moment.
        </p>
      </div>
    </div>
  );
}

interface ProductKnowledgeCompleteProps {
  syncedCount: number;
  syncedAt: string;
}

export function ProductKnowledgeComplete({
  syncedCount,
  syncedAt,
}: ProductKnowledgeCompleteProps) {
  return (
    <div className="rounded-lg border bg-white p-4">
      <h3 className="text-lg font-medium text-gray-900">Product Knowledge</h3>
      <div className="mt-2">
        <div className="flex items-center gap-2">
          <CheckCircle2 className="h-5 w-5 text-green-500" />
          <span className="text-sm text-gray-600">
            {syncedCount} products processed • Last updated{" "}
            {format(new Date(syncedAt), "MMM d, yyyy h:mm a")}
          </span>
        </div>
        <p className="mt-2 text-sm text-gray-600">
          Your product knowledge is up to date and ready to use. The AI can now
          provide accurate answers about your products.
        </p>
      </div>
    </div>
  );
}

export function ProductKnowledge() {
  const productStatus = useGetProductSyncStatus();

  if (!productStatus) {
    return <ProductKnowledgeError />;
  }

  const {
    syncedAt,
    totalCount,
    downloadedCount,
    syncedCount,
    indexedCount,
    embeddedCount,
  } = productStatus;

  const isComplete =
    syncedAt !== null &&
    embeddedCount === syncedCount &&
    indexedCount === syncedCount;

  if (isComplete) {
    return (
      <ProductKnowledgeComplete syncedCount={syncedCount} syncedAt={syncedAt} />
    );
  }

  const isSyncing = totalCount !== null && downloadedCount !== null;

  if (!isSyncing) {
    return <ProductKnowledgeProcessing />;
  }

  const getCurrentStage = () => {
    if (syncedAt === null && downloadedCount < totalCount) {
      return {
        id: 1,
        title: "Downloading product data",
        description:
          "Downloading product data from your WooCommerce store, this may take a few minutes",
        current: downloadedCount,
        total: totalCount,
      };
    }
    if (indexedCount < syncedCount) {
      return {
        id: 2,
        title: "Processing product information",
        description:
          "Processing product information, you can already ask questions about your products, but answers may not be as accurate",
        current: indexedCount,
        total: syncedCount,
      };
    }
    if (embeddedCount < syncedCount) {
      return {
        id: 3,
        title: "Training AI on product knowledge",
        description:
          "Training the AI on product knowledge, this process can take a long time, feel free to leave this page",
        current: embeddedCount,
        total: syncedCount,
      };
    }
    return null;
  };

  return (
    <>
      {(() => {
        const currentStage = getCurrentStage();
        if (!currentStage) return <ProductKnowledgeError />;

        const percentage = Math.round(
          (currentStage.current / currentStage.total) * 100,
        );

        const activeStageClass = "text-primary font-medium";
        const completedStageClass = "text-green-500";

        return (
          <>
            <div className="rounded-lg border bg-white p-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">
                  Product Knowledge
                </h3>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-2">
                    {currentStage.id > 1 && (
                      <CircleCheck className="h-5 w-5 text-green-500" />
                    )}
                    <span
                      className={cn(
                        "text-sm text-gray-600",
                        currentStage.id === 1 && activeStageClass,
                        currentStage.id > 1 && completedStageClass,
                      )}
                    >
                      Download
                    </span>
                  </div>
                  <Separator orientation="vertical" className="h-4" />
                  <div className="flex items-center gap-2">
                    {currentStage.id > 2 && (
                      <CircleCheck className="h-5 w-5 text-green-500" />
                    )}
                    <span
                      className={cn(
                        "text-sm text-gray-600",
                        currentStage.id === 2 && activeStageClass,
                        currentStage.id > 2 && completedStageClass,
                      )}
                    >
                      Process
                    </span>
                  </div>
                  <Separator orientation="vertical" className="h-4" />
                  <span
                    className={cn(
                      "text-sm text-gray-600",
                      currentStage.id === 3 && activeStageClass,
                    )}
                  >
                    Train
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <div className="mt-4 space-y-2">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">
                      {currentStage.current}/{currentStage.total} ({percentage}
                      %)
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-600">
                        {currentStage.title}
                      </span>
                      <Loader2 className="text-primary h-5 w-5 animate-spin" />
                    </div>
                  </div>
                  <Progress value={percentage} />
                </div>
                <p className="mt-4 text-sm text-gray-600">
                  {currentStage.description}
                </p>
              </div>
            </div>
          </>
        );
      })()}
    </>
  );
}
