import { Route } from "..";
import { toast } from "sonner";

interface ApplyMappingResponse {
  success: boolean;
  error?: unknown;
  preview?: object;
}

export function useApplyMapping(onPreview?: (preview: object) => void) {
  const { trpc } = Route.useRouteContext();

  const applyMappingMutation =
    trpc.dashboard.xmlParser.applyMapping.useMutation<ApplyMappingResponse>({
      onError: (error) => {
        console.error("Failed to apply mapping:", error);
      },
      onSuccess: (data) => {
        const { success, error, preview } = data as ApplyMappingResponse;
        if (success) {
          toast.success("Mapping applied successfully");
          if (preview && onPreview) {
            onPreview(preview);
          }
        } else {
          toast.error("Failed to apply mapping");
          console.error("Failed to apply mapping:", error);
        }
      },
    });

  return { applyMappingMutation };
}
