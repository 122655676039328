import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RuleForm, ruleFormSchema } from "../-utils/ruleFormSchema";
import { toast } from "sonner";
import { RuleFormBody } from "./RuleFormBody";
import { useCreateRule } from "../-hooks/useCreateRule";

interface CreateRuleFormProps {
  setOpen: (open: boolean) => void;
}

export function CreateRuleForm({ setOpen }: CreateRuleFormProps) {
  const form = useForm<RuleForm>({
    resolver: zodResolver(ruleFormSchema),
    defaultValues: {
      type: "email",
      value: "",
      action: "handoff",
      isActive: true,
      organization: true,
    },
  });

  const { createRule } = useCreateRule({
    setOpen,
  });

  const onSubmit = (data: RuleForm) => {
    createRule(data);
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to create rule");
  };

  return <RuleFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
