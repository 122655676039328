export const BASE_COLORS = [
  "hsl(217, 65%, 45%)", // Royal blue
  "hsl(157, 65%, 40%)", // Emerald green
  "hsl(347, 75%, 45%)", // Crimson
  "hsl(277, 65%, 45%)", // Purple
  "hsl(187, 65%, 40%)", // Teal
  "hsl(37, 90%, 45%)", // Golden orange
  "hsl(17, 85%, 45%)", // Burnt orange
  "hsl(357, 75%, 50%)", // Bright red
  "hsl(247, 65%, 50%)", // Indigo
  "hsl(127, 50%, 45%)", // Forest green
  "hsl(297, 45%, 45%)", // Muted purple
  "hsl(77, 55%, 45%)", // Olive
  "hsl(327, 55%, 45%)", // Rose
  "hsl(97, 45%, 45%)", // Sage
  "hsl(207, 45%, 55%)", // Light blue
];

export const OTHER_SERIES_COLOR = "hsl(210, 5%, 60%)";

export const CHART_COLORS = {
  BLUE: "hsl(217, 65%, 45%)",
  GREEN: "hsl(157, 65%, 40%)",
};

export const getDynamicColor = (
  index: number,
  saturation = 65,
  lightness = 45,
): string => {
  return `hsl(${(217 + index * 137.5) % 360}, ${saturation}%, ${lightness}%)`;
};

export const getSeriesColor = (index: number): string => {
  if (index < BASE_COLORS.length) {
    return BASE_COLORS[index];
  }

  return getDynamicColor(index);
};
