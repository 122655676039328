import { Route } from "../..";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Tag } from "lucide-react";

export function TagsFilter() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [tags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  const options = tags.map((tag) => ({
    label: tag.title,
    value: tag.id,
  }));

  return (
    <CommandMultiSelect
      filterType="tags"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Tag}
    />
  );
}
