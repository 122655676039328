import { Route } from "..";
import { toast } from "sonner";

export const useEnableCustomMessageTopics = () => {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const createDefaultConfigMutation =
    trpc.dashboard.conversations.messageTopics.createDefaultConfig.useMutation({
      onSuccess: () => {
        window.location.reload();
      },
    });

  const updateMutation = trpc.dashboard.business.update.useMutation({
    onSuccess: () => {
      toast.success("Custom message topics enabled");

      createDefaultConfigMutation.mutate({ businessSlug });
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to enable custom message topics");
    },
  });

  return {
    enableCustomMessageTopics: () => {
      updateMutation.mutate({
        businessSlug,
        data: { useCustomMessageTopics: true },
      });
    },
  };
};
