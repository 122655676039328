import { z } from "zod";

export const externalTicketSystemSchema = z.union([
  z.literal("zendesk"),
  z.literal("gorgias"),
  z.literal("hubspot"),
  z.literal("richpanel"),
  z.literal("console"),
  z.literal("email"),
  z.literal("dixa"),
  z.literal("freshdesk"),
  z.literal("reamaze"),
]);

export const externalTicketSystems = externalTicketSystemSchema.options.map(
  (system) => system.value,
);
