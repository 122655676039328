//const paymentUrl200 = "https://buy.stripe.com/28og2Q1wKb7dgj66pd";
const paymentUrl100 = "https://buy.stripe.com/fZe8Ao6R46QXeaY7ti";
const testPaymentUrl = "https://buy.stripe.com/test_8wMcNW89Q3dGfRKeUW";

export const getStripePaymentLink = ({
  organizationId,
  isTest,
}: {
  organizationId: string;
  isTest: boolean;
}) => {
  const url = new URL(isTest ? testPaymentUrl : paymentUrl100);
  url.searchParams.set("client_reference_id", organizationId);

  return url.toString();
};
