import { cn } from "@dashboard/utils/ui";
import { Link } from "@tanstack/react-router";
import { Tooltip } from "../ui/tooltip";

interface NestedRecord {
  [key: string]: string | null | undefined | NestedRecord;
}

interface SidebarButtonProps {
  text: string;
  to: string;
  active?: boolean;
  count?: number;
  search?: NestedRecord;
  icon?: React.ElementType;
  className?: string;
}

function CountTooltip({ count }: { count: number }) {
  return (
    <Tooltip
      content={count.toLocaleString()}
      trigger={<span className="text-gray text-xs">9,999+</span>}
      side="right"
      sideOffset={20}
    />
  );
}

export function SidebarButton({
  text,
  to,
  active,
  count,
  search,
  icon: Icon,
  className,
}: SidebarButtonProps) {
  return (
    <Link
      className={cn(
        "flex w-full items-center justify-between rounded-lg px-[12px] py-2 text-sm text-black hover:bg-white",
        active
          ? "bg-white font-semibold hover:!bg-white"
          : "bg-grey-lightest hover:!bg-gray-light",
        className,
      )}
      activeProps={{ className: "bg-white font-semibold" }}
      inactiveProps={{ className: "bg-grey-lightest hover:!bg-gray-light" }}
      to={to}
      search={search}
    >
      <div className="flex w-full items-center">
        {Icon && <Icon className="mr-2 h-4 w-4 text-black" />}
        {text}
      </div>
      {count !== undefined && (
        <span className="text-gray text-xs">
          {count < 10000 ? (
            count.toLocaleString()
          ) : (
            <CountTooltip count={count} />
          )}
        </span>
      )}
    </Link>
  );
}
