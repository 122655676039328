import { Link } from "@tanstack/react-router";
import { Ban, PackageSearch, Truck, UserRound } from "lucide-react";

interface AvailableActionsProps {
  organizationSlug: string;
  businessSlug: string;
  flowConfig: {
    orderCancelation: string;
    shippingAddressUpdate: string;
    contactDetailsUpdate: string;
    orderItemsUpdate: string;
    orderTracking: string;
  };
}

const getFlowStatusLabel = (status: string) => {
  switch (status) {
    case "notAllowed":
      return "Not allowed";
    case "handOff":
      return "Hand off to resolve manually";
    case "holdAndHandOff":
      return "Put on hold and hand off to resolve manually";
    case "automatic":
      return "Process automatically";
    case "automaticNoEmail":
      return "Process automatically";
    case "disabled":
      return "Disabled";
    case "resist":
      return "Resist";
    case "handoffWithoutQuestions":
      return "Hand off without questions";
    default:
      return "";
  }
};

export function AvailableActions({
  organizationSlug,
  businessSlug,
  flowConfig,
}: AvailableActionsProps) {
  return (
    <>
      <h3 className="text-lg font-medium text-gray-900">
        Available Mock Actions
      </h3>
      <p className="mb-4 mt-1 text-gray-600">
        The bot has access to the following actions:
      </p>
      <div className="grid grid-cols-3 gap-4">
        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          search={{
            open: "orderTracking",
          }}
          className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
        >
          <div className="flex items-center gap-2">
            <PackageSearch className="h-5 w-5 text-gray-500" />
            <span className="font-medium text-gray-900">Order Tracking</span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {getFlowStatusLabel(flowConfig.orderTracking)}
          </p>
        </Link>

        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          search={{
            open: "orderCancelation",
          }}
          className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
        >
          <div className="flex items-center gap-2">
            <Ban className="h-5 w-5 text-gray-500" />
            <span className="font-medium text-gray-900">
              Order Cancellations
            </span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {getFlowStatusLabel(flowConfig.orderCancelation)}
          </p>
        </Link>

        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          search={{
            open: "shippingAddressUpdate",
          }}
          className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
        >
          <div className="flex items-center gap-2">
            <Truck className="h-5 w-5 text-gray-500" />
            <span className="font-medium text-gray-900">
              Shipping Address Updates
            </span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {getFlowStatusLabel(flowConfig.shippingAddressUpdate)}
          </p>
        </Link>

        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          search={{
            open: "contactDetailsUpdate",
          }}
          className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
        >
          <div className="flex items-center gap-2">
            <UserRound className="h-5 w-5 text-gray-500" />
            <span className="font-medium text-gray-900">
              Contact Details Updates
            </span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {getFlowStatusLabel(flowConfig.contactDetailsUpdate)}
          </p>
        </Link>

        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          search={{
            open: "orderItemsUpdate",
          }}
          className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-colors hover:bg-gray-50"
        >
          <div className="flex items-center gap-2">
            <PackageSearch className="h-5 w-5 text-gray-500" />
            <span className="font-medium text-gray-900">
              Order Item Updates
            </span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {getFlowStatusLabel(flowConfig.orderItemsUpdate)}
          </p>
        </Link>
      </div>
    </>
  );
}
