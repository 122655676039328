import { Route } from "..";

export function useOrderMetadataValues() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [data] =
    trpc.dashboard.conversations.orderMetadata.getOrgFieldValues.useSuspenseQuery(
      {
        organizationSlug,
      },
    );

  return data;
}
