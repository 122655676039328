import { Route } from "..";
import { toast } from "sonner";

export function useSaveMapping() {
  const { trpc } = Route.useRouteContext();

  const saveMappingMutation = trpc.dashboard.xmlParser.saveMapping.useMutation({
    onSuccess: () => {
      toast.success("Mapping saved successfully");
    },
    onError: () => {
      toast.error("Failed to save mapping");
    },
  });

  return { saveMappingMutation };
}
