import { z } from "zod";

export const messageTopicFormSchema = z.object({
  topics: z
    .string({
      invalid_type_error: "Topics is required",
    })
    .min(1, "Topics is required")
    .transform((val) => val.trim()),
});

export type MessageTopicForm = z.infer<typeof messageTopicFormSchema>;
