import { z } from "zod";

export const channelSchema = z.union([
  z.literal("email"),
  z.literal("web"),
  z.literal("instagram"),
  z.literal("instagramComment"),
  z.literal("messenger"),
  z.literal("facebookComment"),
  z.literal("zoom-transcript"),
  z.literal("google-reviews"),
  z.literal("phone-call"),
  z.literal("ebay"),
  z.literal("trustpilot-reviews"),
]);
