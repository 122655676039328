import { z } from "zod";
import { simpleFlowOptionSchema } from "@be/modules/bots/workflows/simpleFlows/simpleFlows.schema";

export const flowConfigFormSchema = z.object({
  orderCancelation: simpleFlowOptionSchema,
  shippingAddressUpdate: simpleFlowOptionSchema,
  contactDetailsUpdate: simpleFlowOptionSchema,
  orderItemsUpdate: simpleFlowOptionSchema,
  orderTracking: simpleFlowOptionSchema,
  handoff: simpleFlowOptionSchema,
  returnRequests: simpleFlowOptionSchema,
  returnStatus: simpleFlowOptionSchema,
});

export type FlowConfigForm = z.infer<typeof flowConfigFormSchema>;
