import { Route } from "../index";
import { periodToRange } from "../-utils/periodToRange";

export function useHandoffTopicsData() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { filters } = Route.useSearch();
  const range = periodToRange(filters.period);

  const [handoffTopicsData] =
    trpc.dashboard.metrics.getHandoffTopicsCounts.useSuspenseQuery({
      organizationSlug,
      filters: {
        ...filters,
        from: range.from,
        to: range.to,
        totalOrderAmountFrom: filters.totalOrderAmountRange?.from,
        totalOrderAmountTo: filters.totalOrderAmountRange?.to,
      },
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  return handoffTopicsData;
}
