export const TOPIC_ASSIGNMENT_CUTOFF_DATE = new Date(
  "2025-02-21T17:00:00.000Z",
);

export const DEFAULT_MESSAGE_TOPICS = [
  // Initial Interaction
  "Pre-Sale Inquiries",

  // Orders / Shipping
  "Order Modification / Address Change",
  "Order Cancellation",
  "Wrong Items Received / Missing Items",
  "Order Details / Shipping Status / Tracking",
  "Shipping Complaints / Issues",

  // Products
  "Product Questions",
  "Quality Complaint",

  // Returns & Refunds
  "Return / Exchange",
  "Refund Status",

  // Payment
  "Payment Issue",
  "Payment Method",
  "Discounts / Promotions / Gift Cards",

  // Customer Service Escalation
  "Request to talk to human/supervisor",
];
