import { Package } from "lucide-react";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { useOrderMetadataValues } from "../../-hooks/useOrderMetadataValues";

export function OrderStatusesFilter() {
  const orderMetadataValues = useOrderMetadataValues();

  const options = orderMetadataValues.status.map((status) => ({
    label: status,
    value: status,
  }));

  return (
    <CommandMultiSelect
      filterType="orderStatuses"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Package}
    />
  );
}
