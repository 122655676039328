import { createFileRoute, redirect } from "@tanstack/react-router";
import { Select } from "@dashboard/common/ui/select";
import { z } from "zod";
import { Accordion } from "@dashboard/common/ui/accordion";
import { AccordionItemComponent } from "./-components/AccordionItemComponent";
import { TestYourBot } from "./-components/TestYourBot";
import { AddKnowledge } from "./-components/SetupSteps/AddKnowledge";
import { StyleBot } from "./-components/SetupSteps/StyleBot";
import { SetupChannels } from "./-components/SetupSteps/SetupChannels";
import {
  BookText,
  Bot,
  BotMessageSquare,
  Mail,
  Split,
  Users,
} from "lucide-react";
import { MessageCircle } from "lucide-react";
import { AddTeamMembers } from "./-components/SetupSteps/AddTeamMembers";
import { ImplementFlows } from "./-components/SetupSteps/ImplementFlows";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@dashboard/common/ui/tabs";
import { App } from "@chatbot/App";
import { LaunchBot } from "./-components/SetupSteps/LaunchBot";
import { useCompleteSetup } from "./-hooks/useCompleteSetup";
import { Button } from "@dashboard/common/ui/button";
import { IntroDialog } from "./-components/IntroDialog";
import { SetupEmail } from "./-components/SetupSteps/SetupEmail";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/setup/",
)({
  validateSearch: z.object({
    businessSlug: z.string().optional(),
    openIntro: z.boolean().optional(),
  }),
  beforeLoad: ({
    context: { businesses },
    search: { businessSlug },
    params: { organizationSlug },
  }) => {
    if (!businessSlug) {
      const business = businesses[0];
      return { business };
    } else {
      const business = businesses.find(
        (business) => business.slug === businessSlug,
      );

      if (!business) {
        throw redirect({
          to: `/organization/$organizationSlug/setup`,
          params: { organizationSlug },
          search: { businessSlug: undefined },
        });
      }

      return { business };
    }
  },
  loader: async ({
    context: { trpcClientUtils, businesses },
    params: { organizationSlug },
  }) => {
    await Promise.all(
      businesses.map((business) =>
        Promise.all([
          trpcClientUtils.dashboard.webChat.getConfig.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.business.getOrThrow.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.integrations.getInstalledIntegrations.ensureData(
            {
              businessSlug: business.slug,
            },
          ),
          trpcClientUtils.dashboard.bots.getKnowledgeSummary.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.products.getConfig.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.products.getCount.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.products.getProductSyncStatus.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.flows.getSimpleFlowConfiguration.ensureData(
            {
              businessSlug: business.slug,
            },
          ),
        ]),
      ),
    );

    await trpcClientUtils.dashboard.externalTickets.getConfiguration.ensureData(
      {
        organizationSlug,
      },
    );
  },
  component: SetupPage,
});

function SetupPage() {
  const { trpc, business, businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const navigate = Route.useNavigate();

  const { complete: completeSetup } = useCompleteSetup();

  const [webChatConfig] = trpc.dashboard.webChat.getConfig.useSuspenseQuery({
    businessSlug: business.slug,
  });

  const [externalTicketConfig] =
    trpc.dashboard.externalTickets.getConfiguration.useSuspenseQuery({
      organizationSlug,
    });

  const [{ completedOnboardingSteps }] =
    trpc.dashboard.business.getOrThrow.useSuspenseQuery({
      businessSlug: business.slug,
    });

  const options = businesses.map((business) => ({
    label: business.internalName,
    value: business.slug,
  }));

  const setBusiness = (value: string) => {
    void navigate({
      search: (prev) => ({ ...prev, businessSlug: value }),
    });
  };

  const includeEmailSetupStep = !externalTicketConfig;

  const setupSteps = [
    {
      value: "add-knowledge",
      title: "Add Knowledge Base",
      subtitle:
        "Train your bot with your business information and documentation for more accurate responses.",
      icon: BookText,
      component: (
        <AddKnowledge
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("knowledge")}
        />
      ),
      completed: completedOnboardingSteps.includes("knowledge"),
    },
    {
      value: "flows",
      title: "Configure Post-Sale Customer Support Flows",
      subtitle:
        "Configure automated workflows to handle common post-sale customer requests.",
      icon: Split,
      component: (
        <ImplementFlows
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("flows")}
        />
      ),
      completed: completedOnboardingSteps.includes("flows"),
    },
    ...(includeEmailSetupStep
      ? [
          {
            value: "email",
            title: "Add Email Integration",
            subtitle:
              "Enable email communications for your bot and team members.",
            icon: Mail,
            component: (
              <SetupEmail
                businessSlug={business.slug}
                organizationSlug={organizationSlug}
                completed={completedOnboardingSteps.includes("email")}
              />
            ),
            completed: completedOnboardingSteps.includes("email"),
          },
        ]
      : []),
    {
      value: "style-bot",
      title: "Style Your Chatbot",
      subtitle:
        "Customize your chatbot's appearance to match your brand and business.",
      icon: Bot,
      component: (
        <StyleBot
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("style")}
        />
      ),
      completed: completedOnboardingSteps.includes("style"),
    },
    {
      value: "team",
      title: "Add Team Members",
      subtitle:
        "Invite and manage team members to collaborate on customer support and bot training.",
      icon: Users,
      component: (
        <AddTeamMembers
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("team")}
        />
      ),
      completed: completedOnboardingSteps.includes("team"),
    },
    {
      value: "launch",
      title: "Launch Your Chatbot",
      subtitle:
        "Deploy your chatbot and make it live for your customers to start getting automated support 24/7.",
      icon: BotMessageSquare,
      component: (
        <LaunchBot completed={completedOnboardingSteps.includes("launch")} />
      ),
      completed: completedOnboardingSteps.includes("launch"),
    },
    {
      value: "channels",
      title: "Set up Additional Channels",
      subtitle:
        "Connect your bot to various platforms where your customers can interact with it.",
      icon: MessageCircle,
      component: (
        <SetupChannels
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("channels")}
          includeEmailChannel={!includeEmailSetupStep}
        />
      ),
      completed: completedOnboardingSteps.includes("channels"),
    },
  ];

  const allStepsComplete = setupSteps.every((step) => step.completed);
  const someStepsComplete = setupSteps.some((step) => step.completed);

  const getButtonText = () => {
    if (allStepsComplete) return "Complete Setup";

    if (someStepsComplete) return "Skip the Rest of Setup";

    return "Skip Setup";
  };

  return (
    <div className="flex h-full w-full flex-col">
      <div className="border-b-gray-light flex w-full items-center justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium">Welcome to Octocom</h1>
        {businesses.length > 1 && (
          <Select
            variant="dropdown"
            value={business.slug}
            onChange={setBusiness}
            options={options}
            placeholder="Select a business"
          />
        )}
      </div>
      <Tabs
        defaultValue="test"
        className="flex w-full flex-1 flex-col overflow-hidden"
      >
        <TabsList
          variant="underline"
          className="border-b-gray-light h-11 w-full shrink-0 justify-start gap-4 border-b px-10"
        >
          <TabsTrigger variant="underline" value="test">
            Test Your Bot
          </TabsTrigger>
          <TabsTrigger variant="underline" value="setup">
            Setup
          </TabsTrigger>
        </TabsList>
        <TabsContent value="test" className="mt-0 flex-1 overflow-hidden">
          <div className="flex h-full w-full overflow-hidden">
            <div className="overflow-auto px-10 py-6">
              <TestYourBot
                organizationSlug={organizationSlug}
                businessSlug={business.slug}
              />
            </div>
            <div className="border-l-gray-light flex h-full w-[50%] items-center justify-center overflow-auto border-l p-4">
              <div className="h-full w-full" id="octocom-container">
                <App presetConfigId={webChatConfig.id} containerMode={true} />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="setup" className="mt-0 flex-1 overflow-auto">
          <div className="flex flex-col">
            <Accordion
              type="single"
              collapsible
              className="flex w-full flex-col gap-4 px-10 py-6"
            >
              {setupSteps.map((step) => (
                <AccordionItemComponent
                  key={step.value}
                  value={step.value}
                  title={step.title}
                  subtitle={step.subtitle}
                  icon={step.icon}
                  completed={step.completed}
                >
                  {step.component}
                </AccordionItemComponent>
              ))}
            </Accordion>
            <div className="flex justify-end px-10 pb-6">
              <Button onClick={() => completeSetup()}>{getButtonText()}</Button>
            </div>
          </div>
        </TabsContent>
      </Tabs>
      <IntroDialog />
    </div>
  );
}
