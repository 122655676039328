import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { UseFormReturn } from "react-hook-form";
import { Input } from "@dashboard/common/ui/input";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { RuleForm } from "../-utils/ruleFormSchema";
import { Switch } from "@dashboard/common/ui/switch";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import { Separator } from "@dashboard/common/ui/separator";
import { Route } from "..";

interface RuleFormBodyProps {
  form: UseFormReturn<RuleForm>;
  onSubmit: (data: RuleForm) => void;
  onError: (errors: unknown) => void;
}

export function RuleFormBody({ form, onSubmit, onError }: RuleFormBodyProps) {
  const { businesses } = Route.useRouteContext();

  const hasMultipleBusinesses = businesses.length > 1;

  const type = form.watch("type");

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="email">Email</SelectItem>
                  <SelectItem value="domain">Domain</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Value</FormLabel>
              <FormControl>
                <Input
                  placeholder={
                    type === "email"
                      ? "Enter email address"
                      : "Enter domain (e.g. example.com)"
                  }
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="action"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Action</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select an action" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="handoff">
                    Hand off (Conversation will be created in an open, handed
                    off state)
                  </SelectItem>
                  <SelectItem value="spam">
                    Mark as spam (Conversation will be created in a closed,
                    handed off state and marked as spam)
                  </SelectItem>
                  <SelectItem value="ignore">
                    Ignore (Conversation will not be created)
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <Separator />

        <FormField
          control={form.control}
          name="isActive"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center justify-start gap-4 space-y-0">
                <FormLabel>Active</FormLabel>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </div>
            </FormItem>
          )}
        />

        {hasMultipleBusinesses && (
          <>
            <Separator />

            <FormField
              control={form.control}
              name="organization"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Apply to organization</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <FormDescription>
                    Enabling this will apply the rule to all businesses in the
                    organization.
                  </FormDescription>
                </FormItem>
              )}
            />
          </>
        )}

        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
