import { Route } from "..";

export function useCustomerConversations() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const { data, isLoading } =
    trpc.dashboard.conversations.getCustomerConversations.useQuery({
      organizationSlug,
      publicId,
    });

  return {
    customerConversations: data?.conversations ?? [],
    isLoading,
  };
}
