import { Button } from "@dashboard/common/ui/button";
import { cn } from "@dashboard/utils/ui";
import {
  AlarmClock,
  ArrowUpRight,
  BriefcaseBusiness,
  Building,
  Building2,
  CircleSlash,
  Globe,
  Globe2,
  Languages,
  LucideIcon,
  MapPin,
  MessageSquareDashed,
  MessageSquareText,
  Network,
  Plus,
  Tag,
  User,
  UserPen,
  Map,
  Package,
  PackageX,
  DollarSign,
  Truck,
  PackageCheck,
  ClipboardList,
} from "lucide-react";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@dashboard/common/ui/command";
import { FilterType, useFilters } from "../../../-context/FiltersContext";

interface FilterItem {
  type: string;
  label: string;
  icon: LucideIcon;
}

interface FilterGroup {
  name: string;
  filters: FilterItem[];
}

const filterGroups: FilterGroup[] = [
  {
    name: "Conversation Status",
    filters: [
      { type: "isHandedOff", label: "Handed Off", icon: ArrowUpRight },
      { type: "isClosed", label: "Status", icon: CircleSlash },
      { type: "isSnoozed", label: "Snoozed", icon: AlarmClock },
    ],
  },
  {
    name: "Conversation Details",
    filters: [
      { type: "businessIds", label: "Businesses", icon: BriefcaseBusiness },
      { type: "channels", label: "Channels", icon: MessageSquareDashed },
      { type: "languages", label: "Languages", icon: Languages },
      { type: "assignees", label: "Assignees", icon: User },
      { type: "tags", label: "Tags", icon: Tag },
      { type: "topics", label: "Topics", icon: MessageSquareText },
      { type: "senders", label: "Senders", icon: UserPen },
      { type: "type", label: "Type", icon: Network },
      { type: "taskNames", label: "Task Names", icon: ClipboardList },
    ],
  },
  {
    name: "Order Details",
    filters: [
      { type: "orderStatuses", label: "Order Statuses", icon: Package },
      { type: "orderCanceled", label: "Order Canceled", icon: PackageX },
      {
        type: "totalOrderAmountRange",
        label: "Total Order Amount",
        icon: DollarSign,
      },
      { type: "orderShippingMethods", label: "Shipping Methods", icon: Truck },
    ],
  },
  {
    name: "Order Recipient Information",
    filters: [
      {
        type: "orderRecipientCities",
        label: "Recipient Cities",
        icon: Building,
      },
      { type: "orderRecipientStates", label: "Recipient States", icon: Map },
      {
        type: "orderRecipientCountries",
        label: "Recipient Countries",
        icon: Globe,
      },
    ],
  },
  {
    name: "Order Billing Information",
    filters: [
      { type: "orderBillingCities", label: "Billing Cities", icon: Building2 },
      { type: "orderBillingStates", label: "Billing States", icon: MapPin },
      {
        type: "orderBillingCountries",
        label: "Billing Countries",
        icon: Globe2,
      },
    ],
  },
  {
    name: "Order Fulfillment Information",
    filters: [
      {
        type: "orderFulfillmentStatuses",
        label: "Fulfillment Status",
        icon: PackageCheck,
      },
      { type: "orderCouriers", label: "Couriers", icon: Truck },
    ],
  },
];

export function AddFilter() {
  const [open, setOpen] = useState(false);
  const { filters, addFilter, setLastAddedFilter } = useFilters();

  const handleSelectFilter = (type: FilterType) => {
    addFilter({ type });
    setLastAddedFilter(type);
    setOpen(false);
  };

  const isFilterAdded = (type: string) => {
    return filters.some((filter) => filter.type === type);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          className={cn(
            "ring-offset-background placeholder:text-muted-foreground",
            "h-9 whitespace-nowrap bg-transparent px-3 py-2 text-sm focus:outline-none",
            "disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
            "flex w-auto select-none items-center justify-between gap-1 rounded-xl font-medium shadow-none",
            "hover:text-blue hover:bg-blue-light",
          )}
        >
          <Plus className="h-4 w-4" />
          Add Filter
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0" align="start" side="bottom">
        <Command>
          <CommandInput placeholder="Search filters..." />
          <CommandList>
            <CommandEmpty>No filters found.</CommandEmpty>

            {filterGroups.map((group, groupIndex) => (
              <div key={group.name}>
                {groupIndex > 0 && <CommandSeparator />}
                <CommandGroup heading={group.name}>
                  {group.filters.map((filter) => (
                    <CommandItem
                      key={filter.type}
                      value={`${group.name} ${filter.label}`}
                      onSelect={() =>
                        handleSelectFilter(filter.type as FilterType)
                      }
                      disabled={isFilterAdded(filter.type)}
                    >
                      <filter.icon className="mr-2 h-3 w-3 shrink-0" />
                      {filter.label}
                      {isFilterAdded(filter.type) && (
                        <span className="text-muted-foreground ml-auto text-xs">
                          Added
                        </span>
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </div>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
