import { Select } from "@dashboard/common/ui/select";
import { Route } from "..";
import { useState } from "react";

export function BusinessSelect() {
  const [business, setBusiness] = useState("all");
  const { businesses } = Route.useRouteContext();

  const options = businesses
    .map((business) => ({
      label: business.internalName,
      value: business.slug,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const groups = [
    {
      title: "Businesses",
      options: [
        {
          label: "All Businesses",
          value: "all",
        },
      ],
    },
    {
      title: "",
      options,
    },
  ];

  const search = Route.useSearch();

  const navigate = Route.useNavigate();
  const setSearch = (value: string | undefined) => {
    setBusiness(value ?? "all");

    if (value === "all") value = undefined;
    void navigate({ search: { ...search, businessSlug: value } });
  };

  return (
    <>
      {businesses.length > 1 && (
        <>
          <Select
            variant="dropdown"
            value={business}
            defaultValue={options[0].value}
            onChange={(value) => setSearch(value)}
            groups={groups}
          />
        </>
      )}
    </>
  );
}
