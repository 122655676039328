import { MessageTopicForm } from "./messageTopicFormSchema";

export function formatPayload(form: MessageTopicForm) {
  return {
    topics: form.topics
      .split(",")
      .map((topic) => topic.trim())
      .filter((topic) => topic !== ""),
  };
}
