import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { Box } from "lucide-react";
import {
  Form,
  FormControl,
  FormMessage,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
} from "@dashboard/common/ui/form";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import { refundDataSchema } from "@be/modules/orders/orders.schemas";
import { RefundData, TrackedOrder } from "@be/modules/orders/orders.types";
import { Separator } from "@dashboard/common/ui/separator";
import { Textarea } from "@dashboard/common/ui/textarea";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { formatCurrency } from "@dashboard/utils/currency";
import { OrderActionButton } from "./OrderActionButton";
import { useOrderRefund } from "../../../-hooks/orders/useOrderRefund";

export function OrderRefundForm({ order }: { order: TrackedOrder }) {
  const [open, setOpen] = useState(false);

  const lastTransaction = order.transactions?.at(-1);

  const defaultValues = {
    amount: order.netPayment?.amount ?? "0",
    staffNote: "",
    lineItems: [],
    notifyCustomer: true,
    parentTransactionId: lastTransaction?.externalId,
    parentTransactionGateway: lastTransaction?.gateway,
  };

  const form = useForm<RefundData>({
    resolver: zodResolver(refundDataSchema),
    defaultValues,
  });

  const items = order.fulfillments.flatMap(
    (fulfillment) => fulfillment.items ?? [],
  );

  const { refundOrder } = useOrderRefund();

  function onSubmit(values: RefundData) {
    refundOrder({
      externalOrderId: order.externalId,
      refundData: values,
      onSuccess: () => {
        setOpen(false);
      },
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <OrderActionButton label="Refund" />
      <DialogContent className="w-[1000px] max-w-full p-0 text-sm">
        <DialogHeader className="border-b p-4">
          <DialogTitle>Refund Order</DialogTitle>
        </DialogHeader>
        {items.length > 0 && (
          <div className="max-w-[999px] border-b px-6 pb-4 pt-0">
            <div className="flex max-h-[300px] flex-col gap-2 overflow-y-auto">
              {items.map((item, index) => (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex max-w-[60%] items-center gap-4">
                      <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-xl">
                        {item.image ? (
                          <img
                            src={item.image}
                            alt={item.name}
                            className="h-full w-full object-cover"
                            onError={(e) => {
                              e.currentTarget.style.display = "none";
                              const nextElement = e.currentTarget
                                .nextElementSibling as HTMLElement;
                              nextElement.style.display = "block";
                            }}
                          />
                        ) : null}
                        <Box
                          className="h-full w-full"
                          strokeWidth={1}
                          style={{
                            display: item.image ? "none" : "block",
                          }}
                        />
                      </div>
                      <TruncatedTooltip text={item.name} />
                    </div>

                    <div className="flex items-center gap-4">
                      <div>
                        {item.discountedUnitPrice &&
                        parseFloat(item.discountedUnitPrice.amount) <
                          parseFloat(item.originalUnitPrice?.amount ?? "0") ? (
                          <>
                            <span className="text-gray-400 line-through">
                              {formatCurrency({
                                amount: parseFloat(
                                  item.originalUnitPrice?.amount ?? "0",
                                ),
                                currency:
                                  item.originalUnitPrice?.currencyCode ?? "USD",
                              })}
                            </span>{" "}
                            <span>
                              {formatCurrency({
                                amount: parseFloat(
                                  item.discountedUnitPrice.amount,
                                ),
                                currency: item.discountedUnitPrice.currencyCode,
                              })}
                            </span>
                          </>
                        ) : (
                          formatCurrency({
                            amount: parseFloat(
                              item.originalUnitPrice?.amount ?? "0",
                            ),
                            currency:
                              item.originalUnitPrice?.currencyCode ?? "USD",
                          })
                        )}{" "}
                        x {item.quantity}
                      </div>
                      <div className="text-right">
                        <p className="font-medium">
                          Total:{" "}
                          {formatCurrency({
                            amount: parseFloat(item.totalPrice?.amount ?? "0"),
                            currency: item.totalPrice?.currencyCode ?? "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  {index < items.length - 1 && <Separator className="my-2" />}
                </>
              ))}
            </div>
          </div>
        )}
        <Form {...form}>
          <form onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}>
            <div className="flex">
              <div className="flex w-[60%] flex-col gap-2 px-6 pb-4 pt-2">
                <FormField
                  control={form.control}
                  name="notifyCustomer"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>Notify customer</FormLabel>
                        <FormDescription>
                          Send a notification to the customer about this refund.
                        </FormDescription>
                      </div>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Amount to refund</FormLabel>
                      <FormControl>
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          className="border-input placeholder:text-muted-foreground focus-visible:ring-ring block h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50"
                          max={order.netPayment?.amount ?? "0"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="staffNote"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Staff note</FormLabel>
                      <FormControl>
                        {/* @ts-expect-error this works, but type errors need to be fixed */}
                        <Textarea
                          placeholder="Enter any additional notes here (optional)"
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="w-[40%] px-6 pb-4 pt-2">
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Subtotal:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.subtotalCost?.amount ?? "0"),
                        currency: order.subtotalCost?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Discounts:</span>
                    <span>
                      -
                      {formatCurrency({
                        amount: parseFloat(order.discounts?.amount ?? "0"),
                        currency: order.discounts?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Taxes:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.taxes?.amount ?? "0"),
                        currency: order.taxes?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Shipping:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.shippingCost?.amount ?? "0"),
                        currency: order.shippingCost?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <Separator className="my-2" />
                  <div className="flex justify-between font-semibold">
                    <span>Total:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.totalCost?.amount ?? "0"),
                        currency: order.totalCost?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Total paid:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.totalReceived?.amount ?? "0"),
                        currency: order.totalReceived?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Total refunded:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.totalRefunded?.amount ?? "0"),
                        currency: order.totalRefunded?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between font-semibold">
                    <span>Available to refund:</span>
                    <span>
                      {formatCurrency({
                        amount: parseFloat(order.netPayment?.amount ?? "0"),
                        currency: order.netPayment?.currencyCode ?? "USD",
                      })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <DialogFooter className="flex items-center justify-between border-t p-4 py-3">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setOpen(false)}
              >
                Keep order
              </Button>
              <Button type="submit" variant="destructive">
                Refund order
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
