import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { useCustomerConversations } from "../../-hooks/useCustomerConversations.hook";
import { Separator } from "@dashboard/common/ui/separator";
import { RecentConversationLink } from "./RecentConversationLink";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { ConversationPreview } from "./ConversationPreview";
import { MergeButton } from "./MergeButton";
import { MergeDialog } from "./MergeDialog";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import { useConversation } from "../../-hooks/useConversation.hook";

export function RecentConversations() {
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const { customerConversations, isLoading } = useCustomerConversations();
  const conversation = useConversation();

  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);

  const handleMergeClick = (conversationId: string) => {
    setSelectedConversationId(conversationId);
    setMergeDialogOpen(true);
  };

  const selectedConversation = customerConversations.find(
    (conversation) => conversation.publicId === selectedConversationId,
  );

  if (!conversation.customer) {
    return null;
  }

  return (
    <>
      {selectedConversation && (
        <MergeDialog
          open={mergeDialogOpen}
          onOpenChange={setMergeDialogOpen}
          sourceConversation={selectedConversation}
        />
      )}

      <Accordion type="multiple" className="w-full">
        <AccordionItem value="item-0" className="border-none !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Recent Conversations{" "}
            {!isLoading && `(${customerConversations.length})`}
          </AccordionTrigger>
          <AccordionContent className="pb-0">
            {isLoading ? (
              <div className="flex items-center justify-center p-4 pb-0">
                <Loader2
                  className="h-8 w-8 animate-spin text-gray-500"
                  strokeWidth={1.5}
                />
                <span className="ml-2 text-sm text-gray-500">
                  Loading conversations...
                </span>
              </div>
            ) : customerConversations.length === 0 ? (
              <div className="px-7 pt-4 text-sm text-gray-500">
                No other conversations found for this customer.
              </div>
            ) : (
              <div className="mt-5 flex max-h-96 flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4">
                {customerConversations.map((conversation, index) => (
                  <div key={conversation.publicId}>
                    <Tooltip
                      triggerAsChild
                      side="left"
                      className="my-10 px-0 py-0"
                      trigger={
                        <div>
                          <RecentConversationLink
                            publicId={conversation.publicId}
                            isClosed={conversation.isClosed}
                            createdAt={new Date(conversation.createdAt)}
                            channel={conversation.latestChannel}
                            orderId={conversation.orderId}
                          />
                        </div>
                      }
                      content={
                        <div className="relative">
                          <ConversationPreview
                            conversation={conversation}
                            mergingEnabled={true}
                            mergeButton={
                              <MergeButton
                                onClick={() =>
                                  handleMergeClick(conversation.publicId)
                                }
                              />
                            }
                          />
                        </div>
                      }
                    />
                    {index !== customerConversations.length - 1 && (
                      <Separator className="bg-offWhite mx-3 w-auto" />
                    )}
                  </div>
                ))}
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}
