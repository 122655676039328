import { createFileRoute, Link } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import {
  Split,
  Package,
  Database,
  Loader2,
  MessageCircle,
  ShoppingCart,
  Truck,
} from "lucide-react";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { cn } from "@dashboard/utils/ui";
import { useBookDemo } from "../-hooks/useBookDemo";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/features/",
)({
  component: FeaturesPage,
});

function FeatureItem({
  icon: Icon,
  title,
  description,
}: {
  icon: React.ElementType;
  title: string;
  description: string;
}) {
  return (
    <div className="flex items-center">
      <Icon className="text-blue-dark mr-3 h-5 w-5 shrink-0" />
      <div>
        <span className="text-sm">{title}</span>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
    </div>
  );
}

function OptionCard({
  title,
  features,
  action,
  subtitle,
}: {
  title: string | React.ReactNode;
  features: {
    icon: React.ElementType;
    title: string;
    description: string;
  }[];
  action: React.ReactNode;
  subtitle?: string;
}) {
  return (
    <div className="flex w-full flex-col rounded-lg border px-10 pb-6 pt-6">
      <h3 className="font-medium">{title}</h3>
      {subtitle && <p className="mt-2 text-sm text-gray-500">{subtitle}</p>}
      <div className="my-4 flex flex-col gap-3 pb-5">
        {features.map((feature, index) => (
          <FeatureItem key={index} {...feature} />
        ))}
      </div>
      <div className="mt-auto">{action}</div>
    </div>
  );
}

function FeaturesPage() {
  const { bookDemo, isLoading } = useBookDemo();
  const { organizationSlug } = Route.useParams();

  const selfOnboardingFeatures = [
    {
      icon: MessageCircle,
      title: "AI General Customer Support",
      description:
        "Instantly answer customer questions about polices, shipping, pricing, and more",
    },
    {
      icon: ShoppingCart,
      title: "Smart Product Recommendations",
      description:
        "AI-powered product suggestions for catalogs up to 100 products to boost sales and customer satisfaction",
    },
    {
      icon: Truck,
      title: "AI Order Management Support",
      description:
        "Streamlined self-service options for customers to track orders, update shipping info, and manage cancellations",
    },
  ];

  const consultationFeatures = [
    {
      icon: Package,
      title: "Custom AI Training and Configuration",
      description:
        "Personalized AI model training and setup optimized for your specific business needs and use cases",
    },
    {
      icon: Database,
      title: "Large Catalog Product Recommendations",
      description:
        "AI recommendations engine designed for product catalogs exceeding 100 products, with customizable recommendation strategies",
    },
    {
      icon: Split,
      title: "Klaviyo Integration",
      description:
        "Whenever customers interact with Octocom, their interests are detected and translated into tags in Klaviyo - enabling more targeted campaigns",
    },
  ];

  return (
    <div className="text-blue-dark flex h-full w-full flex-col items-center justify-center">
      <div className="max-w-4xl">
        <h1 className="pb-3 text-center text-2xl font-medium leading-8">
          You're All Set! Choose Your Path to AI-Powered Customer Service
        </h1>
        <h2 className="text-center leading-6 text-gray-800">
          Select the option that best fits your business needs. Start with our
          essential features for immediate impact, or schedule a consultation to
          unlock advanced capabilities with personalized guidance from our
          experts.
        </h2>
      </div>

      <div className="flex w-full flex-col gap-4 py-8">
        <div className="mx-auto flex max-w-[1120PX] flex-row gap-x-5">
          <OptionCard
            title="Get Started Today with Essential Features"
            subtitle="Start improving your customer service immediately with our core AI features - perfect for small Shopify stores."
            features={selfOnboardingFeatures}
            action={
              <Link
                to="/organization/$organizationSlug/onboarding/plan"
                params={{ organizationSlug }}
              >
                <Button
                  variant="outline"
                  className="border-blue-dark h-10 w-full justify-center font-semibold"
                >
                  Get started with self onboarding
                </Button>
              </Link>
            }
          />

          <OptionCard
            title={
              <div className="flex items-center gap-2">
                Need more advanced features?
                <span className="rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-700">
                  Recommended for you
                </span>
              </div>
            }
            subtitle="If you need custom workflows, large catalog support, or specialized AI training, let's discuss your requirements in a free consultation."
            features={consultationFeatures}
            action={
              <Button
                variant="default"
                className={cn("h-10 w-full justify-center font-semibold", {
                  "border-blue-dark": !isLoading,
                })}
                onClick={bookDemo}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center gap-2">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>Booking your demo...</span>
                  </div>
                ) : (
                  "Book a free consultation call"
                )}
              </Button>
            }
          />
        </div>
        <p className="mx-auto mt-2 text-xs text-gray-400">
          This is just a glimpse of what we can do. There are more features
          waiting for you beyond onboarding.
        </p>
        <div className="mx-auto pr-2">
          <OnboardingNavigation
            backPath="/organization/$organizationSlug/onboarding/knowledge"
            isNextEnabled={true}
          />
        </div>
      </div>
    </div>
  );
}
