import { Route } from "../..";
import { CommandMultiSelect } from "./general/CommandMultiSelect";
import { Languages } from "lucide-react";
export function LanguagesFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [languages] =
    trpc.dashboard.conversations.getLanguages.useSuspenseQuery({
      organizationSlug,
    });

  const options = languages.map((language) => ({
    label: language.name,
    value: language.code,
  }));

  return (
    <CommandMultiSelect
      filterType="languages"
      optionGroups={[
        {
          options,
        },
      ]}
      icon={Languages}
    />
  );
}
