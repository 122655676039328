import { X } from "lucide-react";
import { useBanner } from "../../../-hooks/useBanner";
import { AddFilter } from "./filters/general/AddFilter";
import { CurrentFilters } from "./filters/general/CurrentFilters";
import { RangeSelect } from "./filters/RangeSelect";
import { Button } from "@dashboard/common/ui/button";
import { useFilters } from "../-context/FiltersContext";
import { cn } from "@dashboard/utils/ui";
import { Route } from "..";

export function Topbar() {
  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();
  const { filters, clearFilters } = useFilters();
  const bannerHeightNum = parseInt(bannerHeight);

  const { organization } = Route.useRouteContext();
  const mockMetricsEnabled = organization.enableMockMetricsData;

  return (
    <div
      style={{
        paddingTop: `${120 + (bannerVisible ? bannerHeightNum : 0)}px`,
      }}
    >
      <header
        className="border-gray-light fixed top-0 z-10 w-full border-b bg-white"
        style={{
          top: bannerVisible ? bannerHeight : "0px",
        }}
      >
        <nav className="flex flex-col">
          {/* Page Title */}
          <div className="flex h-16 w-full flex-row items-center px-10">
            <h1 className="text-xl font-medium text-black">Usage & Metrics</h1>
          </div>

          {/* Filters section */}
          <div className="border-gray-light w-full border-t">
            <div className="hide-scrollbar flex h-14 w-full flex-row items-center gap-2 overflow-x-auto px-10">
              <RangeSelect />
              {!mockMetricsEnabled && (
                <>
                  <CurrentFilters />
                  <AddFilter />
                  {filters.length > 0 && (
                    <Button
                      variant="secondary"
                      onClick={clearFilters}
                      className={cn(
                        "ring-offset-background placeholder:text-muted-foreground",
                        "h-9 whitespace-nowrap bg-transparent px-3 py-2 text-sm focus:outline-none",
                        "disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
                        "flex w-auto select-none items-center justify-between gap-1 rounded-xl font-medium shadow-none",
                        "hover:bg-red-50 hover:text-red-500",
                      )}
                    >
                      <X className="h-4 w-4" />
                      Clear Filters
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
