import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import { EmptyState } from "./EmptyState";
import { useRef } from "react";
import { OTHER_SERIES_COLOR } from "../-utils/colors";

interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

function CustomTick({ x, y, payload }: CustomTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={8}
        fontSize={10}
        fontFamily="Arial"
        fontWeight="light"
        textAnchor="middle"
        fill="#6a6a75"
      >
        {payload?.value}
      </text>
    </g>
  );
}

export interface DataSeries {
  data: {
    x: string;
    y: number;
  }[];
  name: string;
  style: {
    color: string;
    barSize: number;
  };
}

interface ChartProps {
  value: number;
  yTitle: string;
  series: DataSeries[];
  stacked?: boolean;
  showLegend?: boolean;
  height?: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    value?: number;
    name?: string;
    color?: string;
  }[];
  label?: string;
  yTitle?: string;
  stacked?: boolean;
}

function CustomTooltip({
  active,
  payload,
  label,
  stacked,
  yTitle,
}: CustomTooltipProps) {
  if (active && payload?.length) {
    const total = stacked
      ? payload.reduce((sum, series) => sum + (series.value ?? 0), 0)
      : 0;

    const filteredPayload = payload
      .filter((series) => series.value !== 0)
      .sort((a, b) => (b.value ?? 0) - (a.value ?? 0));

    return (
      <div className="custom-tooltip rounded-md border border-gray-300 bg-white px-3 py-2.5 shadow-sm">
        {filteredPayload.map((series, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              className="h-3 w-3 rounded-sm"
              style={{ backgroundColor: series.color }}
            />
            <p className="text-gray-700">
              {`${series.value} ${stacked ? series.name : yTitle}`}
            </p>
          </div>
        ))}
        {stacked && (
          <p className="mt-1 border-t border-gray-200 pt-1 text-gray-700">
            Total: {total}
          </p>
        )}
        <p className="text-gray-700">{label ?? ""}</p>
      </div>
    );
  }
  return null;
}

interface CustomLegendProps {
  series: DataSeries[];
}

function CustomLegend({ series }: CustomLegendProps) {
  return (
    <div className="mt-4 flex flex-wrap justify-center gap-4">
      {series.map((s, index) => (
        <div key={index} className="flex items-center">
          <div
            className="mr-2 h-3 w-3 rounded-sm"
            style={{ backgroundColor: s.style.color }}
          />
          <span className="text-sm text-gray-700">{s.name}</span>
        </div>
      ))}
    </div>
  );
}

export function BarChart({
  series,
  yTitle,
  value,
  stacked = false,
  showLegend = false,
  height = 280,
}: ChartProps) {
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const hasData = series.some((s) => s.data.length > 0);
  if (!hasData) {
    return (
      <div className="w-full">
        <div className="mt-4 flex flex-row items-center">
          <span className="text-2xl text-black">
            {value} {yTitle}
          </span>
        </div>
        <div className="mt-10" style={{ height: `${height}px` }}>
          <EmptyState />
        </div>
      </div>
    );
  }

  const sortedSeries = stacked
    ? [...series].sort((a, b) => {
        const aFirstValue = a.data[0]?.y ?? 0;
        const bFirstValue = b.data[0]?.y ?? 0;
        return bFirstValue - aFirstValue;
      })
    : series;

  const seriesWithTotals = sortedSeries.map((s) => ({
    ...s,
    total: s.data.reduce((sum, point) => sum + point.y, 0),
  }));

  const sortedByTotal = [...seriesWithTotals].sort((a, b) => b.total - a.total);
  const topSeries = sortedByTotal.slice(0, 15);
  const otherSeries = sortedByTotal.slice(15);

  const existingOtherSeries = topSeries.find((s) => s.name === "Other");
  const finalSeries =
    otherSeries.length > 0
      ? [
          ...topSeries.filter((s) => s.name !== "Other"),
          {
            name: "Other",
            data: topSeries[0].data.map((item) => ({
              x: item.x,
              y:
                otherSeries.reduce((sum, series) => {
                  const matchingPoint = series.data.find((d) => d.x === item.x);
                  return sum + (matchingPoint?.y ?? 0);
                }, 0) +
                (existingOtherSeries?.data.find((d) => d.x === item.x)?.y ?? 0),
            })),
            style: {
              color: OTHER_SERIES_COLOR,
              barSize: 20,
            },
          },
        ]
      : topSeries;

  const combinedData = finalSeries[0].data.map((item) => {
    const dataPoint: Record<string, string | number> = {
      x: format(new Date(item.x), "EEE, MMM d"), // Format date for x-axis with day of week
    };
    finalSeries.forEach((s) => {
      const matchingPoint = s.data.find((d) => d.x === item.x);
      dataPoint[s.name] = matchingPoint?.y ?? 0;
    });
    return dataPoint;
  });

  const maxValue = Math.max(
    ...finalSeries.flatMap((s) => s.data.map((d) => d.y)),
  );

  return (
    <div className="w-full">
      <div className="mt-4 flex flex-row items-center">
        <span className="text-2xl text-black">
          {value} {yTitle}
        </span>
      </div>
      <div className="mt-10" ref={chartContainerRef}>
        <ResponsiveContainer width="100%" height={height} aspect={undefined}>
          <RechartsBarChart
            data={combinedData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
            barGap={0}
          >
            <CartesianGrid
              stroke="#F2F2F2"
              horizontal={true}
              vertical={false}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={[0, maxValue]}
              tick={{
                dx: -15,
                style: {
                  fontFamily: "Arial",
                  fontSize: 11,
                  fontWeight: "light",
                },
              }}
            />
            <XAxis
              dataKey="x"
              axisLine={false}
              tickLine={false}
              tick={<CustomTick />}
            />
            <Tooltip<number, string>
              content={(props) => (
                <CustomTooltip {...props} yTitle={yTitle} stacked={stacked} />
              )}
              cursor={{ fill: "transparent" }}
            />

            {finalSeries.map((s, index) => (
              <Bar
                key={index}
                dataKey={s.name}
                fill={s.style.color}
                barSize={s.style.barSize}
                name={s.name}
                stackId={stacked ? "stack" : undefined}
              />
            ))}
          </RechartsBarChart>
        </ResponsiveContainer>
        {showLegend && <CustomLegend series={finalSeries} />}
      </div>
    </div>
  );
}
